import React, { useRef, useState } from "react";
import Logo from "../assets/images/logopng.png";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { ContainerBox } from "../shared/ContainerBox";
import { Button } from "primereact/button";
import ShareIcon from "../assets/icons/share.png";
import QRCodeImg from "../assets/icons/qrcode.png";
import ShareLink from "../assets/icons/sharelink.png";
import { Dialog } from "primereact/dialog";
import { useDispatch, useSelector } from "react-redux";
import Constants from "../services/constant";
import { QRCodeCanvas } from "qrcode.react";

export default function WeddingTemplateTopbar() {
    const { invitationId } = useParams();
    let location = useLocation();
    const { getHostWebsite } = useSelector((state) => state?.hostWebsiteData);
    const [shareVisible, setShareVisible] = useState(false);
    const qrCodeRef = useRef(null);
    const websiteTemplateUrl =
        // "https://plantheknot.com/#/invitation/"
        Constants.FRONTEND_URL
            ? `${Constants.FRONTEND_URL}invitation/` +
              getHostWebsite?._id +
              `?name=${getHostWebsite?.name ? getHostWebsite?.name : ""}&partnerName=${getHostWebsite ? getHostWebsite?.partnerName : ""}`
            : "https://plantheknot.com/#/invitation/" +
              getHostWebsite?._id +
              `?name=${getHostWebsite?.name ? getHostWebsite?.name : ""}&partnerName=${getHostWebsite ? getHostWebsite?.partnerName : ""}`;

    const copyWebsiteLink = () => {
        navigator.clipboard.writeText(websiteTemplateUrl);
    };

    const handleQRDownload = () => {
        const canvas = document.querySelector("#QRCodeSVG");
        if (!canvas) throw new Error("<canvas> not found in the DOM");

        const pngUrl = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream");
        const downloadLink = document.createElement("a");
        downloadLink.href = pngUrl;
        downloadLink.download = "QR code.png";
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    };

    return (
        <>
            {!invitationId && (
                <div className={`topbar_container relative z - 3`}>
                    <ContainerBox>
                        <div className=" pb-3 pt-4 grid  p-0 m-0 align-items-center justify-content-between">
                            <div className="col-12 md:col-3 p-0 m-0 lg:w-3  ">
                                <Link to="/" className="no-underline">
                                    <img src={Logo} alt="" width={149} height={38}></img>
                                </Link>
                            </div>
                            {/* <input type="checkbox" name="menuToggle" id="menuToggle" hidden />
                            <label htmlFor="menuToggle">
                                <img
                                    src={hamburgerIcon}
                                    height={24}
                                    width={24}
                                    alt="HamburgerIcon"
                                />
                            </label> */}
                            <div className={`col-12 md:col-7  ${location.pathname === "/" ? "home-nav" : "other-nav"} `}>
                                {/* <div className="grid  align-items-center justify-content-end text-gray-light "> */}
                                <div className=" topbar-button flex-column md:flex-row flex gap-3 justify-content-between md:justify-content-end">
                                    <div className="w-full md:w-auto">
                                        <a href={websiteTemplateUrl} className="w-full" target="_black" focus="true">
                                            <Button
                                                className="bg-white w-full border-main text-main-color text-base font-medium"
                                                label="View as Guest"
                                                icon="pi pi-eye"
                                            />
                                        </a>
                                    </div>
                                    <div className="w-full md:w-auto ">
                                        <Button onClick={() => setShareVisible(true)} className="bg-main w-full">
                                            <img src={ShareIcon} width={24} height={24} alt="" />{" "}
                                            <cod className="ml-2 text-base font-medium text-white">Share</cod>
                                        </Button>
                                    </div>
                                    {/* </div> */}
                                </div>
                            </div>
                        </div>
                        <div hidden>
                            <QRCodeCanvas id="QRCodeSVG" value={websiteTemplateUrl} size={200} ref={qrCodeRef} />
                        </div>
                    </ContainerBox>
                    <Dialog
                        visible={shareVisible}
                        style={{ width: "max-content" }}
                        dismissableMask={true}
                        onHide={() => setShareVisible(false)}
                        draggable={false}
                        closable={false}
                    >
                        <div className="flex gap-3 flex-wrap sharePopupWebTemplateMain">
                            <div className="sharePopupWebTemplate" onClick={handleQRDownload}>
                                <img className="align-items-center" src={QRCodeImg} width={50} height={50} alt="" />
                                <p className="sharePopupWebTemplateText">Download QR Code</p>
                            </div>
                            <div className="sharePopupWebTemplate" onClick={copyWebsiteLink}>
                                <img className="align-items-center" src={ShareLink} width={50} height={50} alt="" />
                                <p className="text-center">
                                    <span className="sharePopupWebTemplateText">Copy Link</span>
                                </p>
                            </div>
                        </div>
                    </Dialog>
                    {/* <Dialog visible={qrCodeVisible} dismissableMask={true} onHide={() => setQrCodeVisible(false)} draggable={false} closable={false}>
                        <div className="align-items-center flex justify-content-center qrCodeMain">
                            <QRCodeCanvas id="QRCodeSVG" value={websiteTemplateUrl} size={200} ref={qrCodeRef} />
                            <p className="text-center sharePopupWebTemplateText cursor-pointer">Download</p>
                        </div>
                    </Dialog> */}
                </div>
            )}
        </>
    );
}
