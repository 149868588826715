import React from "react";
import { IndexLayout } from "../../layout";
import { ContainerBox } from "../../shared/ContainerBox";
import BannerImg from "../../assets/images/workbanner.png";
import { ForVendor } from "./ForVendor";
import { ForHost } from "./ForHost";

export const HowItsWork = () => {
  return (
    <>
      <div
        className="how-its-work-banner flex"
        style={{ backgroundImage: `url(${BannerImg})` }}
      >
        <ContainerBox
          containerType="container lg:w-7 "
          titleClass="text-white text-center font-44 merriweather font-normal mb-5"
          descriptionclass="text-white text-center text-base font-normal"
          title="How It Works"
          description="Unlock a world of free wedding planning tools designed to simplify your journey, including a budget planner, seating chart, guest list manager, a customizable wedding website, and a convenient RSVP feature so your guests can confirm attendance. Join the thriving community of couples who've embraced PlanTheKnot to effortlessly discover and connect with their dream wedding suppliers, all under one roof, making your wedding planning journey more accessible than ever.
          "
        />
      </div>
      <ForVendor />
      <ForHost />
    </>
  );
};
