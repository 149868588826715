import React, { useEffect, useState } from "react";
import { CardComponent } from "../../components/cards/card";
import {
  GoogleMap,
  MarkerF,
  useJsApiLoader,
  DirectionsRenderer,
} from "@react-google-maps/api";

export default function Location({ vendorData }) {
  const initialMarker = [
    {
      position: {
        lat: 28.625485,
        lng: 79.821091,
      },
      label: { color: "white", text: "P1" },
      draggable: true,
    },
  ];
  const [activeInfoWindow, setActiveInfoWindow] = useState(null);
  const [marker, setMarker] = useState(initialMarker);
  const [directionResponse, setDirectionResponse] = useState(null);
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [address, setAddress] = useState();

  const geocodeAddress = async () => {
    try {
      const geocodingEndpoint = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
        address
      )}&key=AIzaSyBFcB2k7pELe8ljicqg3dbVOv-N8J5oWKc`;

      const response = await fetch(geocodingEndpoint);
      const data = await response.json();
      if (data.status === "OK" && data.results.length > 0) {
        const location = data.results[0].geometry.location;
        setLatitude(location.lat);
        setLongitude(location.lng);
      } else {
        console.error("No results found for the given address");
      }
    } catch (error) {
      console.error("Error fetching geocoding data:", error);
    }
  };

  const containerStyle = {
    width: "100%",
    height: "400px",
  };
  const center = {
    lat: latitude,
    lng: longitude,
  };

  const mapClicked = (event) => {
    console.log(event.latLng.lat(), event.latLng.lng());
  };

  const markerClicked = (marker, index) => {
    setActiveInfoWindow(index);
  };

  const markerDragEnd = (event, index) => {
    console.log(event.latLng.lat(), event.latLng.lng());
  };
  const destination = { lat: latitude, lng: longitude };
  useEffect(() => {
    if (address) {
      geocodeAddress();
    }
  }, [address]);
  useEffect(() => {
    let addressData =
      vendorData?.addressLine1?.length > 0
        ? vendorData?.addressLine1.replace(/ {2,}/g, " ")
        : null;
    let addressData2 =
      vendorData?.addressLine2?.length > 0
        ? vendorData?.addressLine2.replace(/ {2,}/g, " ")
        : null;
    let state =
      vendorData?.state?.length > 0
        ? vendorData?.state.replace(/ {2,}/g, " ")
        : null;
    let postalCode =
      vendorData?.postalCode?.length > 0
        ? vendorData?.postalCode.replace(/ {2,}/g, " ")
        : null;
    setAddress(
      addressData + "," + addressData2 + "," + state + "," + postalCode
    );
  }, [vendorData]);
  return (
    <>
      <h3 className="merriweather text-2xl text-dark-gray font-bold">
        {vendorData?.businessName}
      </h3>
      <h5 className="merriweather text-base text-dark-gray font-bold">
        Address
      </h5>
      <CardComponent className="shadow-1 lg:mr-5 mb-5">
        <div className="">
          <p className="text-sm font-normal  text-dark-gray p-0 m-0">
            {vendorData?.addressLine1 ? `${vendorData?.addressLine1}, ` : ""}
            {vendorData?.addressLine2 ? `${vendorData?.addressLine2}, ` : ""}
            {vendorData?.city ? `${vendorData?.city}, ` : ""}
            {vendorData?.state ? `${vendorData?.state}` : ""}
            {vendorData?.postalCode ? `, ${vendorData?.postalCode}` : ""}
          </p>
        </div>
      </CardComponent>
      <div className="grid p-0 m-0 lg:mr-5">
        {address && (
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={15}
            onClick={mapClicked}
          >
            <MarkerF
              position={center}
              draggable={marker.draggable}
              onDragEnd={(event) => markerDragEnd(event)}
              onClick={(event) => markerClicked(marker)}
            />
            {directionResponse && <MarkerF position={destination} />}
            {directionResponse && (
              <DirectionsRenderer directions={directionResponse} />
            )}
          </GoogleMap>
        )}
      </div>
    </>
  );
}
