import React, { useState, useRef, useEffect } from "react";
import { Sidebar } from "primereact/sidebar";
import Logo from "../assets/images/logopng.png";
import hamburgerIcon from "../assets/icons/hamburger.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ContainerBox } from "../shared/ContainerBox";
import ProfileImg from "../assets/images/overview vendor profile.png";
import { Divider } from "primereact/divider";
import SettingIcon from "../assets/icons/setting.png";
import LogoutIcon from "../assets/icons/logout.png";
import ProfileIcon from "../assets/icons/profile icon.png";
import { Dialog } from "primereact/dialog";
import FeaturedLogoutIcon from "../assets/icons/Featured icon.png";
import ButtonComponent from "../components/buttons/button";
import { useDispatch, useSelector } from "react-redux";
import { clearProfile } from "../redux/actions/profileAction";
import { checkUserRole, checkisUserSubscribed, isAuthenticated, logout } from "../services/authService";
import Constants from "../services/constant";
import EmployeImage from "../assets/images/employeeImage.jpg";
import { myProfileDataAction } from "../redux/actions/bussinessInformationAction";
import { Accordion, AccordionTab } from "primereact/accordion";
import { membershipPlanActiveInaActiveAction } from "../redux/actions/memberShipsAction";

export default function VendorTopbar() {
    const [visibleRight, setVisibleRight] = useState(false);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [visible, setVisible] = useState(false);
    // const [first, setFirst] = useState(false);
    const myProfileData = useSelector((state) => state?.vendorProfile?.vendorProfile);
    let location = useLocation();
    const token = localStorage?.getItem("authToken");
    const [option, setOption] = useState(false);
    useEffect(() => {
        if (token) {
            dispatch(myProfileDataAction());
        }
    }, [token]);
    const handleclick = () => {
        setOption(!option);
    };
    const data = { navigate: true };
    const VendorAccountSettings = () => {
        setVisibleRight(false);
        navigate("/vendor/account-settings", { state: data });
    };
    const handleLogout = () => {
        dispatch(clearProfile());
        setVisible(false);
        localStorage.clear();
        logout(() => {
            setTimeout(() => {
                navigate("/");
            }, 400);
        });
    };
    useEffect(() => {
        dispatch(membershipPlanActiveInaActiveAction());
    }, []);
    const myProfileNavigate = () => {
        navigate(`/vendor/business/${myProfileData?._id}`, {
            state: data,
        });
    };
    useEffect(() => {
        if (myProfileData?.subscriptionType === null) {
            navigate("/membership");
        } else {
            navigate("/vendor/dashboard");
        }
    }, [myProfileData]);
    return (
        <>
            <div className={`topbar_container relative z-3`}>
                <ContainerBox>
                    <div className="  pt-2 grid  p-0 m-0 align-items-center justify-content-between lg:justify-content-between ">
                        <div className=" md:col-3 p-0 m-0 lg:w-3  ">
                            <div className="no-underline">
                                <img src={Logo} alt="" width={149} height={38}></img>
                            </div>
                        </div>
                        <input type="checkbox" name="menuToggle" id="menuToggle" hidden />
                        <label htmlFor="menuToggle">
                            <img src={hamburgerIcon} height={24} width={24} alt="HamburgerIcon" onClick={() => setVisibleRight(true)} />
                        </label>
                        <div className={`col-12 md:col-7 vendor-profile-container ${location.pathname === "/" ? "home-nav" : "other-nav"} `}>
                            <div className=" vendor-profie grid  align-items-center justify-content-end text-gray-light  ">
                                <div className=" profile-content relative  ">
                                    <div className="flex align-items-center">
                                        <span>
                                            <img
                                                src={myProfileData?.profileImage ? Constants.HOST + "/" + myProfileData?.profileImage : EmployeImage}
                                                width={52}
                                                height={52}
                                                alt=""
                                                className=""
                                                style={{ borderRadius: "2rem" }}
                                            />
                                        </span>
                                        <span className="text-lg font-bold ml-3 text-dark-gray">
                                            {myProfileData?.businessName}
                                            <i className="pi pi-angle-down ml-3 cursor-pointer" style={{ fontSize: "1.3rem" }} onClick={handleclick}></i>{" "}
                                            <br></br>
                                            <small className="font-normal p-0 m-0 ml-1" style={{ fontSize: "13px", color: "#A4A4A4 " }}>
                                                Vendor
                                            </small>{" "}
                                        </span>
                                    </div>
                                    <div
                                        className=" p-2 profile-modal bg-white  absolute  left-0 right-0 shadow-2 "
                                        style={{
                                            display: option ? "block" : "none",
                                            top: "68px",
                                            width: "200px",
                                        }}
                                    >
                                        <div className="">
                                            <div className=" p-0 m-0 flex justify-content-center">
                                                <div className=" ">
                                                    {myProfileData?.subscriptionType === null ? (
                                                        ""
                                                    ) : (
                                                        <>
                                                            <div
                                                                className="flex align-items-center cursor-pointer ml-2 mt-2"
                                                                // onClick={() =>
                                                                //   navigate(`/business/${myProfileData?._id}`, {
                                                                //     state: data,
                                                                //   })
                                                                // }
                                                                onClick={() => {
                                                                    myProfileNavigate();
                                                                    setOption(false);
                                                                }}
                                                            >
                                                                <img src={ProfileIcon} width={24} height={24} alt="" className="" />

                                                                <span className="ml-3 text-sm font-normal" style={{ color: "#212348" }}>
                                                                    My Profile
                                                                </span>
                                                            </div>
                                                            <Divider />
                                                        </>
                                                    )}
                                                    {myProfileData?.subscriptionType === null ? (
                                                        ""
                                                    ) : (
                                                        <>
                                                            {" "}
                                                            <div
                                                                className="flex align-items-center  cursor-pointer  ml-2"
                                                                onClick={() => {
                                                                    VendorAccountSettings();
                                                                    setOption(false);
                                                                }}
                                                            >
                                                                <img src={SettingIcon} width={24} height={24} alt="" className="" />
                                                                <span className="ml-3 text-sm font-normal" style={{ color: "#212348" }}>
                                                                    Account Settings
                                                                </span>
                                                            </div>
                                                            <Divider />
                                                        </>
                                                    )}

                                                    <div
                                                        className="flex align-items-center   cursor-pointer ml-2 mb-2"
                                                        onClick={() => {
                                                            setVisible(true);
                                                            setOption(false);
                                                        }}
                                                    >
                                                        <img src={LogoutIcon} width={24} height={24} alt="" className="" />
                                                        <span className="ml-3 text-sm font-normal" style={{ color: "#212348" }}>
                                                            Logout
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* 
          for mobile screen */}
                    <Sidebar visible={visibleRight} position="right" onHide={() => setVisibleRight(false)}>
                        <Accordion activeIndex={0}>
                            <AccordionTab
                                header={
                                    <>
                                        <div className="">
                                            <div className="flex align-items-center">
                                                <span>
                                                    <img
                                                        src={myProfileData?.profileImage ? Constants.HOST + "/" + myProfileData?.profileImage : EmployeImage}
                                                        width={52}
                                                        height={52}
                                                        alt=""
                                                        className=""
                                                        style={{ borderRadius: "2rem" }}
                                                    />
                                                </span>
                                                <span className="text-lg font-bold ml-3 text-dark-gray w-full">
                                                    {myProfileData?.businessName}
                                                    <p className="p-0 m-0 mt-2">
                                                        <small className="font-normal p-0 m-0 ml-1 w-full" style={{ fontSize: "13px", color: "#A4A4A4 " }}>
                                                            Vendor
                                                        </small>{" "}
                                                    </p>
                                                </span>
                                            </div>
                                        </div>
                                    </>
                                }
                            >
                                <div className="m-0 vendor-sidebar-content">
                                    <div className=" p-2 profile-modal   ">
                                        <div className=" p-0 m-0 flex ">
                                            <div className="w-full">
                                                <div
                                                    className="flex align-items-center cursor-pointer ml-2 mt-2"
                                                    onClick={() =>
                                                        navigate(`/business/${myProfileData?._id}`, {
                                                            state: data,
                                                        }) && setVisibleRight(false)
                                                    }
                                                >
                                                    <img src={ProfileIcon} width={24} height={24} alt="" className="" />

                                                    <span className="ml-3 text-sm font-normal" style={{ color: "#212348" }}>
                                                        My Profile
                                                    </span>
                                                </div>
                                                <Divider />
                                                <div className="flex align-items-center  cursor-pointer  ml-2" onClick={VendorAccountSettings}>
                                                    <img src={SettingIcon} width={24} height={24} alt="" className="" />
                                                    <span className="ml-3 text-sm font-normal" style={{ color: "#212348" }}>
                                                        Account Settings
                                                    </span>
                                                </div>
                                                <Divider />
                                                <div
                                                    className="flex align-items-center   cursor-pointer ml-2 mb-2"
                                                    onClick={() => setVisible(true) && setVisibleRight(false)}
                                                >
                                                    <img src={LogoutIcon} width={24} height={24} alt="" className="" />
                                                    <span className="ml-3 text-sm font-normal" style={{ color: "#212348" }}>
                                                        Logout
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </AccordionTab>
                        </Accordion>
                    </Sidebar>

                    <Dialog closable={false} visible={visible} style={{ width: "100vw" }} onHide={() => setVisible(false)} className="lg:w-4 md:w-10 w-11">
                        <p className="m-0">
                            <img src={FeaturedLogoutIcon} width={48} height={48} alt="" className="" />
                            <p className="text-lg font-semibold" style={{ color: "#101828" }}>
                                Are you sure you want to Logout?
                            </p>
                            <p className="text-sm font-normal mb-5" style={{ color: "#475467" }}>
                                If you're ready to logout, click "Logout" below. If you'd like to continue using the platform, click "Cancel."
                            </p>
                            <div className="grid">
                                <div className="col-12 md:col-6 flex align-items-center" onClick={() => setVisible(false)}>
                                    <ButtonComponent label="Cancel" className="w-full  bg-white text-dark border-300 text-lg font-semibold"></ButtonComponent>
                                </div>
                                <div className="col-12 md:col-6">
                                    <ButtonComponent
                                        label="Logout"
                                        onClick={handleLogout}
                                        className="w-full bg-red-500 border-0 text-lg font-semibold px-4"
                                    ></ButtonComponent>
                                </div>
                            </div>
                        </p>
                    </Dialog>
                </ContainerBox>
            </div>

            {option === true ? (
                <div
                    className="overlay-box fixed w-full h-full z-2
      top-0
      "
                    style={{ background: "#ff000000" }}
                    onClick={() => setOption(false)}
                ></div>
            ) : null}
        </>
    );
}
