import React, { useState } from "react";
import { Divider } from "primereact/divider";
export default function Photos({
  vendorData,
  setBusinessFilter,
  businessFilter,
  photosFilter,
  videosFilter,
  preWeddingFilter,
}) {
  const [option, setOption] = useState(false);

  const handleClick = () => {
    setOption(!option);
  };
  return (
    <>
      <div className="lg:mr-5 business-container">
        <div className="flex align-items-center justify-content-between">
          <h3 className="merriweather text-2xl text-dark-gray font-bold">
            {vendorData?.businessName}
          </h3>
          <span>
            <i
              className="pi pi-ellipsis-h cursor-pointer"
              onClick={handleClick}
              style={{ fontSize: "2rem" }}
            ></i>
          </span>
        </div>
        <div className="grid lg:mr-5 ">
          {option ? (
            <div className=" col-12 md:col-12 lg:col-8 flex justify-content-end  absolute left-0 right-0 ">
              <div className="shadow-1 p-2 z-1 lg:w-3   lg:mr-5 bg-white cursor-pointer">
                <p
                  className="p-0 m-0 mt-3 px-3 text-dark-gray text-base font-normal"
                  onClick={() => {
                    setBusinessFilter("Photos");
                    setOption(false);
                  }}
                >
                  Photos
                </p>
                <Divider />
                <p
                  className="p-0 m-0 px-3 text-dark-gray text-base font-normal"
                  onClick={() => {
                    setBusinessFilter("Video");
                    setOption(false);
                  }}
                >
                  Video
                </p>
                <Divider />
                <p
                  className="p-0 m-0 px-3 mb-3 text-dark-gray text-base font-normal"
                  onClick={() => {
                    setBusinessFilter("PreWedding");
                    setOption(false);
                  }}
                >
                  Pre-Wedding
                </p>
              </div>
            </div>
          ) : (
            " "
          )}
        </div>
        <h1
          className="p-0 m-0 text-xl font-bold mb-3"
          style={{ color: "#49516F" }}
        >
          {businessFilter}
        </h1>
        {businessFilter === "Photos"
          ? photosFilter()
          : businessFilter === "Video"
          ? videosFilter()
          : businessFilter === "PreWedding"
          ? preWeddingFilter()
          : photosFilter()}
      </div>
    </>
  );
}
