import React, { useState, useEffect } from "react";
import { getDefaultImage, getImageURL } from "../../utils/imageUrl";
import { Dialog } from "primereact/dialog";
import ButtonComponent from "../../components/buttons/button";
import deleteIcon from "../../assets/icons/delete.png";
export default function CustomeBusinessVideo({
  label,
  name,
  onFilesChange,
  errorMessage,
  extraClassName,
  value,
  data,
  limit,
  multiple,
  col,
  required,
  removeable,
  ingredient,
  editable,
  disabled = false,
  ...props
}) {
  const [files, setFiles] = useState(value || data?.[name] || []);
  const [visible, setVisible] = useState(false);
  const [getIndex, setGetIndex] = useState("");
  useEffect(() => {
    if (value || data?.[name]) {
      if (JSON.stringify(value || data?.[name]) !== JSON.stringify(files)) {
        setFiles(value || data?.[name]);
      }
    }
  }, [value || data?.[name]]);

  useEffect(() => {
    if (onFilesChange) {
      onFilesChange({ name, value: files });
    }
  }, [files, name]);

  const onDelete = (id) => {
    if (editable) {
      const s = files.filter((item, index) => index !== id);
      setFiles([...s]);
    }
  };
  const onFileChange = (e) => {
    if (editable) {
      const chossenFile = e.target?.files;
      handlefileChange(Object.values(chossenFile));
    }
  };
  const handlefileChange = (uploadedfile) => {
    let uploadedFiles = [];
    uploadedfile.forEach((file) => {
      if (files.findIndex((f) => f.name === file.name) === -1) {
        uploadedFiles.push(file);
      }
    });
    if (multiple) {
      if (limit) {
        let newarr = [...files, ...uploadedFiles];
        if (newarr.length > limit) {
          newarr = newarr.slice(0, limit);
        }
        setFiles(newarr);
      } else {
        setFiles([...files, ...uploadedFiles]);
      }
    } else {
      setFiles([...uploadedFiles]);
    }
  };
  return (
    <>
      <div className=" flex w-full h-full ">
        <div className=" gap-3 flex justify-content-start flex-wrap ">
          <div
            className="photo-upload text-center  flex justify-content-center align-items-center"
            style={{
              height: "188px",
              width: "154px",
            }}
          >
            <label htmlFor={name} className="text-pink font-medium">
              <i className="pi pi-plus"></i>
              <br />
              Add Video
              <input
                name={name}
                onChange={onFileChange}
                id={name}
                type="file"
                accept="video/*"
                hidden
                {...props}
                multiple={multiple}
                disabled={disabled}
              />
            </label>
          </div>
          {files.length
            ? Object.values(files).map((image, i) => (
                <div key={i} className=" storefront-photo relative">
                  <video
                    width="280"
                    height="190"
                    controls
                    src={
                      image
                        ? typeof image === "string"
                          ? getImageURL(image)
                          : URL.createObjectURL(image)
                        : ""
                    }
                  >
                    <source
                      src={
                        image
                          ? typeof image === "string"
                            ? getImageURL(image)
                            : URL.createObjectURL(image)
                          : ""
                      }
                      type="video/mp4"
                    />
                  </video>
                  {removeable && (
                    <i
                      onClick={() => {
                        setVisible(true);
                        setGetIndex(i);
                      }}
                      className="pi pi-times cursor-pointer absolute right-0"
                    ></i>
                  )}
                </div>
              ))
            : null}
        </div>
      </div>
      <Dialog
        className="lg:w-3 md:w-8 w-11"
        header={<img src={deleteIcon} width={48} height={48} alt="" />}
        visible={visible}
        style={{ width: "50vw" }}
        onHide={() => setVisible(false)}
      >
        <div className="m-0">
          <h3
            className="font-semibold text-lg "
            style={{ color: "#101828", fontFamily: "Inter" }}
          >
            Delete Videos
          </h3>
          <p className="text-sm font-normal" style={{ color: "#475467" }}>
            Are you sure you want to delete the selected images? This action
            cannot be undone.
          </p>

          <ButtonComponent
            label="Confirm"
            onClick={() => {
              onDelete(getIndex);
              setVisible(false);
            }}
            className="w-full bg-red-500 border-0 mb-3 "
          ></ButtonComponent>
          <ButtonComponent
            label="Cancel"
            onClick={() => setVisible(false)}
            className="w-full bg-white text-dark border-400"
          ></ButtonComponent>
        </div>
      </Dialog>
    </>
  );
}
