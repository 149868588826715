import React, { useEffect, useState } from "react";
import VendorNotFoundImg from "../../assets/images/vendorNotFound.png";
import { ContainerBox } from "../../shared/ContainerBox";
import {
  CardComponent,
  SupplierCard,
  Vendorcard,
} from "../../components/cards/card";
import { Link } from "react-router-dom";
import ButtonComponent from "../../components/buttons/button";
import { useDispatch, useSelector } from "react-redux";
import venueImage from "../../assets/images/user.png";
import { vendorListAction } from "../../redux/actions/vendorAction";
import { useNavigate } from "react-router-dom";
import Constants from "../../services/constant";
import { Carousel } from "primereact/carousel";

export const WeedingSupplier = ({
  searchVendor,
  serachVendorData,
  postalCode,
}) => {
  const featuredCategories = useSelector((state) => state.vendor?.categories);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const vendors = useSelector((state) => state.vendor?.allVendors);

  const [chooseVendor, setChooseVendor] = useState();
  useEffect(() => {
    if (chooseVendor) {
      let params = {
        vendorType: chooseVendor,
      };
      dispatch(vendorListAction(params));
    } else {
      let params = {
        vendorType: featuredCategories[0]?._id,
      };
      dispatch(vendorListAction(params));
    }
  }, [chooseVendor, featuredCategories]);
  useEffect(() => {
    if (featuredCategories) {
      setChooseVendor(featuredCategories[0]?._id);
    }
  }, [featuredCategories]);
  const gotoBtn = () => {
    window.scrollTo({ top: 0, left: 0 });
  };

  const responsiveOptions = [
    {
      breakpoint: "1199px",
      numVisible: 5,
      numScroll: 1,
    },
    {
      breakpoint: "991px",
      numVisible: 5,
      numScroll: 1,
    },
    {
      breakpoint: "767px",
      numVisible: 2,
      numScroll: 1,
    },
  ];

  const productTemplate = (featuredCategories) => {
    return (
      <>
        <div className="mb-5 p-2" id="WeddingSupplier">
          <ButtonComponent
            label={featuredCategories?.name}
            className={` text-light-black border-none shadow-1 white-space-nowrap	w-full  ${
              chooseVendor === featuredCategories?._id
                ? "bg-main text-white "
                : "bg-white"
            }`}
            onClick={() => setChooseVendor(featuredCategories?._id)}
          />
        </div>
      </>
    );
  };

  return (
    <>
      <div className="vendor ">
        <ContainerBox
          containerType="container"
          title="Featured wedding suppliers"
        >
          <Carousel
            value={featuredCategories}
            numVisible={5}
            showIndicators={false}
            prevIcon="pi pi-arrow-left"
            nextIcon="pi pi-arrow-right"
            className="wedding-feauture"
            numScroll={1}
            responsiveOptions={responsiveOptions}
            itemTemplate={productTemplate}
            // md:gap-5
          />
          <div className="flex justify-content-start gap-5 flex-wrap">
            {(serachVendorData?.vendors?.length > 0 && searchVendor !== "") ||
            (serachVendorData?.vendors?.length > 0 && postalCode !== "") ? (
              serachVendorData?.vendors?.map((item, key) => {
                return (
                  <SupplierCard
                    key={key}
                    img={
                      item.profileImage
                        ? Constants.HOST + item?.profileImage
                        : venueImage
                    }
                    rating={item.rating}
                    title={item.businessName}
                    location={item.city}
                    item={item}
                    person={item.person}
                    id={item._id}
                    onClick={gotoBtn}
                  />
                );
              })
            ) : (vendors?.vendors?.length > 0 &&
                searchVendor === undefined &&
                postalCode === "") ||
              (vendors?.vendors?.length > 0 &&
                searchVendor === "" &&
                postalCode === "") ? (
              <>
                {vendors?.vendors?.map((item, index) => {
                  return index <= 9 ? (
                    <SupplierCard
                      key={index}
                      id={item?._id}
                      img={
                        item?.profileImage
                          ? Constants.HOST + item?.profileImage
                          : venueImage
                      }
                      rating={item?.reviews[0]?.rating}
                      item={item}
                      title={item?.businessName}
                      location={item?.city}
                      capcity={true}
                      SubscriptionType={item?.subscriptionType}
                    />
                  ) : null;
                })}
                <CardComponent
                  className="view-all-card bg-light-pink border-none shadow-none flex align-items-center justify-content-center"
                  onClick={() => {
                    chooseVendor
                      ? navigate(`/explore/${chooseVendor}`)
                      : navigate(`/explore/${featuredCategories[0]?._id}`);
                  }}
                >
                  <Link
                    to=""
                    className="no-underline text-sm text-dark-gray view-all font-bold"
                  >
                    View All
                  </Link>
                </CardComponent>
              </>
            ) : (
              <div className="grid justify-content-center flex w-full">
                <div className="col-12 md:col-12 text-center">
                  <img
                    src={VendorNotFoundImg}
                    width={327}
                    height={241}
                    alt=""
                    className=""
                  />
                  <p className="text-sm font-medium text-dark-gray mb-5">
                    No vendors available for this category. Are you a business?
                  </p>
                  <ButtonComponent
                    label="Register Now To Be Listed!"
                    onClick={() => navigate("/signup")}
                  ></ButtonComponent>
                </div>
              </div>
            )}
          </div>
          {/* {vendors?.vendors?.length > 0 ? (
            <div className="flex justify-content-start lg:gap-5 md:gap-5 flex-wrap">
              {vendors?.vendors?.map((item, index) => {
                return index <= 9 ? (
                  <SupplierCard
                    id={item._id}
                    img={
                      item?.profileImage
                        ? Constants.HOST + item?.profileImage
                        : venueImage
                    }
                    rating={item?.reviews[0]?.rating}
                    item={item}
                    title={item.businessName}
                    location={item.city}
                    onClick={gotoBtn}
                    capcity={true}
                  />
                ) : null;
              })}
              <CardComponent
                className="view-all-card bg-light-pink border-none shadow-none flex align-items-center justify-content-center"
                onClick={() => {
                  chooseVendor
                    ? navigate(`/explore/${chooseVendor}`)
                    : navigate(`/explore/${featuredCategories[0]?._id}`);
                }}
              >
                <Link
                  to=""
                  className="no-underline text-sm text-dark-gray view-all font-bold"
                >
                  View All
                </Link>
              </CardComponent>
            </div>
          ) : (
            <div className="grid">
              <div className="col-12 md:col-12 text-center">
                <img
                  src={VendorNotFoundImg}
                  width={327}
                  height={241}
                  alt=""
                  className=""
                />
                <p className="text-sm font-medium text-dark-gray mb-5">
                  No vendors available for this category. Are you a business?
                </p>
                <ButtonComponent
                  label="Register Now To Be Listed!"
                  onClick={() => navigate("/signup")}
                ></ButtonComponent>
              </div>
            </div>
          )} */}
        </ContainerBox>
      </div>
    </>
  );
};
