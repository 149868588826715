import React, { useEffect, useRef, useState } from "react";
import { ContainerBox } from "../../shared/ContainerBox";
import { SupplierCard } from "../../components/cards/card";
import { useDispatch, useSelector } from "react-redux";
import Constants from "../../services/constant";
import WeedingSupplierImg from "../../assets/images/user.png";
import { Paginator } from "primereact/paginator";
import { filterVendors } from "../../redux/actions/BusinessProfile";
import { useLocation, useNavigate } from "react-router-dom";
import { vendorListAction } from "../../redux/actions/vendorAction";
import { StandaloneSearchBox } from "@react-google-maps/api";
import { CustomInput } from "../../components/input/AllInput";
import { Divider } from "primereact/divider";
import ButtonComponent from "../../components/buttons/button";
import { showToast } from "../../redux/actions/toastAction";
import NoDataFound from "../../assets/images/NoDataFound.png";
import { Menu } from "primereact/menu";
import SearchBox from "../../components/input/SearchBox";
export const SearchList = ({ isLoaded }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [first, setFirst] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [rows, setRows] = useState(10);
  const [postalCode, setPostalCode] = useState("");
  const [totalRecords, setTotalRecords] = useState(1);
  const [searchVendor, setSearchVendor] = useState("");
  const serachVendorData = useSelector(
    (state) => state?.vendorFilter?.vendorFilterProfile
  );
  const featuredCategories = useSelector((state) => state?.vendor?.categories);
  const highlightSearchQuery = (text, query) => {
    if (!query) {
      return text;
    }

    const regex = new RegExp(`(${query})`, "gi");
    const parts = text.split(regex);

    return parts.map((part, index) =>
      regex.test(part) ? (
        <span key={index} className="highlight">
          {part}
        </span>
      ) : (
        <span key={index}>{part}</span>
      )
    );
  };
  const handlePlaceChanged = (place) => {
    if (place) {
      let addressVar = {
        city: "",
      };
      place?.address_components?.map((address) => {
        if (
          address?.types?.includes("postal_town") ||
          address?.types?.includes("locality")
        ) {
          addressVar.city = address?.long_name;
        }
        setPostalCode(addressVar?.city);
      }, []);
    }
  };
  useEffect(() => {
    if (featuredCategories) {
      setSearchValue(featuredCategories);
    }
  }, [featuredCategories]);

  const handleSearch = () => {
    if (searchVendor === "") {
      dispatch(
        showToast({ severity: "error", summary: "Please Select Category" })
      );
      // } else if (postalCode === "") {
      //   dispatch(showToast({ severity: "error", summary: "Please Select City" }));
    } else if (searchVendor !== "") {
      let city = postalCode?.replace("%20", "");
      let params = {
        city: city,
        vendorType: searchVendor?._id,
        vendor: searchVendor?.name,
      };
      dispatch(filterVendors(params)).then((res) => {
        dispatch(vendorListAction({}, true));
      });
      navigate(`/search?vendor=${searchVendor?.name}&location=${postalCode}`, {
        state: { params },
      });
    }
  };

  const op = useRef(null);
  const filterData = (value) => {
    let data = featuredCategories?.filter((item) => {
      return item?.name?.toLowerCase()?.includes(value?.toLowerCase());
    });
    setSearchValue(data);
    setSearchVendor(value);
  };

  useEffect(() => {
    if (location.state.params) {
      setPostalCode(location?.state?.params?.city);
      setSearchVendor({
        name: location?.state?.params?.vendor,
        _id: location?.state?.params?.vendorType,
      });
    }
  }, [location]);
  return (
    <>
      <div className="search-page-container">
        <ContainerBox>
          <div className=" flex justify-content-center search-box my-5 w-full mb-3 ">
            <div className="block lg:flex justify-content-between bg-white border-round-lg relative align-items-center w-9 ">
              <div className="flex align-items-center w-full  ">
                <span className=" ml-2 p-0 m-0">
                  <i
                    className="pi pi-search mt-2 "
                    style={{ fontSize: "1.2rem" }}
                  ></i>
                </span>

                <span className=" p-0 m-0 mb-0 realtive w-full">
                  <CustomInput
                    extraClassName="w-full"
                    className="border-0 auto-complete"
                    placeholder="Wedding Venues"
                    value={
                      searchVendor?.name || location?.state?.params?.vendor
                    }
                    onClick={(e) => op.current.toggle(e)}
                    onChange={(e) => filterData(e?.target?.value)}
                  />
                  <div className="absolute top-0 w-full home-search-box">
                    {searchValue?.length > 0 ? (
                      <Menu
                        appendTo={"self"}
                        className={
                          searchVendor?.length > 0
                            ? "partication w-auto  "
                            : "overlay-pannel search-panel"
                        }
                        
                        model={searchValue?.map((item, index) => ({
                          label: (
                            <div
                              key={index}
                              className={
                                searchVendor?.length > 0
                                  ? "flex align-items-center divider  py-3 w-full"
                                  : "flex align-items-center cursor-pointer py-2 w-full"
                              }
                              onClick={(e) => {
                                setSearchVendor(item);
                                op.current.toggle(e);
                              }}
                            >
                              <img
                                src={Constants.HOST + item?.icon}
                                width={24}
                                height={24}
                                alt=""
                              />
                              <span>
                                <p className="ml-2 p-0 m-0">
                                  {highlightSearchQuery(
                                    item?.name,
                                    searchVendor
                                  )}
                                </p>
                              </span>
                            </div>
                          ),
                        }))}
                        popup
                        ref={op}
                        id="popup_menu_left"
                        popupAlignment="left"
                      />
                    ) : null}
                  </div>
                </span>
              </div>
              <Divider layout="vertical" className="m-0 hidden lg:block" />
              <Divider className="m-0 lg:hidden block" />
              <div className="flex w-full align-items-center lg:mb-0 mb-1">
                <span className=" ml-2 p-0 m-0">
                  <i
                    className="pi pi-map-marker mt-2 "
                    style={{ fontSize: "1.2rem" }}
                  ></i>
                </span>
                <span className="w-full">
                  {/* {isLoaded && (
                        <StandaloneSearchBox
                          onLoad={(ref) => (inputRef.current = ref)}
                          onPlacesChanged={handlePlaceChanged}
                        >
                          <CustomInput
                            col={12}
                            placeholder="Location"
                            value={
                              postalCode?.length > 9
                                ? postalCode.substring(0, 9) + "..."
                                : postalCode
                            }
                            onChange={(e) => setPostalCode(e?.target?.value)}
                            extraClassName="search-margin w-full relative "
                            className="border-0"
                          />
                        </StandaloneSearchBox>
                      )} */}
                  <SearchBox
                    searchValue={postalCode}
                    setSearchValue={handlePlaceChanged}
                    placeholder="Location"
                    cityOnly={true}
                  />
                </span>
              </div>
              <div className="lg:w-5 md:w-5 w-full">
                <ButtonComponent
                  label="Get Started"
                  className="border-round-lg w-full"
                  style={{ height: "56px" }}
                  onClick={handleSearch}
                />
              </div>
            </div>
          </div>
          <div className="my-5">
            <span className="font-medium text-lg">
              Showing Results {serachVendorData?.count}
            </span>
          </div>
          <div className="flex justify-content-start gap-5 flex-wrap">
            {serachVendorData?.vendors?.length > 0 ? (
              serachVendorData?.vendors?.map((item) => {
                return (
                  <SupplierCard
                    img={
                      item.profileImage
                        ? Constants.HOST + item?.profileImage
                        : WeedingSupplierImg
                    }
                    rating={item.avgRating}
                    title={item.businessName}
                    location={item.city}
                    item={item}
                    person={item.person}
                    id={item._id}
                  />
                );
              })
            ) : (
              <div className="grid justify-content-center flex w-full">
                <div className="col-12 md:col-12 text-center">
                  <img
                    src={NoDataFound}
                    width={327}
                    height={241}
                    alt=""
                    className=""
                  />
                  <p className="text-lg font-bold text-dark-gray mb-5">
                    No Data Found
                  </p>
                </div>
              </div>
            )}
          </div>
          {serachVendorData?.vendors?.length > 0 ? (
            <Paginator first={first} rows={rows} totalRecords={totalRecords} />
          ) : null}
        </ContainerBox>
      </div>
    </>
  );
};
