import React from 'react'
import { ContainerBox } from '../shared/ContainerBox'

export default function WeddingTemplateFooter() {
  return (
    <>
      <ContainerBox>
        <div className='wedding-template-footer-container py-6'>
          <div className='flex align-items-center justify-content-between'>
            <div>
              <h2 className='merriweather text-2xl text-dark-gray font-bold'> Gavano & Angle </h2>
              <p className='merriweather text-2xl text-dark-gray font-noraml p-0 m-0' style={{ fontFamily: "italic" }}>3rd August, 2023</p>
            </div>
            <div>
              <h2 className='merriweather text-2xl text-dark-gray font-bold'> Contact</h2>
              <p className='merriweather text-lg text-dark-gray font-noraml p-0 m-0' style={{ fontFamily: "italic" }}>(704) 555-0127  , (684) 555-0102
              </p>
            </div>

          </div>
        </div>

      </ContainerBox>
    </>
  )
}
