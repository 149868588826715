import React from "react";
import { Card } from "primereact/card";
import { Rating } from "primereact/rating";
import { Link, useNavigate } from "react-router-dom";
import AddIcon from "../../assets/icons/plus add.png";
import CutIcon from "../../assets/icons/cut.png";
import ButtonComponent from "../buttons/button";
import { firstLetterToUppercase } from "../../utils/regex";
import { formatCurrency } from "../../utils/javascript";
// import { classNames } from "primereact/utils";

export const CardComponent = ({ children, className, onClick }) => {
  return (
    <Card onClick={onClick} className={`border-round-lg ${className}`}>
      {children}
    </Card>
  );
};
export const Vendorcard = ({ src, servicename, id }) => {
  const navigate = useNavigate();
  return (
    <div
      className="relative service-card m-2 border-round-lg overflow-hidden"
      onClick={() => navigate(`/explore/${id}`)}
    >
      <img src={src} alt="" className="w-full h-full cover-img" />
      <div className="vendor-detail absolute bottom-0 left-0 w-full p-2 text-center">
        <p className="font-semibold m-0 text-white">{servicename}</p>
      </div>
    </div>
  );
};

export const SupplierCard = ({
  img,
  className = "",
  rating,
  title,
  location,
  price,
  item,
  person,
  onClick,
  id,
  capcity,
  SubscriptionType,
}) => {
  const navigate = useNavigate();

  const header = (
    <>
      <Link to={`/business/${id}`} onClick={onClick}></Link>
    </>
  );
  return (
    <div
      className={`p-0 mb-3 supplier-card border-none shadow-none relative ${className}`}
      style={{ width: "210px" }}
    >
      <div
        className="relative z-1 h-10rem cursor-pointer border-round-xl overflow-hidden"
        onClick={() => navigate(`/business/${id}`)}
      >
        {/* <div
          className="absolute h-full w-full z-0 "
          style={{ filter: "opacity(15%)" }}
        >
          <img
            alt="Vendor"
            className=" h-full w-full"
            src={img} 
            // style={{ filter: "blur(3px)" }}
          />
        </div> */}

        <img
          alt="Card"
          className="h-full w-full z-5 relative"
          src={img}
          // style={img ? { objectFit: "cover" } : ""}
        />
      </div>
      <div className="">
        <h2
          className="text-base font-semibold text-light-black mb-2 cursor-pointer"
          onClick={() => navigate(`/business/${id}`)}
        >
          {firstLetterToUppercase(title)}
        </h2>
        {location ? (
          <small className="text-light-black text-xs flex align-items-center mb-2">
            <i className="pi pi-map-marker"></i>&nbsp;{location}
          </small>
        ) : (
          <small className="text-light-black text-xs flex align-items-center mb-2">
            <i className="pi pi-map-marker"></i>&nbsp; N/A
          </small>
        )}
        <div className="flex align-items-center text-xs">
          {item?.minBudget && item?.maxBudget ? (
            <small className="text-pink font-semibold text-xs flex align-items-center ">
              {formatCurrency(item?.minBudget)}-{" "}
              {formatCurrency(item?.maxBudget)}
            </small>
          ) : item?.minBudget ? (
            <small className="text-pink font-semibold text-xs flex align-items-center">
              {formatCurrency(item?.minBudget)}
            </small>
          ) : item?.maxBudget ? (
            <small className="text-pink font-semibold text-xs flex align-items-center">
              {formatCurrency(item?.maxBudget)}
            </small>
          ) : (
            ""
          )}
          {item?.capacity && !capcity && item?.vendorType?.name === "Venue" ? (
            <span className="ml-3">
              <i className="pi pi-user"></i>&nbsp;{item?.capacity}
            </span>
          ) : (
            ""
          )}
        </div>
      </div>
      {rating === 0 || rating === undefined || rating === null ? (
        <>
          <span className="flex ">
            {/* <p className="text-xl p-0 m-0 lg:mt-1">0</p> */}
            <Rating
              value={0}
              readOnly
              cancel={false}
              className="mb-3 mt-2 ml-2"
            />
          </span>
          {/* <span className="ml-3 text-lg font-normal text-light-black">
            ({rating === undefined ? 0 : rating} Reviews)
          </span> */}
        </>
      ) : (
        <Rating value={rating} readOnly cancel={false} className="mb-3 mt-2" />
      )}
      {SubscriptionType !== "Standard" ? (
        <div className="box">
          <div className="ribbon">
            <span>{SubscriptionType}</span>{" "}
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export const WeddingVenuesCard = ({
  img,
  className,
  rating,
  title,
  location,
  price,
  person,
  id,
}) => {
  const Navigate = useNavigate();
  const WeddingVenues = () => {
    // 👇️ navigate to Wedding venues
    Navigate("/business");
  };

  const vendorSelect = () => {
    // 👇️ navigate to Wedding venues
    Navigate("/vendor-select");
  };

  const header = (
    <>
      <p className="p-0 m-0">{id}</p>

      <div className="relative">
        <img
          onClick={WeddingVenues}
          alt="Card"
          className=" border-round-lg"
          src={img}
        />
        <div className="absolute  top-0 mt-2" style={{ right: "10px" }}>
          <img
            src={AddIcon}
            // width={24}
            // height={24}
            alt=""
            className="cursor-pointer add-icon"
            onClick={vendorSelect}
          />
        </div>
      </div>
    </>
  );

  return (
    <Card
      header={header}
      className={`p-0 mb-3 supplier-card border-none shadow-none ${className}`}
    >
      <Rating value={rating} readOnly cancel={false} />
      <h2 className="text-base font-semibold text-light-black mb-2">{title}</h2>
      <small className="text-light-black text-xs flex align-items-center mb-2">
        <i className="pi pi-map-marker"></i>&nbsp;{location}
      </small>
      <div className="flex align-items-center text-xs justify-content-between">
        <span className="text-pink font-semibold">{price}</span>
        <span>
          <i className="pi pi-user"></i>&nbsp;{person}
        </span>
      </div>
    </Card>
  );
};

export const VendorSelectContentCard = ({
  img,
  className,
  rating,
  title,
  location,
  price,
  person,
  id,
}) => {
  const Navigate = useNavigate();
  const WeddingVenues = () => {
    // 👇️ navigate to Wedding venues
    Navigate("/business");
  };

  const header = (
    <>
      <p className="p-0 m-0">{id}</p>

      <div className="relative">
        <div class="container">
          <img alt="Card" className="border-round-lg " src={img} />
          <div class="middle">
            <div class="view-profile-Button">
              <ButtonComponent
                label="View Profile"
                className="w-full border-0 px-0"
                onClick={WeddingVenues}
              ></ButtonComponent>
            </div>
          </div>
        </div>

        <div className="absolute  top-0 " style={{ right: "10px" }}>
          <img
            src={CutIcon}
            // width={24}
            // height={24}
            alt=""
            className="cursor-pointer mt-1 cut-icon"
          />
        </div>
      </div>
    </>
  );

  return (
    <Card
      header={header}
      className={`p-0 mb-3 supplier-card border-none shadow-none ${className}`}
    >
      <Rating value={rating} readOnly cancel={false} />
      <h2 className="text-base font-semibold text-light-black mb-2">{title}</h2>
      <small className="text-light-black text-xs flex align-items-center mb-2">
        <i className="pi pi-map-marker"></i>&nbsp;{location}
      </small>
      <div className="flex align-items-center text-xs justify-content-between">
        <span className="text-pink font-semibold">{price}</span>
        <span>
          <i className="pi pi-user"></i>&nbsp;{person}
        </span>
      </div>
    </Card>
  );
};

export const BudgetPlannerCard = ({
  img,
  className,
  rating,
  title,
  location,
  price,
  person,
  id,
}) => {
  const Navigate = useNavigate();
  const WeddingVenues = () => {
    // 👇️ navigate to Wedding venues
    Navigate("/business");
  };

  const header = (
    <>
      <p className="p-0 m-0">{id}</p>
      <div className="relative">
        <div class="container">
          <img alt="Card" className="border-round-lg " src={img} />
        </div>
      </div>
    </>
  );

  return (
    <Card
      header={header}
      className={`p-0 mb-3 supplier-card border-none shadow-none ${className}`}
    >
      <Rating value={rating} readOnly cancel={false} />
      <h2 className="text-base font-semibold text-light-black mb-2">{title}</h2>
    </Card>
  );
};

export const WorkCard = ({ src, title }) => {
  return (
    <div className="work-card lg:mb-0 md:mb-0 mb-8 ">
      <div className="bg-white work-card-img border-round-lg  h-full flex align-items-center justify-content-center">
        <img src={src} alt="" width={74} />
      </div>
      <p className="font-semibold text-dark-gray mt-5">{title}</p>
    </div>
  );
};
