import React, { useState, useEffect } from "react";
import { CustomCheckbox1, CustomDropDown, CustomInput } from "../../components/input/AllInput";
import { allValidations } from "../../utils/formValidations";
import { Dialog } from "primereact/dialog";
import ButtonComponent from "../../components/buttons/button";
import { Divider } from "primereact/divider";
import { useDispatch, useSelector } from "react-redux";
import { showArrayFormErrors, showFormErrors } from "../../utils/commonFunctions";
import { addGuestAction1, guestAction } from "../../redux/actions/GuestAction";

const AddGuests = ({ visible, setVisible }) => {
    const initialState = {
        relativeFrom: "",
        name: "",
        invitedTo: "",
        wedding: false,
        reception: false,
        acceptInviteReception: "PENDING",
        acceptInviteWedding: "PENDING",
        familyMemberList: [{ name: "" }],
    };
    const dispatch = useDispatch();
    const hosts = useSelector((state) => state.hostProfile.hosts);
    const [data, setData] = useState(initialState);

    useEffect(() => {
        if (data.wedding || data.reception) {
            let value = data?.wedding && data?.reception ? "BOTH" : data?.wedding ? "WEDDING" : data?.reception ? "RECEPTION" : "";
            const formErrors = allValidations("invitedTo", value, data);
            setData((prev) => ({ ...prev, invitedTo: value, formErrors }));
        }
    }, [data.wedding, data.reception]);

    const handleChange = ({ name, value }) => {
        const formErrors = allValidations(name, value, data);
        setData((prev) => ({ ...prev, [name]: value, formErrors }));
    };
    const handleChangeDynamicField = ({ name, value, customIndex, fieldName }) => {
        const _newData = { ...data };
        let obj = _newData[fieldName][customIndex];
        obj[name] = value;
        const formErrors = allValidations(name, value, obj);
        obj.formErrors = formErrors;
        _newData[fieldName][customIndex] = obj;
        setData(() => ({
            ..._newData,
        }));
    };

    const handleRemove = (indexToRemove, fieldName) => {
        setData((prevData) => ({
            ...prevData,
            [fieldName]: prevData[fieldName].filter((_, index) => index !== indexToRemove),
        }));
    };

    const handleAddMore = () => {
        const newMembers = {
            name: "",
        };
        setData((prevData) => ({
            ...prevData,
            familyMemberList: [...prevData.familyMemberList, newMembers],
        }));
    };

    const addGuestFun = ({ next = false }) => {
        if (showFormErrors(data, setData)) {
            let validatedfamilyMembers = showArrayFormErrors(data.familyMemberList);
            if (!validatedfamilyMembers.isValid) {
                setData((prev) => ({ ...prev, familyMemberList: validatedfamilyMembers.data }));
            }
            if (validatedfamilyMembers.isValid) {
                dispatch(
                    addGuestAction1(data, () => {
                        dispatch(guestAction({ search: "" }));
                        setData({
                            relativeFrom: "",
                            name: "",
                            invitedTo: "",
                            wedding: false,
                            reception: false,
                            acceptInviteReception: "PENDING",
                            acceptInviteWedding: "PENDING",
                            familyMemberList: [{ name: "" }],
                        });
                    })
                );
                if (!next) setVisible(false);
            }
        }
    };

    return (
        <div>
            <Dialog
                className="lg:w-5 md:w-8 w-11"
                header={<h1 className="text-2xl font-bold Merriweather">{data?.isUpdate ? "Update Guest " : "Add Guest"}</h1>}
                visible={visible}
                style={{ width: "50vw" }}
                onHide={() => {
                    setVisible(false);
                    setData(initialState);
                }}
            >
                <p className="m-0">
                    <div className="grid">
                        <div className="col-12 md:col-6">
                            <CustomInput
                                extraClassName="w-full"
                                label="Full Name*"
                                placeholder="Enter guest name"
                                className="w-full"
                                name="name"
                                data={data}
                                onChange={handleChange}
                                labeClassName="text-dark-black tex-sm font-medium"
                            />
                        </div>
                        <div className="col-12 md:col-6">
                            <CustomDropDown
                                label="Relative to*"
                                placeholder="Select Family"
                                extraClassName="w-full"
                                dropdownOptions={hosts}
                                name="relativeFrom"
                                onChange={handleChange}
                                data={data}
                                labelClassName="text-dark-black tex-sm font-medium"
                            />
                        </div>
                        <div className="col-12 md:col-12 mb-1">
                            <p className="text-dark-black tex-sm font-semibold ml-1 p-0 m-0">Add family member</p>
                        </div>
                        <div className="col-12 md:col-6">
                            {data?.familyMemberList?.map((member, index) => {
                                return (
                                    <div className="flex align-items-center" key={index}>
                                        <CustomInput
                                            extraClassName="w-11 md:w-full"
                                            placeholder="Add Member Name"
                                            className=""
                                            name="name"
                                            customIndex={index}
                                            data={member}
                                            fieldName="familyMemberList"
                                            onChange={handleChangeDynamicField}
                                            labeClassName="text-dark-black tex-sm font-medium"
                                        />
                                        {data?.familyMemberList?.length ? (
                                            <i
                                                className="pi pi-trash cursor-pointer text text-red-500 pb-3 ml-2"
                                                onClick={() => {
                                                    handleRemove(index, "familyMemberList");
                                                }}
                                            />
                                        ) : null}
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    <ButtonComponent label="+ Add More" className="ml-1 mb-3" onClick={handleAddMore}></ButtonComponent>
                    <Divider />
                    <p className="text-dark-black tex-sm font-semibold ml-1 p-0 m-0 mb-3">Invited To</p>

                    <div className="checkbox flex ">
                        <span>
                            <CustomCheckbox1 optionLabel="Wedding" data={data} name="wedding" extraClassName="m-0" onChange={handleChange}></CustomCheckbox1>
                        </span>
                        <span className="ml-5">
                            <CustomCheckbox1
                                optionLabel="Reception"
                                extraClassName="m-0"
                                data={data}
                                name="reception"
                                onChange={handleChange}
                            ></CustomCheckbox1>
                        </span>
                    </div>
                    <div className="pl-2 error-msg mb-3">
                        {data?.formErrors?.invitedTo ? <small className="p-error text-sm">{data?.formErrors?.invitedTo}</small> : null}
                    </div>
                    <div className="btn md:flex ">
                        {!data?.isUpdate ? (
                            <span className="col-6">
                                <ButtonComponent
                                    label="Save and add Another"
                                    className=" lg:mb-0 md:mb-0 mb-3 text-sm font-medium bg-white border-400 text-light-black w-full"
                                    onClick={() => addGuestFun({ next: true })}
                                />
                            </span>
                        ) : null}

                        <span className="col-6">
                            <ButtonComponent
                                label={data?.isUpdate ? "Update" : "Add"}
                                className="text-sm font-medium w-full"
                                onClick={() => addGuestFun({ next: false })}
                            />
                        </span>
                    </div>
                </p>
            </Dialog>
        </div>
    );
};

export default AddGuests;
