import Dummy from "../../assets/images/employeeImage.jpg";
import { getImageURL } from "../../utils/imageUrl";
import { imageprocessing } from "../../utils/javascript";
import ButtonComponent from "../buttons/button";
 
 const CustomProfileImage = ({name,value,onChange,title,buttonLabel}) => {

    return(
    <div className="col-12 md:col-12 flex align-items-center">
                <img
                  src={
                    imageprocessing(value)
                  }
                  width={150}
                  height={150}
                  alt=""
                  className="border-circle"
                />
                <span className="ml-5 text-base font-semibold text-dark-gray">
                  {title} <br />
                  <label htmlFor={name} style={{display:"block",cursor:"pointer"}}>
                  <ButtonComponent
                    label={buttonLabel}
                    className="bg-white text-main-color border-main mt-3 p-disabled"
                  ></ButtonComponent>
                  </label>
                  <input id={name} name={name} type="file" hidden onChange={(e)=>onChange && onChange({name:e.target.name,value:e.target.files[0]})}/>
                </span>
              </div>
    )
}

export default CustomProfileImage