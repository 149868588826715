import React, { useEffect, useRef, useState } from "react";
import { ContainerBox } from "../../shared/ContainerBox";
import BannerImg from "../../assets/images/homebanner.png";
import { CustomInput } from "../../components/input/AllInput";
import { Divider } from "primereact/divider";
import ButtonComponent from "../../components/buttons/button";
import { useSelector } from "react-redux";
import Constants from "../../services/constant";
import { Menu } from "primereact/menu";
import SearchBox from "../../components/input/SearchBox";
export const HomeBanner = ({
  searchVendor,
  setPostalCode,
  postalCode,
  handleSearch,
  setSearchVendor,
  isLoaded,
}) => {
  const inputRef = useRef();
  const [searchValue, setSearchValue] = useState("");
  const featuredCategories = useSelector((state) => state?.vendor?.categories);
  const highlightSearchQuery = (text, query) => {
    if (!query) {
      return text;
    }

    const regex = new RegExp(`(${query})`, "gi");
    const parts = text.split(regex);

    return parts.map((part, index) =>
      regex.test(part) ? (
        <span key={index} className="highlight">
          {part}
        </span>
      ) : (
        <span key={index}>{part}</span>
      )
    );
  };
  const handlePlaceChanged = (place) => {
    if (place) {
      let addressVar = {
        city: "",
      };
      place?.address_components?.map((address) => {
        if (
          address?.types?.includes("postal_town") ||
          address?.types?.includes("locality")
        ) {
          addressVar.city = address?.long_name;
        }
        setPostalCode(addressVar?.city);
      }, []);
    }
  };
  useEffect(() => {
    if (featuredCategories) {
      setSearchValue(featuredCategories);
    }
  }, [featuredCategories]);
  const op = useRef(null);
  const filterData = (value) => {
    let data = featuredCategories?.filter((item) => {
      return item?.name?.toLowerCase()?.includes(value?.toLowerCase());
    });
    setSearchValue(data);
    setSearchVendor(value);
  };

  return (
    <div className="bg-lightest landing-banner-container">
      <ContainerBox containerType="container-fluid">
        <div className="grid m-0 w-full banner-content">
          <div className="md:col-7 lg:col-6 flex-order-2 md:flex-order-1 banner-content-left flex flex-column justify-content-center ">
            <div className="content-box">
              <h2 className="font-44 line-height-2 mt-0 merriweather font-normal text-dark-gray mb-0">
                Take charge of <br />
                your
                <span className="font-bold"> special day!</span>
              </h2>
              <p className="font-12 text-light-black mb-5 lg:w-8 md:w-10 w-11 font-normal">
                Become your own wedding planner and transform your dreams into
                reality. Empower your journey and infuse every moment with your
                personal touch. Your wedding, your way.
              </p>
              <div className="search-box w-full mb-3 md:mb-0 md:w-10" id="searchContainer">
                <div className="block lg:flex justify-content-between bg-white border-round-lg relative align-items-center  ">
                  <div className="flex align-items-center w-full  ">
                    <span className=" ml-2 p-0 m-0">
                      <i
                        className="pi pi-search mt-2 "
                        style={{ fontSize: "1.2rem" }}
                      ></i>
                    </span>

                    <span className="w-full p-0 m-0 mb-0 realtive w-full">
                      <CustomInput
                        extraClassName="w-full"
                        className="border-0 auto-complete"
                        placeholder="Wedding Venues"
                        value={searchVendor?.name}
                        onClick={(e) => op.current.toggle(e)}
                        onChange={(e) => filterData(e?.target?.value)}
                      />
                      <div className="absolute top-0 w-full home-search-box">
                        {searchValue?.length > 0 ? (
                          <Menu
                            appendTo={"self"}
                            className={
                              searchVendor?.length > 0
                                ? "partication w-auto  "
                                : "overlay-pannel search-panel"
                            }
                            style={{
                              columnCount: "2",
                              columnRule: "1px solid black",
                            }}
                            model={searchValue?.map((item, index) => ({
                              label: (
                                <div
                                  key={index}
                                  className={
                                    searchVendor?.length > 0
                                      ? "flex align-items-center divider  py-3 w-full"
                                      : "flex align-items-center cursor-pointer py-2 w-full"
                                  }
                                  onClick={(e) => {
                                    setSearchVendor(item);
                                    op.current.toggle(e);
                                  }}
                                >
                                  <img
                                    src={Constants.HOST + item?.icon}
                                    width={24}
                                    height={24}
                                    alt=""
                                  />
                                  <span>
                                    <p className="ml-2 p-0 m-0">
                                      {highlightSearchQuery(
                                        item?.name,
                                        searchVendor
                                      )}
                                    </p>
                                  </span>
                                </div>
                              ),
                            }))}
                            popup
                            ref={op}
                            id="popup_menu_left"
                            popupAlignment="left"
                          />
                        ) : null}
                      </div>
                    </span>
                  </div>
                  <Divider layout="vertical" className="m-0 hidden lg:block" />
                  <Divider className="m-0 lg:hidden block" />
                  <div className="flex w-full align-items-center lg:mb-0 mb-1">
                    <span className=" ml-2 p-0 m-0">
                      <i
                        className="pi pi-map-marker mt-2 "
                        style={{ fontSize: "1.2rem" }}
                      ></i>
                    </span>
                    <span className="w-full">
                      {/* {isLoaded && (
                        <StandaloneSearchBox
                          onLoad={(ref) => (inputRef.current = ref)}
                          onPlacesChanged={handlePlaceChanged}
                        >
                          <CustomInput
                            col={12}
                            placeholder="Location"
                            value={
                              postalCode?.length > 9
                                ? postalCode.substring(0, 9) + "..."
                                : postalCode
                            }
                            onChange={(e) => setPostalCode(e?.target?.value)}
                            extraClassName="search-margin w-full relative "
                            className="border-0"
                          />
                        </StandaloneSearchBox>
                      )} */}
                      <SearchBox
                        searchValue={postalCode}
                        setSearchValue={handlePlaceChanged}
                        placeholder="Location"
                        cityOnly={true}
                      />
                    </span>
                  </div>
                  <div className="w-full  ">
                    <ButtonComponent
                      label="Get Started"
                      className="border-round-lg w-full get-started-button "
                      style={{ height: "56px" }}
                      onClick={handleSearch}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="md:col-5 lg:col-6 flex-order-1 md:flex-order-2 p-0 mb-3 lg:m-0 md:m-0">
            <img src={BannerImg} alt="" className="w-full banner-img h-full" />
          </div>
        </div>
      </ContainerBox>

      {/* <div className="">
        {searchValue?.length > 0 ? (
          <OverlayPanel
            ref={op}
            className={
              searchVendor?.length > 0 ? "partication" : "overlay-pannel"
            }
            style={{ columnCount: "2", columnRule: "1px solid black" }}
          >
            {searchValue?.length > 0 &&
              searchValue?.map((item, index) => (
                <div
                  key={index}
                  className={
                    searchVendor?.length > 0
                      ? "flex align-items-center py-3  border-bottom-1 border-300"
                      : "flex align-items-center py-3 cursor-pointer "
                  }
                  onClick={(e) => {
                    setSearchVendor(item);
                    op.current.toggle(e);
                  }}
                >
                  <img
                    src={Constants.HOST + item?.icon}
                    width={24}
                    height={24}
                    alt=""
                  />
                  <span>
                    <p className="ml-2 p-0 m-0">
                      {highlightSearchQuery(item?.name, searchVendor)}
                    </p>
                  </span>
                </div>
              ))}
          </OverlayPanel>
        ) : null}
      </div> */}
    </div>
  );
};
