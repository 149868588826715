import { types } from "../types";
import Constants from "../../services/constant";
import api from "../../services/api";
import { showToast } from "./toastAction";

export const sendOtpAction = (data, next) => async (dispatch) => {
    console.log(next);
    const res = await api("post", Constants.END_POINT.VENDOR_SEND_OTP, data);
    if (res.success) {
        next();
    } else {
        dispatch(showToast({ severity: "error", summary: res.message }));
    }
};

export const verifyOTP = (data, next) => async (dispatch) => {
    const res = await api("post", Constants.END_POINT.VERIFY_OTP, data);
    if (res.success) {
        // next();
        return res;
    } else {
        dispatch(showToast({ severity: "error", summary: res.message }));
    }
};
