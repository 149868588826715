import { types } from "../types";

const initialState = {
  budgetListProfile: [],
  addBudgetProfile: [],
  budgetListByVendorType: [],
};

export const budgetListReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.BUDGET_LIST:
      return {
        ...state,
        budgetListProfile: action.payload,
      };
    case types.BUDGET_LIST_BY_VENDOR_TYPE:
      return {
        ...state,
        budgetListByVendorType: action.payload,
      };
    default: {
      return state;
    }
  }
};

export const addBudgetReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.ADD_BUDGET:
      return {
        ...state,
        addBudgetProfile: action.payload,
      };
    default: {
      return state;
    }
  }
};
