import React, { useEffect, useState } from "react";
import { VendorLayout } from "../../layout/VendorLayout";
import { ContainerBox } from "../../shared/ContainerBox";
import { CustomPassword } from "../../components/input/AllInput";
import ButtonComponent from "../../components/buttons/button";
import AccountSettingContainer from "../Host/profile/AccountSettingContainer";
import { confirmDialogue, formatCurrency } from "../../utils/javascript";
import TabPanelVendor from "../overview-vendor/TabPanelVendor";
import { useDispatch, useSelector } from "react-redux";
import { myProfileDataAction, quoteDataAction } from "../../redux/actions/bussinessInformationAction";
import { useLocation } from "react-router-dom";
import { deleteVendorProfileAction, myProfileAction } from "../../redux/actions/profileAction";
import { Divider } from "primereact/divider";

import { logout } from "../../services/authService";
import { useNavigate } from "react-router-dom";
import MonthlyIcon from "../../assets/icons/monthly icon.png";
import AnnuallyIcon from "../../assets/icons/annually icon.png";
import CheckIconfrom from "../../assets/icons/Check.png";
import { allMemberShipAction, cancelPlanAction, changePlanAction, showPlan } from "../../redux/actions/memberShipsAction";
import { Dialog } from "primereact/dialog";
import moment from "moment";
import { types } from "../../redux/types";
export default function AccountSettings({ isLoaded }) {
    const { profileChangePassword, handleChange, save } = AccountSettingContainer();

    const { toastInfo } = useSelector((state) => state?.toast);
    useEffect(() => {
        return () => {
            dispatch({ type: types.SHOW_TOAST, payload: {} });
        };
    }, []);
    console.log(toastInfo);

    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const data = location.state;
    const [activeIndex, setActiveIndex] = useState(null);
    const [sortValue, setSortValue] = useState();
    const [visible, setVisible] = useState(false);
    const [yearlyPlan, setYearlyPlan] = useState(false);
    const [monthPlan, setMonthPlan] = useState(false);
    const [yearlyId, setYearlyId] = useState("");
    const [discountCode, setDiscountCode] = useState("");
    const [item, setItem] = useState("");
    useEffect(() => {
        let params;
        if (sortValue === "old") {
            params = {
                sortOrder: "DESC",
            };
        } else {
            params = {
                sortValue: "ASC",
            };
        }
        dispatch(quoteDataAction(params));
    }, [sortValue]);
    const quoteData = useSelector((state) => state?.quoteData?.quoteDataProfile);
    const sort = ["old", "New"];
    useEffect(() => {
        if (data?.navigate === true) {
            setActiveIndex(null);
        }
    }, [data]);
    const deleteAcoount = () => {
        dispatch(
            deleteVendorProfileAction(() => {
                logout(() => {
                    navigate("/login");
                });
            })
        );
    };
    useEffect(() => {
        dispatch(allMemberShipAction());
        dispatch(showPlan());
    }, []);
    const showPlanData = useSelector((state) => state?.showPlan?.showPlanProfile);
    const handleYearly = (item) => {
        setItem(item);
        setDiscountCode(item?.discountCode?.discountCode);
        setYearlyId(item?._id);
        if (item?.isSubscribed === false && item?.subscriptionType === "year") {
            setYearlyPlan(true);
        } else {
            setMonthPlan(true);
        }
    };
    const myProfileData = useSelector((state) => state?.vendorProfile?.vendorProfile);
    const endDate = moment(myProfileData?.subscription?.endDate)?.add(1, "day");
    const oneYearLater = endDate.add(1, "year");
    const handleCancel = (id) => {
        dispatch(cancelPlanAction(id)).then(() => {
            dispatch(myProfileDataAction());
        });
        setVisible(false);
    };
    const handleUpgradePlan = () => {
        let payload = {
            currentSubscriptionId: myProfileData?.subscription?._id,
            subscriptionType: yearlyId,
            discountCode: discountCode,
            vendor: myProfileData?._id,
        };
        dispatch(changePlanAction(payload)).then(() => {
            navigate(`/checkout/${yearlyId}?upgradePlan=true`, {
                state: {
                    item,
                    currentSubscriptionId: showPlanData?.find((item) => item?.isSubscribed)?.userSubscriptionId,
                },
            });
        });
    };
    return (
        <>
            <TabPanelVendor
                activeIndex={activeIndex}
                setActiveIndex={setActiveIndex}
                quoteData={quoteData}
                sort={sort}
                sortValue={sortValue}
                setSortValue={setSortValue}
                isLoaded={isLoaded}
            />
            {activeIndex === null && data?.navigate === true ? (
                <ContainerBox containerType="">
                    <h1 className="merriweather text-2xl text-dark-gray font-bold md:py-3">Account Setting</h1>
                    {toastInfo?.summary && <div className="p-3 border border-2 alert-error mb-2 text-center">{toastInfo?.summary}</div>}

                    <p className="text-base font-semibold  text-dark-gray mb-5">Change Password</p>
                    <div className="grid  p-0 m-0 border-1 p-3 mb-5 border-round-lg" style={{ borderColor: "#ECECEC" }}>
                        <div className="col-12 md:col-12">
                            <CustomPassword
                                placeholder="Enter your old password"
                                label="Old Password"
                                name="password"
                                value={profileChangePassword?.password}
                                onChange={handleChange}
                            ></CustomPassword>
                            <CustomPassword
                                placeholder="Enter your new password"
                                label="New Password"
                                name="newPassword"
                                value={profileChangePassword?.newPassword}
                                onChange={handleChange}
                            ></CustomPassword>
                            <CustomPassword
                                placeholder="confirm new password"
                                label="Confirm password"
                                name="confirmPassword"
                                value={profileChangePassword?.confirmPassword}
                                onChange={handleChange}
                            ></CustomPassword>
                            <ButtonComponent
                                onClick={save}
                                label="Change Password"
                                className="text-sm bg-white text-main-color font-semibold ml-1 mt-2"
                            ></ButtonComponent>
                        </div>
                    </div>
                    <p className="text-base font-semibold  text-dark-gray mb-4">Delete Account</p>
                    <div className="grid p-0 m-0  border-1 p-3 border-round-lg" style={{ borderColor: "#ECECEC" }}>
                        <div className="col-12 md:col-12">
                            <p className="p-0 m-0 mb-5 text-sm font-medium  text-light-black ">
                                Your account and all the personal information and content saved in that account will be removed. Any forum posts made through
                                your account will remain active. Are you sure you want to delete your account?
                            </p>
                            <ButtonComponent
                                onClick={() => confirmDialogue(deleteAcoount)}
                                label="Delete Account"
                                className="text-sm bg-white text-main-color font-semibold"
                            ></ButtonComponent>
                        </div>
                    </div>
                    <p className="text-base font-semibold  text-dark-gray py-3">Subscription Plan</p>
                    <p className="text-base font-semibold  text-dark-gray mb-4">Current Plan</p>
                    {/* current Plan  */}

                    <>
                        <div className="grid mb-5">
                            {showPlanData?.length > 0 &&
                                showPlanData
                                    ?.filter((item) => item?.isSubscribed)
                                    .map((item, index) => {
                                        return (
                                            <div
                                                key={index}
                                                className=" cureent-plan col-12 md:col-5 border-1 p-3 border-round-lg shadow-1 "
                                                style={{ borderColor: "#ECECEC" }}
                                            >
                                                <div className="monthly-plan text-center">
                                                    <div className="">
                                                        <img src={MonthlyIcon} width={45} height={45} alt="" className="" />
                                                        <h1 className="merriweather text-2xl font-normal text-black-dark">{item?.subscriptionPlan}</h1>
                                                        <div className="flex flex align-items-center justify-content-center mb-2 ">
                                                            <h1 className="font-semibold text-black-dark text-6xl p-0 m-0">
                                                                {item?.subscriptionPlan === "Standard" ? "Free" : formatCurrency(item?.price)}{" "}
                                                            </h1>
                                                            <span className="mt-3" style={{ color: " #797878", fontSize: "19px" }}>
                                                                {item?.subscriptionPlan === "Standard" ? "" : "/Year"}
                                                            </span>
                                                        </div>
                                                        {item?.subscriptionPlan !== "Standard" ? (
                                                            <>
                                                                <p className="text-xs font-normal p-0 m-0 ">
                                                                    Billing Yearly at
                                                                    <b className="text-main-color ml-1 line-through">{formatCurrency(item?.price)} </b>{" "}
                                                                    <b className="text-main-color ml-1">{formatCurrency(item?.discountedPrice)} </b>
                                                                    +VAT
                                                                </p>
                                                                <p className="text-main-color text-xs underline">
                                                                    Receive <b className="text-main-color">25% discount</b> on buying Annual Plan
                                                                </p>
                                                            </>
                                                        ) : null}
                                                    </div>
                                                    <Divider className="mt-6 pt-1" />

                                                    <div className="feauture-container ">
                                                        <div>
                                                            {item?.features?.length > 0 &&
                                                                item?.features?.map((items) => {
                                                                    return (
                                                                        <div className="flex align-items-center mb-3">
                                                                            <img src={CheckIconfrom} width={22} height={22} alt="" />
                                                                            <span className="ml-3 text-lg font-normal text-black-dark">{items}</span>
                                                                        </div>
                                                                    );
                                                                })}
                                                        </div>
                                                    </div>
                                                </div>
                                                {item?.isSubscribed === true && item?.subscriptionStatus === "active" ? (
                                                    <ButtonComponent
                                                        label="Cancel Plan"
                                                        className="w-full bg-transparent text-dark-gray text-base font-normal border-500 border-2"
                                                        onClick={() => setVisible(true)}
                                                    ></ButtonComponent>
                                                ) : item?.isSubscribed === true && item?.subscriptionStatus === "canceled" ? (
                                                    <ButtonComponent
                                                        onClick={() => navigate("/checkout/" + item._id)}
                                                        label="Buy Again"
                                                        className="w-full font-semibold text-base"
                                                    ></ButtonComponent>
                                                ) : (
                                                    <ButtonComponent
                                                        onClick={(e) => handleYearly(item)}
                                                        label="Buy Now"
                                                        className="w-full font-semibold text-base"
                                                    ></ButtonComponent>
                                                )}
                                            </div>
                                        );
                                    })}{" "}
                        </div>
                    </>

                    {/* Explore Plan */}
                    <p className="text-base font-semibold  text-dark-gray mb-4">Explore Plan</p>
                    <div className="grid gap-5 p-0 m-0 mb-5  ">
                        {showPlanData?.length > 0 &&
                            showPlanData
                                ?.filter((item) => !item?.isSubscribed)
                                .map((item) => {
                                    return (
                                        <>
                                            <div
                                                className=" cureent-plan col-12 md:col-5  border-1 p-3 border-round-lg shadow-1 relative"
                                                style={{ borderColor: "#ECECEC" }}
                                            >
                                                <div className="monthly-plan ">
                                                    <div className="text-center">
                                                        <img src={MonthlyIcon} width={45} height={45} alt="" className="" />
                                                        <h1 className="merriweather text-2xl font-normal text-black-dark">{item?.subscriptionPlan}</h1>
                                                        <div className="flex flex align-items-center justify-content-center mb-2 ">
                                                            <h1 className="font-semibold text-black-dark text-6xl p-0 m-0">
                                                                {item.subscriptionPlan === "Standard" ? "Free" : formatCurrency(item?.price)}{" "}
                                                            </h1>
                                                            <span className="mt-3" style={{ color: " #797878", fontSize: "19px" }}>
                                                                {item?.subscriptionPlan === "Standard" ? "" : "/Year"}
                                                            </span>
                                                        </div>

                                                        {item?.subscriptionPlan === "Standard" ? (
                                                            ""
                                                        ) : item?.subscriptionType === "year" ? (
                                                            <>
                                                                <p className="text-xs font-normal p-0 m-0 ">
                                                                    Billing Yearly at
                                                                    <b className="text-main-color ml-1 ">{formatCurrency(item?.price)} </b>{" "}
                                                                </p>
                                                            </>
                                                        ) : null}
                                                    </div>
                                                    <Divider className="mt-6 pt-1" />
                                                    <div className="feauture-container ">
                                                        <div>
                                                            {item?.features?.length > 0 &&
                                                                item?.features?.map((items) => {
                                                                    return (
                                                                        <div className="flex align-items-center mb-3">
                                                                            <img src={CheckIconfrom} width={22} height={22} alt="" />
                                                                            <span className="ml-3 text-lg font-normal text-black-dark">{items}</span>
                                                                        </div>
                                                                    );
                                                                })}
                                                        </div>
                                                    </div>
                                                </div>
                                                {item?.isSubscribed === true && myProfileData?.subscription?.status === "active" ? (
                                                    <ButtonComponent
                                                        label="Cancel Plan"
                                                        className="w-full bg-transparent text-dark-gray text-base font-normal border-500 border-2"
                                                        onClick={() => setVisible(true)}
                                                    ></ButtonComponent>
                                                ) : item?.isSubscribed === true && myProfileData?.subscription?.status === "canceled" ? (
                                                    <ButtonComponent
                                                        onClick={() => navigate("/membership")}
                                                        label="Buy Again"
                                                        className="w-full font-semibold text-base"
                                                    ></ButtonComponent>
                                                ) : (
                                                    <ButtonComponent
                                                        onClick={(e) => handleYearly(item)}
                                                        label="Buy Now"
                                                        className="w-full font-semibold text-base"
                                                    ></ButtonComponent>
                                                )}
                                                {item.subscriptionPlan === "Standard" ? (
                                                    ""
                                                ) : (
                                                    <div className="box">
                                                        <div className="ribbon">
                                                            <span>{Math.round(item?.discountPercentage)}% Discount</span>{" "}
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </>
                                    );
                                })}
                    </div>
                </ContainerBox>
            ) : null}
            <Dialog
                header={
                    <>
                        <h1 className="text-2xl font-bold  merriweather text-dark-gray p-0 m-0">Cancel Membership Plan</h1>
                    </>
                }
                visible={visible}
                style={{ width: "100vw" }}
                onHide={() => setVisible(false)}
                className="lg:w-3 md:w-8 w-11"
                draggable={false}
                resizable={false}
            >
                <p>
                    As you choose to end your current plan, keep in mind that platform features will be{" "}
                    <span className="text-main-color">inaccessible after {moment(myProfileData?.subscription?.endDate).format("DD MMM YYYY")}</span>
                </p>
                <p className="m-0">
                    <ButtonComponent label="Cancel Plan" className="w-full" onClick={(e) => handleCancel(myProfileData?.subscription?._id)}></ButtonComponent>
                </p>
            </Dialog>
            <Dialog
                header={
                    <>
                        <h1 className="text-2xl font-bold  merriweather text-dark-gray p-0 m-0">Upgrade Membership Plan</h1>
                    </>
                }
                visible={yearlyPlan}
                style={{ width: "100vw" }}
                onHide={() => setYearlyPlan(false)}
                className="lg:w-3 md:w-8 w-11"
                draggable={false}
                resizable={false}
            >
                <p>
                    The Annual subscription will start once the current plan finishes on the
                    <span className="text-main-color">{moment(myProfileData?.subscription?.endDate).format("DD MMM")}</span>. The Annual plan will start from{" "}
                    <span className="text-main-color"> {moment(myProfileData?.subscription?.endDate)?.add(1, "day").format("DD MMM YYYY")}</span> and ends{" "}
                    <span className="text-main-color">{moment(oneYearLater).format("DD MMM YYYY")}</span>. Please note that you cannot cancel the subscription
                    until the Annual subscriptions ends.
                </p>
                <p className="m-0">
                    <ButtonComponent label="Upgrade Plan" className="w-full" onClick={handleUpgradePlan}></ButtonComponent>
                </p>
            </Dialog>
            <Dialog
                header={
                    <>
                        <h1 className="text-2xl font-bold  merriweather text-dark-gray p-0 m-0">Purchase Membership Plan</h1>
                    </>
                }
                visible={monthPlan}
                style={{ width: "100vw" }}
                onHide={() => setMonthPlan(false)}
                className="lg:w-3 md:w-8 w-11"
                draggable={false}
                resizable={false}
            >
                <p>Please note that downgrading the plan is not possible until your current subscription concludes.</p>
            </Dialog>
        </>
    );
}
