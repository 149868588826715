import React, { useEffect, useState } from "react";
import VendorContentData from "./VendorContentData";
import { TabView, TabPanel } from "primereact/tabview";
import { CardComponent, SupplierCard } from "../../components/cards/card";
import MarriageContent from "./MarriageContent";
import { ContainerBox } from "../../shared/ContainerBox";
import { DomHandler } from "primereact/utils";
import ChartData from "./ChartData";
import GroupImage from "../../assets/icons/Group.png";
import FrameImage from "../../assets/icons/Frame (2).png";
import BudgetTable from "../../components/table/BudgetTable";
import {
  filterVendors,
  showAllTentativeAction,
} from "../../redux/actions/BusinessProfile";
import { useDispatch, useSelector } from "react-redux";
import WeedingSupplierImg from "../../assets/images/venuePlaceholder.png";
import {
  addBudgetAction,
  budgetAction,
  budgetActionByVendotType,
  deleteBudgetAction,
} from "../../redux/actions/BudgetAction";
import ButtonComponent from "../../components/buttons/button";
import Constants from "../../services/constant";
import { Carousel } from "primereact/carousel";

export default function BudgetContent() {
  const dispatch = useDispatch();
  const { activeIndex, setActiveIndex, BudgetVendorData } = VendorContentData();
  const vendorByType = useSelector(
    (state) => state?.vendorFilter?.vendorFilterProfile
  );
  const showAllTentativeData = useSelector(
    (state) => state?.showAllTentative?.showAllTentativeProfile
  );
  const budgetByType = useSelector(
    (state) => state?.budgetList?.budgetListByVendorType
  );

  const [selectedVendorType, setSelectedVendorType] = useState({});
  const [data, setData] = useState({});
  const [error, setError] = useState({});
  const [suppliers, setSuppliers] = useState([]);
  const [budgetData, setBudgetData] = useState([]);

  useEffect(() => {
    if (selectedVendorType?._id) {
      dispatch(showAllTentativeAction(selectedVendorType?._id, () => null));
      setData({
        vendorType: selectedVendorType?._id,
        eventType: activeIndex === 0 ? "Wedding" : "Reception",
        vendor: "",
        estimatedCost: 0,
        finalCost: 0,
        paid: 0,
        pendingAmount: 0,
      });
      dispatch(
        budgetActionByVendotType(selectedVendorType?._id, {
          eventType: activeIndex === 0 ? "Wedding" : "Reception",
        })
      );
      dispatch(filterVendors({ vendorType: selectedVendorType?._id }));
    }
  }, [selectedVendorType, activeIndex]);

  useEffect(() => {
    setSelectedVendorType({});
  }, [activeIndex]);

  useEffect(() => {
    if (showAllTentativeData?.vendors?.length) {
      setSuppliers(
        showAllTentativeData?.vendors?.map((supplier) => ({
          name: supplier.businessName,
          // value: supplier._id,
          value: supplier.businessName,
        }))
      );
    }
  }, [showAllTentativeData]);

  const getUpdatedExpense = () => {
    dispatch(
      budgetAction({
        eventType: activeIndex === 0 ? "Wedding" : "Reception",
      })
    );
    dispatch(
      budgetActionByVendotType(selectedVendorType?._id, {
        eventType: activeIndex === 0 ? "Wedding" : "Reception",
      })
    );
  };

  const addExpense = () => {
    setBudgetData([
      ...budgetData,
      {
        vendor: "",
        estimatedCost: 0,
        finalCost: 0,
        paid: 0,
        pendingAmount: 0,
      },
    ]);
    setData({
      ...data,
      vendor: "",
      estimatedCost: 0,
      finalCost: 0,
      paid: 0,
      pendingAmount: 0,
      isAction: false,
    });

    setTimeout(
      () =>
        DomHandler.find(document.body, "#editorPencil")[
          budgetData.length || 0
        ]?.click(),
      100
    );
  };

  const deleteExpense = (id) => {
    if (id) {
      dispatch(deleteBudgetAction(id, getUpdatedExpense));
    } else {
      getUpdatedExpense();
    }
  };

  useEffect(() => {
    if (data.vendor !== "" && data.isAction) {
      dispatch(addBudgetAction(data, getUpdatedExpense));
    } else if (data.vendor === "" && data.isAction) {
      setError({ vednor: "Vendor is required" });
    }
  }, [data]);

  useEffect(() => {
    if (budgetByType?.budgetList?.length) {
      setBudgetData(budgetByType?.budgetList);
    } else {
      setBudgetData([]);
    }
  }, [budgetByType]);

  const supplierCardTemplate = (item) => {
    return (
      <div className="m-2 p-4" style={{ maxWidth: "250px" }}>
        <SupplierCard
          img={
            item.profileImage
              ? Constants.HOST + item?.profileImage
              : WeedingSupplierImg
          }
          rating={item.rating}
          title={item.businessName}
          location={item.city}
          item={item}
          person={item.person}
          id={item._id}
          SubscriptionType={item?.subscriptionType}
        />
      </div>
    );
  };

  const responsiveOptions = [
    {
      breakpoint: "1400px",
      numVisible: 2,
      numScroll: 1,
    },
    {
      breakpoint: "1199px",
      numVisible: 3,
      numScroll: 1,
    },
    {
      breakpoint: "767px",
      numVisible: 2,
      numScroll: 1,
    },
    {
      breakpoint: "575px",
      numVisible: 1,
      numScroll: 1,
    },
  ];

  return (
    <>
      <div className="profile-container mt-5 mb-7">
        <ContainerBox>
          <div className="budget-container">
            <div className="grid p-0 m-0 flex">
              <div className="col-12 md:col-4   ">
                <CardComponent className="shadow-1 h-full">
                  <TabView
                    activeIndex={activeIndex}
                    onTabChange={(e) => {
                      setActiveIndex(e.index);
                      setSelectedVendorType({});
                    }}
                  >
                    <TabPanel
                      header="Marriage"
                      headerClassName="flex justify-content-center w-full text-center"
                    >
                      <MarriageContent
                        BudgetVendorData={BudgetVendorData}
                        setSelectedVendorType={setSelectedVendorType}
                        selectedVendorType={selectedVendorType}
                        setBudgetData={setBudgetData}
                        budgetData={budgetData}
                      />
                    </TabPanel>

                    <TabPanel
                      header="Reception"
                      headerClassName="flex justify-content-center w-full text-center"
                    >
                      <MarriageContent
                        BudgetVendorData={BudgetVendorData}
                        setSelectedVendorType={setSelectedVendorType}
                        selectedVendorType={selectedVendorType}
                        setBudgetData={setBudgetData}
                        budgetData={budgetData}
                      />
                    </TabPanel>
                  </TabView>
                </CardComponent>
              </div>
              <div className="col-12 md:col-8">
                <CardComponent className="shadow-1 p-3 md:p-5 ">
                  {selectedVendorType?._id ? (
                    <>
                      <div className="flex flex-wrap gap-2 justify-content-between align-items-center ">
                        <h2 className="my-0">{selectedVendorType?.name}</h2>
                        {budgetData[budgetData?.length - 1]?.vendor !== "" ? (
                          <ButtonComponent
                            icon="pi pi-plus"
                            label="Add New Expense"
                            className="flex align-items-center gap-2 cursor-pointer m-0"
                            onClick={addExpense}
                          />
                        ) : null}
                      </div>
                      <div className="flex gap-4 ">
                        <p>
                          Estimated Cost{" "}
                          <span className="text-main-color ">
                            {new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: "GBP",
                            }).format(budgetByType?.totalEstimatedCost)}
                          </span>
                        </p>
                        <p>
                          Final Cost{" "}
                          <span className="text-main-color ">
                            {new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: "GBP",
                            }).format(budgetByType?.totalFinalCost)}
                          </span>
                        </p>
                      </div>
                      <BudgetTable
                        budgetByType={budgetByType}
                        budgetData={budgetData}
                        setBudgetData={setBudgetData}
                        vendorList={suppliers}
                        data={data}
                        setData={setData}
                        deleteExpense={deleteExpense}
                      />
                      {vendorByType?.vendors?.length ? (
                        <div className="mt-8">
                          <p className="text-sm font-semibold">
                            You Might be Interested in these Suppliers
                          </p>
                          <div>
                            <Carousel
                              value={vendorByType?.vendors}
                              numVisible={3}
                              numScroll={3}
                              responsiveOptions={responsiveOptions}
                              className="custom-carousel"
                              circular
                              autoplayInterval={3000}
                              itemTemplate={supplierCardTemplate}
                              showNavigators={false}
                            />
                          </div>
                        </div>
                      ) : null}
                    </>
                  ) : (
                    <>
                      <div className="content p-5">
                        <h1 className="merriweather text-2xl text-dark-gray font-bold p-0 m-0 mb-5 ">
                          Expense
                        </h1>
                        <div className="grid">
                          <div className="col-12 md:col-6 text-center">
                            <div
                              className=" border-round-lg p-3 h-full "
                              style={{ backgroundColor: "#F5F5F5" }}
                            >
                              <div className="img flex justify-content-center">
                                <p
                                  className="p-3 w-3 bg-light-pink p"
                                  style={{ borderRadius: "50%" }}
                                >
                                  <img
                                    src={GroupImage}
                                    alt=""
                                    width={28}
                                    height={28}
                                  />
                                </p>
                              </div>
                              <p className="text-sm md:text-base font-semibold text-light-black p-0 m-0">
                                Estimated Cost
                              </p>
                              <p className="text-lg md:text-2xl font-semibold text-light-black p-0 m-0 mb-3">
                                {new Intl.NumberFormat("en-US", {
                                  style: "currency",
                                  currency: "GBP",
                                }).format(BudgetVendorData?.totalEstimatedCost)}
                              </p>
                            </div>
                          </div>
                          <div className="col-12 md:col-6 text-center">
                            <div
                              className=" border-round-lg p-3 h-full "
                              style={{ backgroundColor: "#F5F5F5" }}
                            >
                              <div className="img flex justify-content-center">
                                <p
                                  className="p-3 w-3 bg-light-pink p"
                                  style={{ borderRadius: "50%" }}
                                >
                                  <img
                                    src={FrameImage}
                                    alt=""
                                    width={28}
                                    height={28}
                                  />
                                </p>
                              </div>
                              <p className="text-sm md:text-base font-semibold text-light-black p-0 m-0">
                                Final Cost
                              </p>
                              <p className="text-lg md:text-2xl font-semibold text-light-black p-0 m-0 mb-3">
                                {new Intl.NumberFormat("en-US", {
                                  style: "currency",
                                  currency: "GBP",
                                }).format(BudgetVendorData?.totalFinalCost)}
                              </p>
                              <p className="text-sm md:text-base">
                                <b>Paid</b>{" "}
                                {new Intl.NumberFormat("en-US", {
                                  style: "currency",
                                  currency: "GBP",
                                }).format(BudgetVendorData?.totalPaidCost)}
                                &nbsp; <b>Pending </b>
                                {new Intl.NumberFormat("en-US", {
                                  style: "currency",
                                  currency: "GBP",
                                }).format(BudgetVendorData?.totalPendingCost)}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="content p-5">
                        {BudgetVendorData?.totalFinalCost ? (
                          <>
                            <h4 className="merriweather  text-dark-gray font-bold p-0 m-0 mb-5 ">
                              Expenses
                            </h4>

                            <ChartData budgetVendorData={BudgetVendorData} />
                          </>
                        ) : null}
                      </div>
                    </>
                  )}
                </CardComponent>
              </div>
            </div>
          </div>
        </ContainerBox>
      </div>
    </>
  );
}
