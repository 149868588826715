import React from "react";
import { IndexLayout } from "../../layout";
import { ExploreBanner } from "./ExploreBanner";
import { ExploreList } from "./ExploreList";

export const Explore = ({ isLoaded }) => {
  return (
    <>
      <ExploreBanner />
      <ExploreList isLoaded={isLoaded} />
    </>
  );
};
