import React, { useState, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import SelectCheckboxIcon from "../../assets/icons/select checkbox.png";
import PendingIcon from "../../assets/icons/pending.png";
import EditIcon from "../../assets/icons/guests edit.png";
import DeleteIcon from "../../assets/icons/guests delete.png";
import DeclinedIcon from "../../assets/icons/declined.png";
import EyeIcon from "../../assets/icons/eye.png";
import moment from "moment/moment";
import { Dialog } from "primereact/dialog";
import { CustomInput } from "../input/AllInput";
import ButtonComponent from "../buttons/button";
import { Menu } from "primereact/menu";
import { confirmDialog } from "primereact/confirmdialog";
export default function Table({
    tableHeading,
    tableData,
    filters,
    title,
    handleGrandTotal,
    selectedProducts,
    setSelectedProducts,
    setDeleteQuoteId,
    visible,
    setVisible,
    handleChange,
    deleteQuote,
    handleDelete,
    error,
    setViewQuote,
    viewQuote,
    handleView,
    viewQuoteData,
    handleImportant,
    handleViewClose,
    setData,
    expendRow,
    ...props
}) {
    const [deleteButton, setDeleteButton] = useState(false);
    const [quoteId, setQuoteId] = useState("");
    const viewPopup = useRef(null);
    const actionMenu = useRef(null);

    const viewPopupItems = [
        {
            className: "pl-3 md:pl-5 cursor-pointer",
            template: () => {
                return (
                    <p
                        className="flex text-sm"
                        onClick={() => {
                            setViewQuote(true);
                            handleView(quoteId?._id);
                        }}
                    >
                        <img src={EyeIcon} width={24} height={24} alt=""></img>
                        <span className="ml-2 mt-1 text-sm font-normal text-dark-gray">View</span>
                    </p>
                );
            },
        },
        {
            className: "pl-3 md:pl-5 ",
            template: () => {
                return quoteId?.status === "IMPORTANT" ? (
                    <p className="flex ">
                        <i className="pi pi-star-fill text-yellow" style={{ fontSize: "1.5rem" }}></i>
                        <span className="ml-2 mt-1 text-base font-normal" style={{ color: "#D9D7D2" }}>
                            Important
                        </span>
                    </p>
                ) : quoteId?.status === "DECLINED" ? (
                    <p className="flex ">
                        <i className="pi pi-star " style={{ fontSize: "1.5rem", color: "#D9D7D2" }}></i>
                        <span className="ml-2 mt-1 text-base font-normal" style={{ color: "#D9D7D2" }}>
                            Important
                        </span>
                    </p>
                ) : (
                    <p className="flex cursor-pointer" onClick={() => handleImportant(quoteId)}>
                        <i className="pi pi-star text-yellow" style={{ fontSize: "1.5rem" }}></i>

                        <span className="ml-2 mt-1 text-sm font-normal text-dark-gray">Important</span>
                    </p>
                );
            },
        },
        {
            className: "pl-3 md:pl-5 cursor-pointer",
            template: () => {
                return quoteId?.status === "DECLINED" ? (
                    <p className="flex " style={{ color: "rgb(217, 215, 210)" }}>
                        <i className="pi pi-trash" style={{ color: "rgb(217, 215, 210)", fontSize: "1.5rem" }}></i>
                        <span className="ml-2 mt-1 text-base font-normal">Decline</span>
                    </p>
                ) : (
                    <p
                        className="flex "
                        onClick={() => {
                            setDeleteQuoteId(quoteId);
                            setVisible(true);
                        }}
                    >
                        <img src={DeleteIcon} width={24} height={24} alt="" className="mt-1"></img>
                        <span className="ml-2 mt-1 text-sm font-normal text-dark-gray">Decline</span>
                    </p>
                );
            },
        },
    ];
    const handleclick = () => {
        setDeleteButton(!deleteButton);
    };

    const ActionBodyTemplate = (col) => {
        const editGuest = (row) => {
            setData({
                isUpdate: true,
                id: row?._id,
                relativeFrom: row?.relativeFrom,
                name: row?.name,
                invitedTo: row?.invitedTo,
                acceptInviteWedding: row?.acceptInviteWedding,
                acceptInviteReception: row?.acceptInviteReception,
                familyMemberList: row?.familyMemberList?.map((member) => ({
                    name: member?.name,
                })),
            });
            props?.setInvitedTo((prev) => ({
                wedding: row.invitedTo === "WEDDING" || row.invitedTo === "BOTH",
                reception: row.invitedTo === "RECEPTION" || row.invitedTo === "BOTH",
            }));

            setVisible(true);
        };

        const deleteGuest = (row) => {
            confirmDialog({
                message: () => (
                    <span>
                        Do you want to delete{" "}
                        <b>
                            {row?.name}
                            {row?.familyMemberList?.length ? "'s family" : ""}
                        </b>{" "}
                        from guest list?
                    </span>
                ),
                header: "Delete Confirmation",
                icon: "pi pi-info-circle",
                defaultFocus: "reject",
                acceptClassName: "p-button-danger",
                accept: () => props.deleteGuest(row._id),
            });
        };

        return (
            <>
                <div className="">
                    <div className="relative">
                        <i
                            className="pi pi-pencil mx-2"
                            style={{ fontSize: "1rem" }}
                            onClick={(event) => {
                                editGuest(col);
                            }}
                        ></i>
                        <i
                            className="pi pi-trash text-red-500 ml-2"
                            style={{ fontSize: "1rem" }}
                            onClick={(event) => {
                                deleteGuest(col);
                            }}
                        ></i>
                    </div>
                </div>
            </>
        );
    };
    const statusBodyTemplate = (col) => {
        return (
            <>
                <div className="text-center">
                    {col?.status === "NEW" ? (
                        <span className=" ">
                            <p className="bg-blue-50 p-2  border-round-md text-light-blue text-xs font-medium" style={{ width: "85px" }}>
                                {col?.status}
                            </p>
                        </span>
                    ) : col?.status === "IMPORTANT" ? (
                        <span>
                            <p className="text-xs font-medium bg-green-50 p-2  border-round-md" style={{ color: "#48964D", width: "85px" }}>
                                {col?.status}
                            </p>
                        </span>
                    ) : col?.status === "DECLINED" ? (
                        <span>
                            <p
                                className="text-xs font-medium  p-2  border-round-md"
                                style={{
                                    color: "#FA564B",
                                    backgroundColor: "#FA564B1A",
                                    width: "85px",
                                }}
                            >
                                {col?.status}
                            </p>
                        </span>
                    ) : col?.status === "VIEWED" ? (
                        <span>
                            <p
                                className="text-xs font-medium  p-2  border-round-md "
                                style={{
                                    color: "#5e1d3d",
                                    backgroundColor: "#fad3e7",
                                    width: "85px",
                                }}
                            >
                                {col?.status}
                            </p>
                        </span>
                    ) : null}
                </div>
            </>
        );
    };
    const VendorActionBodyTemplate = (col) => {
        return (
            <>
                <div className="">
                    <div className="relative">
                        <i
                            className="pi pi-ellipsis-h ml-2"
                            style={{ fontSize: "1rem" }}
                            onClick={(event) => {
                                handleclick();
                                setQuoteId(col);
                                viewPopup.current.toggle(event);
                            }}
                        ></i>
                        <Menu model={viewPopupItems} popup ref={viewPopup} id="popup_menu_left" />
                    </div>
                    {/* <div
            className=" absolute bg-gray-50 border-round-lg z-3 p-2 shadow-1 "
            style={{
              display: deleteButton ? "block" : "none",
              top: "55px",
              right: "120px",
              position: "absolute"

            }}
          >
            <p
              className="flex "
              onClick={() => {
                setViewQuote(true);
                handleView(quoteId?._id);
              }}
            >
              <img src={EyeIcon} width={24} height={24} alt=""></img>
              <span className="ml-2 mt-1 text-sm font-normal text-dark-gray">
                View
              </span>
            </p>
            {quoteId?.status === "IMPORTANT" ? (
              <p
                className="flex "
                onClick={() => handleImportant(quoteId?._id)}
              >
                <i
                  className="pi pi-star-fill text-yellow"
                  style={{ fontSize: "1.5rem" }}
                ></i>
                <span className="ml-2 mt-1 text-sm font-normal text-dark-gray">
                  Important
                </span>
              </p>
            ) : quoteId?.status === "DECLINED" ? (
              <p className="flex ">
                <i
                  className="pi pi-star "
                  style={{ fontSize: "1.5rem", color: "#D9D7D2" }}
                ></i>
                <span
                  className="ml-2 mt-1 text-base font-normal"
                  style={{ color: "#D9D7D2" }}
                >
                  Important
                </span>
              </p>
            ) : (
              <p className="flex " onClick={() => handleImportant(quoteId)}>
                <i
                  className="pi pi-star text-yellow"
                  style={{ fontSize: "1.5rem" }}
                ></i>

                <span className="ml-2 mt-1 text-sm font-normal text-dark-gray">
                  Important
                </span>
              </p>
            )}
            {quoteId?.status === "DECLINED" ? (
              <p className="flex " style={{ color: "rgb(217, 215, 210)" }}>
                <i
                  className="pi pi-trash"
                  style={{ color: "rgb(217, 215, 210)", fontSize: "1.5rem" }}
                ></i>
                <span className="ml-2 mt-1 text-base font-normal">Decline</span>
              </p>
            ) : (
              <p
                className="flex "
                onClick={() => {
                  setDeleteQuoteId(quoteId);
                  setVisible(true);
                }}
              >
                <img
                  src={DeleteIcon}
                  width={24}
                  height={24}
                  alt=""
                  className="mt-1"
                ></img>
                <span className="ml-2 mt-1 text-sm font-normal text-dark-gray">
                  Decline
                </span>
              </p>
            )}
          </div> */}
                </div>
            </>
        );
    };

    const confirmBodyTemplate = () => {
        return (
            <div className="flex align-items-center gap-2">
                <img alt="" src={SelectCheckboxIcon} width={18} height={18} />
                <span>Confirmed</span>
            </div>
        );
    };
    const QuoteDateIconBodyTemplate = (col) => {
        return (
            <div className="flex align-items-center gap-2">
                {/* <i className="pi pi-user" style={{ fontSize: '1rem' }}></i> */}
                <span>{moment(col?.time)?.format("DD/MM/YYYY")}</span>
            </div>
        );
    };
    const pendingBodyTemplate = () => {
        return (
            <div className="flex align-items-center gap-2">
                <img alt="" src={PendingIcon} width={18} height={18} />
                <span>Pending</span>
            </div>
        );
    };

    const UserIconBodyTemplate = (col) => {
        return (
            <>
                {col?.numberOfGuests !== null ? (
                    <div className="flex align-items-center gap-2">
                        <i className="pi pi-user" style={{ fontSize: "1rem" }}></i>
                        <span>{col?.numberOfGuests}</span>
                    </div>
                ) : (
                    "-"
                )}
            </>
        );
    };

    const EventDateIconBodyTemplate = (col) => {
        return (
            <div className="flex align-items-center gap-2">
                {/* <i className="pi pi-user" style={{ fontSize: '1rem' }}></i> */}
                <span>{moment(col?.eventDate)?.format("DD/MM/YYYY")}</span>
            </div>
        );
    };
    //commit

    const selectedData = (e) => {
        setSelectedProducts(e.value);
    };

    const statusTemplate = (col, field) => {
        if (col[field] === "PENDING") {
            return (
                <div className="flex align-items-center gap-2 my-3">
                    <img alt="" src={PendingIcon} width={18} height={18} />
                    <span>Pending</span>
                </div>
            );
        } else if (col[field] === "CONFIRMED") {
            return (
                <div className="flex align-items-center gap-2 my-3">
                    <img alt="" src={SelectCheckboxIcon} width={18} height={18} />
                    <span>Confirmed</span>
                </div>
            );
        } else if (col[field] === "DECLINED") {
            return (
                <div className="flex align-items-center gap-2 my-3">
                    <img alt="" src={DeclinedIcon} width={18} height={18} />
                    <span>Declined</span>
                </div>
            );
        } else {
            return (
                <div className="flex align-items-center gap-2 my-3">
                    <i className="pi pi-exclamation-circle" style={{ fontSize: "15px", marginLeft: "2px" }} />
                    <span>Not Invited</span>
                </div>
            );
        }
    };

    const familyHeadTemplate = (row) => {
        return <span className="my-3">{row?.familyMemberList?.length > 0 ? row?.name + "'s Family" : row?.name}</span>;
    };
    const allowExpansion = (rowData) => {
        return rowData?.familyMemberList?.length > 0;
    };

    // dynamicColumns
    const dynamicColumns = tableHeading
        ? [
              expendRow ? <Column expander={allowExpansion} style={{ width: "5rem" }} /> : null,
              ...tableHeading.map((col, i) => {
                  return col.selectionMode ? (
                      <Column selectionMode="multiple" headerStyle={{ width: "3rem" }} key={i}></Column>
                  ) : (
                      <Column
                          key={col.field}
                          field={col.field}
                          header={col.header}
                          sortable={col.sortable ? true : false}
                          body={col.body ? (row) => eval(col.body) : ""}
                          style={{ fontSize: "14px", ...col.style }}
                      />
                  );
              }),
          ]
        : null;

    return (
        <div>
            {/* {tableData?.length > 0 ? ( */}
            <>
                <DataTable
                    value={tableData ? tableData : null}
                    responsiveLayout="scroll"
                    filters={filters}
                    globalFilterFields={["name", "company_name"]}
                    className="text-center shadow-1 dynamicdata"
                    style={{ borderRadius: "10px" }}
                    // paginator
                    sortMode="multiple"
                    selectionMode={"checkbox"}
                    selection={selectedProducts?.length ? selectedProducts : null}
                    onSelectionChange={setSelectedProducts ? (e) => selectedData(e) : null}
                    paginatorTemplate=" PrevPageLink CurrentPageReport NextPageLink "
                    rows={10}
                    currentPageReportTemplate={`Showing {first} to {last} of {totalRecords}`}
                    {...props}
                    emptyMessage={() => <p className="text-sm">No result found</p>}
                >
                    {dynamicColumns}
                </DataTable>
                {title === "addClient" || title === "viewClient" || title === "editClient" ? handleGrandTotal : ""}
            </>
            {/* ) : (
        <div className="grid p-5">
          <div className="col-12 md:col-12 text-center">
            <img
              src={InquiryNoteFoundImg}
              alt=""
              width={127}
              height={127}
              className=""
            />
            <p className="text-bse font-normal text-dark-gray">
              No Inquiry has been found yet
            </p>
          </div>
        </div>
      )} */}
            <div className="card flex justify-content-center">
                <Dialog
                    header={
                        <>
                            <h1 className="text-2xl font-bold  merriweather text-dark-gray ">Decline Inquiry</h1>
                        </>
                    }
                    visible={visible}
                    style={{ width: "100vw" }}
                    onHide={() => setVisible(false)}
                    className="lg:w-4 md:w-8 w-11"
                >
                    <p className="m-0 mb-3 font-normal text-sm" style={{ color: "#667085" }}>
                        Are you sure you want to delete this Inquiry? This action cannot be undone.
                    </p>
                    <div>
                        <CustomInput
                            label="Reasons for Decline"
                            required
                            onChange={handleChange}
                            name="reasonToDecline"
                            value={deleteQuote?.reasonToDecline}
                            errorMessage={error?.reasonToDecline}
                            col={12}
                        />
                    </div>
                    <div>
                        <ButtonComponent label="Cancel" className="bg-white text-base font-medium  text-dark border-200" onClick={() => setVisible(false)} />
                        <ButtonComponent label="Decline" className="ml-2 text-white bg-red-400 border-0" onClick={handleDelete} />
                    </div>
                </Dialog>
            </div>
            <div className="card flex justify-content-center">
                <Dialog
                    header={
                        <>
                            <h1 className="font-bold text-2xl merriweather text-dark-gray">Inquiry</h1>
                        </>
                    }
                    visible={viewQuote}
                    style={{ width: "100vw" }}
                    onHide={handleViewClose}
                    className="lg:w-4 md:w-10 w-11"
                >
                    <div className="m-0">
                        <h1 className="font-bold text-lg merriweather text-dark-gray flex align-items-center capitalize">
                            {viewQuoteData?.name}
                            <span className="ml-3">
                                <i
                                    className={`pi ${
                                        viewQuoteData?.status === "IMPORTANT" ? "pi-star-fill text-yellow" : "pi-star"
                                    }  border-1 p-1 border-round-2xl`}
                                    style={{ fontSize: "1.4rem", borderColor: "#E9ECFF" }}
                                ></i>
                            </span>
                        </h1>
                        <div className="flex justify-content-between">
                            <p className="font-normal text-sm flex align-items-center text-light-black ">
                                <i className="pi pi-calendar font-bold" style={{ fontSize: "1rem" }}></i>
                                <span className="ml-2"> {moment(viewQuoteData?.eventDate).format("DD/MM/YYYY")}</span>
                            </p>
                            <p className="font-normal text-sm flex align-items-center text-light-black ">
                                <i className="pi pi-envelope font-bold" style={{ fontSize: "1rem" }}></i>
                                <span className="ml-2">{viewQuoteData?.email}</span>
                            </p>
                            <p className="font-normal text-sm flex align-items-center text-light-black ">
                                <i className="pi pi-phone font-bold" style={{ fontSize: "1rem" }}></i>
                                <span className="ml-2">{viewQuoteData?.phoneNumber}</span>
                            </p>
                            <p className="font-normal text-sm flex align-items-center text-light-black ">
                                <i className="pi pi-user font-bold" style={{ fontSize: "1rem" }}></i>
                                <span className="ml-2">{viewQuoteData?.numberOfGuests}</span>
                            </p>
                        </div>

                        <p className="text-sm font-normal text-light-black mb-3 ">{viewQuoteData?.eventSummarise}</p>
                    </div>
                </Dialog>
            </div>
            {deleteButton === true ? (
                <div
                    className="overlay-box fixed w-full h-full z-2
      top-0
      "
                    style={{ background: "#ff000000" }}
                    onClick={() => setDeleteButton(false)}
                ></div>
            ) : null}{" "}
        </div>
    );
}
