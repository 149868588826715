import React, { useState, useEffect } from "react";
import { IndexLayout } from "../../layout";
import { ContainerBox } from "../../shared/ContainerBox";
import ForgotImg from "../../assets/images/forgot.png";
import ButtonComponent from "../../components/buttons/button";
import OtpInput from "react-otp-input";
import ClockIcon from "../../assets/icons/clock.png";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { types } from "../../redux/types";
import { loadStateFn } from "../../utils/localStorage";
import { sendOtpAction, verifyOTP } from "../../redux/actions/otpActions";

export const VerificationCode = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.signup.SignupProfile);
  const [codeTimeout, setCodeTimeout] = useState(30);
  const [otp, setOtp] = useState("");

  const formatEmail = (email) => {
    if (!email) return ""; // Handle empty or invalid email

    const atIndex = email.indexOf("@");
    if (atIndex <= 1) return email; // Handle emails with length <= 2

    const visibleChar = email.charAt(0);
    const hiddenPart = "*".repeat(atIndex - 1); // -1 to account for the visible character

    return `${visibleChar}${hiddenPart}${email.substring(atIndex)}`;
  };

  const localStorageSignupEmail = loadStateFn(types.USER_SIGNUP);
  const signupEmail = formatEmail(localStorageSignupEmail);

  useEffect(() => {
    const otpInterval = setInterval(() => {
      if (codeTimeout > 0 && codeTimeout <= 30) {
        setCodeTimeout((prev) => prev - 1);
      } else {
        clearInterval(otpInterval); // Stop the interval when codeTimeout is 0
      }
    }, 1000);

    return () => {
      clearInterval(otpInterval);
    };
  }, [codeTimeout]);
  const handleSubmit = () => {
    if (otp.length < 6) {
      return;
    }
    dispatch(verifyOTP({ otp, email: userData.email })).then((res) => {
      if (res.success === true && userData?.role === "Vendor") {
        navigate("/under-review");
      } else {
        navigate("/login");
      }
    });
  };
  const vendorEmail = localStorage.getItem("vendorEmail");
  const handleResendCode = () => {
    if (vendorEmail) {
      dispatch(sendOtpAction({ email: vendorEmail }, setCodeTimeout(30)));
    }
  };
  useEffect(() => {
    if (codeTimeout > 0) {
      setTimeout(() => setCodeTimeout(codeTimeout - 1), 1000);
    }
  }, [codeTimeout]);
  // useEffect(() => {
  //   if (codeTimeout === 0) {
  //     setCodeTimeout(30);
  //   }
  // }, [codeTimeout]);
  return (
    <>
      <ContainerBox containerType="">
        <div className="grid p-0 m-0">
          <div className="lg:col-6 lg:p-5 md:p-5">
            <img src={ForgotImg} alt="" className="w-full" />
          </div>
          <div className="lg:col-6 ">
            <div className="bg-white lg:p-4 md:p-4 p-2 py-5 mt-4">
              {/* <img src={CheckIcon} alt="" /> */}
              <h4 className="font-32 mb-2 merriweather font-normal text-dark-gray">
                We Send a code to your email
              </h4>
              <p className="text-dark-gray text-sm mb-5">
                Enter the 6-digit verification code sent to {signupEmail}
                {/* Enter the 6-digit verification code sent to z****@gmail.com */}
              </p>
              <div className="col-12 md:col-12 p-0 m-0">
                <OtpInput
                  value={otp}
                  onChange={setOtp}
                  numInputs={6}
                  inputStyle={{
                    width: "48px",
                    height: "48px",
                    border: "1px solid #ECECEC",
                    borderRadius: "5px",
                  }}
                  renderSeparator={<span>&nbsp;&nbsp;&nbsp;</span>}
                  renderInput={(props) => <input {...props} />}
                />
              </div>
              <div className="flex align-items-end">
                <ButtonComponent
                  label="Submit"
                  className="mt-3 "
                  onClick={handleSubmit}
                  disabled={otp.length < 6}
                />
                <span
                  className="ml-3 flex align-items-center text-sm  font-normal"
                  style={{ color: "#425466" }}
                >
                  <img src={ClockIcon} height={20} width={20} alt="" />
                  &nbsp; {codeTimeout} Sec
                </span>
              </div>
              <p className="col-12 font-semibold text-gray-light text-sm underline">
                Didn’t receive the code!
                {codeTimeout === 0 ? (
                  <Link
                    to=""
                    onClick={handleResendCode}
                    className="text-main-color no-underline font-semibold"
                  >
                    Resend
                  </Link>
                ) : (
                  <Link
                    to=""
                    className="text-main-color no-underline font-semibold"
                  >
                    Resend
                  </Link>
                )}
              </p>
            </div>
          </div>
        </div>
      </ContainerBox>
    </>
  );
};
