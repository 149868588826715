import Constants from "../services/constant";
import GalleryImg from "../assets/images/business wedding banner 2.png";
export const getImageURL = (path) => {
  if (path) {
    if (path.includes("http")) {
      return path;
    } else {
      return Constants.HOST + path;
    }
  } else {
    return "";
  }
};

export const getDefaultImage = () => {
  return GalleryImg;
};
