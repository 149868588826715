import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addWebsiteAction, getPublicWeddingWebsiteAction, getWeddingWebsiteAction, updateWebsiteAction } from "../../../redux/actions/weddingWebsiteAction";
import { useNavigate, useParams } from "react-router-dom/dist";

const WeddingWebsiteContainer = () => {
    const { id } = useParams();
    const { invitationId } = useParams();

    const dispatch = useDispatch();

    const { getHostWebsite } = useSelector((state) => state?.hostWebsiteData);

    const [websiteData, setWebsiteData] = useState({
        welcomeTitle: "Welcome",
        welcomeDescription:
            "🎉 We're thrilled to invite you to join us as we embark on the beautiful journey of love and companionship. Our wedding day is a celebration of not only our commitment to each other but also the union of two families and the merging of two souls.",
        brideTitle: "👰 Meet the Bride",
        brideDescription:
            "Allow us to introduce the radiant bride, [Bride's Name]. With her infectious laughter, kind heart, and a smile that can light up a room, she stole [Groom's Name]'s heart from the very beginning.",
        brideImage: "",
        groomTitle: "👰 Meet the Groom",
        groomDescription:
            "Allow us to introduce the radiant bride, [Bride's Name]. With her infectious laughter, kind heart, and a smile that can light up a room, she stole [Groom's Name]'s heart from the very beginning.",
        groomImage: "",
        saveTheDateTitle: "📅 Save the Date",
        saveTheDateDescription:
            "Mark your calendars for [Date], as we exchange vows and declare our love surrounded by friends, family, and the beauty of [Venue]. We can't wait to share this special moment with you all.",
        saveTheDateImage: "",
        ourStoryTitle: "📜 Our Story",
        ourStoryDescription:
            "Our journey began [X years] ago, and it's been a whirlwind of adventures, laughter,and shared dreams. From the moment we met [describe how you met], we knew that our connection was something truly extraordinary.",
        ourStoryImages: [],
        rsvpTitle: "💌 RSVP",
        rsvpDescription:
            "Kindly let us know if you can join the celebration by [RSVP Date]. Your response will help us finalize the arrangements and ensure we create an unforgettable experience for everyone.",
        rsvpDate: "",
        endMessageTitle: "🕊️ Join Us in Celebration",
        endMessageDescription:
            "We can't wait to share our joy with you and create lasting memories together. Keep an eye on this website for updates and further details. We're counting down the days until we can celebrate, laugh, and dance with you!",
        endMessageImage: "",
        bannerImage: "",
        contacts: "[Contacts]",
        name: "",
        partnerName: "",
        weddingDate: "",
        receptionDate: "",
        receptionLocation: "[Reception Location]",
        weddingLocation: "[Wedding Location]",
    });
    const [websiteDataTemp, setWebsiteDataTemp] = useState({
        welcomeTitle: "Welcome",
        welcomeDescription:
            "🎉 We're thrilled to invite you to join us as we embark on the beautiful journey of love and companionship. Our wedding day is a celebration of not only our commitment to each other but also the union of two families and the merging of two souls.",
        brideTitle: "👰 Meet the Bride",
        brideDescription:
            "Allow us to introduce the radiant bride, [Bride's Name]. With her infectious laughter, kind heart, and a smile that can light up a room, she stole [Groom's Name]'s heart from the very beginning.",
        brideImage: "",
        groomTitle: "👰 Meet the Groom",
        groomDescription:
            "Allow us to introduce the radiant bride, [Bride's Name]. With her infectious laughter, kind heart, and a smile that can light up a room, she stole [Groom's Name]'s heart from the very beginning.",
        groomImage: "",
        saveTheDateTitle: "📅 Save the Date",
        saveTheDateDescription:
            "Mark your calendars for [Date], as we exchange vows and declare our love surrounded by friends, family, and the beauty of [Venue]. We can't wait to share this special moment with you all.",
        saveTheDateImage: "",
        ourStoryTitle: "📜 Our Story",
        ourStoryDescription:
            "Our journey began [X years] ago, and it's been a whirlwind of adventures, laughter,and shared dreams. From the moment we met [describe how you met], we knew that our connection was something truly extraordinary.",
        ourStoryImages: [],
        rsvpTitle: "💌 RSVP",
        rsvpDescription:
            "Kindly let us know if you can join the celebration by [RSVP Date]. Your response will help us finalize the arrangements and ensure we create an unforgettable experience for everyone.",
        rsvpDate: "",
        endMessageTitle: "🕊️ Join Us in Celebration",
        endMessageDescription:
            "We can't wait to share our joy with you and create lasting memories together. Keep an eye on this website for updates and further details. We're counting down the days until we can celebrate, laugh, and dance with you!",
        endMessageImage: "",
        bannerImage: "",
        contacts: "[Contacts]",
        name: "",
        partnerName: "",
        weddingDate: "",
        receptionDate: "",
        receptionLocation: "[Reception Location]",
        weddingLocation: "[Wedding Location]",
    });

    // const websiteDataHandleChange = (name, value) => {
    //   const [nestedProperty, propertyName] = name.split(".");

    //   setWebsiteData((prev) => {
    //     if (
    //       nestedProperty &&
    //       propertyName &&
    //       prev.hasOwnProperty(nestedProperty)
    //     ) {
    //       return {
    //         ...prev,
    //         [nestedProperty]: {
    //           ...prev[nestedProperty],
    //           [propertyName]: value,
    //         },
    //       };
    //     } else {
    //       return {
    //         ...prev,
    //         [name]: value,
    //       };
    //     }
    //   });
    // };

    const websiteDataHandleChange = (name, value) => {
        setWebsiteData((prev) => {
            const updatedData = {
                ...prev,
                [name]: value,
            };

            // Save the data immediately after updating bannerImage
            if (name === "bannerImage") {
                save(updatedData);
            }

            return updatedData;
        });
    };

    const save = (dataToSave) => {
        const formData = { ...(dataToSave || websiteData) };

        formData.welcome = {
            title: formData.welcomeTitle,
            description: formData.welcomeDescription,
        };
        formData.bride = {
            title: formData.brideTitle,
            description: formData.brideDescription,
            image: formData.brideImage,
        };
        formData.groom = {
            title: formData.groomTitle,
            description: formData.groomDescription,
            image: formData.groomImage,
        };
        formData.saveTheDate = {
            title: formData.saveTheDateTitle,
            description: formData.saveTheDateDescription,
            image: formData.saveTheDateImage,
        };
        formData.ourStory = {
            title: formData.ourStoryTitle,
            description: formData.ourStoryDescription,
            images: formData.ourStoryImages,
        };
        formData.rsvp = {
            title: formData.rsvpTitle,
            description: formData.rsvpDescription,
            rsvpDate: formData.rsvpDate,
        };
        formData.endMessage = {
            title: formData.endMessageTitle,
            description: formData.endMessageDescription,
            image: formData.endMessageImage,
        };
        // dispatch(
        //   addWebsiteAction(formData, () => {
        //     navigate(`/host/wedding-website-template`);
        //   })
        // );
        dispatch(updateWebsiteAction(formData, id));
        // dispatch(getWeddingWebsiteAction());
    };

    useEffect(() => {
        if (invitationId) {
            dispatch(getPublicWeddingWebsiteAction(invitationId));
            return;
        }
        dispatch(getWeddingWebsiteAction());
    }, []);

    return {
        websiteData,
        websiteDataHandleChange,
        save,
        getHostWebsite,
        setWebsiteData,
        setWebsiteDataTemp,
        websiteDataTemp,
    };
};

export default WeddingWebsiteContainer;
