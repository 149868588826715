import WeddingVenues from "../views/vendor/WeddingVenues";
import VendorSelectView from "../views/vendor/VendorSelectView";
import VendorSelect from "../views/vendor/VendorSelect";
import Profile from "../views/Host/profile/Index";
import AccountSettings from "../views/Host/profile/AccountSettings";
import EditProfile from "../views/Host/profile/EditProfile";
import MemberShipPlan from "../views/membersship-plan";
import PlanCheckout from "../views/membersship-plan/PlanCheckout";
import VendorDashboard from "../views/overview-vendor";
import VendorContent from "../views/vendor/VendorContent";
import GuestsContent from "../views/vendor/GuestsContent";
import WeddingWebsite from "../views/Host/WeddingWebsite/WeddingWebsite";
import BudgetContent from "../views/vendor/BudgetContent";
import { TentativeVendor } from "../views/vendor/TentativeVendor";
import VendorAccountSettings from "../views/Vendor-profile/AccountSettings";
import SettingContent from "../views/vendor/SettingContent";
import VendorProfile from "../views/business/VendorProfile";
import WeddingTemplate from "../views/Host/WeddingWebsite/WeddingTemplate";
import SeatingPlanner from "../views/vendor/SeatingPlanner2";

export const PrivateRoutes = [
  {
    path: "/host/wedding-venue",
    name: "Wedding Venue",
    element: <WeddingVenues />,
    exact: true,
  },
  {
    path: "/host/vendor-select",
    name: "Vendor Select",
    element: <VendorSelect />,
    exact: true,
  },
  {
    path: "/host/vendor-select-view",
    name: "Vendor Select View",
    element: <VendorSelectView />,
    exact: true,
  },
  {
    path: "/host/profile",
    name: "Profile",
    element: <Profile />,
    exact: true,
  },
  {
    path: "/host/account-settings",
    name: "Account-Setting",
    element: <AccountSettings />,
    exact: true,
  },
  {
    path: "/host/edit-profile",
    name: "Edit-Profile",
    element: <EditProfile />,
    exact: true,
  },
  {
    path: "/host/membership-plan",
    name: "MemberShip Plan",
    element: <MemberShipPlan />,
    exact: true,
  },
  {
    path: "/host/plan-checkout",
    name: "Plan Checkout",
    element: <PlanCheckout />,
    exact: true,
  },
  {
    path: "/host/tentative-vendor",
    name: "tentative-vendors",
    element: <VendorContent />,
    exact: true,
  },
  {
    path: "/host/guest",
    name: "guest",
    element: <GuestsContent />,
    exact: true,
  },
  {
    path: "/host/wedding-website",
    name: "Wedding Website",
    element: <WeddingWebsite />,
    exact: true,
  },
  {
    path: "/host/budget",
    name: "Budget",
    element: <BudgetContent />,
    exact: true,
  },
  {
    path: "/host/wedding-website-template/:id",
    name: "Wedding Website",
    element: <WeddingTemplate />,
    exact: true,
  },
  // {
  //   path: "/host/setting",
  //   name: "Setting Content",
  //   element: <SettingContent />,
  //   exact: true,
  // },
  {
    path: "/host/tentatives-vendors/:id",
    name: "tentative-Vendor-id",
    element: <TentativeVendor />,
    exact: true,
  },
  {
    path: "/host/seating-planner",
    name: "Seating PLanner",
    element: <SeatingPlanner />,
    exact: true,
  },
];

export const VendorRoutes = [
  {
    path: "/vendor/dashboard",
    name: "Vendor Dashboard",
    element: VendorDashboard,
    exact: true,
  },
  {
    path: "/vendor/account-settings",
    name: "Account-Setting",
    element: VendorAccountSettings,
    exact: true,
  },
  {
    path: "/membership",
    name: "MemberShipPlan",
    element: MemberShipPlan,
    exact: true,
  },
  {
    path: "/checkout/:id",
    name: "checkout",
    element: PlanCheckout,
    exact: true,
  },
  {
    path: "/vendor/business/:id",
    name: "Business",
    element: VendorProfile,
    exact: true,
  },
];
