import { useSelector } from "react-redux";
const LayoutData = () => {
  const featuredCategories = useSelector((state) => state.vendor?.categories);
  const menuItems = [
    {
      name: "How it works",
      link: "/how-it-works",
    },
    {
      name: "vendors",
      icon: <i className="pi pi-angle-down" style={{ fontSize: "1rem" }}></i>,
      subMenu: featuredCategories,
    },

    {
      name: "Contact Us",
      link: "/contact",
    },
  ];
  return { menuItems };
};
export default LayoutData;
