import { types } from "../types";

const intitalState = {
  SignupProfile: {},
  SigninProfile: {},
  forgetPasswordProfile: {},
  resetPasswordProfile: {},
  checkEmailProfile: [],
  verifyOtpProfile: [],
  setPasswordProfile: [],
};

export const signupReducer = (state = intitalState, action) => {
  switch (action.type) {
    case types.SING_UP:
      return {
        ...state,
        SignupProfile: action.payload,
      };
    default: {
      return {
        ...state,
      };
    }
  }
};

export const signinReducer = (state = intitalState, action) => {
  switch (action.type) {
    case types.SIGN_IN:
      return {
        ...state,
        SigninProfile: action.payload,
      };
    case types.CLEAR_PROFILE:
      return {
        ...state,
        SigninProfile: {},
      };
    default: {
      return {
        ...state,
      };
    }
  }
};

export const forgetPasswordReducer = (state = intitalState, action) => {
  switch (action.type) {
    case types.FORGET_PASSWORD:
      return {
        ...state,
        forgetPasswordProfile: action.payload,
      };
    default: {
      return {
        ...state,
      };
    }
  }
};

export const resetPasswordReducer = (state = intitalState, action) => {
  switch (action.type) {
    case types.RESET_PASSWORD:
      return {
        ...state,
        resetPasswordProfile: action.payload,
      };
    default: {
      return {
        ...state,
      };
    }
  }
};

export const checkEmailReducer = (state = intitalState, action) => {
  switch (action.type) {
    case types.EMAIL_CHECK:
      return {
        ...state,
        checkEmailProfile: action.payload,
      };
    default: {
      return state;
    }
  }
};

export const verifyOtpReducer = (state = intitalState, action) => {
  switch (action.type) {
    case types.REQUEST_FORGET_OTP:
      return {
        ...state,
        verifyOtpProfile: action.payload,
      };
    default: {
      return state;
    }
  }
};

export const setPasswordReducer = (state = intitalState, action) => {
  switch (action.type) {
    case types.SET_PASSWORD:
      return {
        ...state,
        setPasswordProfile: action.payload,
      };
    default: {
      return state;
    }
  }
};
