import { types } from "../types";

const initialState = {
  uploadBusinessProfile: {},
  vendorProfile: {},
  updateVendorProfile: [],
  quoteDataProfile: [],
  deleteQuoteProfile: [],
  viewQuoteProfile: [],
  importantQuoteProfile: [],
  amenitiesProfile: [],
  graphProfile: [],
  filterAmenitiesProfile: [],
  weddingReceptionProfile: [],
};
export const uploadBussinessImageReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.UPLOAD_BUSSINESS_IMAGE:
      return {
        ...state,
        uploadBusinessProfile: action.payload,
      };
    default: {
      return state;
    }
  }
};
export const vendorProfileReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.VENDOR_PROFILE:
      return {
        ...state,
        vendorProfile: action.payload,
      };
    default: {
      return state;
    }
  }
};

export const updateVendorProfileReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.UPDATE_VENDER_PROFILE:
      return {
        ...state,
        updateVendorProfile: action.payload,
      };
    default: {
      return state;
    }
  }
};

export const quoteDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.QUOTE_REQUEST:
      return {
        ...state,
        quoteDataProfile: action.payload,
      };
    default: {
      return state;
    }
  }
};

export const deleteQuoteReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.DELETE_QUOTE:
      return {
        ...state,
        deleteQuoteProfile: action.payload,
      };
    default: {
      return state;
    }
  }
};

export const viewQuoteReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.VIEW_QUOTE_DATA:
      return {
        ...state,
        viewQuoteProfile: action.payload,
      };
    default: {
      return state;
    }
  }
};

export const importantQuoteReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.IMPORTANT_QUOTE:
      return {
        ...state,
        importantQuoteProfile: action.payload,
      };
    default: {
      return state;
    }
  }
};

export const amenitiesReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.AMENITIES_DATA:
      return {
        ...state,
        amenitiesProfile: action.payload,
      };
    default: {
      return state;
    }
  }
};

export const graphReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.QUOTE_GRAPH:
      return {
        ...state,
        graphProfile: action.payload,
      };
    default: {
      return state;
    }
  }
};

export const filterAmenitiesReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FILTER_AMENTIES:
      return {
        ...state,
        filterAmenitiesProfile: action.payload,
      };
    default: {
      return state;
    }
  }
};

export const weddingReceptionReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.WEDDING_RECEPTION_COUNT:
      return {
        ...state,
        weddingReceptionProfile: action.payload,
      };
    default: {
      return state;
    }
  }
};
