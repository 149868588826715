import { Dropdown } from "primereact/dropdown";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputNumber } from "primereact/inputnumber";
import React, { useState } from "react";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import { confirmDialog } from "primereact/confirmdialog";
import { CustomInput } from "../input/AllInput";
import ButtonComponent from "../buttons/button";
import { InputText } from "primereact/inputtext";
export default function BudgetTable({
  budgetByType,
  budgetData,
  setBudgetData,
  vendorList,
  data,
  setData,
  deleteExpense,
}) {
  const [anotherVendor, setAnotherVendor] = useState(false);
  const onRowEditComplete = (e) => {
    let _budgetData = [...budgetData];
    let { newData, index } = e;

    newData = { ...newData, pendingAmount: newData.finalCost - newData.paid };

    _budgetData[index] = newData;
    setData({ ...data, ...newData, isAction: true });
    setBudgetData(_budgetData);
    setAnotherVendor(false);
  };

  const supplierEditor = (options) => {
    const panelFooterTemplate = () => {
      return (
        <div className="w-full py-2 px-3">
          <ButtonComponent
            label="Add Manually"
            icon="pi pi-plus"
            onClick={() => setAnotherVendor(true)}
          />
        </div>
      );
    };
    return anotherVendor ||
      (options.value !== "" &&
        !vendorList.map((item) => item.name).includes(options.value)) ? (
      <InputText
        placeholder="Supplier's Name"
        onChange={(e) => {
          options.editorCallback(e?.target.value);
        }}
        value={options.value}
        col={12}
        className="border-none border-bottom-1 border-gray-500 p-0 border-noround"
        inputClassName="border-0 p-0"
      />
    ) : (
      <Dropdown
        value={options.value}
        options={vendorList.map((item) =>
          budgetData.map((budget) => budget.vendor).includes(item.value)
            ? { ...item, disabled: true }
            : item
        )}
        onChange={(e) => options.editorCallback(e.value)}
        optionLabel="name"
        className="capitalize border-none border-bottom-1 border-noround border-gray-500 editor-dropdown"
        itemTemplate={(option) => {
          return <span className="capitalize">{option.name}</span>;
        }}
        placeholder="Select Supplier"
        panelFooterTemplate={panelFooterTemplate}
      />
    );
  };

  const priceEditor = (options) => {
    return (
      <InputNumber
        value={options.value || ""}
        onValueChange={(e) => options.editorCallback(e.value)}
        mode="currency"
        currency="GBP"
        locale="en-US"
        className="border-bottom-1 border-gray-500"
        inputClassName="border-0 p-0"
      />
    );
  };

  const priceBodyTemplate = (rowData, field) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "GBP",
    }).format(rowData[field]);
  };

  const expenseBodyTemplate = (col, field) => {
    // const getVendorName = (id) => {
    //   console.log("id", id);
    //   let vendor = vendorList.find((item) => item.value === id);
    //   return vendor?.name;
    // };

    return col[field] === "" ? (
      <Dropdown
        disabled
        placeholder="Select Supplier"
        className="border-none editor-dropdown"
      />
    ) : (
      <span className="capitalize">{col[field]}</span>
    );
  };

  const footerGroup = (
    <ColumnGroup>
      <Row>
        <Column footer="Total" />
        <Column
          footer={new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "GBP",
          }).format(budgetByType?.totalEstimatedCost)}
          className="text-gray-600"
        />
        <Column
          footer={new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "GBP",
          }).format(budgetByType?.totalFinalCost)}
          className="text-gray-600"
        />
        <Column
          footer={new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "GBP",
          }).format(budgetByType?.totalPaidCost)}
          className="text-gray-600"
        />
        <Column
          footer={new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "GBP",
          }).format(budgetByType?.totalPendingCost)}
          className="text-gray-600"
        />
        <Column footer="" />
      </Row>
    </ColumnGroup>
  );

  const rowEditorTemplate = (rowData, options) => {
    return (
      <>
        {options.rowEditor?.editing ? (
          <>
            <i
              className="pi pi-save m-3 ml-0  cursor-pointer text-blue-500"
              onClick={(e) =>
                options.rowEditor?.onSaveClick &&
                options.rowEditor?.onSaveClick(e)
              }
            />
            <i
              className="pi pi-times cursor-pointer text-yellow-500"
              onClick={(e) => {
                options.rowEditor?.onCancelClick &&
                  options.rowEditor?.onCancelClick(e);
                setData(data);
              }}
            />
          </>
        ) : (
          <>
            <i
              className="pi pi-pencil m-3 ml-0 cursor-pointer text-green-500"
              onClick={(e) =>
                options.rowEditor?.onInitClick &&
                options.rowEditor?.onInitClick(e)
              }
              id="editorPencil"
            />
            <i
              className="pi pi-trash cursor-pointer text-red-500"
              onClick={() =>
                confirmDialog({
                  message: "Are you sure you want to delete?",
                  header: "Confirmation",
                  icon: "pi pi-exclamation-triangle",
                  defaultFocus: "reject",
                  acceptClassName: "p-button-danger",
                  accept: () => deleteExpense(rowData._id),
                })
              }
            />
          </>
        )}
      </>
    );
  };

  const columns = [
    {
      field: "vendor",
      header: "Expense",
      editor: (options) => supplierEditor(options),
      body: (options, col) => expenseBodyTemplate(options, col.field),
      style: { width: "20%" },
    },
    {
      field: "estimatedCost",
      header: "Estimated cost",
      editor: priceEditor,
      body: (options, col) => priceBodyTemplate(options, col.field),
    },
    {
      field: "finalCost",
      header: "Final cost",
      editor: priceEditor,
      body: (options, col) => priceBodyTemplate(options, col.field),
    },
    {
      field: "paid",
      header: "Paid",
      editor: priceEditor,
      body: (options, col) => priceBodyTemplate(options, col.field),
    },
    {
      field: "pendingAmount",
      header: "Pending Amt.",
      body: (options, col) => priceBodyTemplate(options, col.field),
    },
  ];

  return (
    <div>
      <DataTable
        value={budgetData}
        editMode="row"
        dataKey="id"
        onRowEditComplete={onRowEditComplete}
        tableStyle={{ minWidth: "50rem" }}
        style={{ fontSize: "14px" }}
        footerColumnGroup={footerGroup}
        emptyMessage={"Not Available"}
      >
        {columns?.map((col, index) => (
          <Column
            field={col.field}
            header={col.header}
            body={col.body}
            editor={col.editor}
            style={{ fontSize: "14px", minWidth: "18%", ...col.style }}
          />
        ))}
        <Column
          header="Action"
          rowEditor={true}
          headerStyle={{ minWidth: "8rem" }}
          body={rowEditorTemplate}
        ></Column>
      </DataTable>
    </div>
  );
}
