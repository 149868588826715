import { jwtDecode } from "jwt-decode";

export const isAuthenticated = () => {
  if (typeof window == "undefined") {
    return false;
  }
  if (localStorage.getItem("authToken")) {
    return JSON.parse(localStorage.getItem("authToken"));
  } else {
    return false;
  }
};

export const getUserRole = () => {
  if (localStorage.getItem("authToken")) {
    let decoded = jwtDecode(JSON.parse(localStorage.getItem("authToken")));
    return decoded.type;
  }
};

export const checkUserRole = (type) => {
  if (localStorage.getItem("authToken")) {
    let decoded = jwtDecode(JSON.parse(localStorage.getItem("authToken")));

    if (decoded.role === type) {
      return true;
    }
    return false;
  }
};

export const checkUserLoginType = (type) => {
  if (localStorage.getItem("authToken")) {
    let decoded = jwtDecode(JSON.parse(localStorage.getItem("authToken")));
    if (decoded.loginType === type) {
      return true;
    }
    return false;
  }
};
export const authenticate = (authToken, next) => {
  if (typeof window !== "undefined") {
    localStorage.setItem("authToken", JSON.stringify(authToken));
    next();
  }
};
export const logout = (next) => {
  if (typeof window !== "undefined") {
    localStorage.clear();
    next();
  }
};

export const checkisUserSubscribed = (type) => {
  if (localStorage.getItem("authToken")) {
    let decoded = jwtDecode(JSON.parse(localStorage.getItem("authToken")));
    if (decoded?.isUserSubscribed === type) {
      return true;
    }
    return false;
  }
};
