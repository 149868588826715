import React, { useState, useEffect } from "react";
import { CustomInput, CustomPassword } from "../../components/input/AllInput";
import { Link, useNavigate } from "react-router-dom";
import ButtonComponent from "../../components/buttons/button";
import { SocialLogin } from "../signup/CommonFeild";
import { useDispatch, useSelector } from "react-redux";
import { checkUserRole, checkisUserSubscribed, isAuthenticated } from "../../services/authService";
import { allValidations } from "../../utils/formValidations";
import { googlelogin, signInAction, socialLogin } from "../../redux/actions/signupAction";
import { useGoogleLogin } from "@react-oauth/google";
import { types } from "../../redux/types";

export const LoginPage = ({ userType }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const myProfileData = useSelector((state) => state?.vendorProfile?.vendorProfile);
    const { userData } = useSelector((state) => state.signup.SignupProfile);
    const [form, setForm] = useState({
        email: "",
        password: "",
        userType: userType,
    });
    const [errors, setErrors] = useState({});
    useEffect(() => {
        if (isAuthenticated()) {
            if (checkUserRole("Vendor")) {
                navigate("/vendor/dashboard");
            } else {
                navigate("/host/profile");
            }
        }
    }, [navigate]);

    const setField = (field, value) => {
        setForm({
            ...form,
            [field]: value,
        });
        if (!!errors[field]) {
            setErrors({
                ...errors,
                [field]: null,
            });
        }
    };

    const updateFormErrors = (errors) => {
        // Extract the error text from the errors object
        const errorText = Object.keys(errors).reduce((acc, key) => {
            acc[key] = errors[key][Object.keys(errors[key])[0]];
            return acc;
        }, {});

        setErrors(errorText);
    };
    useEffect(() => {
        return () => {
            dispatch({ type: types.SHOW_TOAST, payload: {} });
        };
    }, []);

    const validateSubmit = (e) => {
        e.preventDefault();

        const tempErrors = {
            email: allValidations("email", form.email, form),
        };

        updateFormErrors(tempErrors);

        const hasErrors = Object.values(tempErrors).some((errors) => Object.values(errors).some((error) => error !== ""));

        if (hasErrors) {
            return;
        }
        handleSubmit();
    };
    // const userData = useSelector((state)=>state)
    const handleSubmit = () => {
        if (!form.email || !form.password) {
            return;
        }

        dispatch(signInAction(form, navigate));
    };
    const { toastInfo } = useSelector((state) => state?.toast);
    const googleLoginHandler = useGoogleLogin({
        onSuccess: async (respose) => {
            dispatch(googlelogin(respose)).then((res) => {
                if (res.data.sub) {
                    let payload = {
                        email: res.data.email,
                        socialId: res.data.sub,
                        loginType: "Google",
                        name: res.data.given_name,
                    };
                    dispatch(socialLogin(payload)).then((res) => {
                        if (res) {
                            navigate("/host/profile");
                        } else {
                            navigate("/login");
                        }
                    });
                }
            });
        },
    });

    console.log(toastInfo);
    return (
        <div className="grid mt-3 p-0 m-0">
            <form className="col-12" onSubmit={validateSubmit}>
                {toastInfo?.summary && <div className="p-3 border border-2 alert-error mb-2 text-center">{toastInfo?.summary}</div>}
                <CustomInput
                    col={12}
                    placeholder="Email"
                    value={form?.email}
                    id="email"
                    type="email"
                    onChange={(e) => {
                        setField("email", e.target.value);
                    }}
                    errorMessage={errors?.email}
                />
                <CustomPassword
                    col={12}
                    placeholder="Password"
                    value={form?.password}
                    onChange={(e) => {
                        setField("password", e.target.value);
                    }}
                    toggleMask
                    feedback={false}
                />

                <div className="col-12 text-right">
                    <Link to="/forgot-password" className="text-14 font-semibold text-light-gray no-underline">
                        Forgot Password?
                    </Link>
                </div>
                <ButtonComponent label="Sign In" className="w-full lg:w-3 lg:ml-2 md:ml-2" />
            </form>

            <SocialLogin googleLoginHandler={googleLoginHandler} />
            <p className="col-12 text-center lg:text-left">
                Don’ t have Account?{" "}
                <Link to="/signup" className="text-main-color no-underline">
                    Register here
                </Link>
            </p>
        </div>
    );
};
