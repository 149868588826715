import { types } from "../types";

const initialState = {
  getallSubscriptionProfile: [],
  membershipProfile: [],
  paymentProfile: {},
  showPlanProfile: [],
  membershipPlanActiveInactiveProfile: [],
  cancelPlanProfile: [],
  changePlanProfile: [],
};

export const getAllMembershipReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.ALL_SUBSCRIPTION_TYPE:
      return {
        ...state,
        getallSubscriptionProfile: action.payload,
      };
    default: {
      return state;
    }
  }
};

export const membershipReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SUBSCRIPTION_PLAN:
      return {
        ...state,
        membershipProfile: action.payload,
      };
    default: {
      return state;
    }
  }
};

export const paymentReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.PAYMENT_METHOD:
      return {
        ...state,
        paymentProfile: action.payload,
      };
    default: {
      return state;
    }
  }
};
export const showPlanReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SHOW_PLAN:
      return {
        ...state,
        showPlanProfile: action.payload,
      };
    default: {
      return state;
    }
  }
};

export const membershipPlanActiveInactiveReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case types.MEMBERSHIPPLAN_ACTIVE_INACTIVE:
      return {
        ...state,
        membershipPlanActiveInactiveProfile: action.payload,
      };
    default: {
      return state;
    }
  }
};

export const cancelPlanReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CANCEL_PLAN:
      return {
        ...state,
        cancelPlanProfile: action.payload,
      };
    default: {
      return state;
    }
  }
};

export const changePlanReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CHANGE_PLAN:
      return {
        ...state,
        changePlanProfile: action.payload,
      };
    default: {
      return state;
    }
  }
};
