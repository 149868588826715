import React, { useState, useEffect, useRef } from "react";
import { CustomCheckbox, CustomCheckbox2, CustomDropDown, CustomInput, CustomPassword, PhoneNumberInput } from "../../components/input/AllInput";
import ButtonComponent from "../../components/buttons/button";
import { EmailFeild, SocialLogin } from "./CommonFeild";
import { Link, useNavigate } from "react-router-dom";
import "react-phone-number-input/style.css";
import { signupAction, socialLogin } from "../../redux/actions/signupAction";
import { useDispatch, useSelector } from "react-redux";
import SearchBox from "../../components/input/SearchBox";

import { allValidations, matchPasswordsValidation } from "../../utils/formValidations";
import { parsePhoneNumber } from "react-phone-number-input";
import { vendorCategoryAction } from "../../redux/actions/vendorAction";
import { StandaloneSearchBox } from "@react-google-maps/api";
import { useGoogleLogin } from "@react-oauth/google";
import { googlelogin } from "../../redux/actions/signupAction";
import { sendOtpAction } from "../../redux/actions/otpActions";
import { showToast } from "../../redux/actions/toastAction";
import { types } from "../../redux/types";
export const Bussiness = ({ isLoaded }) => {
    const inputRef = useRef();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const vendorCategories = useSelector((state) => state?.vendor?.categories);
    const [form, setForm] = useState({
        businessName: "",
        email: "",
        password: "",
        confirmPassword: "",
        countryCode: "",
        mobile: "",
        vendorType: "",
        postalCode: "",
        state: "",
        city: "",
        addressLine1: "",
        addressLine2: "",
        isVerified: false,
        isDeleted: false,
        userType: "BUSINESS",
        role: "Vendor",
    });
    const [checked, setChecked] = useState(true);
    const [selectedVendor, setSelectedVendor] = useState("");
    const [errors, setErrors] = useState({});
    const { toastInfo } = useSelector((state) => state?.toast);

    useEffect(() => {
        dispatch(vendorCategoryAction());
    }, []);
    useEffect(() => {
        return () => {
            dispatch({ type: types.SHOW_TOAST, payload: {} });
        };
    }, []);

    const setField = (field, value) => {
        setForm({
            ...form,
            [field]: value,
        });
        if (!!errors[field]) {
            setErrors({
                ...errors,
                [field]: null,
            });
        }
    };
    const updateFormErrors = (errors) => {
        // Extract the error text from the errors object
        const errorText = Object.keys(errors).reduce((acc, key) => {
            acc[key] = errors[key][Object.keys(errors[key])[0]];
            return acc;
        }, {});

        setErrors(errorText);
    };

    const validateSubmit = (e) => {
        e.preventDefault();

        const tempErrors = {
            businessName: allValidations("business name", form.businessName, form),
            email: allValidations("email", form.email, form),
            mobile: allValidations("mobile", form.mobile, form),
            password: allValidations("password", form.password, form),
            addressLine1: allValidations("addressLine1", form?.addressLine1, form),
            vendorType: allValidations("vendorType", form?.vendorType, form),
            city: allValidations("city", form?.city, form),
            confirmPassword: matchPasswordsValidation("confirmPassword", form.password, form.confirmPassword),
        };

        updateFormErrors(tempErrors);

        const hasErrors = Object.values(tempErrors).some((errors) => Object.values(errors).some((error) => error !== ""));

        if (hasErrors) {
            return;
        }
        handleSubmit();
    };

    const handleSubmit = () => {
        const data = { ...form };

        const phoneNumberInstance = parsePhoneNumber(data.mobile);
        data["mobile"] = phoneNumberInstance?.nationalNumber;
        data["countryCode"] = `+${phoneNumberInstance?.countryCallingCode}`;
        dispatch(
            signupAction(data, () => {
                dispatch(sendOtpAction({ email: form.email }, () => {}));
                navigate("/otp");
            })
        );
    };
    const handlePlaceChanged = (place) => {
        if (place) {
            let addressvar = {
                zipcode: "",
                country: "",
                city: "",
                addressline2: "",
                addressLine1: "",
            };
            place?.address_components?.map((address) => {
                if (address?.types?.includes("postal_code")) {
                    addressvar.zipcode = address?.long_name;
                }
                if (address?.types?.includes("country")) {
                    addressvar.country = address?.long_name;
                }
                if (address?.types?.includes("locality") || address?.types?.includes("postal_town")) {
                    addressvar.city = address?.long_name;
                }
                if (address?.types?.includes("sublocality_level_1") || address?.types?.includes("sublocality")) {
                    addressvar.addressline2 = address?.long_name;
                }
                if (address?.types?.includes("street_number") || address?.types?.includes("route")) {
                    addressvar.addressLine1 += " " + address?.long_name;
                }
                setForm({
                    ...form,
                    addressLine1: addressvar?.addressLine1,
                    addressLine2: addressvar?.addressline2,
                    city: addressvar?.city,
                    state: addressvar?.country,
                    postalCode: addressvar?.zipcode,
                });
            }, []);
        }
    };
    const googleLoginHandler = useGoogleLogin({
        onSuccess: async (respose) => {
            dispatch(googlelogin(respose)).then((res) => {
                if (res.data.sub) {
                    let payload = {
                        email: res.data.email,
                        socialId: res.data.sub,
                        loginType: "Google",
                        name: res.data.given_name,
                        role: "Host",
                    };
                    dispatch(socialLogin(payload)).then((res) => {
                        console.log("res>>", res);
                        if (res === "error") {
                            dispatch(showToast({ severity: "error", summary: "User with this email already exists. Please try again!" }));
                        } else if (res) {
                            navigate("/host/profile");
                        } else {
                            navigate("/login");
                        }
                    });
                }
            });
        },
    });

    const clearSearchValue = () => {
        setForm((prev) => ({ ...prev, addressLine1: "" }));
    };
    return isLoaded ? (
        <div className="grid mt-2">
            <form onSubmit={validateSubmit} className="col-12">
                {toastInfo?.summary && <div className="p-3 border border-2 alert-error mb-2 text-center">{toastInfo?.summary}</div>}
                <CustomInput
                    value={form.name}
                    onChange={(e) => setField("businessName", e.target.value)}
                    col={12}
                    placeholder="Business Name"
                    errorMessage={errors?.businessName}
                />
                <EmailFeild value={form.email} col={12} onChange={(e) => setField("email", e.target.value)} errorMessage={errors?.email} />
                <CustomPassword
                    value={form.password}
                    onChange={(e) => setField("password", e.target.value)}
                    col={12}
                    placeholder="Password"
                    errorMessage={errors?.password}
                />
                <CustomPassword
                    value={form.confirmPassword}
                    onChange={(e) => setField("confirmPassword", e.target.value)}
                    col={12}
                    errorMessage={errors?.confirmPassword}
                    placeholder="Confirm Password"
                />
                <PhoneNumberInput
                    international
                    value={form.mobile}
                    onChange={(e) => {
                        setField("mobile", e);
                    }}
                    col={12}
                    errorMessage={errors?.mobile}
                />
                <CustomDropDown
                    value={selectedVendor}
                    onChange={(e) => {
                        setField("vendorType", e.target.value._id);
                        setSelectedVendor(e.target.value);
                    }}
                    col={12}
                    placeholder="Select Vendor Type"
                    dropdownOptions={vendorCategories}
                    errorMessage={errors?.vendorType}
                    optionLabel="name"
                />
                <SearchBox
                    searchValue={form.addressLine1}
                    setSearchValue={handlePlaceChanged}
                    placeholder="Address Line 1"
                    extraClassName="border"
                    clearSearchValue={clearSearchValue}
                />

                <CustomInput col={12} value={form.addressLine2} placeholder="Address Line 2" onChange={(e) => setField("addressLine2", e.target.value)} />
                <div className="grid p-0 m-0 ">
                    <CustomInput col={6} value={form.city} placeholder="City" onChange={(e) => setField("city", e.target.value)} />
                    <CustomInput value={form.state} col={6} placeholder="Country" onChange={(e) => setField("state", e.target.value)} />
                </div>
                <CustomInput
                    value={form.postalCode}
                    col={12}
                    placeholder="Postal Code"
                    errorMessage={errors?.postalCode}
                    onChange={(e) => setField("postalCode", e.target.value)}
                />
                <CustomCheckbox2
                    onChange={({ value }) => setChecked(value)}
                    col="12"
                    checked={checked}
                    label={
                        <>
                            I accept PlanTheKnot’s{" "}
                            <a className="text-blue-400" href="http://plantheknot.com/#/privacy-policy" target="_blank" rel="noopener noreferrer">
                                Privacy Policy
                            </a>{" "}
                            and{" "}
                            <a className="text-blue-400" href="http://plantheknot.com/#/terms-and-condition" target="_blank" rel="noopener noreferrer">
                                Terms and Conditions
                            </a>
                        </>
                    }
                />
                <ButtonComponent label="Sign Up" className="w-full lg:w-3" onClick={validateSubmit} disabled={!checked} />
            </form>
            <SocialLogin googleLoginHandler={googleLoginHandler} />
            <p className="col-12 text-center lg:text-left md:text-left">
                Already have Account?{" "}
                <Link to="/login" className="text-main-color no-underline">
                    Login here
                </Link>
            </p>
        </div>
    ) : (
        <></>
    );
};
