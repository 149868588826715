import { types } from "../types";

const initialState = {
    categories: [],
    allVendors: [],
    vendorsByCategory: []
};

const vendorReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.VENDOR_CATEGORIES:
            return {...state, categories: action.payload };
        case types.ALL_VENDORS:
            return {...state, allVendors: action.payload };
        case types.VENDORS_BY_CATEGORY:
            return {...state, vendorsByCategory: action.payload };
        default:
            return state;
    }
};

export default vendorReducer;