import React from "react";
import { ProfileLayout } from "../../layout/ProfileLayout";
import { ContainerBox } from "../../shared/ContainerBox";
import { SupplierCard } from "../../components/cards/card";
import VendorContentData from "./VendorContentData";
import Constants from "../../services/constant";
import WeedingSupplierImg from "../../assets/images/venuePlaceholder.png";
export const TentativeVendor = () => {
  const { showAllTentativeData, handleTentativeData } = VendorContentData();

  return (
    <>
      <div className="profile-container mt-5 mb-7">
        <ContainerBox
          containerType="container py-4"
          title={showAllTentativeData?.name}
          titleClass="mt-4"
          descriptionclass="text-light-black"
        >
          <div className="flex justify-content-start gap-5 flex-wrap">
            {showAllTentativeData?.vendors?.length > 0 &&
              showAllTentativeData?.vendors?.map((item) => {
                return (
                  <SupplierCard
                    img={
                      item.profileImage
                        ? Constants.HOST + item?.profileImage
                        : WeedingSupplierImg
                    }
                    rating={item.rating}
                    title={item.businessName}
                    location={item.city}
                    item={item}
                    person={item.person}
                    id={item._id}
                  />
                );
              })}
          </div>
        </ContainerBox>
      </div>
    </>
  );
};
