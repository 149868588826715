import React, { useEffect, useState } from "react";
import { ContainerBox } from "../../shared/ContainerBox";
import SecureIcon from "../../assets/icons/secure icon.png";
import { CardComponent } from "../../components/cards/card";
import { Divider } from "primereact/divider";
import PayIcon from "../../assets/icons/payicon.png";
import ButtonComponent from "../../components/buttons/button";
import { CustomInput } from "../../components/input/AllInput";
import { useDispatch, useSelector } from "react-redux";
import {
  changePlanAction,
  memberShipAction,
  paymentAction,
} from "../../redux/actions/memberShipsAction";
import { useLocation, useParams } from "react-router-dom";
import { capitalizeFirstLetter } from "../../utils/regex";
import { formatCurrency } from "../../utils/javascript";
export default function PlanCheckout() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { state } = useLocation();
  const [discount, setDiscount] = useState(false);
  const [error, setError] = useState("");
  const [applyDiscount, setApplyDiscount] = useState("");
  const [discountedPrice, setDiscountedPrice] = useState("");
  const [showPriceBreakdwon, setShowPriceBreakdwon] = useState(false);
  const [option, setOption] = useState(false);
  const location = useLocation();
  console.log("location", location);
  const handleClick = (e) => {
    e.preventDefault();
    if (e.target.value) {
      setDiscount(!discount);
      setOption(!option);
    }
    setDiscount(!discount);
    setOption(!option);
  };
  useEffect(() => {
    dispatch(memberShipAction(id));
  }, []);
  const showData = useSelector((state) => state?.membership?.membershipProfile);
  const myProfileData = useSelector(
    (state) => state?.vendorProfile?.vendorProfile
  );

  const handleSubmit = () => {
    if (applyDiscount === "") {
      setError("Please Enter Discount Code");
      return false;
    } else {
      let params = {
        discount: applyDiscount,
      };
      dispatch(memberShipAction(id, params)).then(() => {
        setDiscount(false);
        setApplyDiscount("");
      });
    }
  };
  useEffect(() => {
    if (applyDiscount !== "") {
      setError("");
    }
  }, [applyDiscount]);
  useEffect(() => {
    let price;
    if (showData?.discountedPrice !== null) {
      price = showData?.price - showData?.discountedPrice;
      setDiscountedPrice(price);
    }
  }, [showData]);
  const handlePayment = () => {
    let payload;
    let UpgradePayLoad;
    UpgradePayLoad = {
      vendor: myProfileData?._id,
      subscriptionType: showData?._id,
      discountCode: showData?.discountCode,
      currentSubscriptionId: state?.currentSubscriptionId,
    };
    if (showData?.discountCode === "" || showData?.discountCode === null) {
      payload = {
        vendor: myProfileData?._id,
        subscriptionType: showData?._id,
      };
    } else {
      payload = {
        vendor: myProfileData?._id,
        subscriptionType: showData?._id,
        discountCode: showData?.discountCode,
      };
    }

    location.search === "?upgradePlan=true"
      ? dispatch(changePlanAction(UpgradePayLoad)).then((res) => {
          if (res.success === true) {
            console.log("res", res);
            window.open(res.data, "_self");
          }
        })
      : dispatch(paymentAction(payload)).then((res) => {
          if (res.success === true) {
            window.open(res.data, "_self");
          }
        });
  };
  useEffect(() => {
    if (state) {
      let params = {
        discount: state?.item?.discountCode?.discountCode,
      };
      dispatch(memberShipAction(id, params)).then(() => {
        setDiscount(false);
      });
      setApplyDiscount(state?.item?.discountCode?.discountCode);
    }
  }, [state]);

  return (
    <>
      <ContainerBox containerType="">
        <div className="checkout-plan-container">
          <div className="icon flex align-items-center mb-5 py-3">
            <img src={SecureIcon} width={24} height={24} alt="" className="" />
            <span className="text-2xl font-bold merriweather text-dark-gray ml-3 mt-1">
              {" "}
              Secure Checkout
            </span>
          </div>
          <div className="mb-5">
            <h6 className="merriweather text-2xl font-normal text-black-dark p-0 m-0 mb-2">
              Confirm your billing cycle
            </h6>
            {/* {showData?.subscriptionType === "month" ? (
              ""
            ) : (
              <p className="text-base font-normal text-dark-gray p-0 m-0">
                Select annual to save 25% and expense with a single receipt.
              </p>
            )} */}
          </div>
          <CardComponent className="shadow-1 mb-5">
            <div className="flex align-items-center justify-content-between mb-5 p-0 m-0">
              <p className="font-semibold text-base text-dark-gray p-0 m-0">
                {capitalizeFirstLetter(showData?.subscriptionPlan)}
              </p>
              <p className="text-right">
                <span className="text-base font-normal text-dark-gray ml-7">
                  {formatCurrency(showData?.price)}
                </span>
                <br></br>
                {showData?.subscriptionType === "month" ? (
                  ""
                ) : showData?.subscriptionPlan !== "Standard" ? (
                  <span className="font-medium text-base text-main-color ml-5">
                    You Saved{" "}
                    {showData?.discountedPrice
                      ? Math.round(
                          ((showData?.price - showData?.discountedPrice) /
                            showData?.price) *
                            100
                        ) + "%"
                      : Math.round(
                          ((showData?.price - showData?.offeredPrice) /
                            showData?.price) *
                            100
                        ) + "%"}
                  </span>
                ) : (
                  ""
                )}
              </p>
            </div>

            {/* <div className="flex align-items-center justify-content-between p-0 m-0 mb-2">
                <p className="font-normal text-base text-dark-gray p-0 m-0">
                  Taxes
                </p>
                <span className="text-base font-normal text-dark-gray">
                  0.00
                </span>
              </div> */}
            {showData?.discountedPrice !== null ? (
              <div className="flex align-items-center justify-content-between p-0 m-0 mb-2">
                <p className="font-normal text-base text-dark-gray p-0 m-0">
                  Discount Code:{" "}
                  <span className="font-semibold">
                    {showData?.discountCode}
                  </span>
                </p>

                <p className="text-right">
                  <span
                    className="text-base font-normal text-dark-gray"
                    style={{ marginLeft: "148px" }}
                  >
                    -{formatCurrency(discountedPrice)}
                    <br></br>
                  </span>
                  <span className="font-medium text-base text-main-color">
                    Applied Discount Coupon
                  </span>
                </p>
              </div>
            ) : (
              ""
            )}

            <Divider className="mb-5" />
            <div className="flex align-items-start justify-content-between  p-0 m-0">
              <div className="">
                <h6
                  className="font-normal text-black-dark merriweather p-0 m-0 mb-2"
                  style={{ fontSize: "22px" }}
                >
                  Total{" "}
                </h6>
                <p
                  className="text-main-color text-base font-normal underline flex align-items-center p-0 m-0 cursor-pointer"
                  onClick={handleClick}
                >
                  {showData?.discountedPrice === null
                    ? "Have a Discount Code"
                    : "Change Discount Code"}
                  <span className="dropdown ml-2">
                    <i
                      className=" pi pi-angle-down mt-2 cursor-pointer"
                      style={{ fontSize: "1.2rem" }}
                    ></i>
                  </span>
                </p>
              </div>
              <div className="text-right">
                <p className="font-semibold text-2xl text-black-dark p-0 m-0">
                  {formatCurrency(
                    showData?.discountedPrice !== null
                      ? showData?.discountedPrice
                      : showData?.offeredPrice
                      ? showData?.offeredPrice
                      : showData?.price
                  )}
                </p>
                {showData?.offeredPrice || showData?.discountedPrice ? (
                  <span
                    className="font-medium text-base text-main-color ml-5 cursor-pointer"
                    onClick={() => setShowPriceBreakdwon((prev) => !prev)}
                  >
                    {showPriceBreakdwon ? "Hide" : "Show"} Price Breakdowns
                  </span>
                ) : null}
                {showPriceBreakdwon ? (
                  <div className="text-right">
                    <p className="m-0">
                      Actual Price : {formatCurrency(showData.price)}
                    </p>
                    {showData?.offeredPrice ? (
                      <p className="m-0">
                        Offered Price{" "}
                        <small className="text-gray-500 font-italic">
                          (-
                          {formatCurrency(
                            showData?.price - showData?.offeredPrice
                          )}
                          )
                        </small>{" "}
                        : {formatCurrency(showData?.offeredPrice)}
                      </p>
                    ) : null}
                    {showData?.discountedPrice ? (
                      <p className="m-0">
                        <small className="text-gray-700  font-italic">
                          (With coupon code)
                        </small>
                        <p className="m-0">
                          Discounted Price{" "}
                          <small className="text-gray-500 font-italic">
                            (-
                            {formatCurrency(
                              showData?.offeredPrice
                                ? showData?.offeredPrice -
                                    showData?.discountedPrice
                                : showData?.price - showData?.discountedPrice
                            )}
                            )
                          </small>{" "}
                          : {formatCurrency(showData?.discountedPrice)}
                        </p>
                      </p>
                    ) : null}
                  </div>
                ) : null}
              </div>
            </div>

            {discount === true ? (
              <div
                className="absolute left-0 lg:ml-8 ml:ml-6 bg-white"
                style={{ zIndex: "999", display: option ? "block" : "none" }}
              >
                <div className="discount-code shadow-2 p-2 border-round-md ">
                  <h1 className="font-bold text-2xl merriweather text-dark-gray">
                    Add Discount Code{" "}
                  </h1>
                  <p
                    className="font-normal text-sm"
                    style={{ color: "#797878" }}
                  >
                    Please note that only one Coupon can be applied per vendor.{" "}
                  </p>
                  <CustomInput
                    placeholder="Enter Code"
                    extraClassName="w-full"
                    value={applyDiscount}
                    onChange={(e) => setApplyDiscount(e?.target?.value)}
                    errorMessage={error}
                  ></CustomInput>
                  <ButtonComponent
                    label="Apply"
                    className="w-full mb-3"
                    onClick={handleSubmit}
                  ></ButtonComponent>
                </div>
              </div>
            ) : (
              " "
            )}

            <Divider className="mb-5" />
            <p className="text-base text-dark-gray font-normal">
              {showData?.subscriptionDescription}
            </p>
          </CardComponent>
          <div className="grid">
            <div className="col-12  text-right">
              <button
                className="bg-main border-0 border-1 p-2 px-3 border-round-md cursor-pointer"
                onClick={handlePayment}
              >
                <div className="flex align-items-center">
                  <img
                    src={PayIcon}
                    width={24}
                    height={24}
                    alt=""
                    className=""
                  />
                  <span className="font-semibold text-base text-white ml-2 mt-1">
                    Pay &nbsp;
                    {formatCurrency(
                      showData?.discountedPrice !== null
                        ? showData?.discountedPrice
                        : showData?.offeredPrice
                        ? showData?.offeredPrice
                        : showData?.price
                    )}
                  </span>
                </div>
              </button>
            </div>
          </div>
        </div>
      </ContainerBox>
      {option === true ? (
        <div
          className="overlay-box fixed w-full h-full z-2
      top-0
      "
          style={{ background: "#ff000000" }}
          onClick={() => setOption(false)}
        ></div>
      ) : null}
    </>
  );
}
