import React from "react";
import { ContainerBox } from "../../shared/ContainerBox";
import ForgotImg from "../../assets/images/forgot.png";
import CheckIcon from "../../assets/icons/successful img.png";
import ButtonComponent from "../../components/buttons/button";
import { useNavigate } from "react-router-dom";

export const ResetSuccessful = () => {
    const navigate = useNavigate();
    const navigateToSuccess = () => {
        // 👇️ navigate to Login
        navigate("/login");
    };
    return (
        <>
            <ContainerBox containerType="">
                <div className="grid p-0 m-0">
                    <div className="lg:col-6 lg:p-5 md:p-5">
                        <img src={ForgotImg} alt="" className="w-full" />
                    </div>
                    <div className="lg:col-6 lg:p-5 md:p-5">
                        <div className="bg-white py-3 mt-5 mt-5">
                            <img src={CheckIcon} width={131} height={136} alt="" className="mb-3" />
                            <h4 className="font-32 merriweather font-normal text-dark-gray ml-3 p-0 m-0">Password Reset Completed</h4>
                            <p className="text-dark-gray ml-3 mb-5 font-medium text-sm">Now, you can log in to your account with the new password.</p>
                            <ButtonComponent label="Got It" className="lg:ml-3 w-full lg:w-3" onClick={navigateToSuccess} />
                        </div>
                    </div>
                </div>
            </ContainerBox>
        </>
    );
};
