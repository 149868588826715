import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import VendorContentData from "./VendorContentData";
import { ProfileLayout } from "../../layout/ProfileLayout";
import { ContainerBox } from "../../shared/ContainerBox";
import Constants from "../../services/constant";
export default function VendorContent() {
  const navigate = useNavigate();
  const { showAllTentativeData, setVendorId, handleTentativeData, id } =
    VendorContentData();
  return (
    <>
      <div className="profile-container mt-5 mb-7">
        <ContainerBox>
          <h1 className="merriweather text-2xl text-dark-gray font-bold mb-5">
            Vendors
          </h1>
          <div className="grid ">
            {Array.isArray(showAllTentativeData) === true &&
              showAllTentativeData?.map((item, index) => {
                return item?.vendors?.length > 0 ? (
                  <>
                    <div
                      className=" vendor-content-list col-10 md:col-2 relative"
                      style={{
                        width: "19.6667%",
                      }}
                    >
                      <div
                        className="border-1  border-round-xl text-center  flex align-items-center flex relative"
                        style={{
                          borderColor: "#CDD4F0",
                          height: "206px",
                          backgroundImage: `url(${
                            Constants.HOST + item?.image
                          })`,
                          backgroundSize: "cover",
                        }}
                        onClick={() => handleTentativeData(item?._id)}
                      >
                        <div className="overlay overlay_2  border-round-xl">
                          <div className="align-items-center w-full">
                            <img
                              src={Constants.HOST + item.icon}
                              alt=""
                              width={44}
                              height={44}
                              className="filtered-image"
                            ></img>
                            <p className="text-base font-semibold text-white p-0 m-0 mb-3">
                              {item.name}
                            </p>
                            <span className="ml-4 text-lg font-semibold text-white ">
                              {item?.vendorCount}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div
                        onClick={() => {
                          navigate(`/explore/${item?._id}`);
                          setVendorId(item?._id);
                        }}
                        className=" add-vendor-menu cursor-pointer absolute "
                        style={{
                          borderColor: "#CDD4F0",
                          top: "145px",
                          left: "108px",
                        }}
                      >
                        <i
                          className="pi pi-plus p-1 bg-white text-dark"
                          style={{
                            fontSize: "0.875rem",
                            borderRadius: "15px",
                          }}
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div
                      className=" vendor-content-list col-10 md:col-2 relative"
                      style={{ width: "19.6667%" }}
                    >
                      <div
                        className="border-1  border-round-xl text-center  flex align-items-center flex"
                        style={{ borderColor: "#CDD4F0", height: "206px" }}
                      >
                        <div className="align-items-center w-full">
                          <img
                            src={Constants.HOST + item.icon}
                            alt=""
                            width={44}
                            height={44}
                          ></img>
                          <p className="text-base font-semibold text-light-black p-0 m-0 mb-3">
                            {item.name}
                          </p>
                          <button
                            onClick={() => {
                              navigate(`/explore/${item?._id}`);
                              setVendorId(item?._id);
                            }}
                            className=" border-1 p-2 px-5  border-round-md bg-transparent cursor-pointer"
                            style={{ borderColor: "#CDD4F0" }}
                          >
                            <i
                              className="pi pi-plus text-light-black"
                              style={{ fontSize: "1rem" }}
                            />
                            <span className="ml-2 text-base font-medium text-light-black ">
                              Add
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
          </div>
        </ContainerBox>
      </div>
    </>
  );
}
