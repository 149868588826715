import React from "react";
import { IndexLayout } from "../../layout";

export default function Gallery() {
  return (
    <>
      <div className="gallery-container text-white w-full mb-7 ">
        <div className="align-items-center h-full flex justify-content-center">
          <div className="text-center">
            <h1
              className="merriweather font-normal  "
              style={{ fontSize: "44px" }}
            >
              Gallery
            </h1>
            <p className="text-base font-normal ">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry.<br></br>
              Lorem Ipsum has been the industry's standard dummy text ever since
              the 1500s, <br></br>
              when an unknown printer took a galley of type and scrambled
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
