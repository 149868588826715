import React, { useEffect, useState } from "react";
import { HomeBanner } from "./Banner";
import { FindVendor } from "./FindVendor";
import { WeedingSupplier } from "./WeedingSupplier";
import { Review } from "./Review";
import { GetStarted } from "./GetStarted";
import { useDispatch, useSelector } from "react-redux";
import {
  vendorCategoryAction,
  vendorListAction,
} from "../../redux/actions/vendorAction";
import { filterVendors } from "../../redux/actions/BusinessProfile";
import {
  checkUserRole,
  isAuthenticated,
} from "../../services/authService";
import { useNavigate } from "react-router-dom";
import { showToast } from "../../redux/actions/toastAction";
export default function Home({ isLoaded }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    if (isAuthenticated()) {
      if (checkUserRole("Vendor")) {
        navigate("/vendor/dashboard");
      } else {
        navigate("/host/profile");
      }
    }
  }, [navigate]);
  const getVendorCategories = () => {
    dispatch(vendorCategoryAction());
  };
  useEffect(() => {
    getVendorCategories();
  }, []);
  const [searchVendor, setSearchVendor] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const serachVendorData = useSelector(
    (state) => state?.vendorFilter?.vendorFilterProfile
  );
  const handleSearch = () => {
    if (searchVendor === "") {
      dispatch(
        showToast({ severity: "error", summary: "Please Select Category" })
      );
      // } else if (postalCode === "") {
      //   dispatch(showToast({ severity: "error", summary: "Please Select City" }));
    } else if (searchVendor !== "") {
      let city = postalCode?.replace("%20", "");
      let params = {
        city: city,
        vendorType: searchVendor?._id,
        vendor: searchVendor?.name,
      };
      dispatch(filterVendors(params)).then((res) => {
        dispatch(vendorListAction({}, true));
      });
      navigate(`/search?vendor=${searchVendor?.name}&location=${postalCode}`, {
        state: { params },
      });
    }
  };
  return (
    <>
      <HomeBanner
        searchVendor={searchVendor}
        setPostalCode={setPostalCode}
        postalCode={postalCode}
        handleSearch={handleSearch}
        setSearchVendor={setSearchVendor}
        isLoaded={isLoaded}
      />
      {/* <VendorList /> */}
      <FindVendor />
      <WeedingSupplier
        serachVendorData={serachVendorData}
        searchVendor={searchVendor}
        postalCode={postalCode}
      />
      <Review />
      <GetStarted />
    </>
  );
}
