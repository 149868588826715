import { types } from "../types";
import Constants from "../../services/constant";
import api from "../../services/api";
import { showToast } from "./toastAction";
import { hideLoaderAction, showLoaderAction } from "./loaderAction";
import { singleUploadImage, uploadFiles, uploadImages } from "./uploadFiles";

export const getWeddingWebsiteAction =
    (data = {}) =>
    async (dispatch) => {
        dispatch(showLoaderAction());
        const res = await api("GET", Constants.END_POINT.WEBSITE_TEMPLATE, data);
        if (res.success) {
            if (res.data) {
                dispatch({
                    type: types.WEBSITE_TEMPLATE,
                    payload: res.data,
                });
            }
            // dispatch(showToast({ severity: "success", summary: res.message }))
        } else {
            dispatch(showToast({ severity: "error", summary: res.message }));
        }
        dispatch(hideLoaderAction());
    };

export const addWebsiteAction =
    (data = {}, navigate, next) =>
    async (dispatch) => {
        dispatch(showLoaderAction());
        // if (data.bride.image) {
        //   data.bride.image = await singleUploadImage(data.bride.image[0]);
        // }
        // if (data.groom.image) {
        //   data.groom.image = await singleUploadImage(data.groom.image[0]);
        // }
        // if (data.bannerImage) {
        //   data.bannerImage = await singleUploadImage(data.bannerImage);
        // }
        // if (data.endMessage.image) {
        //   data.endMessage.image = await singleUploadImage(data.endMessageImage[0]);
        // }
        // if (data.saveTheDate.image) {
        //   data.saveTheDate.image = await singleUploadImage(
        //     data.saveTheDate.image[0]
        //   );
        // }
        // if (data.ourStory.images) {
        //   data.ourStory.images = await uploadImages(data.ourStory.images);
        // }

        const res = await api("POST", Constants.END_POINT.WEBSITE_TEMPLATE, data);
        if (res.success) {
            if (res.data) {
                dispatch({
                    type: types.WEBSITE_TEMPLATE,
                    payload: res.data,
                });
                navigate(`/host/wedding-website-template/${res.data._id}`);
                if (next) {
                    next();
                }
            }
            // dispatch(showToast({ severity: "success", summary: res.message }))
        } else {
            dispatch(showToast({ severity: "error", summary: res.message }));
        }
        dispatch(hideLoaderAction());
    };

export const updateWebsiteAction =
    (data = {}, id) =>
    async (dispatch) => {
        dispatch(showLoaderAction());
        if (data.bride.image && data.bride.image[0]) {
            data.bride.image = await singleUploadImage(data.bride.image[0]);
        } else {
            data.bride.image = "";
        }
        if (data.groom.image && data.groom.image[0]) {
            data.groom.image = await singleUploadImage(data.groom.image[0]);
        } else {
            data.groom.image = "";
        }
        if (data.bannerImage) {
            data.bannerImage = await singleUploadImage(data.bannerImage);
        }
        if (data.endMessageImage && data.endMessageImage[0]) {
            data.endMessage.image = await singleUploadImage(data.endMessageImage[0]);
        } else {
            data.endMessage.image = "";
        }
        if (data.saveTheDate.image && data.saveTheDate.image[0]) {
            data.saveTheDate.image = await singleUploadImage(data.saveTheDate.image[0]);
        } else {
            data.saveTheDate.image = "";
        }
        if (data.ourStory.images) {
            data.ourStory.images = await uploadImages(data.ourStory.images);
        }

        const res = await api("PUT", `${Constants.END_POINT.WEBSITE_TEMPLATE}${id}`, data);
        if (res.success) {
            if (res.data) {
                dispatch({
                    type: types.WEBSITE_TEMPLATE,
                    payload: res.data,
                });
            }
            // dispatch(showToast({ severity: "success", summary: res.message }))
        } else {
            dispatch(showToast({ severity: "error", summary: res.message }));
        }
        dispatch(hideLoaderAction());
    };

export const getPublicWeddingWebsiteAction = (id) => async (dispatch) => {
    dispatch(showLoaderAction());
    const res = await api("GET", Constants.END_POINT.PUBLIC_WEBSITE_TEMPLATE + id, {});
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.WEBSITE_TEMPLATE,
                payload: res.data,
            });
        }
        // dispatch(showToast({ severity: "success", summary: res.message }))
    } else {
        dispatch(showToast({ severity: "error", summary: res.message }));
    }
    dispatch(hideLoaderAction());
};
