import React from "react";

export const ContainerBox = ({ children, containerType, title, titleClass, style, description, descriptionclass, hightlighttext }) => {
    return (
        <div className={`relative ${containerType ? containerType : "container"}`} style={style}>
            {title ? <h4 className={`font-24 mt-0 merriweather text-dark-gray mb-3 ${titleClass}`}>{title}</h4> : null}
            {description ? (
                <p className={`font-12 text-gray ${descriptionclass}`}>
                    {description} {hightlighttext ? <span className="font-bold">{hightlighttext}</span> : null}
                </p>
            ) : null}

            {children}
        </div>
    );
};
