import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  changePasswordAction,
  deleteHostProfileAction,
} from "../../../redux/actions/profileAction";
import { showToast } from "../../../redux/actions/toastAction";
import { logout } from "../../../services/authService";
import { useNavigate } from "react-router-dom";
import { confirmDialogue } from "../../../utils/javascript";

const AccountSettingContainer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [profileChangePassword, setProfileChangePassword] = useState({
    password: null,
    newPassword: null,
    confirmPassword: null,
  });

  const handleChange = ({ name, value }) => {
    setProfileChangePassword((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const save = () => {
    if (
      profileChangePassword?.newPassword ==
      profileChangePassword?.confirmPassword
    ) {
      dispatch(
        changePasswordAction(profileChangePassword, () => {
          logout(() => {
            navigate("/login");
          });
        })
      );
    } else {
      dispatch(
        showToast({
          severity: "error",
          summary: "New Password And Confirm Password is not Matched",
        })
      );
    }
  };

  const deleteAcoount = () => {
    dispatch(
      deleteHostProfileAction(() => {
        logout(() => {
          navigate("/login");
        });
      })
    );
  };

  return {
    profileChangePassword,
    handleChange,
    save,
    deleteAcoount,
  };
};

export default AccountSettingContainer;
