import React from "react";
import { IndexLayout } from "../../layout";

export const PrivacyPolicy = () => {
  return (
    <>
      <div className="container mt-1 md:mt-7">
        <h3 className="text-2xl merriweather text-dark-gray mb-2">
          Privacy Policy
        </h3>
        {/* <h4 className="text-xs font-bold text-light-black mb-5">
          Last updated: 11 April 2022
        </h4> */}
        <p className="text-xs line-height-4 text-light-black mb-5">
          Your privacy is important to us. It is PlanTheKnot's policy to respect
          your privacy and comply with any applicable law and regulation
          regarding any personal information we may collect about you, including
          across our website,{" "}
          <span className="text-blue-300">https://plantheknot.co.uk/</span>, and
          other sites we own and operate
        </p>
        <p className="text-xs line-height-4 text-light-black mb-5">
          Personal information is any information about you which can be used to
          identify you. This includes information about you as a person (such as
          name, address, and date of birth), your devices, payment details, and
          even information about how you use a website or online service.
        </p>
        <p className="text-xs line-height-4 text-light-black mb-5">
          In the event our site contains links to third-party sites and
          services, please be aware that those sites and services have their own
          privacy policies. After following a link to any third-party content,
          you should read their posted privacy policy information about how they
          collect and use personal information. This Privacy Policy does not
          apply to any of your activities after you leave our site.
        </p>
        <p className="text-xs line-height-4 text-light-black mb-5">
          This policy is effective as of 1 November 2023
        </p>
        <p className="text-xs line-height-4 text-light-black mb-5">
          Last updated: 1 November 2023
        </p>
        <h4 className="text-xs font-bold text-light-black">
          Information We Collect
        </h4>
        <p className="text-xs line-height-4 text-light-black mb-5">
          Information we collect falls into one of two categories: “voluntarily
          provided” information and “automatically collected” information.
        </p>
        <p className="text-xs line-height-4 text-light-black mb-5">
          “Voluntarily provided” information refers to any information you
          knowingly and actively provide us when using or participating in any
          of our services and promotions.
        </p>
        <p className="text-xs line-height-4 text-light-black mb-5">
          “Automatically collected” information refers to any information
          automatically sent by your devices in the course of accessing our
          products and services.
        </p>

        <h4 className="text-xs font-bold text-light-black">Log Data</h4>
        <p className="text-xs line-height-4 text-light-black mb-5">
          When you visit our website, our servers may automatically log the
          standard data provided by your web browser. It may include your
          device’s Internet Protocol (IP) address, your browser type and
          version, the pages you visit, the time and date of your visit, the
          time spent on each page, and other details about your visit.
        </p>
        <p className="text-xs line-height-4 text-light-black mb-5">
          Additionally, if you encounter certain errors while using the site, we
          may automatically collect data about the error and the circumstances
          surrounding its occurrence. This data may include technical details
          about your device, what you were trying to do when the error happened,
          and other technical information relating to the problem. You may or
          may not receive notice of such errors, even in the moment they occur,
          that they have occurred, or what the nature of the error is.
        </p>
        <p className="text-xs line-height-4 text-light-black mb-5">
          Please be aware that while this information may not be personally
          identifying by itself, it may be possible to combine it with other
          data to personally identify individual persons
        </p>
        <h4 className="text-xs font-bold text-light-black">
          Personal Information
        </h4>
        <p className="text-xs line-height-4 text-light-black mb-5">
          We may ask for personal information — for example, when you submit
          content to us or when you contact us — which may include one or more
          of the following:
        </p>
        <ul className="text-xs line-height-4 text-light-black mb-5">
          <li>Name</li>
          <li>Email</li>
          <li>Social media profiles</li>
          <li>Date of birth</li>
          <li>Phone/mobile number</li>
          <li>Home/mailing address</li>
        </ul>
        <h4 className="text-xs font-bold text-light-black">
          User-Generated Content
        </h4>
        <p className="text-xs line-height-4 text-light-black mb-5">
          We consider “user-generated content” to be materials (text, image
          and/or video content) voluntarily supplied to us by our users for the
          purpose of publication on our website or re-publishing on our social
          media channels. All user-generated content is associated with the
          account or email address used to submit the materials.
        </p>
        <p className="text-xs line-height-4 text-light-black mb-5">
          Please be aware that any content you submit for the purpose of
          publication will be public after posting (and subsequent review or
          vetting process). Once published, it may be accessible to third
          parties not covered under this privacy policy.
        </p>
        <h4 className="text-xs font-bold text-light-black">
          Legitimate Reasons for Processing Your Personal Information
        </h4>
        <p className="text-xs line-height-4 text-light-black mb-5">
          We only collect and use your personal information when we have a
          legitimate reason for doing so. In which instance, we only collect
          personal information that is reasonably necessary to provide our
          services to you.
        </p>
        <h4 className="text-xs font-bold text-light-black">
          Collection and Use of Information
        </h4>
        <p className="text-xs line-height-4 text-light-black mb-5">
          We may collect personal information from you when you do any of the
          following on our website:
        </p>
        <ul className="text-xs line-height-4 text-light-black mb-5">
          <li>Register for an account</li>
          <li>
            Sign up to receive updates from us via email or social media
            channels
          </li>
          <li>Use a mobile device or web browser to access our content</li>
          <li>
            Contact us via email, social media, or on any similar technologies
          </li>
          <li>When you mention us on social media</li>
        </ul>
        <p className="text-xs line-height-4 text-light-black mb-5">
          We may collect, hold, use, and disclose information for the following
          purposes, and personal information will not be further processed in a
          manner that is incompatible with these purposes:
        </p>
        <ul className="text-xs line-height-4 text-light-black mb-5">
          <li>to provide you with our platform's core features and services</li>
          <li>
            to enable you to customise or personalise your experience of our
            website
          </li>
          <li>
            for analytics, market research, and business development, including
            to operate and improve our website, associated applications, and
            associated social media platforms
          </li>
          <li>
            to enable you to access and use our website, associated
            applications, and associated social media platforms
          </li>
          <li>for internal record keeping and administrative purposes</li>
        </ul>
        <p className="text-xs line-height-4 text-light-black mb-5">
          We may combine voluntarily provided and automatically collected
          personal information with general information or research data we
          receive from other trusted sources. For example, Our marketing and
          market research activities may uncover data and insights, which we may
          combine with information about how visitors use our site to improve
          our site and your experience on it.
        </p>
        <h4 className="text-xs font-bold text-light-black">
          Security of Your Personal Information
        </h4>
        <p className="text-xs line-height-4 text-light-black mb-5">
          When we collect and process personal information, and while we retain
          this information, we will protect it within commercially acceptable
          means to prevent loss and theft, as well as unauthorised access,
          disclosure, copying, use or modification.
        </p>
        <p className="text-xs line-height-4 text-light-black mb-5">
          Although we will do our best to protect the personal information you
          provide to us, we advise that no method of electronic transmission or
          storage is 100% secure and no one can guarantee absolute data
          security.
        </p>
        <p className="text-xs line-height-4 text-light-black mb-5">
          You are responsible for selecting any password and its overall
          security strength, ensuring the security of your own information
          within the bounds of our services. For example, ensuring any passwords
          associated with accessing your personal information and accounts are
          secure and confidential.
        </p>
        <h4 className="text-xs font-bold text-light-black">
          How Long We Keep Your Personal Information
        </h4>
        <p className="text-xs line-height-4 text-light-black mb-5">
          We keep your personal information only for as long as we need to. This
          time period may depend on what we are using your information for, in
          accordance with this privacy policy. For example, if you have provided
          us with personal information as part of creating an account with us,
          we may retain this information for the duration your account exists on
          our system. If your personal information is no longer required for
          this purpose, we will delete it or make it anonymous by removing all
          details that identify you.
        </p>
        <p className="text-xs line-height-4 text-light-black mb-5">
          However, if necessary, we may retain your personal information for our
          compliance with a legal, accounting, or reporting obligation or for
          archiving purposes in the public interest, scientific, or historical
          research purposes or statistical purposes.
        </p>
        <h4 className="text-xs font-bold text-light-black">
          Children’s Privacy
        </h4>
        <p className="text-xs line-height-4 text-light-black mb-5">
          We do not aim any of our products or services directly at children
          under the age of 13 and we do not knowingly collect personal
          information about children under 13.
        </p>
        <h4 className="text-xs font-bold text-light-black">
          International Transfers of Personal Information
        </h4>
        <p className="text-xs line-height-4 text-light-black mb-5">
          The personal information we collect is stored and/or processed in
          United Kingdom, or where we or our partners, affiliates, and
          third-party providers maintain facilities.
        </p>
        <p className="text-xs line-height-4 text-light-black mb-5">
          The countries to which we store, process, or transfer your personal
          information may not have the same data protection laws as the country
          in which you initially provided the information. If we transfer your
          personal information to third parties in other countries:
        </p>
        <p className="text-xs line-height-4 text-light-black mb-5">
          (i) we will perform those transfers in accordance with the
          requirements of applicable law; and (ii) we will protect the
          transferred personal information in accordance with this privacy
          policy.
        </p>
        <h4 className="text-xs font-bold text-light-black">
          Your Rights and Controlling Your Personal Information
        </h4>
        <p className="text-xs line-height-4 text-light-black mb-5">
          <span className="text-xs font-bold text-light-black">
            Your choice:
          </span>{" "}
          By providing personal information to us, you understand we will
          collect, hold, use, and disclose your personal information in
          accordance with this privacy policy. You do not have to provide
          personal information to us, however, if you do not, it may affect your
          use of our website or the products and/or services offered on or
          through it.
        </p>
        <p className="text-xs line-height-4 text-light-black mb-5">
          <span className="text-xs font-bold text-light-black">
            Information from third parties:
          </span>{" "}
          If we receive personal information about you from a third party, we
          will protect it as set out in this privacy policy. If you are a third
          party providing personal information about somebody else, you
          represent and warrant that you have such person’s consent to provide
          the personal information to us
        </p>
        <p className="text-xs line-height-4 text-light-black mb-5">
          <span className="text-xs font-bold text-light-black">
            Marketing permission:
          </span>{" "}
          If you have previously agreed to us using your personal information
          for direct marketing purposes, you may change your mind at any time by
          contacting us using the details below.
        </p>
        <p className="text-xs line-height-4 text-light-black mb-5">
          <span className="text-xs font-bold text-light-black">Access:</span>
          You may request details of the personal information that we hold about
          you.
        </p>
        <p className="text-xs line-height-4 text-light-black mb-5">
          <span className="text-xs font-bold text-light-black">
            Correction:{" "}
          </span>{" "}
          If you believe that any information we hold about you is inaccurate,
          out of date, incomplete, irrelevant, or misleading, please contact us
          using the details provided in this privacy policy. We will take
          reasonable steps to correct any information found to be inaccurate,
          incomplete, misleading, or out of date.
        </p>
        <p className="text-xs line-height-4 text-light-black mb-5">
          <span className="text-xs font-bold text-light-black">
            Non-discrimination{" "}
          </span>{" "}
          We will not discriminate against you for exercising any of your rights
          over your personal information. Unless your personal information is
          required to provide you with a particular service or offer (for
          example providing user support), we will not deny you goods or
          services and/or charge you different prices or rates for goods or
          services, including through granting discounts or other benefits, or
          imposing penalties, or provide you with a different level or quality
          of goods or services.
        </p>
        <p className="text-xs line-height-4 text-light-black mb-5">
          <span className="text-xs font-bold text-light-black">
            Notification of data breaches:
          </span>{" "}
          We will comply with laws applicable to us in respect of any data
          breach.
        </p>
        <p className="text-xs line-height-4 text-light-black mb-5">
          <span className="text-xs font-bold text-light-black">
            Complaints:
          </span>{" "}
          If you believe that we have breached a relevant data protection law
          and wish to make a complaint, please contact us using the details
          below and provide us with full details of the alleged breach. We will
          promptly investigate your complaint and respond to you, in writing,
          setting out the outcome of our investigation and the steps we will
          take to deal with your complaint. You also have the right to contact a
          regulatory body or data protection authority in relation to your
          complaint.
        </p>
        <p className="text-xs line-height-4 text-light-black mb-5">
          <span className="text-xs font-bold text-light-black">
            Unsubscribe:
          </span>{" "}
          To unsubscribe from our email database or opt-out of communications
          (including marketing communications), please contact us using the
          details provided in this privacy policy, or opt-out using the opt-out
          facilities provided in the communication. We may need to request
          specific information from you to help us confirm your identity
        </p>
        <h4 className="text-xs font-bold text-light-black">Use of Cookies</h4>
        <p className="text-xs line-height-4 text-light-black mb-5">
          We use "cookies" to collect information about you and your activity
          across our site. A cookie is a small piece of data that our website
          stores on your computer, and accesses each time you visit, so we can
          understand how you use our site. This helps us serve you content based
          on preferences you have specified.
        </p>
        <p className="text-xs line-height-4 text-light-black mb-5">
          Please refer to our Cookie Policy for more information.
        </p>
        <h4 className="text-xs font-bold text-light-black">
          Business Transfers
        </h4>
        <p className="text-xs line-height-4 text-light-black mb-5">
          If we or our assets are acquired, or in the unlikely event that we go
          out of business or enter bankruptcy, we would include data, including
          your personal information, among the assets transferred to any parties
          who acquire us. You acknowledge that such transfers may occur, and
          that any parties who acquire us may, to the extent permitted by
          applicable law, continue to use your personal information according to
          this policy, which they will be required to assume as it is the basis
          for any ownership or use rights we have over such information.
        </p>
        <h4 className="text-xs font-bold text-light-black">
          Limits of Our Policy
        </h4>
        <p className="text-xs line-height-4 text-light-black mb-5">
          Our website may link to external sites that are not operated by us.
          Please be aware that we have no control over the content and policies
          of those sites, and cannot accept responsibility or liability for
          their respective privacy practices.
        </p>
        <h4 className="text-xs font-bold text-light-black">
          Changes to This Policy
        </h4>
        <p className="text-xs line-height-4 text-light-black mb-5">
          At our discretion, we may change our privacy policy to reflect updates
          to our business processes, current acceptable practices, or
          legislative or regulatory changes. If we decide to change this privacy
          policy, we will post the changes here at the same link by which you
          are accessing this privacy policy.
        </p>
        <p className="text-xs line-height-4 text-light-black mb-5">
          If the changes are significant, or if required by applicable law, we
          will contact you (based on your selected preferences for
          communications from us) and all our registered users with the new
          details and links to the updated or changed policy.
        </p>
        <p className="text-xs line-height-4 text-light-black mb-5">
          If required by law, we will get your permission or give you the
          opportunity to opt in to or opt out of, as applicable, any new uses of
          your personal information
        </p>
        <h4 className="text-xs font-bold text-light-black">
          Additional Disclosures for UK General Data Protection Regulation (UK
          GDPR) Compliance (UK)
        </h4>
        <h4 className="text-xs font-bold text-light-black">
          Data Controller / Data Processor
        </h4>
        <p className="text-xs line-height-4 text-light-black mb-5">
          The GDPR distinguishes between organisations that process personal
          information for their own purposes (known as “data controllers”) and
          organizations that process personal information on behalf of other
          organizations (known as “data processors”). For the purposes covered
          by this Privacy Policy, we are a Data Controller with respect to the
          personal information you provide to us and remain compliant with our
          data controller obligations under GDPR.
        </p>
        <h4 className="text-xs font-bold text-light-black">
          Third-Party Provided Content
        </h4>
        <p className="text-xs line-height-4 text-light-black mb-5">
          We may indirectly collect personal information about you from
          third-parties who have your permission to share it. For example, if
          you purchase a product or service from a business working with us, and
          give your permission for us to use your details in order to complete
          the transaction.
        </p>
        <p className="text-xs line-height-4 text-light-black mb-5">
          We may also collect publicly available information about you, such as
          from any social media and messaging platforms you may use. The
          availability of this information will depend on both the privacy
          policies and your own privacy settings on such platforms.
        </p>
        <h4 className="text-xs font-bold text-light-black">
          Additional Disclosure for Collection and Use of Personal Information
        </h4>
        <p className="text-xs line-height-4 text-light-black mb-5">
          In addition to the aforementioned purposes warranting the collection
          and use of personal information, we may also conduct marketing and
          market research activities, including how visitors use our site,
          website improvement opportunities and user experience.
        </p>
        <h4 className="text-xs font-bold text-light-black">
          Personal Information No Longer Required for Our Purposes
        </h4>
        <p className="text-xs line-height-4 text-light-black mb-5">
          If your personal information is no longer required for our stated
          purposes, or if you instruct us under your Data Subject Rights, we
          will delete it or make it anonymous by removing all details that
          identify you (“Anonymisation”). However, if necessary, we may retain
          your personal information for our compliance with a legal, accounting,
          or reporting obligation or for archiving purposes in the public
          interest, scientific, or historical research purposes or statistical
          purposes.
        </p>
        <h4 className="text-xs font-bold text-light-black">
          Legal Bases for Processing Your Personal Information
        </h4>
        <p className="text-xs line-height-4 text-light-black mb-5">
          Data Protection and Privacy Laws permit us to collect and use your
          personal data on a limited number of grounds. In which case, we will
          collect and use your personal information lawfully, fairly and in a
          transparent manner. We never directly market to any person(s) under 18
          years of age.
        </p>
        <p className="text-xs line-height-4 text-light-black mb-5">
          Our lawful bases depend on the services you use and how you use them.
          This is a non-exhaustive list of the lawful bases we use:
        </p>
        <h4 className="text-xs font-bold text-light-black">Consent From You</h4>
        <p className="text-xs line-height-4 text-light-black mb-5">
          Where you give us consent to collect and use your personal information
          for a specific purpose. You may withdraw your consent at any time
          using the facilities we provide; however this will not affect any use
          of your information that has already taken place. When you contact us,
          we assume your consent based on your positive action of contact,
          therefore you consent to your name and email address being used so we
          can respond to your enquiry.
        </p>
        <p className="text-xs line-height-4 text-light-black mb-5">
          Where you agree to receive marketing communications from us, we will
          do so based solely on your indication of consent or until you instruct
          us not to, which you can do at any time.
        </p>
        <p className="text-xs line-height-4 text-light-black mb-5">
          While you may request that we delete your contact details at any time,
          we cannot recall any email we have already sent. If you have any
          further enquiries about how to withdraw your consent, please feel free
          to enquire using the details provided in the Contact Us section of
          this privacy policy.
        </p>
        <h4 className="text-xs font-bold text-light-black">
          Performance of a Contract or Transaction
        </h4>
        <p className="text-xs line-height-4 text-light-black mb-5">
          Where you have entered into a contract or transaction with us, or in
          order to take preparatory steps prior to our entering into a contract
          or transaction with you. For example, if you contact us with an
          enquiry, we may require personal information such as your name and
          contact details in order to respond.
        </p>
        <h4 className="text-xs font-bold text-light-black">
          Our Legitimate Interests
        </h4>
        <p className="text-xs line-height-4 text-light-black mb-5">
          Where we assess it is necessary for our legitimate interests, such as
          for us to provide, operate, improve and communicate our services. We
          consider our legitimate interests to include research and development,
          understanding our audience, marketing and promoting our services,
          measures taken to operate our services efficiently, marketing
          analysis, and measures taken to protect our legal rights and
          interests.
        </p>
        <h4 className="text-xs font-bold text-light-black">
          Compliance with Law
        </h4>
        <p className="text-xs line-height-4 text-light-black mb-5">
          In some cases, we may have a legal obligation to use or keep your
          personal information. Such cases may include (but are not limited to)
          court orders, criminal investigations, government requests, and
          regulatory obligations. For example, we are required to keep financial
          records for a period of 7 years. If you have any further enquiries
          about how we retain personal information in order to comply with the
          law, please feel free to enquire using the details provided in the
          Contact Us section of this privacy policy.
        </p>
        <h4 className="text-xs font-bold text-light-black">
          International Transfers of Personal Information
        </h4>
        <p className="text-xs line-height-4 text-light-black mb-5">
          The personal information we collect is stored and/or processed in the
          United Kingdom by us. Following an adequacy decision by the EU
          Commission, the UK has been granted an essentially equivalent level of
          protection to that guaranteed under UK GDPR.
        </p>
        <p className="text-xs line-height-4 text-light-black mb-5">
          On some occasions, where we share your data with third parties, they
          may be based outside of the UK, or the European Economic Area (“EEA”).
          These countries to which we store, process, or transfer your personal
          information may not have the same data protection laws as the country
          in which you initially provided the information.
        </p>
        <p className="text-xs line-height-4 text-light-black mb-5">
          If we transfer your personal information to third parties in other
          countries:
        </p>
        <ul className="text-xs line-height-4 text-light-black mb-5">
          <li>
            we will perform those transfers in accordance with the requirements
            of the UK GDPR (Article 45) and Data Protection Act 2018;
          </li>
          <li>
            we will adopt appropriate safeguards for protecting the transferred
            data, including in transit, such as standard contractual clauses
            (“SCCs”) or binding corporate rules.
          </li>
        </ul>
        <h4 className="text-xs font-bold text-light-black">
          Your Data Subject Rights
        </h4>
        <p className="text-xs line-height-4 text-light-black mb-5">
          <span className="text-xs font-bold text-light-black">
            Right to Restrict Processing:{" "}
          </span>{" "}
          You have the right to request that we restrict the processing of your
          personal information if (i) you are concerned about the accuracy of
          your personal information; (ii) you believe your personal information
          has been unlawfully processed; (iii) you need us to maintain the
          personal information solely for the purpose of a legal claim; or (iv)
          we are in the process of considering your objection in relation to
          processing on the basis of legitimate interests.
        </p>
        <p className="text-xs line-height-4 text-light-black mb-5">
          <span className="text-xs font-bold text-light-black">
            Right to Object:
          </span>{" "}
          You have the right to object to processing of your personal
          information that is based on our legitimate interests or public
          interest. If this is done, we must provide compelling legitimate
          grounds for the processing which overrides your interests, rights, and
          freedoms, in order to proceed with the processing of your personal
          information.
        </p>
        <p className="text-xs line-height-4 text-light-black mb-5">
          <span className="text-xs font-bold text-light-black">
            Right to be Informed:
          </span>{" "}
          You have the right to be informed with how your data is collected,
          processed, shared and stored.
        </p>
        <p className="text-xs line-height-4 text-light-black mb-5">
          <span className="text-xs font-bold text-light-black">
            Right of Access:
          </span>{" "}
          You may request a copy of the personal information that we hold about
          you at any time by submitting a Data Subject Access Request (DSAR).
          The statutory deadline for fulfilling a DSAR request is 30 calendar
          days from our receipt of your request.
        </p>
        <p className="text-xs line-height-4 text-light-black mb-5">
          <span className="text-xs font-bold text-light-black">
            Right to Erasure:
          </span>{" "}
          In certain circumstances, you can ask for your personal data to be
          erased from the records held by organisations. However this is a
          qualified right; it is not absolute, and may only apply in certain
          circumstances.
        </p>
        <p className="text-xs line-height-4 text-light-black mb-5">
          When may the right to erasure apply?
        </p>
        <ul className="text-xs line-height-4 text-light-black mb-5">
          <li>
            When the personal data is no longer necessary for the purpose for
            which it was originally collected or processed for.
          </li>
          <li>
            If consent was the lawful basis for processing personal data and
            that consent has been withdrawn. PlanTheKnot relies on consent to
            process personal data in very few circumstances.
          </li>
          <li>
            The Company is relying on legitimate interests as a legal basis for
            processing personal data and an individual has exercised the right
            to object and it has been determined that the Company has no
            overriding legitimate grounds to refuse that request.
          </li>
          <li>
            Personal data are being processed for direct marketing purposes e.g.
            a person’s name and email address, and the individual objects to
            that processing.
          </li>
          <li>
            There is legislation that requires that personal data are to be
            destroyed.
          </li>
        </ul>
        <p className="text-xs line-height-4 text-light-black mb-5">
          <span className="text-xs font-bold text-light-black">
            Right to Portability:
          </span>
          Individuals have the right to get some of their personal data from an
          organisation in a way that is accessible and machine-readable, for
          example as a csv file. Associated with this, individuals also have the
          right to ask an organisation to transfer their personal data to
          another organisation.
        </p>
        <p className="text-xs line-height-4 text-light-black mb-5">
          However, the right to portability:
        </p>
        <ul className="text-xs line-height-4 text-light-black mb-5">
          <li>
            only applies to personal data which a person has directly given to
            PlanTheKnot in electronic form; and
          </li>
          <li>
            onward transfer will only be available where this is “technically
            feasible”.
          </li>
        </ul>
        <p className="text-xs line-height-4 text-light-black mb-5">
          <span className="text-xs font-bold text-light-black">
            Right to Rectification:
          </span>
          If personal data is inaccurate, out of date, or incomplete,
          individuals have the right to correct, update or complete that data.
          Collectively this is referred to as the right to rectification.
          Rectification may involve filling the gaps i.e. to have to have
          incomplete personal data completed – although this will depend on the
          purposes for the processing. This may involve adding a supplementary
          statement to the incomplete data to highlight any inaccuracy or claim
          thereof.
        </p>
        <p className="text-xs line-height-4 text-light-black mb-5">
          This right only applies to an individual’s own personal data; a person
          cannot seek the rectification of another person’s information.
        </p>
        <p className="text-xs line-height-4 text-light-black mb-5">
          <span className="text-xs font-bold text-light-black">
            Notification of data breaches:
          </span>
          Upon discovery of a data breach, we will investigate the incident and
          report it to the UK’s data protection regulator and yourself, if we
          deem it appropriate to do so.
        </p>
        <p className="text-xs line-height-4 text-light-black mb-5">
          <span className="text-xs font-bold text-light-black">
            Complaints:
          </span>
          You have the right, at any time, to lodge a complaint with the
          Information Commissioner’s Office (ICO), the UK supervisory authority
          for data protection issues (www.ico.org.uk). We would, however,
          appreciate the chance to deal with your concerns before you approach
          the ICO so please contact us in the first instance using the details
          below. Please provide us with as much information as you can about the
          alleged breach. We will promptly investigate your complaint and
          respond to you, in writing, setting out the outcome of our
          investigation and the steps we will take to deal with your complaint.
        </p>
        <h4 className="text-xs font-bold text-light-black">
          Enquiries, Reports and Escalation
        </h4>
        <p className="text-xs line-height-4 text-light-black mb-5">
          To enquire about PlanTheKnot's privacy policy, or to report violations
          of user privacy, you may contact our Data Protection Officer using the
          details in the Contact us section of this privacy policy.
        </p>
        <p className="text-xs line-height-4 text-light-black mb-5">
          If we fail to resolve your concern to your satisfaction, you may also
          contact the Information Commissioner’s Office (ICO), the UK Data
          Protection regulator:
        </p>
        <p className="text-xs line-height-4 text-light-black">
          Information Commissioner's Office
        </p>
        <p className="text-xs line-height-4 text-light-black">Wycliffe House</p>
        <p className="text-xs line-height-4 text-light-black ">Water Lane</p>
        <p className="text-xs line-height-4 text-light-black ">Wilmslow</p>
        <p className="text-xs line-height-4 text-light-black">Cheshire</p>
        <p className="text-xs line-height-4 text-light-black">SK9 5AF</p>
        <p className="text-xs line-height-4 text-light-black mb-5">
          <span className="text-xs font-bold text-light-black"> Tel:</span> Tel:
          0303 123 1113 (local rate)
        </p>
        <p className="text-xs line-height-4 text-light-black mb-5">
          <span className="text-xs font-bold text-light-black">Website:</span>
          <span className="text-blue-300">www.ico.org.uk</span>
        </p>
        <h4 className="text-xs font-bold text-light-black">Contact Us</h4>
        <p className="text-xs line-height-4 text-light-black mb-5">
          For any questions or concerns regarding your privacy, you may contact
          us using the following details:
        </p>
        <p className="text-xs line-height-4 text-light-black">PlanTheKnot</p>
        <p className="text-xs line-height-4 text-light-black ">
          <span className="text-blue-300">support@plantheknot.co.uk</span>
        </p>
      </div>
    </>
  );
};
