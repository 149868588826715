import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { quoteDataAction } from "../../redux/actions/bussinessInformationAction";
import { quoteGraph } from "../../redux/actions/bussinessInformationAction";
import TabPanelVendor from "./TabPanelVendor";

export default function VendorDashboard({ isLoaded }) {
  const dispatch = useDispatch();
  const [activeIndex, setActiveIndex] = useState(0);
  const [selectedYear, setSelectedYear] = useState();
  const [sortValue, setSortValue] = useState();
  useEffect(() => {
    let params;
    if (sortValue === "Old") {
      params = {
        sortOrder: "ASC",
      };
    } else {
      params = {
        sortOrder: "DESC",
      };
    }
    dispatch(quoteDataAction(params));
  }, [sortValue]);
  const quoteData = useSelector((state) => state?.quoteData?.quoteDataProfile);
  const sort = ["Old", "New"];

  useEffect(() => {
    getChatData();
  }, [selectedYear]);

  const getChatData = () => {
    let params = {
      year: selectedYear,
    };
    dispatch(quoteGraph(params));
  };

  return (
    <>
      <TabPanelVendor
        activeIndex={activeIndex}
        setActiveIndex={setActiveIndex}
        quoteData={quoteData}
        sort={sort}
        sortValue={sortValue}
        setSortValue={setSortValue}
        isLoaded={isLoaded}
        getChatData={getChatData}
        selectedYear={selectedYear}
        setSelectedYear={setSelectedYear}
      />
    </>
  );
}
