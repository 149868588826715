import { types } from "../types";

const initialState = {
    ProfileData: {},
    hosts: [],
};

const profileReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.MY_PROFILE:
            const updatedHosts = [{ name: `${action.payload.name}'s Family`, value: action.payload.name }];
            if (action.payload.partnerName) {
                updatedHosts.push({ name: `${action.payload.partnerName}'s Family`, value: action.payload.partnerName });
            }
            return {
                ...state,
                ProfileData: action.payload,
                hosts: updatedHosts,
            };
        case types.CLEAR_PROFILE:
            return { ...state, ProfileData: {} };
        default:
            return state;
    }
};

export default profileReducer;
