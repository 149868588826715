
import React from "react";
import WeddingTemplateTopbar from "./WeddingTemplateTopbar";
import WeddingTemplateFooter from "./WeddingTemplateFooter";

export const WeddingTemplateLayout = ({ children }) => {
    return (
        <div>
            <WeddingTemplateTopbar />
            {children}
            {/* <WeddingTemplateFooter /> */}
        </div>
    );
};
