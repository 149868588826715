import React, { useState, useEffect } from "react";
import Table from "../../components/table/table";
import {
  quoteDataAction,
  deleteQuoteAction,
  viewQuoteAction,
  importantQuoteAction,
} from "../../redux/actions/bussinessInformationAction";
import { useDispatch, useSelector } from "react-redux";
export default function VendorData({ quoteData, searchData, getChatData }) {
  const dispatch = useDispatch();
  const [tableData, setTableData] = useState();
  const [deleteQuoteId, setDeleteQuoteId] = useState();
  const [viewQuote, setViewQuote] = useState(false);
  const [deleteQuote, setDeleteQuote] = useState({
    reasonToDecline: "",
  });
  const [error, setError] = useState({
    reasonToDecline: "",
  });
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (searchData?.quotes?.length > 0) {
      setTableData(searchData?.quotes);
    } else {
      setTableData(quoteData?.quotes);
    }
  }, [quoteData, searchData]);
  const handleChange = (e) => {
    const { name, value } = e?.target;
    setDeleteQuote({
      ...deleteQuote,
      [name]: value,
    });
    setError({
      ...error,
      [name]: null,
    });
  };
  const handleDelete = () => {
    if (deleteQuote?.reasonToDecline === "") {
      setError({
        ...error,
        reasonToDecline:
          deleteQuote?.reasonToDecline === ""
            ? "Please Enter Reason"
            : error?.reasonToDecline,
      });
      return false;
    } else {
      dispatch(deleteQuoteAction(deleteQuoteId?._id, deleteQuote)).then(
        (res) => {
          if (res.success === true) {
            setVisible(false);
            dispatch(quoteDataAction());
            getChatData();
          }
        }
      );
    }
  };
  const handleView = (id) => {
    dispatch(viewQuoteAction(id));
  };
  const viewQuoteData = useSelector(
    (state) => state?.viewQuote?.viewQuoteProfile
  );
  const handleImportant = (id) => {
    dispatch(importantQuoteAction(id?._id)).then((res) => {
      if (res.success === true) {
        dispatch(quoteDataAction());
        getChatData();
      }
    });
  };
  const handleViewClose = () => {
    setViewQuote(false);
    dispatch(quoteDataAction());
    getChatData();
  };
  const TableHeading = [
    {
      field: "eventDate",
      header: "Event Date",
      sortable: true,
      body: "EventDateIconBodyTemplate(row)",
    },
    {
      field: "time",
      header: "Quote Date",
      sortable: true,
      body: "QuoteDateIconBodyTemplate(row)",
    },
    { field: "email", header: "Email Address", sortable: true },
    {
      field: "numberOfGuests",
      header: "No. of Guests",
      sortable: true,
      body: "UserIconBodyTemplate(row)",
    },
    { field: "phoneNumber", header: "Mobile Number", sortable: true },
    {
      field: "status",
      header: "Status",
      body: "statusBodyTemplate(row)",
    },
    {
      field: "Actions",
      header: "Actions",
      body: "VendorActionBodyTemplate(row)",
    },
  ];

  return (
    <>
      <div className="tabledata relative mb-5  ">
        <Table
          className=""
          tableHeading={TableHeading}
          tableData={tableData}
          setDeleteQuoteId={setDeleteQuoteId}
          visible={visible}
          setVisible={setVisible}
          handleChange={handleChange}
          deleteQuote={deleteQuote}
          handleDelete={handleDelete}
          error={error}
          setViewQuote={setViewQuote}
          viewQuote={viewQuote}
          handleView={handleView}
          viewQuoteData={viewQuoteData}
          handleImportant={handleImportant}
          handleViewClose={handleViewClose}
        />
      </div>
    </>
  );
}
