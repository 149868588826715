import { entries, notEqual, values } from "./javascript";
import { allValidations } from "./formValidations";
import Constants from "../services/constant";
import api from "../services/api";

export const shareFunction = (setDialog, surveyId, dispatch, showToast) => {
    setDialog({
        visible: true,
        title: "Copy Link",
        description: `${Constants.WEBSITE_URL + "survey/" + surveyId}`,
        cancel: true,
        type: "SHARE",
        saveLabel: "Copy Link",
        buttonPosition: "center",
        saveFunction: () => copyLink(setDialog, surveyId, dispatch, showToast),
    });
};

const copyLink = (setDialog, surveyId, dispatch, showToast) => {
    navigator.clipboard.writeText(Constants.WEBSITE_URL + "survey/" + surveyId);
    dispatch(
        showToast({
            severity: "success",
            summary: "Link copied to Clipboard",
            detail: "",
        })
    );
    setDialog({ visible: false });
};

export const imagePath = (path) => {
    if (path !== null) {
        if (typeof path === "object") {
            return URL.createObjectURL(path);
        } else {
            if (path.includes("http")) {
                return path;
            } else {
                return Constants.HOST + path;
            }
        }
    }
};

export const uploadFile = async (data) => {
    if (typeof data === "string") return data;
    else {
        let formData = new FormData();
        formData.append("image", data);
        const res = await api("post", Constants.END_POINT.UPLOAD_PROFILE_IMAGE, formData, { formData: true });
        if (res.success && res.data) {
            return res.data.image;
        } else {
            return;
        }
    }
};

export const showFormErrors = (data, setData, ignore) => {
    let formErrors = {};
    entries(data).forEach(([key, value]) => {
        formErrors = {
            ...formErrors,
            ...allValidations(key, value, data, ignore),
        };
    });
    setData({ ...data, formErrors });
    return !values(formErrors).some((v) => notEqual(v, ""));
};

export const showArrayFormErrors = (array, ignore) => {
    console.log("array>>", array);
    let isValid = true;
    let res = array.map((data) => {
        let formErrors = {};
        entries(data).forEach(([key, value]) => {
            formErrors = {
                ...formErrors,
                ...allValidations(key, value, data, ignore),
            };
        });
        ignore?.forEach((name) => {
            if (formErrors[name]) {
                formErrors[name] = "";
            }
        });
        if (values(formErrors).some((v) => notEqual(v, ""))) {
            isValid = false;
        }
        return { ...data, formErrors };
    });

    return { isValid, data: res };
};

export const removeEmpty = (obj) => {
    const newObj = {};
    Object.entries(obj).forEach(([k, v]) => {
        if (v === Object(v)) {
            newObj[k] = removeEmpty(v);
        } else if (v !== "" && v !== null) {
            newObj[k] = obj[k];
        }
    });
    return newObj;
};

export const getSearchedData = (arr, keyword, keys) => {
    if (keyword.length) {
        arr = arr.filter((obj) =>
            keys.some((key) => {
                const keys = key.split(".");
                let value = obj;
                keys.forEach((k) => (value = value[k]));
                return value.toLowerCase()?.includes(keyword?.toLowerCase());
            })
        );
    }
    return arr;
};

export const convertToFormdata = (obj) => {
    const formData = new FormData();
    Object.entries(obj).forEach(([k, v]) => {
        if (Array.isArray(v)) {
            v.forEach((item, i) => {
                Object.entries(item).forEach(([k1, v1], i1) => {
                    formData.append(`${k}[${i}][${k1}]`, v1);
                });
            });
        } else {
            formData.append(k, v);
        }
    });
    return formData;
};
export const PasswordRequirement = ({ isValid, text }) => {
    const iconClass = isValid ? "pi pi-check text-sm" : "pi pi-times text-sm";
    const textColor = isValid ? "text-success" : "p-error";

    return (
        <p className={`text-sm font-normal ${textColor}`}>
            <i className={iconClass}></i>&nbsp;{text}
        </p>
    );
};

export function checkPasswordRequirement(regex, message, value) {
    return (
        <p className={regex.test(value) ? "text-sm text-success font-normal" : "text-sm p-error font-normal"}>
            <i className={regex.test(value) ? "pi pi-check text-sm" : "pi pi-times text-sm"}></i> &nbsp;{message}
        </p>
    );
}

export const formatDate = (date, format, from) => {
    if (date) {
        if (format === "UTC") date = date.split("T")[0];
        date = new Date(date);
        let year = date.getFullYear();
        let month = date.getMonth() + 1;
        if (month < 10) month = `0${month}`;
        let day = date.getDate();
        if (day < 10) day = `0${day}`;

        if (format === "YY-MM-DD") {
            return `${year}-${month}-${day}`;
        } else if (format === "ISO") {
            return new Date(`${year}-${month}-${day} UTC`).toISOString();
        } else if (from === "ISO") return date;
        else {
            return `${day}-${month}-${year}`;
        }
    } else return "N/A";
};
