import React from "react";
import VendorContentData from "./VendorContentData";
import AddIcon from "../../assets/icons/add.png";
import { ProfileLayout } from "../../layout/ProfileLayout";

export default function SettingContent() {
  const { BudgetContentData } = VendorContentData();

  return (
    <>
      <div className="guests-container text-white w-full mb-7 ">
        <div className="align-items-center h-full flex justify-content-center">
          <div className="text-center ">
            <h1
              className=" font-bold p-0 m-0 mb-3"
              style={{ fontSize: "90px", fontFamily: "Dancing Script" }}
            >
              Coming Soon
            </h1>
            <p className="text-xl font-normal p-0 m-0 ">
              This is a Premium feature and it will be available soon.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
