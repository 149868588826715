import { showLoaderAction, hideLoaderAction } from "./loaderAction";
import api from "../../services/api";
import Constants from "../../services/constant";
import { showToast } from "./toastAction";
import { types } from "../types";
export const budgetAction = (params) => async (dispatch) => {
    dispatch(showLoaderAction());
    const res = await api("get", `${Constants.END_POINT.BUDGET_LIST}`, {}, params);
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.BUDGET_LIST,
                payload: res.data,
            });
        }
        dispatch(hideLoaderAction());
    } else {
        dispatch(hideLoaderAction());
        dispatch(showToast({ severity: "error", summary: res.message }));
    }
};

export const budgetActionByVendotType = (id, params) => async (dispatch) => {
    dispatch(showLoaderAction());
    const res = await api("get", Constants.END_POINT.BUDGET_LIST + "/" + id, {}, params);
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.BUDGET_LIST_BY_VENDOR_TYPE,
                payload: res.data,
            });
        }
        dispatch(hideLoaderAction());
    } else {
        dispatch(hideLoaderAction());
        dispatch(showToast({ severity: "error", summary: res.message }));
    }
};

export const addBudgetAction = (data, next) => async (dispatch) => {
    dispatch(showLoaderAction());
    const { _id } = data;
    const res = _id ? await api("put", Constants.END_POINT.ADD_BUDGET + `/${_id}`, data) : await api("post", Constants.END_POINT.ADD_BUDGET, data);
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.ADD_BUDGET,
                payload: res.data,
            });
        }
        next();
        dispatch(hideLoaderAction());
    } else {
        dispatch(hideLoaderAction());
        dispatch(showToast({ severity: "error", summary: res.message }));
    }
};

export const deleteBudgetAction = (id, next) => async (dispatch) => {
    dispatch(showLoaderAction());
    const res = await api("delete", Constants.END_POINT.ADD_BUDGET + `/${id}`, {});
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.ADD_BUDGET,
                payload: res.data,
            });
        }
        next();
        dispatch(hideLoaderAction());
    } else {
        dispatch(hideLoaderAction());
        dispatch(showToast({ severity: "error", summary: res.message }));
    }
};
