import "./styles.scss";
import Home from "./views/home";
import { Routes, Route, HashRouter, useNavigate, useLocation } from "react-router-dom";
import "primeicons/primeicons.css";
import { Explore } from "./views/explore/Explore";
import { Signup } from "./pages/signup";
import { Login } from "./pages/login";
import { SignupSuccessful } from "./pages/signup/SignupSuccessful";
import { VerificationCode } from "./pages/signup/VerificationCode";
import { ForgotPassword } from "./pages/forgot/ForgotPassword";
import { VerificationOtp } from "./pages/forgot/VerificationOtp";
import { NewPassword } from "./pages/forgot/NewPassword";
import { ResetSuccessful } from "./pages/forgot/ResetSuccessful";
import TermsandCondition from "./views/terms-and-condition/TermsandCondition";
import { PrivacyPolicy } from "./views/privacy-policy/PrivacyPolicy";
import { Contact } from "./views/contact/Contact";
import Loader from "./shared/Loader";
import ToastContainer from "./shared/ToastContainer.js";
import AdminReview from "./pages/login/AdminReview.jsx";
import { PrivateRoutes, VendorRoutes } from "./routes.js/allRoute.js";
import { HostRoutes, PublicRoutes, VendorRoute } from "./routes.js/ProtectedRoute.js";
import React, { useState } from "react";
import Business from "./views/business/index.jsx";
import { HowItsWork } from "./views/how-its-work/index.jsx";
import { useJsApiLoader } from "@react-google-maps/api";
import { ConfirmDialog } from "primereact/confirmdialog";
import About from "./views/About/index.jsx";
import Gallery from "./views/gallery/index.jsx";
import ScrollToTop from "./ScrollToTop.jsx";
import { SearchList } from "./views/explore/SearchList.jsx";
import WeddingTemplate from "./views/Host/WeddingWebsite/WeddingTemplate";
import ProfileSetup from "./pages/login/ProfileSetup.jsx";
function App() {
    const { isLoaded } = useJsApiLoader({
        id: "google-map-script",
        libraries: ["places"],
    });

    return (
        <div className="App">
            <Loader />
            <ConfirmDialog />
            <ScrollToTop />
            <Routes>
                <Route element={<PublicRoutes />}>
                    <Route path="/how-it-works" element={<HowItsWork />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/profile-setup" element={<ProfileSetup />} />
                    <Route path="/business/:id" element={<Business isLoaded={isLoaded} />} />
                    <Route path="/" element={<Home isLoaded={isLoaded} />} />
                    <Route path="/signup" element={<Signup isLoaded={isLoaded} />} />
                    <Route path="/signup-successful" element={<SignupSuccessful />} />
                    <Route path="/otp" element={<VerificationCode />} />
                    <Route path="/explore/:id" element={<Explore isLoaded={isLoaded} />} />
                    <Route path="/forgot-password" element={<ForgotPassword />} />
                    <Route path="/verification-otp" element={<VerificationOtp />} />
                    <Route path="/new-password" element={<NewPassword />} />
                    <Route path="/reset-successful" element={<ResetSuccessful />} />
                    <Route path="/terms-and-condition" element={<TermsandCondition />} />
                    <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                    <Route path="/contact" element={<Contact />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/gallery" element={<Gallery />} />
                    <Route path="/under-review" element={<AdminReview />} />
                    <Route path="/search" element={<SearchList isLoaded={isLoaded} />} />
                    <Route path="/invitation/:invitationId" element={<WeddingTemplate />} />
                </Route>
                <Route element={<HostRoutes />}>
                    {PrivateRoutes.map((route, key) => (
                        <Route key={key} path={route.path} element={route.element} />
                    ))}
                </Route>
                <Route element={<VendorRoute />}>
                    {VendorRoutes.map((route, key) => (
                        <Route key={key} path={route.path} element={<route.element isLoaded={isLoaded} />} />
                    ))}
                </Route>
            </Routes>
        </div>
    );
}

export default App;
