import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import moment from "moment";
export const exportToExcel = (jsonData) => {
    const { rows, fullMergeInfo } = getHeadersAndMerge(jsonData);

    let fileName = formatTime();
    const worksheet = XLSX.utils.aoa_to_sheet(rows);
    worksheet["!merges"] = fullMergeInfo;

    const workbook = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });

    const data = new Blob([excelBuffer], { type: "application/octet-stream" });

    saveAs(data, `${fileName}.xlsx`);
};

const getHeadersAndMerge = (arr) => {
    console.log("jsonData==>", arr);
    const rows = [
        ["Chair No.", "Guest Name"],
        ["", ""],
    ];
    const fullMergeInfo = [];

    if (arr.length) {
        arr.forEach((table, i) => {
            //Add Table Name and merge that cell
            rows.push([table.name]);
            fullMergeInfo.push({
                s: { r: rows.length - 1, c: 0 },
                e: { r: rows.length - 1, c: 1 },
            });
            //Add Guests row for every table
            table.guests.forEach((guest, i) => {
                rows.push([`Chair ${i + 1}`, guest.name]);
            });
            //Add empty row after every table
            rows.push(["", ""]);
        });
    }

    return { rows, fullMergeInfo };
};

const formatTime = () => {
    let _time = moment().format("DD-MM-YYYY HH:mm");
    return _time;
};
