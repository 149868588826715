import React, { useEffect, useRef, useState } from "react";
import { ContainerBox } from "../../shared/ContainerBox";
import { Stage, Layer, Rect, Circle, Text, Group, Image } from "react-konva";
import { Dialog } from "primereact/dialog";
import { CustomDropDown, CustomInput } from "../../components/input/AllInput";
import { allValidations } from "../../utils/formValidations";
import ButtonComponent, { ButtonOutlined } from "../../components/buttons/button";
import rectTableImg from "../../assets/icons/react-table.png";
import cicleTableImg from "../../assets/icons/circle-table.png";
import oneSideRectangeImg from "../../assets/icons/OneSideRectange.png";
import chairRow from "../../assets/icons/ChairRow.png";
import { guestAction } from "../../redux/actions/GuestAction";
import { useDispatch, useSelector } from "react-redux";
import { addSeatingChartAction, seatingChartAction, updateSeatingChartAction } from "../../redux/actions/SeatingAction";
import { ConfirmDialog } from "primereact/confirmdialog";
import AddGuests from "./AddGuests";
import { Accordion, AccordionTab } from "primereact/accordion";
import deleteIcon from "../../assets/icons/deleteIcon.png";
import editIcon from "../../assets/icons/editIcon.png";
import PersonIcon from "../../assets/icons/person.png";
import DeleteIcon1 from "../../assets/icons/deletesiting.png";

import { BrowserView, MobileView } from "react-device-detect";
import { OverlayPanel } from "primereact/overlaypanel";

import JSZip from "jszip";
import { showFormErrors } from "../../utils/commonFunctions";
import { exportToExcel } from "./Canvas/ExportExcel";

export default function SeatingPlanner() {
    const dispatch = useDispatch();
    //Get List Of Guests
    useEffect(() => {
        dispatch(guestAction({}));
    }, [dispatch]);

    const guestList = useSelector((state) => state.guest.guestList);
    const [isGuestDialogOpen, setGuestDialogOpen] = useState(false);
    const [allGuests, setGuests] = useState([]);

    //get Guests and members from redux and set in local
    useEffect(() => {
        let list = [];
        guestList?.forEach((guest) => {
            list.push({ value: guest._id, name: guest.name, receptionStatus: guest.acceptInviteReception, WeddingStatus: guest.acceptInviteWedding });
            guest.familyMemberList?.forEach((member) =>
                list.push({ value: member._id, name: member.name, receptionStatus: member.acceptInviteReception, WeddingStatus: member.acceptInviteWedding })
            );
        });
        setGuests(list);
    }, [guestList]);

    const [id, setId] = useState(null);
    const [data, setData] = useState([]);

    console.log("data==>", data);
    //On initial load get saved seating plan from backend
    useEffect(() => {
        dispatch(
            seatingChartAction((res) => {
                if (res._id) {
                    setId(res._id);
                    setData(res.weddingSeatingPlanner);
                }
            })
        );
    }, [dispatch]);

    const onGetExcel = () => {
        exportToExcel(data);
    };

    const generateTable = (data, visible) => {
        let rectChairs = {
            4: {
                1: { x: 40, y: 4, face: "down" },
                2: { x: 110, y: 4, face: "down" },
                3: { x: 40, y: 126, face: "up" },
                4: { x: 110, y: 126, face: "up" },
            },
            5: {
                1: { x: 38, y: 4, face: "down" },
                2: { x: 100, y: 4, face: "down" },
                3: { x: 165, y: 4, face: "down" },
                4: { x: 130, y: 126, face: "up" },
                5: { x: 60, y: 126, face: "up" },
            },
            6: {
                1: { x: 38, y: 4, face: "down" },
                2: { x: 100, y: 4, face: "down" },
                3: { x: 165, y: 4, face: "down" },
                4: { x: 38, y: 126, face: "up" },
                5: { x: 100, y: 126, face: "up" },
                6: { x: 165, y: 126, face: "up" },
            },
            7: {
                1: { x: 38, y: 4, face: "down" },
                2: { x: 100, y: 4, face: "down" },
                3: { x: 165, y: 4, face: "down" },
                4: { x: 230, y: 4, face: "down" },
                5: { x: 200, y: 126, face: "up" },
                6: { x: 130, y: 126, face: "up" },
                7: { x: 60, y: 126, face: "up" },
            },
            8: {
                1: { x: 38, y: 4, face: "down" },
                2: { x: 100, y: 4, face: "down" },
                3: { x: 165, y: 4, face: "down" },
                4: { x: 230, y: 4, face: "down" },
                5: { x: 230, y: 126, face: "up" },
                6: { x: 165, y: 126, face: "up" },
                7: { x: 100, y: 126, face: "up" },
                8: { x: 38, y: 126, face: "up" },
            },
            9: {
                1: { x: 38, y: 4, face: "down" },
                2: { x: 100, y: 4, face: "down" },
                3: { x: 165, y: 4, face: "down" },
                4: { x: 230, y: 4, face: "down" },
                5: { x: 295, y: 4, face: "down" },
                6: { x: 270, y: 126, face: "up" },
                7: { x: 205, y: 126, face: "up" },
                8: { x: 140, y: 126, face: "up" },
                9: { x: 78, y: 126, face: "up" },
            },
            10: {
                1: { x: 38, y: 4, face: "down" },
                2: { x: 100, y: 4, face: "down" },
                3: { x: 165, y: 4, face: "down" },
                4: { x: 230, y: 4, face: "down" },
                5: { x: 295, y: 4, face: "down" },

                6: { x: 295, y: 126, face: "up" },
                7: { x: 230, y: 126, face: "up" },
                8: { x: 165, y: 126, face: "up" },
                9: { x: 100, y: 126, face: "up" },
                10: { x: 38, y: 126, face: "up" },
            },
            11: {
                1: { x: 38, y: 4, face: "down" },
                2: { x: 100, y: 4, face: "down" },
                3: { x: 165, y: 4, face: "down" },
                4: { x: 230, y: 4, face: "down" },
                5: { x: 295, y: 4, face: "down" },
                6: { x: 360, y: 4, face: "down" },
                7: { x: 325, y: 126, face: "up" },
                8: { x: 260, y: 126, face: "up" },
                9: { x: 195, y: 126, face: "up" },
                10: { x: 130, y: 126, face: "up" },
                11: { x: 68, y: 126, face: "up" },
            },
            12: {
                1: { x: 38, y: 4, face: "down" },
                2: { x: 100, y: 4, face: "down" },
                3: { x: 165, y: 4, face: "down" },
                4: { x: 230, y: 4, face: "down" },
                5: { x: 295, y: 4, face: "down" },
                6: { x: 360, y: 4, face: "down" },

                7: { x: 360, y: 126, face: "up" },
                8: { x: 295, y: 126, face: "up" },
                9: { x: 230, y: 126, face: "up" },
                10: { x: 165, y: 126, face: "up" },
                11: { x: 100, y: 126, face: "up" },
                12: { x: 38, y: 126, face: "up" },
            },
        };
        let rowChairs = {
            4: {
                1: { x: 40, y: 4, face: "down" },
                2: { x: 80, y: 4, face: "down" },
                3: { x: 120, y: 4, face: "down" },
                4: { x: 160, y: 4, face: "down" },
            },
            5: {
                1: { x: 40, y: 4, face: "down" },
                2: { x: 80, y: 4, face: "down" },
                3: { x: 120, y: 4, face: "down" },
                4: { x: 160, y: 4, face: "down" },
                5: { x: 200, y: 4, face: "down" },
            },
            6: {
                1: { x: 40, y: 4, face: "down" },
                2: { x: 80, y: 4, face: "down" },
                3: { x: 120, y: 4, face: "down" },
                4: { x: 160, y: 4, face: "down" },
                5: { x: 200, y: 4, face: "down" },
                6: { x: 240, y: 4, face: "down" },
            },
            7: {
                1: { x: 40, y: 4, face: "down" },
                2: { x: 80, y: 4, face: "down" },
                3: { x: 120, y: 4, face: "down" },
                4: { x: 160, y: 4, face: "down" },
                5: { x: 200, y: 4, face: "down" },
                6: { x: 240, y: 4, face: "down" },
                7: { x: 280, y: 4, face: "down" },
            },
            8: {
                1: { x: 40, y: 4, face: "down" },
                2: { x: 80, y: 4, face: "down" },
                3: { x: 120, y: 4, face: "down" },
                4: { x: 160, y: 4, face: "down" },
                5: { x: 200, y: 4, face: "down" },
                6: { x: 240, y: 4, face: "down" },
                7: { x: 280, y: 4, face: "down" },
                8: { x: 320, y: 4, face: "down" },
            },
            9: {
                1: { x: 40, y: 4, face: "down" },
                2: { x: 80, y: 4, face: "down" },
                3: { x: 120, y: 4, face: "down" },
                4: { x: 160, y: 4, face: "down" },
                5: { x: 200, y: 4, face: "down" },
                6: { x: 240, y: 4, face: "down" },
                7: { x: 280, y: 4, face: "down" },
                8: { x: 320, y: 4, face: "down" },
                9: { x: 360, y: 4, face: "down" },
            },
            10: {
                1: { x: 40, y: 4, face: "down" },
                2: { x: 80, y: 4, face: "down" },
                3: { x: 120, y: 4, face: "down" },
                4: { x: 160, y: 4, face: "down" },
                5: { x: 200, y: 4, face: "down" },
                6: { x: 240, y: 4, face: "down" },
                7: { x: 280, y: 4, face: "down" },
                8: { x: 320, y: 4, face: "down" },
                9: { x: 360, y: 4, face: "down" },
                10: { x: 400, y: 4, face: "down" },
            },
        };
        let circleChairs = {
            4: {
                1: { x: 75, y: 0, r: 0, tx: 75, ty: 8 },
                2: { x: 180, y: 75, r: 90, tx: 150, ty: 84 },
                3: { x: 105, y: 180, r: 180, tx: 75, ty: 158 },
                4: { x: 0, y: 105, r: 270, tx: 0, ty: 84 },
            },
            5: {
                1: { x: 75, y: 0, r: 0, tx: 75, ty: 8 },
                2: { x: 172, y: 50, r: 75, tx: 148, ty: 62 },
                3: { x: 155, y: 155, r: 145, tx: 120, ty: 145 },
                4: { x: 35, y: 165, r: 230, tx: 22, ty: 137 },
                5: { x: 0, y: 70, r: 295, tx: 4, ty: 55 },
            },
            6: {
                1: { x: 75, y: 0, r: 0, tx: 75, ty: 8 },
                2: { x: 160, y: 30, r: 60, tx: 140, ty: 45 },
                3: { x: 180, y: 120, r: 120, tx: 145, ty: 122 },
                4: { x: 105, y: 180, r: 180, tx: 75, ty: 158 },
                5: { x: 20, y: 148, r: 240, tx: 10, ty: 122 },
                6: { x: 4, y: 55, r: 305, tx: 10, ty: 45 },
            },
            7: {
                1: { x: 75, y: 0, r: 0, tx: 75, ty: 8 },
                2: { x: 151, y: 21, r: 50, tx: 135, ty: 35 },
                3: { x: 183, y: 90, r: 100, tx: 150, ty: 95 },
                4: { x: 136, y: 168, r: 155, tx: 102, ty: 158 },
                5: { x: 52, y: 173, r: 210, tx: 28, ty: 150 },
                6: { x: 0, y: 115, r: 265, tx: 0, ty: 92 },
                7: { x: 12, y: 42, r: 310, tx: 17, ty: 32 },
            },
            8: {
                1: { x: 75, y: 0, r: 0, tx: 75, ty: 8 },
                2: { x: 144, y: 16, r: 45, tx: 130, ty: 28 },
                3: { x: 180, y: 75, r: 90, tx: 150, ty: 84 },
                4: { x: 169, y: 139, r: 135, tx: 135, ty: 135 },
                5: { x: 105, y: 180, r: 180, tx: 75, ty: 158 },
                6: { x: 36, y: 164, r: 225, tx: 20, ty: 135 },
                7: { x: 0, y: 105, r: 270, tx: 0, ty: 84 },
                8: { x: 16, y: 36, r: 315, tx: 20, ty: 28 },
            },
            10: {
                1: { x: 75, y: 0, r: 0, tx: 75, ty: 8 },
                2: { x: 135, y: 10, r: 36, tx: 125, ty: 24 },
                3: { x: 173, y: 50, r: 72, tx: 150, ty: 62 },
                4: { x: 180, y: 105, r: 108, tx: 150, ty: 112 },
                5: { x: 155, y: 155, r: 144, tx: 120, ty: 150 },
                6: { x: 105, y: 180, r: 180, tx: 75, ty: 160 },
                7: { x: 45, y: 170, r: 216, tx: 25, ty: 145 },
                8: { x: 8, y: 132, r: 252, tx: 0, ty: 108 },
                9: { x: 0, y: 75, r: 288, tx: 0, ty: 60 },
                10: { x: 25, y: 25, r: 324, tx: 28, ty: 23 },
            },
            11: {
                1: { x: 75, y: 0, r: 0, tx: 75, ty: 8 },
                2: { x: 125, y: 5, r: 32, tx: 115, ty: 17 },
                3: { x: 163, y: 35, r: 64, tx: 145, ty: 52 },
                4: { x: 182, y: 82, r: 96, tx: 152, ty: 95 },
                5: { x: 172, y: 133, r: 130, tx: 140, ty: 133 },
                6: { x: 135, y: 170, r: 160, tx: 100, ty: 158 },
                7: { x: 82, y: 181, r: 190, tx: 55, ty: 165 },
                8: { x: 33, y: 162, r: 226, tx: 15, ty: 140 },
                9: { x: 2, y: 122, r: 262, tx: 0, ty: 100 },
                10: { x: 1, y: 65, r: 298, tx: 0, ty: 55 },
                11: { x: 27, y: 24, r: 324, tx: 30, ty: 20 },
            },
            12: {
                1: { x: 124, y: 5, r: 30, tx: 115, ty: 18 },
                2: { x: 161, y: 32, r: 60, tx: 143, ty: 48 },
                3: { x: 180, y: 75, r: 90, tx: 150, ty: 85 },
                4: { x: 176, y: 123, r: 120, tx: 145, ty: 125 },
                5: { x: 149, y: 160, r: 150, tx: 115, ty: 155 },
                6: { x: 105, y: 180, r: 180, tx: 75, ty: 160 },
                7: { x: 58, y: 176, r: 210, tx: 38, ty: 153 },
                8: { x: 20, y: 148, r: 240, tx: 10, ty: 124 },
                9: { x: 0, y: 105, r: 270, tx: 0, ty: 85 },
                10: { x: 4, y: 59, r: 300, tx: 5, ty: 45 },
                11: { x: 33, y: 19, r: 330, tx: 35, ty: 16 },
                12: { x: 75, y: 0, r: 360, tx: 75, ty: 5 },
            },
        };

        if (data.tableType === "rect") {
            let table = {
                type: data.tableType,
                name: data.tableName,
                width: data.guestCount > 10 ? 368 : data.guestCount > 8 ? 300 : data.guestCount > 6 ? 240 : data.guestCount > 4 ? 170 : 120,
                height: 100,
                isDragging: false,
                x: data.x,
                y: data.y,
                chairs: [],
                guests: data.guests,
            };

            for (let i = 0; i < data.guestCount; i++) {
                let chair = {
                    x: rectChairs[data.guestCount][i + 1].x,
                    y: rectChairs[data.guestCount][i + 1].y,
                    face: rectChairs[data.guestCount][i + 1].face,
                };

                table.chairs.push(chair);
            }

            return table;
        }
        if (data.tableType === "rectOneSide") {
            let table = {
                type: data.tableType,
                name: data.tableName,
                width: data.guestCount * 40 + 10,
                height: 100,
                isDragging: false,
                x: data.x,
                y: data.y,
                chairs: [],
                guests: data.guests,
            };

            for (let i = 0; i < data.guestCount; i++) {
                let chair = {
                    x: rowChairs[data.guestCount][i + 1].x,
                    y: rowChairs[data.guestCount][i + 1].y,
                    face: rowChairs[data.guestCount][i + 1].face,
                };

                table.chairs.push(chair);
            }

            return table;
        }
        if (data.tableType === "reactChair") {
            let table = {
                type: data.tableType,
                name: data.tableName,
                width: rowChairs[data.guestCount][data.guestCount].x + 10,
                isDragging: false,
                x: data.x,
                y: data.y,
                chairs: [],
                guests: data.guests,
            };

            for (let i = 0; i < data.guestCount; i++) {
                let chair = {
                    x: rowChairs[data.guestCount][i + 1].x,
                    y: rowChairs[data.guestCount][i + 1].y,
                    face: rowChairs[data.guestCount][i + 1].face,
                };

                table.chairs.push(chair);
            }

            return table;
        } else {
            let table = {
                type: data.tableType,
                name: data.tableName,
                width: 150,
                height: 100,
                isDragging: false,
                radius: 60,
                x: data.x,
                y: data.y,
                xAxis: 90,
                yAxis: 90,
                chairs: [],
                guests: data.guests,
            };

            for (let i = 0; i < data.guestCount; i++) {
                let chair = {
                    x: circleChairs[data.guestCount][i + 1].x,
                    y: circleChairs[data.guestCount][i + 1].y,
                    r: circleChairs[data.guestCount][i + 1].r,
                    tx: circleChairs[data.guestCount][i + 1].tx,
                    ty: circleChairs[data.guestCount][i + 1].ty,
                };

                table.chairs.push(chair);
            }

            return table;
        }
    };

    const handleDeleteTable = (index) => {
        setDeleteSitingTable(index + 1);
    };
    const handleDeleteTableAction = () => {
        setData((prev) => {
            const updatedData = prev.filter((_, i) => i !== deleteSitingTable - 1);
            return updatedData;
        });
        setDeleteSitingTable(null);
    };

    const onAddOrUpdateChart = () => {
        if (id) {
            dispatch(updateSeatingChartAction(id, { weddingSeatingPlanner: data }));
        } else {
            dispatch(addSeatingChartAction({ weddingSeatingPlanner: data }));
        }
    };

    const apiRef = useRef(null);
    useEffect(() => {
        if (apiRef.current) {
            clearInterval(apiRef.current);
        }
        apiRef.current = setTimeout(() => {
            onAddOrUpdateChart();
        }, 2000);
    }, [data]);

    const [deleteSitingTable, setDeleteSitingTable] = useState(null);

    const URLImage = ({ src, onClick, x, y }) => {
        const [image, setImage] = useState(null);
        const imageNode = useRef(null);

        useEffect(() => {
            const img = new window.Image();
            img.src = src;
            img.addEventListener("load", () => {
                setImage(img);
            });

            return () => {
                img.removeEventListener("load", () => {
                    setImage(img);
                });
            };
        }, [src]);

        return <Image x={x} y={y} image={image} ref={imageNode} onClick={onClick} style={{ width: "20px", zIndex: "999" }} />;
    };

    const canvasWidth = 3000;
    const canvasHeight = 2000;
    const viewWidth = 1500;
    const viewHeight = 1000;
    const [scrollPos, setScrollPos] = useState({ x: 0, y: 0 });
    const handleScroll = (e) => {
        const { scrollLeft, scrollTop } = e.target;
        setScrollPos({ x: scrollLeft, y: scrollTop });
    };

    const downloadZip = async () => {
        onGetExcel();
        const zip = new JSZip();
        const canvas = document.createElement("canvas");
        canvas.style.backgroundColor = "#ff8800";
        canvas.width = canvasWidth;
        canvas.height = canvasHeight;

        const ctx = canvas.getContext("2d");
        ctx.fillStyle = "white";
        ctx.fillRect(0, 0, canvas.width, canvas.height);

        const can = document.getElementsByTagName("canvas");

        console.log(can);

        // Use a regular for-loop
        for (let i = 0; i < can.length; i++) {
            ctx.drawImage(can[i], 0, 0);
        }

        let imgDataUrl = canvas.toDataURL("image/png");
        imgDataUrl = imgDataUrl.split(",");
        imgDataUrl = imgDataUrl[1];
        zip.file(`Seating-Plan.png`, imgDataUrl, { base64: true });
        const content = await zip.generateAsync({ type: "blob" });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(content);
        link.download = `SeatingChart.zip`;
        link.click();
    };

    //For Hover and Edit Functionality
    const timerRef = useRef(null);
    const [hId, setHid] = useState(null);
    const onShowControls = (name) => {
        if (timerRef.current) {
            clearTimeout(timerRef.current);
        }
        setHid(name);
    };
    const onHideControls = () => {
        timerRef.current = setTimeout(() => {
            setHid(null);
        }, 200);
    };

    const tableFormGuestOptions = {
        rect: [{ value: 4 }, { value: 5 }, { value: 6 }, { value: 7 }, { value: 8 }, { value: 9 }, { value: 10 }, { value: 11 }, { value: 12 }],
        rectOneSide: [{ value: 4 }, { value: 5 }, { value: 6 }],
        reactChair: [{ value: 4 }, { value: 5 }, { value: 6 }, { value: 7 }, { value: 8 }, { value: 9 }, { value: 10 }],
        circle: [{ value: 4 }, { value: 5 }, { value: 6 }, { value: 7 }, { value: 8 }, { value: 10 }, { value: 11 }, { value: 12 }],
    };
    const [tableForm, setTableForm] = useState(null);

    const handleTableFormChange = ({ name, value }) => {
        const formErrors = allValidations(name, value, tableForm);
        if (name === "guestCount") {
            let _existing = [...tableForm.guests];
            let _newGuests = [];
            for (let i = 0; i < value; i++) {
                _newGuests.push(_existing[i] || { name: "", value: "" });
            }
            setTableForm((prev) => ({ ...prev, [name]: value, guests: _newGuests, formErrors }));
        } else {
            setTableForm((prev) => ({ ...prev, [name]: value, formErrors }));
        }
    };
    const handleTableFormGuestChange = (guestId, i) => {
        let _guests = [...tableForm.guests];
        // remove guest if already added on any other index
        let _index = _guests.findIndex((item) => item.value === guestId);
        if (_index !== -1) {
            _guests[_index] = { name: "", value: "" };
        }
        // now find the original guest obj from local state
        let _guest = allGuests.find((item) => item.value === guestId);
        // set it on given index
        _guests[i] = _guest;
        setTableForm((prev) => ({ ...prev, guests: _guests }));
    };
    const onAddOrDragTable = (tableType, e) => {
        const tableFormInitialState = {
            tableName: "",
            guestCount: 0,
            x: 40,
            y: 40,
        };
        let tables = data.filter((item) => item.type !== "reactChair");
        let rows = data.filter((item) => item.type === "reactChair");
        switch (tableType) {
            case "circle":
                tableFormInitialState.guestCount = 10;
                tableFormInitialState.tableName = `Table ${tables.length + 1}`;
                break;
            case "rect":
                tableFormInitialState.guestCount = 4;
                tableFormInitialState.tableName = `Table ${tables.length + 1}`;
                break;
            case "rectOneSide":
                tableFormInitialState.guestCount = 4;
                tableFormInitialState.tableName = `Table ${tables.length + 1}`;
                break;
            case "reactChair":
                tableFormInitialState.guestCount = 4;
                tableFormInitialState.tableName = `Row ${rows.length + 1}`;
                break;

            default:
                break;
        }
        let guests = [];
        for (let i = 1; i <= tableFormInitialState.guestCount; i++) {
            guests.push({ value: "", name: "" });
        }
        setTableForm({ tableType, ...tableFormInitialState, guests });
    };
    const onClickEditTable = (i) => {
        let _tableObj = data[i];
        let _tableFormObj = {
            tableIndex: i + 1,
            tableType: _tableObj.type,
            tableName: _tableObj.name,
            guestCount: _tableObj.guests.length,
            x: _tableObj.x,
            y: _tableObj.y,
            guests: _tableObj.guests,
        };
        setTableForm(_tableFormObj);
    };

    //On Save
    const OnAddOrEditTableInPlanner = async () => {
        if (showFormErrors(tableForm, setTableForm)) {
            let konvaTable = generateTable(tableForm);
            let _data = [...data];

            // if its a new table
            if (!tableForm?.tableIndex) {
                let checkName = _data.find((item) => item.name === konvaTable.name);
                if (checkName) {
                    setTableForm((prev) => ({ ...prev, formErrors: { tableName: `Table name ${checkName.name} alreadt exist!` } }));
                    return;
                }

                let _lastObj = data[data.length - 1];
                let _x = _lastObj?.x || 40;
                let _tblHeight = konvaTable.height ? konvaTable.height : 140;
                let _y = _lastObj?.y ? _lastObj?.y + _tblHeight + 80 : 40;
                if (_x > canvasWidth - 200) {
                    _x = canvasWidth - 200;
                }
                if (_y > canvasHeight - 200) {
                    _y = canvasHeight - 200;
                }
                konvaTable.x = _x;
                konvaTable.y = _y;

                _data.push(konvaTable);
            }
            // if editing the existing table then no need to change the x and y
            else {
                const editIndex = tableForm?.tableIndex - 1;
                let checkNameIndex = _data.findIndex((item) => item.name === konvaTable.name);
                if (checkNameIndex !== -1 && checkNameIndex !== editIndex) {
                    setTableForm((prev) => ({ ...prev, formErrors: { tableName: `Table name ${konvaTable.name} alreadt exist!` } }));
                    return;
                }
                _data[editIndex] = konvaTable;
            }
            setData(_data);
            setTableForm(null);
        }
    };

    const op = useRef(null);
    //Dialog to add table on canvas
    const TableDialog = () => {
        return (
            <Dialog
                blockScroll={true}
                draggable={false}
                resizable={false}
                className="lg:w-5 md:w-8 w-11 seating-dialog"
                header={<div className="text-2xl font-bold Merriweather p-0">{tableForm?.tableIndex ? "Edit Table" : "Add Table"}</div>}
                visible={tableForm}
                style={{ width: "50vw" }}
                onHide={() => setTableForm(null)}
            >
                <div className="grid">
                    <div className="col-12 flex justify-content-end flex-wrap text-base font-normal">
                        <div>
                            RSVP Status <i className="pi pi-info-circle cursor-pointer" onClick={(e) => op.current.toggle(e)}></i>
                        </div>
                    </div>
                    <CustomInput
                        label="Table Name"
                        name="tableName"
                        data={tableForm}
                        onChange={handleTableFormChange}
                        placeholder="Enter Table Name"
                        required
                    />
                    <CustomDropDown
                        label={`No. of Chairs (${tableFormGuestOptions[tableForm?.tableType]?.[0]?.value}~${
                            tableFormGuestOptions[tableForm?.tableType]?.[tableFormGuestOptions?.[tableForm?.tableType].length - 1]?.value
                        })`}
                        name="guestCount"
                        data={tableForm}
                        onChange={handleTableFormChange}
                        options={tableFormGuestOptions[tableForm?.tableType]}
                        optionLabel="value"
                        required
                    />
                    {tableForm?.guests?.map((item, i) => (
                        <CustomDropDown
                            value={item.value}
                            options={allGuests}
                            label={`Chair ${i + 1}`}
                            optionLabel="name"
                            placeholder="Select Guest"
                            itemTemplate={optionTemplate}
                            valueTemplate={optionTemplate}
                            onChange={(e) => handleTableFormGuestChange(e.value, i)}
                            filter
                        />
                    ))}

                    <div className="col-12 flex justify-content-center">
                        <ButtonComponent label={tableForm?.tableIndex ? "Update" : "Add"} onClick={OnAddOrEditTableInPlanner} />
                    </div>
                </div>
            </Dialog>
        );
    };

    const optionTemplate = (option, props) => {
        return option ? (
            <div className="flex align-items-center justify-content-between">
                <div>{option.name}</div>
                <div className="flex" style={{ fontSize: "12px" }}>
                    {getInviteStatus(option.WeddingStatus, "Wedding")}
                    {getInviteStatus(option.receptionStatus, "Reception")}
                </div>
            </div>
        ) : (
            <span>{props.placeholder}</span>
        );
    };

    const getInviteStatus = (key, string) => {
        switch (key) {
            case "CONFIRMED":
                return <div className="text-green-500 ml-1">{string}</div>;
            case "DECLINED":
                return <div className="text-red-500 ml-1">{string}</div>;
            case "PENDING":
                return <div className="text-yellow-500 ml-1">{string}</div>;
            default:
                return "";
        }
    };

    const chairColor = "#D9D9D9";
    const chairBackColor = "#333333";
    const chairActiveBackColor = "#008dbb";

    return (
        <div className="profile-container mt-1 md:mt-5 mb-7">
            <OverlayPanel ref={op}>
                <div className="flex">Confirmed = {getInviteStatus("CONFIRMED", "Green")} </div>
                <div className="flex">Pending = {getInviteStatus("PENDING", "Yellow ")} </div>
                <div className="flex">Declined = {getInviteStatus("DECLINED", "Red")} </div>
            </OverlayPanel>

            <ConfirmDialog />
            <ContainerBox>
                <h1 className=" hidden md:block merriweather text-2xl text-dark-gray font-bold ">Seating Chart</h1>
                <div className="flex flex-wrap justify-content-start">
                    <div className="hidden md:block col-12 md:col-8 seating-grid relative m-0" style={{ overflow: "auto" }}>
                        <BrowserView>
                            <div
                                id="container"
                                style={{ width: viewWidth, height: viewHeight }}
                                onScroll={handleScroll}
                                onDrop={(e) => {
                                    e.preventDefault();
                                    // setTableData((prev) => ({
                                    //     ...prev,
                                    //     tableType: tableData.tableType,
                                    //     x: e.clientX / 2,
                                    //     y: e.clientY / 2,
                                    // }));
                                }}
                                onDragOver={(e) => e.preventDefault()}
                            >
                                <div>
                                    <Stage
                                        width={canvasWidth}
                                        height={canvasHeight}
                                        offsetX={scrollPos.x}
                                        offsetY={scrollPos.y}
                                        id="seatingGrid"
                                        container="container"
                                    >
                                        {data.map((table, index) => {
                                            return (
                                                <Layer>
                                                    <Group
                                                        x={table.x}
                                                        y={table.y}
                                                        draggable
                                                        onDragStart={() => {
                                                            setData(
                                                                data.map((item, i) =>
                                                                    i === index
                                                                        ? {
                                                                              ...item,
                                                                              isDragging: true,
                                                                          }
                                                                        : item
                                                                )
                                                            );
                                                        }}
                                                        dragBoundFunc={(pos) => {
                                                            let newX = pos.x;
                                                            let newY = pos.y;

                                                            // Calculate the boundaries for the x and y coordinates
                                                            const maxX = canvasWidth - table.width;
                                                            const maxY = canvasHeight - table.height;

                                                            // Check if newX is within the allowed boundaries
                                                            if (newX < 0) newX = 0;
                                                            if (newX > maxX) newX = maxX;

                                                            // Check if newY is within the allowed boundaries
                                                            if (newY < 0) newY = 0;
                                                            if (newY > maxY) newY = maxY;

                                                            return {
                                                                x: newX,
                                                                y: newY,
                                                            };
                                                        }}
                                                        onDragEnd={(e) => {
                                                            let newX = e.target.x();
                                                            let newY = e.target.y();
                                                            const updatedData = data.map((item, i) =>
                                                                i === index
                                                                    ? {
                                                                          ...item,
                                                                          isDragging: false,
                                                                          x: newX,
                                                                          y: newY,
                                                                      }
                                                                    : item
                                                            );
                                                            setData(updatedData);
                                                        }}
                                                    >
                                                        {table.type === "rect" ? (
                                                            <Group onMouseEnter={() => onShowControls(table.name + index)} onMouseLeave={onHideControls}>
                                                                <Rect width={table.width} height={table.height} fill="#ccc" stroke={"#000"} y={30} x={30} />
                                                                {table.chairs.map((chair, i) => (
                                                                    <>
                                                                        <Group
                                                                            rotation={
                                                                                chair.face === "right"
                                                                                    ? 90
                                                                                    : chair.face === "left"
                                                                                    ? 270
                                                                                    : chair.face === "up"
                                                                                    ? 180
                                                                                    : 0
                                                                            }
                                                                            x={
                                                                                chair.face === "right"
                                                                                    ? chair.x + 30
                                                                                    : chair.face === "left"
                                                                                    ? chair.x
                                                                                    : chair.face === "up"
                                                                                    ? chair.x + 30
                                                                                    : chair.x
                                                                            }
                                                                            y={
                                                                                chair.face === "right"
                                                                                    ? chair.y
                                                                                    : chair.face === "left"
                                                                                    ? chair.y + 30
                                                                                    : chair.face === "up"
                                                                                    ? chair.y + 30
                                                                                    : chair.y
                                                                            }
                                                                        >
                                                                            <Rect width={30} height={25} fill={chairColor} />
                                                                            <Text
                                                                                width={30}
                                                                                text={i + 1}
                                                                                align="center"
                                                                                fill="black"
                                                                                rotation={
                                                                                    chair.face === "right"
                                                                                        ? -90
                                                                                        : chair.face === "left"
                                                                                        ? 90
                                                                                        : chair.face === "up"
                                                                                        ? 180
                                                                                        : 0
                                                                                }
                                                                                x={
                                                                                    chair.face === "right"
                                                                                        ? 0 + 10
                                                                                        : chair.face === "left"
                                                                                        ? 0 + 20
                                                                                        : chair.face === "up"
                                                                                        ? 0 + 30
                                                                                        : 0
                                                                                }
                                                                                y={
                                                                                    chair.face === "right"
                                                                                        ? 0 + 30
                                                                                        : chair.face === "left"
                                                                                        ? 0
                                                                                        : chair.face === "up"
                                                                                        ? 0 + 20
                                                                                        : 0 + 10
                                                                                }
                                                                            />
                                                                            <Rect
                                                                                width={30}
                                                                                height={5}
                                                                                fill={table?.guests[i]?.value ? chairActiveBackColor : chairBackColor}
                                                                                stroke={table?.guests[i]?.value ? chairActiveBackColor : chairBackColor}
                                                                                lineJoin="bevel"
                                                                            />
                                                                        </Group>
                                                                    </>
                                                                ))}
                                                                <Text
                                                                    text={table.name}
                                                                    align="center"
                                                                    width={table.width + 60}
                                                                    y={70}
                                                                    wrap="word"
                                                                    fill="#008dbb"
                                                                />
                                                                {hId === table.name + index && (
                                                                    <>
                                                                        <URLImage
                                                                            src={editIcon}
                                                                            onClick={() => onClickEditTable(index)}
                                                                            x={table.width + 20}
                                                                            y={27}
                                                                        />
                                                                        <URLImage
                                                                            src={deleteIcon}
                                                                            onClick={() => handleDeleteTable(index)}
                                                                            x={table.width + 20}
                                                                            y={80}
                                                                        />
                                                                    </>
                                                                )}
                                                            </Group>
                                                        ) : table.type === "rectOneSide" ? (
                                                            <Group onMouseEnter={() => onShowControls(table.name + index)} onMouseLeave={onHideControls}>
                                                                <Rect width={table.width} height={table.height} fill="#ccc" stroke={"#000"} y={30} x={30} />
                                                                {console.log("table===>", table)}
                                                                {table.chairs.map((chair, i) => (
                                                                    <>
                                                                        <Group
                                                                            rotation={
                                                                                chair.face === "right"
                                                                                    ? 90
                                                                                    : chair.face === "left"
                                                                                    ? 270
                                                                                    : chair.face === "up"
                                                                                    ? 180
                                                                                    : 0
                                                                            }
                                                                            x={
                                                                                chair.face === "right"
                                                                                    ? chair.x + 30
                                                                                    : chair.face === "left"
                                                                                    ? chair.x
                                                                                    : chair.face === "up"
                                                                                    ? chair.x + 30
                                                                                    : chair.x
                                                                            }
                                                                            y={
                                                                                chair.face === "right"
                                                                                    ? chair.y
                                                                                    : chair.face === "left"
                                                                                    ? chair.y + 30
                                                                                    : chair.face === "up"
                                                                                    ? chair.y + 30
                                                                                    : chair.y
                                                                            }
                                                                        >
                                                                            <Rect width={30} height={25} fill={chairColor} />
                                                                            <Text
                                                                                width={30}
                                                                                text={i + 1}
                                                                                align="center"
                                                                                fill="black"
                                                                                rotation={
                                                                                    chair.face === "right"
                                                                                        ? -90
                                                                                        : chair.face === "left"
                                                                                        ? 90
                                                                                        : chair.face === "up"
                                                                                        ? 180
                                                                                        : 0
                                                                                }
                                                                                x={
                                                                                    chair.face === "right"
                                                                                        ? 0 + 10
                                                                                        : chair.face === "left"
                                                                                        ? 0 + 20
                                                                                        : chair.face === "up"
                                                                                        ? 0 + 30
                                                                                        : 0
                                                                                }
                                                                                y={
                                                                                    chair.face === "right"
                                                                                        ? 0 + 30
                                                                                        : chair.face === "left"
                                                                                        ? 0
                                                                                        : chair.face === "up"
                                                                                        ? 0 + 20
                                                                                        : 0 + 10
                                                                                }
                                                                            />
                                                                            <Rect
                                                                                width={30}
                                                                                height={5}
                                                                                fill={table?.guests[i]?.value ? chairActiveBackColor : chairBackColor}
                                                                                stroke={table?.guests[i]?.value ? chairActiveBackColor : chairBackColor}
                                                                                lineJoin="bevel"
                                                                            />
                                                                        </Group>
                                                                    </>
                                                                ))}
                                                                <Text
                                                                    text={table.name}
                                                                    align="center"
                                                                    width={table.width + 60}
                                                                    y={70}
                                                                    wrap="word"
                                                                    fill="#008dbb"
                                                                />
                                                                {hId === table.name + index && (
                                                                    <>
                                                                        <URLImage
                                                                            src={editIcon}
                                                                            onClick={() => onClickEditTable(index)}
                                                                            x={table.width + 20}
                                                                            y={27}
                                                                        />
                                                                        <URLImage
                                                                            src={deleteIcon}
                                                                            onClick={() => handleDeleteTable(index)}
                                                                            x={table.width + 20}
                                                                            y={80}
                                                                        />
                                                                    </>
                                                                )}
                                                            </Group>
                                                        ) : table.type === "reactChair" ? (
                                                            <Group onMouseEnter={() => onShowControls(index)} onMouseLeave={onHideControls}>
                                                                {table.chairs.map((chair, i) => (
                                                                    <>
                                                                        <Group
                                                                            rotation={
                                                                                chair.face === "right"
                                                                                    ? 90
                                                                                    : chair.face === "left"
                                                                                    ? 270
                                                                                    : chair.face === "up"
                                                                                    ? 180
                                                                                    : 0
                                                                            }
                                                                            x={
                                                                                chair.face === "right"
                                                                                    ? chair.x + 30
                                                                                    : chair.face === "left"
                                                                                    ? chair.x
                                                                                    : chair.face === "up"
                                                                                    ? chair.x + 30
                                                                                    : chair.x
                                                                            }
                                                                            y={
                                                                                chair.face === "right"
                                                                                    ? chair.y
                                                                                    : chair.face === "left"
                                                                                    ? chair.y + 30
                                                                                    : chair.face === "up"
                                                                                    ? chair.y + 30
                                                                                    : chair.y
                                                                            }
                                                                        >
                                                                            <Rect width={30} height={25} fill={chairColor} />
                                                                            <Text
                                                                                width={30}
                                                                                text={i + 1}
                                                                                align="center"
                                                                                fill="black"
                                                                                rotation={
                                                                                    chair.face === "right"
                                                                                        ? -90
                                                                                        : chair.face === "left"
                                                                                        ? 90
                                                                                        : chair.face === "up"
                                                                                        ? 180
                                                                                        : 0
                                                                                }
                                                                                x={
                                                                                    chair.face === "right"
                                                                                        ? 0 + 10
                                                                                        : chair.face === "left"
                                                                                        ? 0 + 20
                                                                                        : chair.face === "up"
                                                                                        ? 0 + 30
                                                                                        : 0
                                                                                }
                                                                                y={
                                                                                    chair.face === "right"
                                                                                        ? 0 + 30
                                                                                        : chair.face === "left"
                                                                                        ? 0
                                                                                        : chair.face === "up"
                                                                                        ? 0 + 20
                                                                                        : 0 + 10
                                                                                }
                                                                            />
                                                                            <Rect
                                                                                width={30}
                                                                                height={5}
                                                                                fill={table?.guests[i]?.value ? chairActiveBackColor : chairBackColor}
                                                                                stroke={table?.guests[i]?.value ? chairActiveBackColor : chairBackColor}
                                                                                lineJoin="bevel"
                                                                            />
                                                                        </Group>
                                                                    </>
                                                                ))}
                                                                <Text
                                                                    text={table.name}
                                                                    align="center"
                                                                    width={table.chairs[table.chairs.length - 1].x + 60}
                                                                    y={30}
                                                                    wrap="word"
                                                                    fill="#008dbb"
                                                                />
                                                                {hId === index && (
                                                                    <>
                                                                        <URLImage
                                                                            src={editIcon}
                                                                            onClick={() => onClickEditTable(index)}
                                                                            x={table.width + 20}
                                                                            y={0}
                                                                        />
                                                                        <URLImage
                                                                            src={deleteIcon}
                                                                            onClick={() => handleDeleteTable(index)}
                                                                            x={table.width + 20}
                                                                            y={55}
                                                                        />
                                                                    </>
                                                                )}
                                                            </Group>
                                                        ) : (
                                                            <Group onMouseEnter={() => onShowControls(table.name + index)} onMouseLeave={onHideControls}>
                                                                <Circle stroke="black" x={table.xAxis} y={table.yAxis} radius={63} fill="#ccc" />
                                                                {table.chairs.map((chair, i) => (
                                                                    <>
                                                                        <Group rotation={chair.r} x={chair.x} y={chair.y}>
                                                                            <Rect width={30} height={25} fill={chairColor} />
                                                                            <Rect
                                                                                width={30}
                                                                                height={5}
                                                                                fill={table?.guests[i]?.value ? chairActiveBackColor : chairBackColor}
                                                                                stroke={table?.guests[i]?.value ? chairActiveBackColor : chairBackColor}
                                                                                lineJoin="bevel"
                                                                            />
                                                                        </Group>
                                                                        <Text width={30} text={i + 1} align="center" fill="black" x={chair.tx} y={chair.ty} />
                                                                    </>
                                                                ))}
                                                                <Group>
                                                                    <Rect width={30} height={25} />
                                                                </Group>
                                                                <Text text={table.name} align="center" width={table.width + 30} y={85} fill="#008dbb" />
                                                                {hId === table.name + index && (
                                                                    <>
                                                                        <URLImage
                                                                            src={editIcon}
                                                                            onClick={() => onClickEditTable(index)}
                                                                            x={table.width + 30}
                                                                            y={40}
                                                                        />
                                                                        <URLImage
                                                                            src={deleteIcon}
                                                                            onClick={() => handleDeleteTable(index)}
                                                                            x={table.width + 30}
                                                                            y={90}
                                                                        />
                                                                    </>
                                                                )}
                                                            </Group>
                                                        )}
                                                    </Group>
                                                </Layer>
                                            );
                                        })}
                                    </Stage>
                                </div>
                            </div>{" "}
                        </BrowserView>
                    </div>

                    <div className="col-12 md:col-4 p-0 shadow-1 m-0">
                        <div style={{ background: "rgb(245, 245, 245)" }} className="p-3">
                            <h1 className="merriweather text-2xl mt-0 text-dark-gray font-bold ">Seating Plan</h1>
                        </div>
                        <div className="p-3">
                            <h3 className="merriweather text-sm mt-0 text-dark-gray font-bold">Add Table</h3>
                            <div
                                style={{ background: "rgb(245, 245, 245)" }}
                                className="p-5 flex-wrap flex align-items-center gap-6 border-round-xl justify-content-center"
                            >
                                <div className="w-3">
                                    <img
                                        src={cicleTableImg}
                                        alt="Circle Table"
                                        style={{ width: "100%" }}
                                        onClick={() => onAddOrDragTable("circle")}
                                        className="cursor-pointer"
                                        draggable="true"
                                        onDragEnd={(e) => onAddOrDragTable("circle", e)}
                                    />
                                </div>
                                <div className="w-3">
                                    <img
                                        src={oneSideRectangeImg}
                                        alt="rectOneSide"
                                        style={{ width: "100%" }}
                                        onClick={() => onAddOrDragTable("rectOneSide")}
                                        className="cursor-move"
                                        draggable="true"
                                        onDragEnd={() => onAddOrDragTable("rectOneSide")}
                                    />
                                </div>
                                <div className="w-3">
                                    <img
                                        src={chairRow}
                                        alt="rect"
                                        style={{ width: "100%" }}
                                        onClick={() => onAddOrDragTable("reactChair")}
                                        className="cursor-move"
                                        draggable="true"
                                        onDragEnd={() => onAddOrDragTable("reactChair")}
                                    />
                                </div>
                                <div className="w-3">
                                    <img
                                        src={rectTableImg}
                                        alt="rect"
                                        style={{ width: "100%" }}
                                        onClick={() => onAddOrDragTable("rect")}
                                        className="cursor-move"
                                        draggable="true"
                                        onDragEnd={() => onAddOrDragTable("rect")}
                                    />
                                </div>
                            </div>
                            <h3 className="merriweather text-sm  text-dark-gray font-bold">Guest List</h3>
                            <div className="h-20rem overflow-auto">
                                <Accordion activeIndex={0} className="sitingplan-accordion">
                                    {data?.map((table, index) => (
                                        <AccordionTab header={table.name}>
                                            <div className="grid">
                                                <div className="md:hidden col-12 flex justify-content-end">
                                                    <i className="pi pi-pencil mr-3" onClick={() => onClickEditTable(index)}></i>
                                                </div>
                                                {table?.guests?.map((item, index) => (
                                                    <>
                                                        <div className="col-6">
                                                            <img src={PersonIcon} alt="" className="mr-2" />
                                                            {item.name !== "" ? item.name : "Not Assigned"}
                                                        </div>
                                                    </>
                                                ))}
                                            </div>
                                        </AccordionTab>
                                    ))}
                                </Accordion>
                            </div>
                            <div className="mt-4">
                                <ButtonComponent label="Add Guest" className="w-full mb-3" onClick={() => setGuestDialogOpen(true)} />
                                <BrowserView>
                                    <ButtonOutlined label={"Download"} className="w-full" onClick={downloadZip} />
                                </BrowserView>
                                <MobileView>
                                    <ButtonOutlined label={"Download"} className="w-full" onClick={onGetExcel} />
                                </MobileView>
                            </div>
                        </div>
                    </div>
                </div>

                {deleteSitingTable && (
                    <Dialog
                        header="Header"
                        visible={deleteSitingTable}
                        style={{ width: "500px" }}
                        onHide={() => {
                            if (!deleteSitingTable) return;
                            setDeleteSitingTable(null);
                        }}
                        showHeader={false}
                    >
                        <img src={DeleteIcon1} alt="" className="m-auto block mt-4" width={70} />
                        <p className="m-0 text-lg text-center font-normal text-dark-gray ">Are you sure you want to delete?</p>
                        <div className="flex gap-3 mt-4">
                            <ButtonComponent
                                label="Cancel"
                                className="w-6 btn-cancel"
                                onClick={() => {
                                    setDeleteSitingTable(null);
                                }}
                            />
                            <ButtonComponent label="Ok" className="bg-main w-6" onClick={handleDeleteTableAction} />
                        </div>
                    </Dialog>
                )}
                {TableDialog()}
            </ContainerBox>
            {/* All Dialogs  */}
            <AddGuests visible={isGuestDialogOpen} setVisible={setGuestDialogOpen} />
        </div>
    );
}
