import React, { useState, useEffect } from "react";
import { Image } from "primereact/image";
import { getDefaultImage, getImageURL } from "../../utils/imageUrl";
import Gallery from "../../assets/images/business wedding banner 2.png";
export default function CustomImageInput({
  label,
  name,
  onFilesChange,
  errorMessage,
  extraClassName,
  value,
  data,
  limit,
  multiple,
  boxContainer,
  col,
  required,
  removeable,
  editable,
  disabled = false,
  ...props
}) {
  const [files, setFiles] = useState(value || data?.[name] || []);

  useEffect(() => {
    if (value || data?.[name]) {
      if (JSON.stringify(value || data?.[name]) !== JSON.stringify(files)) {
        setFiles(value || data?.[name]);
      }
    }
  }, [value || data?.[name]]);

  useEffect(() => {
    if (onFilesChange) {
      onFilesChange({ name, value: files });
    }
  }, [files]);

  const onDelete = (id) => {
    if (editable) {
      const s = files.filter((item, index) => index !== id);
      setFiles([...s]);
    }
  };
  const onFileChange = (e) => {
    if (editable) {
      const chossenFile = e.target?.files;
      handlefileChange(Object.values(chossenFile));
    }
  };
  const handlefileChange = (uploadedfile) => {
    let uploadedFiles = [];
    uploadedfile.forEach((file) => {
      if (files.findIndex((f) => f.name === file.name) === -1) {
        uploadedFiles.push(file);
      }
    });
    if (multiple) {
      if (limit) {
        let newarr = [...files, ...uploadedFiles];
        if (newarr.length > limit) {
          newarr = newarr.slice(0, limit);
        }
        setFiles(newarr);
      } else {
        setFiles([...files, ...uploadedFiles]);
      }
    } else {
      setFiles([...uploadedFiles]);
    }
  };
  return (
    <>
      <div className={boxContainer}>
        <div
          className=" border-round-lg relative "
          style={{ backgroundColor: "#D9D9D9", height: "140px" }}
        >
          {files.length
            ? Object.values(files).map((image, i) => (
                <div key={i} className="w-full">
                  <Image
                    className="w-full cover-img "
                    src={
                      image
                        ? typeof image === "string"
                          ? getImageURL(image)
                          : URL.createObjectURL(image)
                        : Gallery
                    }
                    onError={(e) => (e.target.src = getDefaultImage())}
                    alt="Image"
                    preview
                    icon="pi pi-eye"
                    width="154px"
                    height="140px"
                  />{" "}
                  {removeable && (
                    <i
                      onClick={() => onDelete(i)}
                      className="fa-solid fa-circle-xmark cursor-pointer"
                    ></i>
                  )}
                </div>
              ))
            : null}
        </div>
      </div>

      <div className="col-12 md:col-8 align-items-center flex">
        <label
          htmlFor={name}
          className="flex align-items-center bg-white shadow-1 p-2 border-round-lg lg:w-3"
        >
          <i className="pi pi-cloud-upload" style={{ fontSize: "1.5rem" }}></i>
          <span className="text-sm ml-2 font-normal text-light-black">
            Upload Photo
          </span>
          <input
            name={name}
            onChange={onFileChange}
            id={name}
            type="file"
            accept="image/*"
            hidden
            {...props}
            multiple={multiple}
            disabled={disabled}
          />
        </label>
      </div>
    </>
  );
}
