import { types } from "../types";
import Constants from "../../services/constant";
import axios from "axios";
import api from "../../services/api";
import { authenticate, checkUserRole, checkisUserSubscribed, isAuthenticated } from "../../services/authService";
import { showLoaderAction, hideLoaderAction } from "./loaderAction";
import { showToast } from "./toastAction";
import { forgetApi } from "../../services/forgetApi";
import { sendOtpAction } from "./otpActions";
export const signupAction = (data, next) => async (dispatch) => {
    dispatch(showLoaderAction());
    let res = "";
    if (data.userType === "HOST") {
        res = await api("post", Constants.END_POINT.HOST_SIGNUP, data);
    } else {
        res = await api("post", Constants.END_POINT.VENDOR_SIGNUP, data);
    }
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.SING_UP,
                payload: res.data,
            });
            localStorage.setItem("vendorEmail", data.email);
        }

        next();
        dispatch(hideLoaderAction());
    } else {
        if (res.message.includes("E11000")) {
            dispatch(hideLoaderAction());
            dispatch(showToast({ severity: "error", summary: "Email allready Exist" }));
        } else {
            dispatch(hideLoaderAction());
            dispatch(showToast({ severity: "error", summary: res.message }));
        }
    }
    dispatch(hideLoaderAction());
};

export const signInAction = (data, next) => async (dispatch) => {
    dispatch(showLoaderAction());
    let res = "";
    if (data.userType === "HOST") {
        res = await api("post", Constants.END_POINT.HOST_LOGIN, data);
    } else {
        res = await api("post", Constants.END_POINT.VENDOR_LOGIN, data);
    }
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.SIGN_IN,
                payload: res.data,
            });
            authenticate(res.data.token, () => {
                if (isAuthenticated()) {
                    localStorage.setItem("name", res.data.businessName);
                }
            });
        }
        dispatch(hideLoaderAction());
        if (checkUserRole("Vendor")) {
            next("/vendor/dashboard");
        } else {
            next("/host/profile");
        }
        return res.data;
    } else {
        dispatch(hideLoaderAction());
        dispatch(showToast({ severity: "error", summary: res.message }));
    }
};

//testing
export const forgetPasswordAction = (data, next) => async (dispatch) => {
    dispatch(showLoaderAction());
    const res = await api("post", Constants.END_POINT.FORGOT_PASSWORD, data);
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.FORGET_PASSWORD,
                payload: res.data,
            });
            localStorage.setItem("email", data.email);
        }
        dispatch(hideLoaderAction());
        next();
    } else {
        dispatch(hideLoaderAction());
        dispatch(showToast({ severity: "error", summary: res.message }));
    }
};

export const resetPasswordAction = (data, token, next) => async (dispatch) => {
    dispatch(showLoaderAction());
    const res = await forgetApi("post", Constants.END_POINT.VENDOR_RESET_PASSWORD, token, data);
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.RESET_PASSWORD,
                payload: res.data,
            });
        }
        dispatch(hideLoaderAction());
        next();
    } else {
        dispatch(hideLoaderAction());
        dispatch(showToast({ severity: "error", summary: res.message }));
    }
};

export const emailCheckAction = (data) => async (dispatch) => {
    dispatch(showLoaderAction());
    const res = await api("post", Constants.END_POINT.EMAIL_CHECK, data);
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.EMAIL_CHECK,
                payload: res.data,
            });
        }
        dispatch(hideLoaderAction());
        return res;
    } else {
        dispatch(hideLoaderAction());
        return res;

        // dispatch(showToast({ severity: "error", summary: res.message }))
    }
};

export const verifyOtpAction = (data) => async (dispatch) => {
    const res = await api("post", Constants.END_POINT.VERIFY_FORGET_PASSWORD, data);
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.REQUEST_FORGET_OTP,
                payload: res.data,
            });
        }

        return res;
    } else {
        dispatch(showToast({ severity: "error", summary: res.message }));
        return res;
    }
};

export const setPasswordAction = (data) => async (dispatch) => {
    const res = await api("post", Constants.END_POINT.SET_PASSWORD, data);
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.SET_PASSWORD,
                payload: res.data,
            });
        }
        return res;
    } else {
        dispatch(showToast({ severity: "error", summary: res.message }));
    }
};

export const googlelogin = (respose) => async (disaptch) => {
    try {
        const res = await axios.get("https://www.googleapis.com/oauth2/v3/userinfo", {
            headers: {
                Authorization: `Bearer ${respose.access_token}`,
            },
        });
        return res;
    } catch (err) {
        console.log(err);
    }
};

export const socialLogin = (data) => async (dispatch) => {
    let value = "";
    dispatch(showLoaderAction());
    const res = await api("post", Constants.END_POINT.SOCIAL_LOGIN, { ...data });
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.SOCIAL_SIGNUP,
                payload: res.data,
            });

            authenticate(res.data.token, () => {
                if (isAuthenticated()) {
                    window.localStorage.setItem("name", res.data.firstName);
                    dispatch(hideLoaderAction());
                    value = true;
                } else {
                    dispatch(hideLoaderAction());
                    value = false;
                }
            });
        }

        return value;
    } else {
        dispatch(hideLoaderAction());
        value = "error";
        dispatch(showToast({ severity: "error", summary: res.message }));
        return value;
    }
};
