import React, { useState } from "react";
import { IndexLayout } from "../../layout";
import { ContainerBox } from "../../shared/ContainerBox";
import { TabView, TabPanel } from "primereact/tabview";
import VendorContentData from "./VendorContentData";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import FilterIcon from "../../assets/icons/filter.png";
import { VendorSelectContentCard } from "../../components/cards/card";
import ButtonComponent, {
  ButtonOutlined,
} from "../../components/buttons/button";
import { Dialog } from "primereact/dialog";
import GraphIcon from "../../assets/icons/graph-monitoring.png";
import { useNavigate } from "react-router-dom";
import { ProfileLayout } from "../../layout/ProfileLayout";
import GuestsContent from "./GuestsContent";
import Vendor from ".";
export default function VendorSelectView() {
  const [visible, setVisible] = useState(false);
  const [ingredients, setIngredients] = useState([]);
  const Navigate = useNavigate();
  const WeddingVenues = () => {
    // 👇️ navigate to Wedding venues
    Navigate("/wedding-venues");
  };

  const onIngredientsChange = (e) => {
    let _ingredients = [...ingredients];

    if (e.checked) _ingredients.push(e.value);
    else _ingredients.splice(_ingredients.indexOf(e.value), 1);

    setIngredients(_ingredients);
  };
  const { VendorSelectContent } = VendorContentData();

  return (
    <>
      <ContainerBox>
        <div className="wedding-venues-container mt-5 mb-7">
          <div className="grid p-0 m-0 flex ">
            <div className="col-12 md:col-12 lg:col-12">
              <TabView>
                <TabPanel header="Vendors">
                  <div className="vendor-content">
                    <div className="grid mt-3 mb-3 flex justify-content-between p-0 ">
                      <div className="col-12 md:col-4">
                        <h1 className="merriweather text-2xl text-dark-gray font-bold mb-5">
                          Wedding Venues
                        </h1>
                      </div>
                      <div className="col-12 md:col-6 flex">
                        <div className="w-full">
                          <div className="p-inputgroup flex-1 mb-6 w-full">
                            <InputText
                              placeholder="Search Vendor"
                              className="bg-blue-50 w-full"
                            />
                            <Button
                              icon="pi pi-search"
                              className="p-button-primary"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-12 md:col-2">
                        <ButtonComponent
                          label="Add Vendor"
                          className=""
                          onClick={() => setVisible(true)}
                        ></ButtonComponent>
                      </div>
                    </div>
                    <div className="flex  gap-3 flex-wrap relative">
                      {VendorSelectContent.map((item) => {
                        return (
                          <VendorSelectContentCard
                            id={item.id}
                            img={item.img}
                            rating={item.rating}
                            title={item.title}
                            location={item.location}
                            price={item.price}
                            person={item.person}
                          />
                        );
                      })}
                    </div>
                  </div>
                </TabPanel>

                <TabPanel header="Guests">
                  <div className="guests-content">
                    <GuestsContent />
                  </div>
                </TabPanel>

                <TabPanel header="Wedding Website">
                  <div className="wedding-content">
                    {/* <h1>WEDDING</h1> */}
                  </div>
                </TabPanel>

                <TabPanel header="Seating Chart">
                  <div className="seating-chart-content">
                    {/* <h1>SEATING</h1> */}
                  </div>
                </TabPanel>

                <TabPanel header="Budget Planner">
                  <div className="budget-planeer-content">
                    {/* <h1>BUDGET </h1> */}
                  </div>
                </TabPanel>
              </TabView>
            </div>
          </div>
        </div>
      </ContainerBox>

      <Dialog
        header={
          <>
            <h3 className="merriweather text-2xl text-dark-gray font-bold">
              Add Wedding Venue
            </h3>
          </>
        }
        visible={visible}
        style={{ width: "100vw" }}
        onHide={() => setVisible(false)}
        className="lg:w-4 md:w-8 w-11"
      >
        <p className="m-0">
          <div className="p-inputgroup flex-1 mb-5">
            <InputText placeholder="Write the name of the Vendor" />
            <Button icon="pi pi-search" className="p-button-primary" />
          </div>
          <div className="text-center">
            <img src={GraphIcon} width={68} height={68} alt=""></img>
            <p className="text-lg font-semibold p-0 m-0 text-light-black">
              Still Looking
            </p>
            <p className="font-normal text-sm  text-light-black ">
              Search our Local Venues directoryh
            </p>
            <ButtonOutlined
              label="Search Wedding Venues"
              onClick={WeddingVenues}
            ></ButtonOutlined>
          </div>
        </p>
      </Dialog>
    </>
  );
}
