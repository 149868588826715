import { types } from "../types";

const initialState = {
  guestList: [],
};

export const guestListReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GUEST_LIST:
      return {
        ...state,
        guestList: action.payload,
      };
    default: {
      return state;
    }
  }
};