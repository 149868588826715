import React, { useEffect, useState } from "react";
import { Dialog } from "primereact/dialog";
import Table from "../../components/table/table";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import ButtonComponent from "../../components/buttons/button";
import { CustomCheckbox, CustomDropDown, CustomInput } from "../../components/input/AllInput";
import { Divider } from "primereact/divider";
import { ContainerBox } from "../../shared/ContainerBox";
import { addGuestAction, deleteGuestAction, guestAction } from "../../redux/actions/GuestAction";
import { useDispatch, useSelector } from "react-redux";
import { allValidations } from "../../utils/formValidations";
import { showFormErrors } from "../../utils/commonFunctions";

export default function GuestsContent() {
    const dispatch = useDispatch();
    const guestList = useSelector((state) => state.guest.guestList);
    const hostProfile = useSelector((state) => state.hostProfile.ProfileData);
    const [search, setSearch] = useState({ value: "", isSearch: false });
    const [expandedRows, setExpandedRows] = useState(null);
    const [data, setData] = useState({
        relativeFrom: "",
        name: "",
        invitedTo: "",
        acceptInviteReception: "PENDING",
        acceptInviteWedding: "PENDING",
        familyMemberList: [{ name: "" }],
    });

    const [invitedTo, setInvitedTo] = useState({
        wedding: false,
        reception: false,
    });
    const [visible, setVisible] = useState(false);
    const [hosts, setHosts] = useState([]);
    const [guests, setGuests] = useState({});
    const [totalGuests, setTotalGuest] = useState(0);

    // const [deleteButton, setDeletebutton] = useState(false);

    const tableHeading = (from) => {
        return [
            {
                field: "name",
                header: () => <span className="capitalize">{from}'s family</span>,
                style: { width: "35%" },
                body: "familyHeadTemplate(row)",
            },
            {
                field: "acceptInviteWedding",
                header: "Wedding",
                body: "statusTemplate(row, 'acceptInviteWedding')",
                style: { width: "25%" },
            },
            {
                field: "acceptInviteReception",
                header: "Reception",
                body: "statusTemplate(row, 'acceptInviteReception')",
                style: { width: "25%" },
            },
            {
                field: "Action",
                header: "Action",
                body: "ActionBodyTemplate(row)",
                style: { width: "15%" },
            },
        ];
    };

    const handleChange = ({ name, value, index }) => {
        const formErrors = allValidations(name, value, data);
        if (name === "familyMemberList") {
            if (data?.familyMemberList?.length) {
                let updatedMembers = data?.familyMemberList?.map((member, i) => (i === index ? { name: value } : member));
                setData({
                    ...data,
                    familyMemberList: updatedMembers,
                    formErrors,
                    error: {},
                });
            } else {
                setData({
                    ...data,
                    familyMemberList: [{ name: value }],
                    formErrors,
                    error: {},
                });
            }
        } else if (name === "wedding" || name === "reception") {
            setInvitedTo({
                ...invitedTo,
                [name]: !invitedTo[name],
            });
        } else {
            setData((prev) => ({ ...prev, [name]: value, formErrors }));
        }
    };

    const getGuests = ({ search }) => {
        dispatch(guestAction({ search }));
        setData({
            relativeFrom: "",
            name: "",
            invitedTo: "",
            acceptInviteReception: "PENDING",
            acceptInviteWedding: "PENDING",
            familyMemberList: [{ name: "" }],
        });
        setInvitedTo({
            wedding: false,
            reception: false,
        });
    };
    useEffect(() => {
        getGuests({ search: "" });
    }, []);

    useEffect(() => {
        if (guestList?.length) {
            let count = guestList.length;
            let guests = { [hostProfile.name]: [], [hostProfile.partnerName]: [] };

            guestList?.forEach((guest) => {
                count += guest?.familyMemberList?.length;
                if (guest.relativeFrom === hostProfile.name) {
                    guests[hostProfile.name].push(guest);

                    // if (guest?.familyMemberList?.length) {
                    //   guest?.familyMemberList?.forEach((member) =>
                    //     guests[hostProfile.name].push(member)
                    //   );
                    // }
                } else {
                    guests[hostProfile.partnerName].push(guest);
                    // if (guest?.familyMemberList?.length) {
                    //   guest?.familyMemberList?.forEach((member) =>
                    //     guests[hostProfile.partnerName].push(member)
                    //   );
                    // }
                }
            });

            setGuests(guests);
            setTotalGuest(count);
        } else {
            setGuests({ [hostProfile.name]: [], [hostProfile.partnerName]: [] });
            setTotalGuest(0);
        }
    }, [guestList]);

    useEffect(() => {
        setHosts([
            { name: hostProfile?.name + "'s Family", value: hostProfile?.name },
            {
                name: hostProfile?.partnerName + "'s Family",
                value: hostProfile?.partnerName,
            },
        ]);
    }, [hostProfile]);
    const updateInvite = async (invitedTo) => {
        let value = invitedTo?.wedding && invitedTo?.reception ? "BOTH" : invitedTo?.wedding ? "WEDDING" : invitedTo?.reception ? "RECEPTION" : "";
        let formErrors = {};
        if (data?.name !== "") {
            formErrors = await allValidations("invitedTo", value, data);
        }

        setData({
            ...data,
            invitedTo: value,
            acceptInviteReception: invitedTo?.reception ? "PENDING" : "NOTINVITED",
            acceptInviteWedding: invitedTo?.wedding ? "PENDING" : "NOTINVITED",
            familyMemberList: data?.familyMemberList?.map((member) => ({
                ...member,
                acceptInviteReception: invitedTo?.reception ? "PENDING" : "NOTINVITED",
                acceptInviteWedding: invitedTo?.wedding ? "PENDING" : "NOTINVITED",
            })),
            formErrors,
        });
    };
    useEffect(() => {
        updateInvite(invitedTo);
    }, [invitedTo]);

    const addGuestFun = ({ next = false }) => {
        if (showFormErrors(data, setData)) {
            if (data?.familyMemberList?.length) {
                let emptyMemberName = false;
                data?.familyMemberList?.forEach((item) => {
                    if (item.name === "") emptyMemberName = true;
                });
                setData({
                    ...data,
                    error: {
                        member: emptyMemberName ? "Please enter member name" : "",
                    },
                });
                if (emptyMemberName) return;
            }
            dispatch(addGuestAction(data, () => getGuests({ search: "" })));
            if (!next) setVisible(false);
        }
    };

    const deleteGuest = (id) => {
        dispatch(deleteGuestAction(id, () => getGuests({ search: "" })));
    };

    const searchGuest = ({ clear }) => {
        if (clear) setSearch({ value: "", isSearch: false });
        else setSearch({ isSearch: true, ...search });
        getGuests({ search: clear ? "" : search.value });
    };

    const rowExpansionTemplate = (row) => {
        return (
            <>
                <Table
                    tableHeading={[
                        {
                            field: "",
                            header: "",
                            body: "",
                            style: { minWidth: "4rem" },
                        },
                        {
                            field: "name",
                            header: `Family Members`,
                            style: { width: "35%" },
                        },
                        {
                            field: "acceptInviteWedding",
                            header: "Wedding",
                            body: "statusTemplate(row, 'acceptInviteWedding')",
                            style: { width: "25%" },
                        },
                        {
                            field: "acceptInviteReception",
                            header: "Reception",
                            body: "statusTemplate(row, 'acceptInviteReception')",
                            style: { width: "25%" },
                        },
                        {
                            field: "",
                            header: "",
                            body: "",
                            style: { width: "25%" },
                        },
                    ]}
                    tableData={row.familyMemberList}
                    empty="testing"
                />
            </>
        );
    };

    return (
        <>
            <div className="profile-container mt-1 md:mt-5 mb-7">
                <ContainerBox>
                    <div className="grid mb-1 flex align-items-center ">
                        <div className="col-12 md:col-7">
                            <h1 className="merriweather text-2xl text-dark-gray font-bold ">Guests</h1>
                            <p className="p-0 m-0 text-sm font-normal text-light-black">{totalGuests} Result</p>
                        </div>

                        <div className="col-12 md:col-5 ">
                            <div className="w-full md:mt-5">
                                <div className="p-inputgroup flex-1 md:mb-6 w-full">
                                    <InputText
                                        placeholder="Search Guest"
                                        className="bg-blue-50"
                                        onChange={(e) => setSearch({ value: e?.target?.value })}
                                        value={search.value}
                                    />
                                    <Button icon="pi pi-search" className="p-button-primary" onClick={searchGuest} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        {search.isSearch ? (
                            <p>
                                <b>Related search results for: </b>
                                {search.value}
                                <i className="pi pi-times-circle text-red-500 ml-4 cursor-pointer" onClick={() => searchGuest({ clear: true })} />
                            </p>
                        ) : null}
                    </div>
                    <div className="tabledata relative mb-5">
                        <Table
                            className=""
                            expendRow
                            expandedRows={expandedRows}
                            onRowToggle={(e) => setExpandedRows(e.data)}
                            tableHeading={tableHeading(hostProfile.name)}
                            tableData={guests[hostProfile.name]}
                            rowExpansionTemplate={rowExpansionTemplate}
                            dataKey="_id"
                            setData={setData}
                            setVisible={setVisible}
                            setInvitedTo={setInvitedTo}
                            deleteGuest={deleteGuest}
                        />
                    </div>
                    <div className="tabledata relative mb-5">
                        <Table
                            className=""
                            expendRow
                            expandedRows={expandedRows}
                            onRowToggle={(e) => setExpandedRows(e.data)}
                            tableHeading={tableHeading(hostProfile.partnerName)}
                            tableData={guests[hostProfile.partnerName]}
                            rowExpansionTemplate={rowExpansionTemplate}
                            dataKey="_id"
                            setData={setData}
                            setVisible={setVisible}
                            setInvitedTo={setInvitedTo}
                            deleteGuest={deleteGuest}
                        />
                    </div>

                    <ButtonComponent label="Add Guests" onClick={() => setVisible(true)} />
                    <Dialog
                        className="lg:w-5 md:w-8 w-11"
                        header={
                            <>
                                <h1 className="text-2xl font-bold Merriweather">{data?.isUpdate ? "Update Guest " : "Add Guest"}</h1>
                            </>
                        }
                        visible={visible}
                        style={{ width: "50vw" }}
                        onHide={() => {
                            setVisible(false);
                            setData({
                                relativeFrom: "",
                                name: "",
                                invitedTo: "",
                                acceptInviteReception: "PENDING",
                                acceptInviteWedding: "PENDING",
                                familyMemberList: [{ name: "" }],
                            });
                        }}
                    >
                        <p className="m-0">
                            <div className="grid">
                                <div className="col-12 md:col-6">
                                    <CustomInput
                                        extraClassName="w-full"
                                        label="Full Name*"
                                        placeholder="Enter guest name"
                                        className="w-full"
                                        value={data?.name}
                                        name="name"
                                        data={data}
                                        onChange={handleChange}
                                        labeClassName="text-dark-black tex-sm font-medium"
                                    />
                                </div>
                                <div className="col-12 md:col-6">
                                    <CustomDropDown
                                        label="Relative to*"
                                        placeholder="Select Family"
                                        extraClassName="w-full"
                                        dropdownOptions={hosts}
                                        value={data?.relativeFrom}
                                        name="relativeFrom"
                                        onChange={handleChange}
                                        data={data}
                                        labelClassName="text-dark-black tex-sm font-medium"
                                    />
                                </div>
                                <div className="col-12 md:col-12 mb-1">
                                    <p className="text-dark-black tex-sm font-semibold ml-1 p-0 m-0">Add family member</p>
                                </div>
                                <div className="col-12 md:col-6">
                                    {data?.familyMemberList?.map((member, index) => {
                                        return (
                                            <div className="flex align-items-center" key={index}>
                                                <CustomInput
                                                    extraClassName="w-11 md:w-full"
                                                    // label={` Member ${index + 1} - Full Name`}
                                                    placeholder="Add Member Name"
                                                    className=""
                                                    name="familyMemberList"
                                                    value={member?.name}
                                                    onChange={(e) =>
                                                        handleChange({
                                                            ...e,
                                                            name: e.target.name,
                                                            value: e.target.value,
                                                            index,
                                                        })
                                                    }
                                                    errorMessage={index === data?.familyMemberList?.length - 1 ? data?.error?.member : ""}
                                                    labeClassName="text-dark-black tex-sm font-medium"
                                                />
                                                {data?.familyMemberList?.length ? (
                                                    <i
                                                        className="pi pi-trash cursor-pointer text text-red-500 pb-3 ml-2"
                                                        onClick={() => {
                                                            let updatedMembers = [];
                                                            data?.familyMemberList?.forEach((item, i) => {
                                                                if (i !== index) updatedMembers.push(item);
                                                            });
                                                            setData({
                                                                ...data,
                                                                familyMemberList: updatedMembers,
                                                            });
                                                        }}
                                                    />
                                                ) : null}
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                            <ButtonComponent
                                label="+ Add More"
                                className="ml-1 mb-3"
                                onClick={() => {
                                    if (data?.familyMemberList?.length) {
                                        if (data?.familyMemberList[data?.familyMemberList?.length - 1]?.name !== "") {
                                            setData({
                                                ...data,
                                                familyMemberList: [...data.familyMemberList, { name: "" }],
                                            });
                                        } else {
                                            setData({
                                                ...data,
                                                error: { member: "Please enter member name" },
                                            });
                                        }
                                    } else {
                                        setData({
                                            ...data,
                                            familyMemberList: [{ name: "" }],
                                        });
                                    }
                                }}
                            ></ButtonComponent>
                            <Divider />
                            <p className="text-dark-black tex-sm font-semibold ml-1 p-0 m-0 mb-3">Invited To</p>

                            <div className="checkbox flex ">
                                <span>
                                    <CustomCheckbox
                                        optionLabel="Wedding"
                                        data={invitedTo}
                                        checked={invitedTo?.wedding}
                                        name="wedding"
                                        extraClassName="m-0"
                                        onChange={handleChange}
                                    ></CustomCheckbox>
                                </span>
                                <span className="ml-5">
                                    <CustomCheckbox
                                        optionLabel="Reception"
                                        extraClassName="m-0"
                                        checked={invitedTo?.reception}
                                        data={invitedTo}
                                        name="reception"
                                        onChange={handleChange}
                                    ></CustomCheckbox>
                                </span>
                            </div>
                            <div className="pl-2 error-msg mb-3">
                                {data?.formErrors?.invitedTo ? <small className="p-error text-sm">{data?.formErrors?.invitedTo}</small> : null}
                            </div>
                            <div className="btn md:flex ">
                                {!data?.isUpdate ? (
                                    <span className="col-6">
                                        <ButtonComponent
                                            label="Save and add Another"
                                            className=" lg:mb-0 md:mb-0 mb-3 text-sm font-medium bg-white border-400 text-light-black w-full"
                                            onClick={() => addGuestFun({ next: true })}
                                        />
                                    </span>
                                ) : null}

                                <span className="col-6">
                                    <ButtonComponent
                                        label={data?.isUpdate ? "Update" : "Add"}
                                        className="text-sm font-medium w-full"
                                        onClick={() => addGuestFun({ next: false })}
                                    />
                                </span>
                            </div>
                        </p>
                    </Dialog>
                </ContainerBox>
            </div>
        </>
    );
}
