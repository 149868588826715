import React, { useEffect } from "react";
import { IndexLayout } from "../../layout";
import { ContainerBox } from "../../shared/ContainerBox";
import { CardComponent } from "../../components/cards/card";
import MonthlyIcon from "../../assets/icons/monthly icon.png";
import AnnuallyIcon from "../../assets/icons/annually icon.png";
import { Divider } from "primereact/divider";
import CheckIconfrom from "../../assets/icons/Check.png";
import ButtonComponent from "../../components/buttons/button";
import { useNavigate } from "react-router-dom";
import { allMemberShipAction } from "../../redux/actions/memberShipsAction";
import { useDispatch, useSelector } from "react-redux";
import { capitalizeFirstLetter } from "../../utils/regex";
import { formatCurrency } from "../../utils/javascript";
import animationData from "../../utils/wedding.json";
export default function MemberShipPlan() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const isLoading = useSelector((state) => state.loader.isLoading);
    const animationOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
        },
    };

    useEffect(() => {
        dispatch(allMemberShipAction());
    }, []);
    const allPlans = useSelector((state) => state?.getAllMembership?.getallSubscriptionProfile);

    const handleSubmit = (item) => {
        if (item?.subscriptionType === "month") {
            navigate(`/checkout/${item?._id}`);
        } else {
            navigate(`/checkout/${item?._id}`, { state: { item } });
        }
    };
    return (
        <>
            {isLoading ? (
                <div className="">
                    {" "}
                    {/* <Lottie
            speed={3}
            options={animationOptions}
            height={400}
            width={400}
          /> */}
                </div>
            ) : (
                <>
                    <ContainerBox containerType="">
                        <h1 className="text-center font-bold text-2xl merriweather text-dark-gray mt-7 mb-4">Choose your Membership Plan as per your Fit</h1>
                        <p className="text-center text-base font-normal text-dark-gray mb-5">
                            Discover the ideal plan to fuel your business growth. Our pricing
                            <br></br>
                            options are carefully crafted to cater to businesses.
                        </p>

                        <div className="grid flex p-0 m-0 justify-content-center mb-5">
                            {allPlans?.length > 0 &&
                                allPlans?.map((item) => {
                                    return (
                                        <div className=" col-12 md:col-6 lg:col-4">
                                            <CardComponent className=" border-transparent border-2 hover:border-primary  shadow-1  w-full cursor-pointer h-full relative">
                                                <div className="monthly-plan text-center">
                                                    <div className="top-content">
                                                        <img src={AnnuallyIcon} width={45} height={45} alt="" />
                                                        <h1 className="merriweather text-2xl font-normal text-black-dark">
                                                            {capitalizeFirstLetter(item?.subscriptionPlan)}
                                                        </h1>
                                                        <div className="flex align-items-center justify-content-center mb-2">
                                                            <h1 className="font-semibold text-black-dark text-6xl p-0 m-0">
                                                                {item?.subscriptionPlan === "Standard" ? "Free" : formatCurrency(item?.offeredPrice)}
                                                            </h1>
                                                            <span className="mt-3 " style={{ color: " #797878", fontSize: "19px" }}>
                                                                {item?.subscriptionPlan === "Standard" ? "" : "/Year"}
                                                            </span>
                                                        </div>

                                                        {item?.subscriptionPlan === "Standard" ? (
                                                            ""
                                                        ) : (
                                                            <p className="text-xs font-normal p-0 m-0 ">
                                                                Billing Yearly at
                                                                <b className="text-main-color "> {formatCurrency(item?.price)}</b>
                                                                {/* +VAT */}
                                                            </p>
                                                        )}
                                                    </div>
                                                    <Divider className="mt-6 pt-1" />
                                                    <div className="feauture-container ">
                                                        {item?.features?.length > 0 &&
                                                            item?.features?.map((items) => {
                                                                return (
                                                                    <>
                                                                        <div className="feature">
                                                                            <div className="flex align-items-start mb-3">
                                                                                <img src={CheckIconfrom} width={22} height={22} alt="" className="mt-1" />
                                                                                <span className="ml-3 text-lg text-left font-normal text-black-dark">
                                                                                    {items}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                );
                                                            })}
                                                    </div>
                                                </div>
                                                <div className="btn mt-5">
                                                    <ButtonComponent
                                                        label="Buy Now"
                                                        className="text-dark-gray w-full  bg-transparent border-gray text-base h-full hover:bg-primary hover:border-0 "
                                                        onClick={(e) => handleSubmit(item)}
                                                    ></ButtonComponent>
                                                </div>
                                                {item.subscriptionPlan === "Standard" ? (
                                                    ""
                                                ) : (
                                                    <div className="box">
                                                        <div className="ribbon">
                                                            <span>{Math.round(item?.discountPercentage)}% Discount</span>{" "}
                                                        </div>
                                                    </div>
                                                )}
                                            </CardComponent>
                                        </div>
                                    );
                                })}
                        </div>
                    </ContainerBox>
                </>
            )}
        </>
    );
}
