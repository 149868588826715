import React, { useEffect, useRef, useState } from "react";
import { ContainerBox } from "../../shared/ContainerBox";
import { Stage, Layer, Rect, Circle, Text, Group, Image } from "react-konva";
import { Dialog } from "primereact/dialog";
import { CustomDropDown, CustomInput } from "../../components/input/AllInput";
import { allValidations } from "../../utils/formValidations";
import ButtonComponent, { ButtonOutlined } from "../../components/buttons/button";
import rectTableImg from "../../assets/icons/react-table.png";
import cicleTableImg from "../../assets/icons/circle-table.png";
import oneSideRectangeImg from "../../assets/icons/OneSideRectange.png";
import chairRow from "../../assets/icons/ChairRow.png";
import { guestAction } from "../../redux/actions/GuestAction";
import { useDispatch, useSelector } from "react-redux";
import { addSeatingChartAction, seatingChartAction, updateSeatingChartAction } from "../../redux/actions/SeatingAction";
import { ConfirmDialog } from "primereact/confirmdialog";
import AddGuests from "./AddGuests";
import { Accordion, AccordionTab } from "primereact/accordion";
import deleteIcon from "../../assets/icons/deleteIcon.png";
import editIcon from "../../assets/icons/editIcon.png";
import PersonIcon from "../../assets/icons/person.png";
import DeleteIcon1 from "../../assets/icons/deletesiting.png";
import html2canvas from "html2canvas";
import JSZip from "jszip";
import { tab } from "@testing-library/user-event/dist/tab";

export default function SeatingPlanner() {
    const guestList = useSelector((state) => state.guest.guestList);
    const dispatch = useDispatch();
    const tableRef = useRef(null);
    const [data, setData] = useState([]);
    const [guest, setGuests] = useState([]);
    const [chartData, setChartData] = useState({
        _id: null,
        weddingSeatingPlanner: [],
        receptionSeatingPlaner: [],
    });
    const [addedGuest, setAddedGuests] = useState([]);
    const [isEdited, setIsEdited] = useState(false);
    const [isWeddingSeating, setIsWeddingSeating] = useState(true);
    const [tableData, setTableData] = useState({
        tableName: "Table",
        guestCount: 4,
        x: 40,
        y: 40,
        guests: [
            { value: "", name: "" },
            { value: "", name: "" },
            { value: "", name: "" },
            { value: "", name: "" },
        ],
        tempGuest: [],
    });
    const [visible, setVisible] = useState("");
    const [openGuestDialog, setOpenGuestDialog] = useState(false);
    const [tableDataView, setTableDataView] = useState({});

    const generateTable = (data, visible) => {
        let rectChairs = {
            4: {
                1: { x: 75, y: 0, face: "down" },
                2: { x: 150, y: 60, face: "right" },
                3: { x: 75, y: 130, face: "up" },
                4: { x: 0, y: 60, face: "left" },
            },
            5: {
                1: { x: 40, y: 0, face: "down" },
                2: { x: 110, y: 0, face: "down" },
                3: { x: 110, y: 130, face: "up" },
                4: { x: 40, y: 130, face: "up" },
                5: { x: 150, y: 60, face: "right" },
            },
            6: {
                1: { x: 40, y: 0, face: "down" },
                2: { x: 110, y: 0, face: "down" },
                6: { x: 0, y: 60, face: "left" },
                5: { x: 40, y: 130, face: "up" },
                4: { x: 110, y: 130, face: "up" },
                3: { x: 150, y: 60, face: "right" },
            },
            7: {
                1: { x: 38, y: 0, face: "down" },
                2: { x: 100, y: 0, face: "down" },
                3: { x: 165, y: 0, face: "down" },
                4: { x: 230, y: 0, face: "down" },
                5: { x: 200, y: 130, face: "up" },
                6: { x: 130, y: 130, face: "up" },
                7: { x: 60, y: 130, face: "up" },
            },
            8: {
                1: { x: 38, y: 0, face: "down" },
                2: { x: 100, y: 0, face: "down" },
                3: { x: 165, y: 0, face: "down" },
                4: { x: 230, y: 0, face: "down" },
                5: { x: 230, y: 130, face: "up" },
                6: { x: 165, y: 130, face: "up" },
                7: { x: 100, y: 130, face: "up" },
                8: { x: 38, y: 130, face: "up" },
            },
            9: {
                1: { x: 38, y: 0, face: "down" },
                2: { x: 100, y: 0, face: "down" },
                3: { x: 165, y: 0, face: "down" },
                4: { x: 230, y: 0, face: "down" },
                5: { x: 270, y: 60, face: "right" },
                6: { x: 230, y: 130, face: "up" },
                7: { x: 165, y: 130, face: "up" },
                8: { x: 100, y: 130, face: "up" },
                9: { x: 38, y: 130, face: "up" },
            },
            10: {
                1: { x: 38, y: 0, face: "down" },
                2: { x: 100, y: 0, face: "down" },
                3: { x: 165, y: 0, face: "down" },
                4: { x: 230, y: 0, face: "down" },
                5: { x: 270, y: 60, face: "right" },
                6: { x: 230, y: 130, face: "up" },
                7: { x: 165, y: 130, face: "up" },
                8: { x: 100, y: 130, face: "up" },
                9: { x: 38, y: 130, face: "up" },
                10: { x: 0, y: 60, face: "left" },
            },
        };
        let rowChairs = {
            4: {
                1: { x: 40, y: 0, face: "down" },
                2: { x: 110, y: 0, face: "down" },
                3: { x: 180, y: 0, face: "down" },
                4: { x: 250, y: 0, face: "down" },
            },
            5: {
                1: { x: 40, y: 0, face: "down" },
                2: { x: 110, y: 0, face: "down" },
                3: { x: 180, y: 0, face: "down" },
                4: { x: 250, y: 0, face: "down" },
                5: { x: 320, y: 0, face: "down" },
            },
            6: {
                1: { x: 40, y: 0, face: "down" },
                2: { x: 110, y: 0, face: "down" },
                3: { x: 180, y: 0, face: "down" },
                4: { x: 250, y: 0, face: "down" },
                5: { x: 320, y: 0, face: "down" },
                6: { x: 390, y: 0, face: "down" },
            },
        };

        let circleChairs = {
            4: {
                1: { x: 75, y: 0, r: 0, tx: 75, ty: 8 },
                2: { x: 180, y: 75, r: 90, tx: 150, ty: 84 },
                3: { x: 105, y: 180, r: 180, tx: 75, ty: 158 },
                4: { x: 0, y: 105, r: 270, tx: 0, ty: 84 },
            },
            5: {
                1: { x: 75, y: 0, r: 0, tx: 75, ty: 8 },
                2: { x: 172, y: 50, r: 75, tx: 148, ty: 62 },
                3: { x: 155, y: 155, r: 145, tx: 120, ty: 145 },
                4: { x: 35, y: 165, r: 230, tx: 22, ty: 137 },
                5: { x: 0, y: 70, r: 295, tx: 4, ty: 55 },
            },
            6: {
                1: { x: 75, y: 0, r: 0, tx: 75, ty: 8 },
                2: { x: 160, y: 30, r: 60, tx: 140, ty: 45 },
                3: { x: 180, y: 120, r: 120, tx: 145, ty: 122 },
                4: { x: 105, y: 180, r: 180, tx: 75, ty: 158 },
                5: { x: 20, y: 148, r: 240, tx: 10, ty: 122 },
                6: { x: 4, y: 55, r: 305, tx: 10, ty: 45 },
            },
            7: {
                1: { x: 75, y: 0, r: 0, tx: 75, ty: 8 },
                2: { x: 152, y: 20, r: 50, tx: 135, ty: 35 },
                3: { x: 183, y: 90, r: 100, tx: 150, ty: 95 },
                4: { x: 138, y: 170, r: 155, tx: 102, ty: 158 },
                5: { x: 50, y: 175, r: 210, tx: 28, ty: 150 },
                6: { x: 0, y: 115, r: 265, tx: 0, ty: 92 },
                7: { x: 10, y: 40, r: 310, tx: 17, ty: 32 },
            },
            8: {
                1: { x: 75, y: 0, r: 0, tx: 75, ty: 8 },
                2: { x: 145, y: 15, r: 45, tx: 130, ty: 28 },
                3: { x: 180, y: 75, r: 90, tx: 150, ty: 84 },
                4: { x: 170, y: 140, r: 135, tx: 135, ty: 135 },
                5: { x: 105, y: 180, r: 180, tx: 75, ty: 158 },
                6: { x: 35, y: 165, r: 225, tx: 20, ty: 135 },
                7: { x: 0, y: 105, r: 270, tx: 0, ty: 84 },
                8: { x: 15, y: 35, r: 315, tx: 20, ty: 28 },
            },
            10: {
                1: { x: 75, y: 0, r: 0, tx: 75, ty: 8 },
                2: { x: 135, y: 10, r: 36, tx: 125, ty: 24 },
                3: { x: 173, y: 50, r: 72, tx: 150, ty: 62 },
                4: { x: 180, y: 105, r: 108, tx: 150, ty: 112 },
                5: { x: 155, y: 155, r: 144, tx: 120, ty: 150 },
                6: { x: 105, y: 180, r: 180, tx: 75, ty: 160 },
                7: { x: 45, y: 170, r: 216, tx: 25, ty: 145 },
                8: { x: 8, y: 132, r: 252, tx: 0, ty: 108 },
                9: { x: 0, y: 75, r: 288, tx: 0, ty: 60 },
                10: { x: 25, y: 25, r: 324, tx: 28, ty: 23 },
            },
        };

        if (data.tableType === "rect") {
            let table = {
                type: typeof visible === "number" ? tableDataView.type : data.tableType,
                name: data.tableName,
                width: data.guestCount > 6 ? 240 : 120,
                height: 100,
                isDragging: false,
                x: data.x,
                y: data.y,
                chairs: [],
                guests: data.guests,
            };

            for (let i = 0; i < data.guestCount; i++) {
                let chair = {
                    x: rectChairs[data.guestCount][i + 1].x,
                    y: rectChairs[data.guestCount][i + 1].y,
                    face: rectChairs[data.guestCount][i + 1].face,
                };

                table.chairs.push(chair);
            }

            return table;
        }
        if (data.tableType === "rectOneSide") {
            let table = {
                type: typeof visible === "number" ? tableDataView.type : data.tableType,
                name: data.tableName,
                width: data.guestCount === 4 ? 260 : data.guestCount === 5 ? 330 : 400,
                height: 100,
                isDragging: false,
                x: data.x,
                y: data.y,
                chairs: [],
                guests: data.guests,
            };

            for (let i = 0; i < data.guestCount; i++) {
                let chair = {
                    x: rowChairs[data.guestCount][i + 1].x,
                    y: rowChairs[data.guestCount][i + 1].y,
                    face: rowChairs[data.guestCount][i + 1].face,
                };

                table.chairs.push(chair);
            }

            return table;
        }
        if (data.tableType === "reactChair") {
            let table = {
                type: typeof visible === "number" ? tableDataView.type : data.tableType,
                // name: data.tableName,
                width: data.guestCount === 4 ? 260 : data.guestCount === 5 ? 330 : 400,
                // height: 100,
                isDragging: false,
                x: data.x,
                y: data.y,
                chairs: [],
                guests: data.guests,
            };

            for (let i = 0; i < data.guestCount; i++) {
                let chair = {
                    x: rowChairs[data.guestCount][i + 1].x,
                    y: rowChairs[data.guestCount][i + 1].y,
                    face: rowChairs[data.guestCount][i + 1].face,
                };

                table.chairs.push(chair);
            }

            return table;
        } else {
            let table = {
                type: typeof visible === "number" ? tableDataView.type : data.tableType,
                name: data.tableName,
                width: 150,
                height: 100,
                isDragging: false,
                radius: 60,
                x: 40,
                y: 40,
                xAxis: 90,
                yAxis: 90,
                chairs: [],
                guests: data.guests,
            };

            for (let i = 0; i < data.guestCount; i++) {
                let chair = {
                    x: circleChairs[data.guestCount][i + 1].x,
                    y: circleChairs[data.guestCount][i + 1].y,
                    r: circleChairs[data.guestCount][i + 1].r,
                    tx: circleChairs[data.guestCount][i + 1].tx,
                    ty: circleChairs[data.guestCount][i + 1].ty,
                };

                table.chairs.push(chair);
            }

            return table;
        }
    };

    const handleChange = ({ name, value, e }) => {
        const formErrors = allValidations(name, value, tableData);
        if (name === "guestCount") {
            if (tableData.guests.length < value) {
                let newCount = value - tableData.guests.length;
                let addGuests = [];
                for (let i = 0; i < newCount; i++) {
                    addGuests.push({ value: "", name: "" });
                }
                setTableData((prev) => ({
                    ...prev,
                    [name]: value,
                    guests: [...prev.guests, ...addGuests],
                }));
            } else
                setTableData((prev) => ({
                    ...prev,
                    [name]: value,
                    guests: prev.guests.slice(0, value),
                }));
        } else setTableData((prev) => ({ ...prev, [name]: value, formErrors }));
    };

    const handleTableGuest = ({ name, value, e, index }) => {
        setTableData((prev) => ({
            ...prev,
            guests: prev.guests.map((item, i) =>
                i === index ? guest.find((gst) => gst.value === value) : item.value === value ? { value: "", name: "" } : item
            ),
            tempGuest: prev?.tempGuest?.includes(value) ? prev.tempGuest.filter((item) => item !== value) : [...prev.tempGuest, value],
        }));
    };

    const [isDownloading, setIsDownloading] = useState(false);

    useEffect(() => {
        if (typeof visible === "number" && tableDataView) {
            setTableData({
                tableName: tableDataView.name,
                tableType: tableDataView.type,
                guestCount: tableDataView.guests.length,
                x: tableDataView.x,
                y: tableDataView.y,
                guests: tableDataView.guests,
                tempGuest: [],
            });
        }
    }, [visible, tableDataView]);

    const handleEditTableSave = async () => {
        let _formErrors = tableData?.formErrors;

        if (_formErrors && Object.keys(_formErrors)) {
            let _errors = Object.values(_formErrors);

            let err = _errors.some((item) => item);
            if (err) {
                return;
            }
        }
        let newTableData = {
            ...tableData,
            guests: tableData.guests,
            tableName: tableData.tableName === "Table" ? tableData.tableName + " " + (data.length + 1) : tableData.tableName,
        };
        if (typeof visible === "number") {
            let table = await generateTable(newTableData, visible);
            setData((prev) => {
                const updatedData = [...prev];
                updatedData[visible - 1] = table;
                return updatedData;
            });
            setAddedGuests([...addedGuest, ...tableData.guests]);
            setVisible(false);
            setIsEdited(true);
        }
    };

    const handleAddTable = async () => {
        let _formErrors = tableData?.formErrors;
        if (_formErrors && Object.keys(_formErrors)) {
            let _errors = Object.values(_formErrors);
            let err = _errors.some((item) => item);
            if (err) {
                return;
            }
        }

        let newTableData = {
            ...tableData,
            guests: tableData.guests,
            tableName: tableData.tableName === "Table" ? tableData.tableName + " " + (data.length + 1) : tableData.tableName,
        };

        let table = await generateTable(newTableData);

        console.log("table==>", table);

        let _lastObj = data.length ? data[data.length - 1] : null;

        let _x = _lastObj?.x || 40;
        let _tblHeight = table.height ? table.height : 140;

        let _y = _lastObj?.y ? _lastObj?.y + _tblHeight + 80 : 40;

        if (_x > canvasWidth - 200) {
            _x = canvasWidth - 200;
        }
        if (_y > canvasHeight - 200) {
            _y = canvasHeight - 200;
        }

        table.x = _x;
        table.y = _y;
        console.log("table2==>", table);
        setData((prev) => [...prev, table]);
        setAddedGuests([...addedGuest, ...tableData.guests]);

        setTableData({
            tableName: "Table",
            guestCount: 4,
            x: 40,
            y: 40,
            guests: [
                { value: "", name: "" },
                { value: "", name: "" },
                { value: "", name: "" },
                { value: "", name: "" },
            ],
            tempGuest: [],
        });
        setVisible(false);
        setIsEdited(true);
    };

    const handleDeleteTable = (index) => {
        setDeleteSitingTable(index + 1);
        setIsEdited(true);
    };
    const handleDeleteTableAction = () => {
        setData((prev) => {
            const updatedData = prev.filter((_, i) => i !== deleteSitingTable - 1);
            return updatedData;
        });
        setDeleteSitingTable(null);
    };

    const handleEditTable = (index, table) => {
        setVisible(index + 1);
        setTableDataView(table);
    };

    const [isInitialRender, setIsInitialRender] = useState(true);

    const getChairsState = () => {
        return chartData.weddingSeatingPlanner.map((table) => table.chairs.length).join(",");
    };

    useEffect(() => {
        if (isInitialRender) {
            setIsInitialRender(false);
            return;
        } else if (chartData._id === null) {
            dispatch(addSeatingChartAction(chartData, setChartData));
        } else dispatch(updateSeatingChartAction(chartData, setChartData));
    }, [chartData?.weddingSeatingPlanner?.length, getChairsState()]);

    const saveSeatingPlanner = () => {
        if (chartData._id === null) {
            dispatch(addSeatingChartAction(chartData, setChartData));
        } else dispatch(updateSeatingChartAction(chartData, setChartData));
    };

    const [deleteSitingTable, setDeleteSitingTable] = useState(null);

    const TableDialog = (visible, data) => {
        return (
            <Dialog
                blockScroll={true}
                draggable={false}
                resizable={false}
                className="lg:w-5 md:w-8 w-11"
                header={
                    <>
                        <h1 className="text-2xl font-bold Merriweather">{typeof visible === "number" ? "Edit Table" : "Add Table"}</h1>
                    </>
                }
                visible={visible}
                style={{ width: "50vw" }}
                onHide={() => setVisible("")}
            >
                <div className="flex flex-wrap">
                    {tableData.tableType !== "reactChair" && (
                        <div className="col-6">
                            <CustomInput
                                extraClassName="w-full"
                                label="Table Name*"
                                placeholder="Table 1"
                                className="w-full"
                                value={tableData?.tableName}
                                name="tableName"
                                data={tableData}
                                onChange={handleChange}
                                labeClassName="text-dark-black tex-sm font-medium"
                                errorMessage={tableData?.formErrors?.tableName}
                            />
                        </div>
                    )}
                    <div className="col-6">
                        <CustomDropDown
                            extraClassName={"w-full"}
                            value={tableData.guestCount}
                            options={
                                tableData.tableType === "rect"
                                    ? [{ value: 4 }, { value: 5 }, { value: 6 }, { value: 7 }, { value: 8 }, { value: 9 }, { value: 10 }]
                                    : tableData.tableType === "rectOneSide" || tableData.tableType === "reactChair"
                                    ? [{ value: 4 }, { value: 5 }, , { value: 6 }]
                                    : [{ value: 10 }]
                            }
                            // label={`No. of Chairs (4~${tableData.tableType === "rect" ? 10 : 8})`}
                            label={`No. of Chairs`}
                            optionLabel="value"
                            name="guestCount"
                            placeholder="No. of chairs"
                            onChange={handleChange}
                        />
                    </div>
                    {tableData?.guests?.map((item, index) => (
                        <div className="col-6">
                            <CustomDropDown
                                extraClassName={"w-full"}
                                value={item.value}
                                options={guest}
                                label={`Chair ${index + 1}`}
                                optionLabel="name"
                                name="tableGuest"
                                placeholder="Select Guest"
                                onChange={(e) => handleTableGuest({ name: e.name, value: e.value, e, index })}
                            />
                        </div>
                    ))}

                    {/* <div className="col-12">
            <MultiSelect
              value={tableGuests}
              onChange={(e) => setTableGuests(e.value)}
              options={guest.map((guest) =>
                addedGuest.includes(guest.value)
                  ? { ...guest, disabled: true }
                  : guest
              )}
              maxSelectedLabels={tableData.guestCount}
              selectionLimit={tableData.guestCount}
              optionLabel="name"
              display="chip"
              placeholder="Select Guest for this Table"
              className="w-full"
            />
          </div> */}
                    <div className="col p-3">
                        <ButtonComponent label="Add" onClick={typeof visible === "number" ? handleEditTableSave : handleAddTable} />
                    </div>
                </div>
            </Dialog>
        );
    };

    useEffect(() => {
        dispatch(guestAction({}));
        dispatch(seatingChartAction(setChartData));
    }, [dispatch]);

    useEffect(() => {
        let list = [];
        guestList?.forEach((guest) => {
            list.push({ value: guest._id, name: guest.name });
            guest.familyMemberList?.forEach((member) => list.push({ value: member._id, name: member.name }));
        });
        setGuests(list);
    }, [guestList]);

    useEffect(() => {
        if (isWeddingSeating) setChartData((prev) => ({ ...prev, weddingSeatingPlanner: data }));
        else setChartData((prev) => ({ ...prev, receptionSeatingPlaner: data }));
    }, [data, isWeddingSeating]);

    useEffect(() => {
        if (isWeddingSeating && chartData?.weddingSeatingPlanner?.length) {
            setData(chartData.weddingSeatingPlanner);
        } else if (!isWeddingSeating && chartData?.receptionSeatingPlaner?.length) {
            setData(chartData.receptionSeatingPlaner);
        } else return;
    }, [isWeddingSeating, chartData]);

    const stageRef = useRef();
    function downloadURI(uri, name) {
        var link = document.createElement("a");
        link.download = name;
        link.href = uri;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
    const downloadPdf = async () => {
        if (stageRef.current) {
            const container = document.getElementById("container");

            container.style.overflow = "visible !important";
            const canvas = await html2canvas(container);
            const image = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream");
            const link = document.createElement("a");
            link.download = "my-image.png";
            link.href = image;
            link.click();
        }
    };
    // const downloadPdf = () => {
    //     if (stageRef.current) {
    //         const uri = stageRef.current.toDataURL({ x: 0, y: 100, width: 5000, height: 3000 });
    //         console.log(stageRef.current);
    //         downloadURI(uri, "stage.png");
    //     }
    //     setIsDownloading(false);
    // };
    useEffect(() => {
        if (isDownloading) {
            setTimeout(() => {
                downloadPdf();
            }, 20);
        }
    }, [isDownloading]);

    const URLImage = ({ src, onClick, x, y }) => {
        const [image, setImage] = useState(null);
        const imageNode = useRef(null);

        useEffect(() => {
            const img = new window.Image();
            img.src = src;
            img.addEventListener("load", () => {
                setImage(img);
            });

            return () => {
                img.removeEventListener("load", () => {
                    setImage(img);
                });
            };
        }, [src]);

        return <Image x={x} y={y} image={image} ref={imageNode} onClick={onClick} style={{ width: "20px" }} />;
    };

    const canvasWidth = 3000;
    const canvasHeight = 2000;
    const viewWidth = 1500;
    const viewHeight = 1000;
    const [scrollPos, setScrollPos] = useState({ x: 0, y: 0 });
    const handleScroll = (e) => {
        const { scrollLeft, scrollTop } = e.target;
        setScrollPos({ x: scrollLeft, y: scrollTop });
    };

    const downlo = async () => {
        const zip = new JSZip();
        const canvas = document.createElement("canvas");
        canvas.style.backgroundColor = "#ff8800";
        canvas.width = canvasWidth;
        canvas.height = canvasHeight;

        const ctx = canvas.getContext("2d");
        ctx.fillStyle = "white";
        ctx.fillRect(0, 0, canvas.width, canvas.height);

        const can = document.getElementsByTagName("canvas");

        console.log(can);

        // Use a regular for-loop
        for (let i = 0; i < can.length; i++) {
            ctx.drawImage(can[i], 0, 0);
        }

        let imgDataUrl = canvas.toDataURL("image/png");
        imgDataUrl = imgDataUrl.split(",");
        imgDataUrl = imgDataUrl[1];
        zip.file(`Seating-Plan.png`, imgDataUrl, { base64: true });
        const content = await zip.generateAsync({ type: "blob" });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(content);
        link.download = `SeatingChart.zip`;
        link.click();
    };

    const timerRef = useRef(null);

    const [hId, setHid] = useState(null);

    const onShowControls = (name) => {
        if (timerRef.current) {
            clearTimeout(timerRef.current);
        }
        setHid(name);
    };

    const onHideControls = () => {
        timerRef.current = setTimeout(() => {
            setHid(null);
        }, 200);
    };

    const onAddOrDragTable = (tableType, e) => {
        if (e) {
            tableRef.current = e;
        } else {
            setVisible(true);
        }
        setTableData((prev) => ({
            ...prev,
            tableType,
        }));
    };

    return (
        <div className="profile-container mt-1 md:mt-5 mb-7">
            <ConfirmDialog />
            <ContainerBox>
                <h1 className="merriweather text-2xl text-dark-gray font-bold ">Seating Chart</h1>
                <div className="flex flex-wrap justify-content-start">
                    <div className="col-12 md:col-8 seating-grid relative m-0" style={{ overflow: "auto" }}>
                        <div
                            id="container"
                            style={{ width: viewWidth, height: viewHeight }}
                            onScroll={handleScroll}
                            onDrop={(e) => {
                                // Get the position relative to the grid container
                                e.preventDefault();
                                // register event position
                                // stageRef.current.setPointersPositions(e);
                                setVisible(true);
                                setTableData((prev) => ({
                                    ...prev,
                                    tableType: tableData.tableType,
                                    x: e.clientX / 2,
                                    y: e.clientY / 2,
                                }));
                            }}
                            onDragOver={(e) => e.preventDefault()}
                        >
                            {/* {isEdited ? (
                            <ButtonComponent label="Save" className={"absolute top-2 right-0 mr-3 z-5 cursor-pointer shadow-2"} onClick={saveSeatingPlanner} />
                        ) : (
                            ""
                        )} */}
                            <div>
                                <Stage
                                    width={canvasWidth}
                                    height={canvasHeight}
                                    offsetX={scrollPos.x}
                                    offsetY={scrollPos.y}
                                    id="seatingGrid"
                                    ref={stageRef}
                                    className=""
                                    container="container"
                                >
                                    {data.map((table, index) => {
                                        return (
                                            <Layer>
                                                <Group
                                                    x={table.x}
                                                    y={table.y}
                                                    draggable
                                                    // onClick={() => handleDeleteTable(index)}
                                                    onDragStart={() => {
                                                        setData(
                                                            data.map((item, i) =>
                                                                i === index
                                                                    ? {
                                                                          ...item,
                                                                          isDragging: true,
                                                                      }
                                                                    : item
                                                            )
                                                        );
                                                    }}
                                                    dragBoundFunc={(pos) => {
                                                        let newX = pos.x;
                                                        let newY = pos.y;

                                                        // Calculate the boundaries for the x and y coordinates
                                                        const maxX = canvasWidth - table.width;
                                                        const maxY = canvasHeight - table.height;

                                                        // Check if newX is within the allowed boundaries
                                                        if (newX < 0) newX = 0;
                                                        if (newX > maxX) newX = maxX;

                                                        // Check if newY is within the allowed boundaries
                                                        if (newY < 0) newY = 0;
                                                        if (newY > maxY) newY = maxY;

                                                        return {
                                                            x: newX,
                                                            y: newY,
                                                        };
                                                    }}
                                                    onDragEnd={(e) => {
                                                        let newX = e.target.x();
                                                        let newY = e.target.y();
                                                        const updatedData = data.map((item, i) =>
                                                            i === index
                                                                ? {
                                                                      ...item,
                                                                      isDragging: false,
                                                                      x: newX,
                                                                      y: newY,
                                                                  }
                                                                : item
                                                        );
                                                        setData(updatedData);
                                                        const newChartData = { ...chartData, weddingSeatingPlanner: updatedData };
                                                        setChartData(newChartData);
                                                        dispatch(updateSeatingChartAction(newChartData, setChartData));
                                                        setIsEdited(true);
                                                    }}
                                                >
                                                    {table.type === "rect" ? (
                                                        <Group onMouseEnter={() => onShowControls(table.name + index)} onMouseLeave={onHideControls}>
                                                            <Rect width={table.width} height={table.height} fill="#ccc" stroke={"#000"} y={30} x={30} />
                                                            {table.chairs.map((chair, i) => (
                                                                <>
                                                                    <Group
                                                                        rotation={
                                                                            chair.face === "right"
                                                                                ? 90
                                                                                : chair.face === "left"
                                                                                ? 270
                                                                                : chair.face === "up"
                                                                                ? 180
                                                                                : 0
                                                                        }
                                                                        x={
                                                                            chair.face === "right"
                                                                                ? chair.x + 30
                                                                                : chair.face === "left"
                                                                                ? chair.x
                                                                                : chair.face === "up"
                                                                                ? chair.x + 30
                                                                                : chair.x
                                                                        }
                                                                        y={
                                                                            chair.face === "right"
                                                                                ? chair.y
                                                                                : chair.face === "left"
                                                                                ? chair.y + 30
                                                                                : chair.face === "up"
                                                                                ? chair.y + 30
                                                                                : chair.y
                                                                        }
                                                                    >
                                                                        <Rect width={30} height={25} fill="#008dbb" />
                                                                        <Text
                                                                            width={30}
                                                                            text={i + 1}
                                                                            align="center"
                                                                            fill="black"
                                                                            rotation={
                                                                                chair.face === "right"
                                                                                    ? -90
                                                                                    : chair.face === "left"
                                                                                    ? 90
                                                                                    : chair.face === "up"
                                                                                    ? 180
                                                                                    : 0
                                                                            }
                                                                            x={
                                                                                chair.face === "right"
                                                                                    ? 0 + 10
                                                                                    : chair.face === "left"
                                                                                    ? 0 + 20
                                                                                    : chair.face === "up"
                                                                                    ? 0 + 30
                                                                                    : 0
                                                                            }
                                                                            y={
                                                                                chair.face === "right"
                                                                                    ? 0 + 30
                                                                                    : chair.face === "left"
                                                                                    ? 0
                                                                                    : chair.face === "up"
                                                                                    ? 0 + 20
                                                                                    : 0 + 10
                                                                            }
                                                                        />
                                                                        <Rect width={30} height={5} fill="#008dbb" stroke="#008dbb" lineJoin="bevel" />
                                                                    </Group>
                                                                </>
                                                            ))}
                                                            <Text text={table.name} align="center" width={table.width + 60} y={70} wrap="word" fill="#008dbb" />
                                                            {hId === table.name + index && (
                                                                <>
                                                                    <URLImage
                                                                        src={editIcon}
                                                                        onClick={() => handleEditTable(index, table)}
                                                                        x={table.width + 90}
                                                                        y={27}
                                                                    />
                                                                    <URLImage
                                                                        src={deleteIcon}
                                                                        onClick={() => handleDeleteTable(index)}
                                                                        x={table.width + 90}
                                                                        y={80}
                                                                    />
                                                                </>
                                                            )}
                                                        </Group>
                                                    ) : table.type === "rectOneSide" ? (
                                                        <Group onMouseEnter={() => onShowControls(table.name + index)} onMouseLeave={onHideControls}>
                                                            <Rect width={table.width} height={table.height} fill="#ccc" stroke={"#000"} y={30} x={30} />
                                                            {table.chairs.map((chair, i) => (
                                                                <>
                                                                    <Group
                                                                        rotation={
                                                                            chair.face === "right"
                                                                                ? 90
                                                                                : chair.face === "left"
                                                                                ? 270
                                                                                : chair.face === "up"
                                                                                ? 180
                                                                                : 0
                                                                        }
                                                                        x={
                                                                            chair.face === "right"
                                                                                ? chair.x + 30
                                                                                : chair.face === "left"
                                                                                ? chair.x
                                                                                : chair.face === "up"
                                                                                ? chair.x + 30
                                                                                : chair.x
                                                                        }
                                                                        y={
                                                                            chair.face === "right"
                                                                                ? chair.y
                                                                                : chair.face === "left"
                                                                                ? chair.y + 30
                                                                                : chair.face === "up"
                                                                                ? chair.y + 30
                                                                                : chair.y
                                                                        }
                                                                    >
                                                                        <Rect width={30} height={25} fill="#008dbb" />
                                                                        <Text
                                                                            width={30}
                                                                            text={i + 1}
                                                                            align="center"
                                                                            fill="black"
                                                                            rotation={
                                                                                chair.face === "right"
                                                                                    ? -90
                                                                                    : chair.face === "left"
                                                                                    ? 90
                                                                                    : chair.face === "up"
                                                                                    ? 180
                                                                                    : 0
                                                                            }
                                                                            x={
                                                                                chair.face === "right"
                                                                                    ? 0 + 10
                                                                                    : chair.face === "left"
                                                                                    ? 0 + 20
                                                                                    : chair.face === "up"
                                                                                    ? 0 + 30
                                                                                    : 0
                                                                            }
                                                                            y={
                                                                                chair.face === "right"
                                                                                    ? 0 + 30
                                                                                    : chair.face === "left"
                                                                                    ? 0
                                                                                    : chair.face === "up"
                                                                                    ? 0 + 20
                                                                                    : 0 + 10
                                                                            }
                                                                        />
                                                                        <Rect width={30} height={5} fill="#008dbb" stroke="#008dbb" lineJoin="bevel" />
                                                                    </Group>
                                                                </>
                                                            ))}
                                                            <Text text={table.name} align="center" width={table.width + 60} y={70} wrap="word" fill="#008dbb" />
                                                            {hId === table.name + index && (
                                                                <>
                                                                    <URLImage
                                                                        src={editIcon}
                                                                        onClick={() => handleEditTable(index, table)}
                                                                        x={table.width + 90}
                                                                        y={27}
                                                                    />
                                                                    <URLImage
                                                                        src={deleteIcon}
                                                                        onClick={() => handleDeleteTable(index)}
                                                                        x={table.width + 90}
                                                                        y={80}
                                                                    />
                                                                </>
                                                            )}
                                                        </Group>
                                                    ) : table.type === "reactChair" ? (
                                                        <Group onMouseEnter={() => onShowControls(index)} onMouseLeave={onHideControls}>
                                                            {table.chairs.map((chair, i) => (
                                                                <>
                                                                    <Group
                                                                        rotation={
                                                                            chair.face === "right"
                                                                                ? 90
                                                                                : chair.face === "left"
                                                                                ? 270
                                                                                : chair.face === "up"
                                                                                ? 180
                                                                                : 0
                                                                        }
                                                                        x={
                                                                            chair.face === "right"
                                                                                ? chair.x + 30
                                                                                : chair.face === "left"
                                                                                ? chair.x
                                                                                : chair.face === "up"
                                                                                ? chair.x + 30
                                                                                : chair.x
                                                                        }
                                                                        y={
                                                                            chair.face === "right"
                                                                                ? chair.y
                                                                                : chair.face === "left"
                                                                                ? chair.y + 30
                                                                                : chair.face === "up"
                                                                                ? chair.y + 30
                                                                                : chair.y
                                                                        }
                                                                    >
                                                                        <Rect width={30} height={25} fill="#008dbb" />
                                                                        <Text
                                                                            width={30}
                                                                            text={i + 1}
                                                                            align="center"
                                                                            fill="black"
                                                                            rotation={
                                                                                chair.face === "right"
                                                                                    ? -90
                                                                                    : chair.face === "left"
                                                                                    ? 90
                                                                                    : chair.face === "up"
                                                                                    ? 180
                                                                                    : 0
                                                                            }
                                                                            x={
                                                                                chair.face === "right"
                                                                                    ? 0 + 10
                                                                                    : chair.face === "left"
                                                                                    ? 0 + 20
                                                                                    : chair.face === "up"
                                                                                    ? 0 + 30
                                                                                    : 0
                                                                            }
                                                                            y={
                                                                                chair.face === "right"
                                                                                    ? 0 + 30
                                                                                    : chair.face === "left"
                                                                                    ? 0
                                                                                    : chair.face === "up"
                                                                                    ? 0 + 20
                                                                                    : 0 + 10
                                                                            }
                                                                        />
                                                                        <Rect width={30} height={5} fill="#008dbb" stroke="#008dbb" lineJoin="bevel" />
                                                                    </Group>
                                                                </>
                                                            ))}
                                                            {hId === index && (
                                                                <>
                                                                    <URLImage
                                                                        src={editIcon}
                                                                        onClick={() => handleEditTable(index, table)}
                                                                        x={table.width + 70}
                                                                        y={5}
                                                                    />
                                                                    <URLImage
                                                                        src={deleteIcon}
                                                                        onClick={() => handleDeleteTable(index)}
                                                                        x={table.width + 70}
                                                                        y={60}
                                                                    />
                                                                </>
                                                            )}
                                                        </Group>
                                                    ) : (
                                                        <Group onMouseEnter={() => onShowControls(table.name + index)} onMouseLeave={onHideControls}>
                                                            <Circle stroke="black" x={table.xAxis} y={table.yAxis} radius={60} fill="#ccc" />
                                                            {table.chairs.map((chair, i) => (
                                                                <>
                                                                    <Group rotation={chair.r} x={chair.x} y={chair.y}>
                                                                        <Rect width={30} height={25} fill="#008dbb" />
                                                                        <Rect width={30} height={5} fill="#008dbb" stroke="#008dbb" lineJoin="bevel" />
                                                                    </Group>
                                                                    <Text width={30} text={i + 1} align="center" fill="black" x={chair.tx} y={chair.ty} />
                                                                </>
                                                            ))}
                                                            <Group>
                                                                <Rect width={30} height={25} />
                                                            </Group>
                                                            <Text text={table.name} align="center" width={table.width + 30} y={85} fill="#008dbb" />
                                                            {hId === table.name + index && (
                                                                <>
                                                                    <URLImage
                                                                        src={editIcon}
                                                                        onClick={() => handleEditTable(index, table)}
                                                                        x={table.width + 60}
                                                                        y={40}
                                                                    />
                                                                    <URLImage
                                                                        src={deleteIcon}
                                                                        onClick={() => handleDeleteTable(index)}
                                                                        x={table.width + 60}
                                                                        y={90}
                                                                    />
                                                                </>
                                                            )}
                                                        </Group>
                                                    )}
                                                </Group>
                                            </Layer>
                                        );
                                    })}
                                </Stage>
                            </div>
                        </div>{" "}
                    </div>
                    <div className="col-12 md:col-4 p-0 shadow-1 m-0">
                        <div style={{ background: "rgb(245, 245, 245)" }} className="p-3">
                            <h1 className="merriweather text-2xl mt-0 text-dark-gray font-bold ">Seating Plan</h1>
                        </div>
                        <div className="p-3">
                            <h3 className="merriweather text-sm mt-0 text-dark-gray font-bold">Add Table</h3>
                            <div
                                style={{ background: "rgb(245, 245, 245)" }}
                                className="p-5 flex-wrap flex align-items-center gap-6 border-round-xl justify-content-center"
                            >
                                <div className="w-3">
                                    <img
                                        src={cicleTableImg}
                                        alt="Circle Table"
                                        style={{ width: "100%" }}
                                        onClick={() => onAddOrDragTable("circle")}
                                        className="cursor-pointer"
                                        draggable="true"
                                        onDragStart={(e) => onAddOrDragTable("circle", e)}
                                    />
                                </div>
                                <div className="w-3">
                                    <img
                                        src={oneSideRectangeImg}
                                        alt="rectOneSide"
                                        style={{ width: "100%" }}
                                        onClick={() => onAddOrDragTable("rectOneSide")}
                                        className="cursor-move"
                                        draggable="true"
                                        onDragStart={(e) => onAddOrDragTable("rectOneSide", e)}
                                    />
                                </div>

                                <div className="w-3">
                                    <img
                                        src={chairRow}
                                        alt="rect"
                                        style={{ width: "100%" }}
                                        onClick={() => onAddOrDragTable("reactChair")}
                                        className="cursor-move"
                                        draggable="true"
                                        onDragStart={(e) => onAddOrDragTable("reactChair", e)}
                                    />
                                </div>
                                <div className="w-3">
                                    <img
                                        src={rectTableImg}
                                        alt="rect"
                                        style={{ width: "100%" }}
                                        onClick={() => onAddOrDragTable("rect")}
                                        className="cursor-move"
                                        draggable="true"
                                        onDragStart={(e) => onAddOrDragTable("rect", e)}
                                    />
                                </div>
                            </div>
                            <h3 className="merriweather text-sm  text-dark-gray font-bold">Guest List</h3>
                            <div className="h-20rem overflow-auto">
                                <Accordion activeIndex={0} className="sitingplan-accordion">
                                    {data?.map((table, index) => (
                                        <AccordionTab header={table.name}>
                                            <div className="grid">
                                                {table?.guests?.map((item, index) => (
                                                    <>
                                                        <div className="col-6">
                                                            <img src={PersonIcon} alt="" className="mr-2" />
                                                            {item.name !== "" ? item.name : "Not Assigned"}
                                                        </div>
                                                    </>
                                                ))}
                                            </div>
                                        </AccordionTab>
                                    ))}
                                </Accordion>
                            </div>
                            <div>
                                <ButtonComponent
                                    label="Add Guest"
                                    className="w-full mb-3"
                                    onClick={
                                        () => setOpenGuestDialog(true)
                                        // isEdited ? (e) => confirmAddGuest() : () => navigate("/host/guest")
                                    }
                                />
                                {isDownloading ? (
                                    <ButtonOutlined
                                        label={"Your PDF is being downloaded. This may take a few moments. Please wait."}
                                        className="w-full"
                                        loading={isDownloading}
                                    />
                                ) : (
                                    <ButtonOutlined label={"Download"} className="w-full" onClick={downlo} />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                {openGuestDialog && <AddGuests visible={openGuestDialog} setVisible={setOpenGuestDialog} />}
                {deleteSitingTable && (
                    <Dialog
                        header="Header"
                        visible={deleteSitingTable}
                        style={{ width: "500px" }}
                        onHide={() => {
                            if (!deleteSitingTable) return;
                            setDeleteSitingTable(null);
                            setIsEdited(false);
                        }}
                        showHeader={false}
                    >
                        <img src={DeleteIcon1} alt="" className="m-auto block mt-4" width={70} />
                        <p className="m-0 text-lg text-center font-normal text-dark-gray ">Are you sure you want to delete?</p>
                        <div className="flex gap-3 mt-4">
                            <ButtonComponent
                                label="Cancel"
                                className="w-6 btn-cancel"
                                onClick={() => {
                                    setDeleteSitingTable(null);
                                    setIsEdited(false);
                                }}
                            />
                            <ButtonComponent label="Ok" className="bg-main w-6" onClick={handleDeleteTableAction} />
                        </div>
                    </Dialog>
                )}
                {TableDialog(visible)}
            </ContainerBox>
        </div>
    );
}
