import { isValidPhoneNumber } from "react-phone-number-input";
import { equal, greaterThan, isWebsiteUrl, length } from "./javascript";
import { firstLetterToUppercase, stringValidation, emailValidation, regularString, number } from "./regex";

export const allValidations = (name, value, state, ignore = []) => {
    const formErrors = { ...state.formErrors };
    if (ignore.includes(name)) {
        if (formErrors[name]) formErrors[name] = "";
        return formErrors;
    }
    switch (name) {
        case "email":
            if (equal(length(value))) {
                formErrors[name] = `${firstLetterToUppercase(name)} is required!`;
            } else if (!emailValidation(value)) {
                formErrors[name] = `Please enter valid email!`;
            } else {
                formErrors[name] = "";
            }
            break;

        case "categoryName":
            if (equal(length(value))) {
                formErrors[name] = `${firstLetterToUppercase(name)} is required!`;
            } else if (!stringValidation(value)) {
                formErrors[name] = `Unnecessary space in word!`;
            } else {
                formErrors[name] = "";
            }
            break;
        case "tableName":
            if (equal(length(value))) {
                formErrors[name] = `${firstLetterToUppercase(name)} is required!`;
            } else if (!regularString(value)) {
                formErrors[name] = `Unnecessary space in word!`;
            } else {
                formErrors[name] = "";
            }
            break;

        case "color":
        case "firstName":

        case "relativeFrom":
        case "invitedTo":
        case "lastName":
        case "code":
        case "status":
        case "category":
        case "subCategory":
        case "fullName":
        case "name":
            if (equal(length(value))) {
                formErrors[name] = `${firstLetterToUppercase(name)} is required!`;
                // } else if (!stringValidation(value)) {
                //   formErrors[name] = `Unnecessary space or special chracter in word!`;
            } else {
                formErrors[name] = "";
            }
            break;
        case "expertise":
        case "names":
        case "sizes":
        case "processName":
        case "labels":
        case "label":
            if (!value.length) {
                formErrors[name] = `${firstLetterToUppercase(name)} are required!`;
            } else {
                formErrors[name] = "";
            }
            break;
        case "website":
        case "facebook":
        case "instagram":
        case "twitter":
            if (!isWebsiteUrl(value)) {
                formErrors[name] = `${firstLetterToUppercase(name)} is not a valid url!`;
            } else {
                formErrors[name] = "";
            }
            break;
        case "qty":
        case "noOflabels":
        case "capacityPerHour":
            if (!value) {
                if (name === "noOflabels") {
                    formErrors[name] = `No of labels is required!`;
                } else {
                    formErrors[name] = `${firstLetterToUppercase(name)} is required!`;
                }
            } else if (!number(value)) {
                if (name === "noOflabels") {
                    formErrors[name] = `No of labels should be number!`;
                } else {
                    formErrors[name] = `${firstLetterToUppercase(name)} should be number!`;
                }
            } else {
                formErrors[name] = "";
            }
            break;
        case "mobile":
        case "phoneNumber":
            if (!value) {
                formErrors[name] = `${firstLetterToUppercase(name)} is required!`;
            } else if (!isValidPhoneNumber(value)) {
                formErrors[name] = "Please check the number and try again!";
            }
            break;
        case "event/Ceremony":
        case "comment":
        case "password":
        case "required":
            if (!value) {
                formErrors[name] = `${firstLetterToUppercase(name)} is required!`;
            }
            break;
        case "otp":
            if (!value) {
                formErrors[name] = `${firstLetterToUppercase(name)} is required!`;
            }
            break;
        case "addressLine1":
            if (!value) {
                formErrors[name] = `${firstLetterToUppercase(name)} is required!`;
            } else {
                formErrors[name] = "";
            }
            break;
        case "city":
            if (!value) {
                formErrors[name] = "Please Enter Valid Address";
            } else {
                formErrors[name] = "";
            }
            break;
        case "vendorType":
            if (!value) {
                formErrors[name] = `${firstLetterToUppercase(name)} is required!`;
            } else {
                formErrors[name] = "";
            }
            break;
        case "business name":
        case "vendor type":
        case "postal code":
            if (!value) {
                const spaceIndex = name.indexOf(" ");
                if (spaceIndex !== -1) {
                    const firstWord = name.slice(0, spaceIndex);
                    const secondWord = name.slice(spaceIndex + 1);
                    formErrors[name] = `${firstLetterToUppercase(firstWord)} ${firstLetterToUppercase(secondWord)} is required!`;
                } else {
                    // Handle scenario where only one word is present
                    formErrors[name] = `${firstLetterToUppercase(name)} is required!`;
                }
            }
            break;

        default:
            break;
    }
    return formErrors;
};

export const matchPasswordsValidation = (name, password, confirmPassword) => {
    const formErrors = {};
    if (!confirmPassword) {
        formErrors[name] = "Confirm Password is required!";
        return formErrors;
    }
    if (password.length < 8) {
        formErrors[name] = "Password should contain atleast 8 charaters!";
        return formErrors;
    }
    if (password !== confirmPassword) {
        formErrors[name] = "New password and confirm password do not match";
        return formErrors;
    }
    return (formErrors[name] = "");
};
