import React from "react";
import { IndexLayout } from "../../layout";
import { ContainerBox } from "../../shared/ContainerBox";
import AboutImage from "../../assets/images/aboutImage.jpg";
import ImageTwo from "../../assets/images/about2.jpg";
import WorkContent from "../how-its-work/WorkContent";
import ButtonComponent from "../../components/buttons/button";
import { useNavigate } from "react-router-dom";
export default function About() {
  const { AboutContent } = WorkContent();
  const navigate = useNavigate();
  let data = true;
  return (
    <>
      <>
        <div className="about-top-content text-white w-full mb-5 md:mb-7 ">
          <div className="align-items-center h-full flex justify-content-center">
            <div className="text-center ">
              <h1
                className="merriweather font-normal  "
                style={{ fontSize: "44px" }}
              >
                About
              </h1>
              <p className="text-base font-normal ">
                At PlanTheKnot, our goal is to have a centralised platform where
                couples can find anything
                <br /> they need to plan their wedding. We aim to make the whole
                process easier and stress-free as possible.
              </p>
            </div>
          </div>
        </div>
        <ContainerBox>
          <div className="mb-5">
            <h1 className="font-24 mt-0 merriweather text-dark-gray mb-3">
              Our Journey
            </h1>
            <p
              className="text-base font-normal mb-5"
              style={{ color: "#626363" }}
            >
              PlantheKnot was created from the shared challenges that the
              Founder, Ramgith Jagajith, and co-founder Sonia Justinsuthakaran
              faced while planning their own wedding. Being south Asians they
              realised that looking for vendors on Instagram and other social
              network platforms were becoming increasingly difficult as most of
              them lived abroad or were not catering for the unique needs of a
              south Asian wedding.
            </p>
            <p
              className="text-base font-normal mb-5"
              style={{ color: "#626363" }}
            >
              They realised a centralised hub where couples could seamlessly
              discover thousands of venues and wedding suppliers would simplify
              the planning journey. It took them a year to turn this dream into
              a reality.
            </p>
            <p
              className="text-base font-normal mb-5"
              style={{ color: "#626363" }}
            >
              And so, PlanTheKnot was woven into existence!
            </p>
            <div className="about-images">
              <img src={AboutImage} alt="" className="w-full" />
            </div>
          </div>

          <div className="grid pt-6">
            {AboutContent.map((item, index) => {
              return (
                <div className="col-12 md:col-4 text-center">
                  <div className="mb-5">
                    <img src={item.img} width={48} height={48} alt="" />
                    <div className="flex justify-content-center">
                      <p className="lg:w-8 text-center">{item.title}</p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="about-images">
            <img src={ImageTwo} alt="" className="w-full" />
          </div>
          <p
            className="text-base font-normal mb-5 text-center"
            style={{ color: "#626363" }}
          >
            Now that we've introduced ourselves, please come and join us!
          </p>
          <div className="flex justify-content-center">
            <span>
              <ButtonComponent
                label="I'm getting married"
                className="bg-purple-800 border-0 font-bold"
                onClick={() => navigate("/signup")}
              />
            </span>
            <span className="ml-3">
              <ButtonComponent
                label="I'm a wedding Professional"
                className="bg-purple-800 border-0 font-bold"
                onClick={() => navigate("/signup", { state: { data } })}
              />
            </span>
          </div>
        </ContainerBox>
      </>
    </>
  );
}
