import WeedingSupplierImg from "../../assets/images/image 3.png";
import WeddingVenuesImg2 from "../../assets/images/wedding venues 2.png";
import WeddingVenuesImg3 from "../../assets/images/wedding venues 3.png";
import WeddingVenuesImg4 from "../../assets/images/wedding venues 4.png";
import WeddingVenuesImg5 from "../../assets/images/wedding venues 5.png";
import DecorsIcon from "../../assets/icons/decors.png";
import CaterersIcon from "../../assets/icons/caterers.png";
import MakeupIcon from "../../assets/icons/hair mekup.png";
import MusicIcon from "../../assets/icons/DJ music.png";
import GiftIcon from "../../assets/icons/favors gift.png";
import DressessIcon from "../../assets/icons/dressess.png";
import CarIcon from "../../assets/icons/big car.png";
import MemberCardIcon from "../../assets/icons/members card.png";
import OtherIcon from "../../assets/icons/other.png";
import { useDispatch, useSelector } from "react-redux";
import {
  showAllTentativeAction,
  filterVendors,
} from "../../redux/actions/BusinessProfile";
import { useEffect, useCallback, useState } from "react";
import _debounce from "lodash/debounce";
import { useNavigate, useParams } from "react-router-dom";
import { budgetAction } from "../../redux/actions/BudgetAction";
const VendorContentData = () => {
  const [vendorId, setVendorId] = useState();
  const [activeIndex, setActiveIndex] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  useEffect(() => {
    if (!id) {
      dispatch(showAllTentativeAction());
    }
  }, [id]);
  const showAllTentativeData = useSelector(
    (state) => state?.showAllTentative?.showAllTentativeProfile
  );
  const handleDebounceFn = (payload) => {
    dispatch(filterVendors(payload));
  };
  const debounceFn = useCallback(_debounce(handleDebounceFn, 1000), []);
  const handleSearchChange = (e) => {
    debounceFn({
      search: e?.target?.value,
      vendorType: vendorId,
    });
  };
  const serachVendorData = useSelector(
    (state) => state.vendorFilter.vendorFilterProfile
  );
  const handleTentativeData = (id) => {
    dispatch(
      showAllTentativeAction(id, () =>
        navigate(`/host/tentatives-vendors/${id}`)
      )
    );
  };
  useEffect(() => {
    let params;
    if (activeIndex === 0) {
      params = {
        eventType: "Wedding",
      };
    } else {
      params = {
        eventType: "Reception",
      };
    }
    dispatch(budgetAction(params));
  }, [activeIndex]);
  const BudgetVendorData = useSelector(
    (state) => state?.budgetList?.budgetListProfile
  );
  const VendorSelect = [
    // {
    //   img: WeddingVenuIcon,
    //   title: "Wedding Venue",
    // },
    {
      img: DecorsIcon,
      title: "Decors",
    },
    {
      img: CaterersIcon,
      title: "Caterers",
    },
    {
      img: MakeupIcon,
      title: "Hair and Makeup",
    },
    {
      img: MusicIcon,
      title: "DJ & music",
    },
    {
      img: GiftIcon,
      title: "Favors & Gift",
    },
    {
      img: DressessIcon,
      title: "Dressess",
    },
    {
      img: CarIcon,
      title: "Car Hire",
    },
    {
      img: MemberCardIcon,
      title: " Religious Members",
    },
    {
      img: OtherIcon,
      title: "Others",
    },
  ];

  const WeddingVenuesContent = [
    {
      img: WeedingSupplierImg,
      rating: "5",
      title: "The Aesthetic",
      location: "Bekasi, Jawa Barat",
      price: "£1200- £20000",
      person: "50-150",
    },
    {
      img: WeddingVenuesImg2,
      rating: "5",
      title: "The Aesthetic",
      location: "Bekasi, Jawa Barat",
      price: "£1200- £20000",
      person: "50-150",
    },
    {
      img: WeddingVenuesImg3,
      rating: "5",
      title: "The Aesthetic",
      location: "Bekasi, Jawa Barat",
      price: "£1200- £20000",
      person: "50-150",
    },
    {
      img: WeddingVenuesImg4,
      rating: "5",
      title: "The Aesthetic",
      location: "Bekasi, Jawa Barat",
      price: "£1200- £20000",
      person: "50-150",
    },
    {
      img: WeddingVenuesImg5,
      rating: "5",
      title: "The Aesthetic",
      location: "Bekasi, Jawa Barat",
      price: "£1200- £20000",
      person: "50-150",
    },
    {
      img: WeddingVenuesImg3,
      rating: "5",
      title: "The Aesthetic",
      location: "Bekasi, Jawa Barat",
      price: "£1200- £20000",
      person: "50-150",
    },
    {
      img: WeedingSupplierImg,
      rating: "5",
      title: "The Aesthetic",
      location: "Bekasi, Jawa Barat",
      price: "£1200- £20000",
      person: "50-150",
    },
    {
      img: WeddingVenuesImg2,
      rating: "5",
      title: "The Aesthetic",
      location: "Bekasi, Jawa Barat",
      price: "£1200- £20000",
      person: "50-150",
    },
    {
      img: WeddingVenuesImg4,
      rating: "5",
      title: "The Aesthetic",
      location: "Bekasi, Jawa Barat",
      price: "£1200- £20000",
      person: "50-150",
    },
    {
      img: WeedingSupplierImg,
      rating: "5",
      title: "The Aesthetic",
      location: "Bekasi, Jawa Barat",
      price: "£1200- £20000",
      person: "50-150",
    },
  ];

  const VendorSelectContent = [
    {
      img: WeedingSupplierImg,
      rating: "5",
      title: "The Aesthetic",
      location: "Bekasi, Jawa Barat",
      price: "£1200- £20000",
      person: "50-150",
    },
    {
      img: WeddingVenuesImg2,
      rating: "5",
      title: "The Aesthetic",
      location: "Bekasi, Jawa Barat",
      price: "£1200- £20000",
      person: "50-150",
    },
    {
      img: WeddingVenuesImg3,
      rating: "5",
      title: "The Aesthetic",
      location: "Bekasi, Jawa Barat",
      price: "£1200- £20000",
      person: "50-150",
    },
  ];

  return {
    WeddingVenuesContent,
    handleSearchChange,
    VendorSelect,
    VendorSelectContent,
    showAllTentativeData,
    vendorId,
    setVendorId,
    serachVendorData,
    handleTentativeData,
    activeIndex,
    setActiveIndex,
    id,
    BudgetVendorData,
  };
};
export default VendorContentData;
