import React, { useEffect, useState } from "react";
import Topbar from "./topbar";
import { Footer } from "./Footer";
import { useSelector } from "react-redux";
import ProfileTopbar from "./ProfileTopbar";
import { checkUserRole, isAuthenticated } from "../services/authService";
import VendorTopbar from "./VendorTopbar";

export const IndexLayout = ({ children }) => {
  const userData = useSelector((state) => state?.signin?.SigninProfile);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    if (userData.token === localStorage.getItem("authToken")) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, [userData]);

  return (
    <>
      <div>
        {isAuthenticated && checkUserRole("Host") ? (
          <ProfileTopbar />
        ) : (
          <Topbar />
        )}
        {/* {userData.token && checkUserRole("Host") ? (
          <ProfileTopbar />
        ) : (
          <Topbar />
        )} */}
        {children}
      </div>
    </>
  );
};
