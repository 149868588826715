import React from "react";
import { ProfileLayout } from "../../../layout/ProfileLayout";
import { TabView, TabPanel } from "primereact/tabview";
import { ContainerBox } from "../../../shared/ContainerBox";
import ButtonComponent from "../../../components/buttons/button";
import {
  CustomCalenderInput,
  CustomDropDown,
  CustomInput,
} from "../../../components/input/AllInput";
import EditProfileContainer from "./EditProfileContainer";
import CustomProfileImage from "../../../components/customProfileImage/customProfileImage";
import { capitalizeFirstLetter } from "../../../utils/regex";

export default function EditProfile() {
  const { profileData, profileHandleChange, save, allCity } =
    EditProfileContainer();

  return (
    <>
      <div className=" mt-5 mb-7">
        <ContainerBox containerType="">
          <h1 className="merriweather text-2xl text-dark-gray font-bold mb-5 p-0 m-0">
            Edit My Profile
          </h1>
          <p className="merriweather text-lg text-dark-gray font-bold mb-5 p-0 m-0">
            Personal Details
          </p>

          <div className="grid">
            <CustomProfileImage
              title="Profile Picture"
              name="profileImage"
              value={profileData.profileImage}
              onChange={profileHandleChange}
              buttonLabel="Edit Image"
            />
          </div>

          <div
            className="  border-1 p-3 mb-5 border-round-lg"
            style={{ borderColor: "#ECECEC" }}
          >
            <CustomInput
              label="Username"
              col={6}
              name="name"
              value={capitalizeFirstLetter(profileData?.name)}
              onChange={profileHandleChange}
              placeholder="Gavano"
            ></CustomInput>
            <CustomInput
              disabled={true}
              label="Email"
              col={6}
              name="email"
              value={capitalizeFirstLetter(profileData?.email)}
              onChange={profileHandleChange}
              placeholder="user@email.com"
            ></CustomInput>
            <CustomInput
              label="Partner Name"
              col={6}
              name="partnerName"
              value={capitalizeFirstLetter(profileData?.partnerName)}
              onChange={profileHandleChange}
              placeholder="Partner Name"
            ></CustomInput>
            <CustomInput
              keyfilter="num"
              label="Phone Number"
              col={6}
              name="mobile"
              value={profileData?.mobile}
              onChange={profileHandleChange}
              placeholder={123456789}
              className="mb-2 w-full"
            ></CustomInput>
          </div>

          <p className=" merriweather text-lg font-bold  text-dark-gray mb-4">
            Wedding Details
          </p>

          <div className="wedding-details-content grid flex ">
            <div className="col-12 md:col-12 lg:col-12">
              <TabView>
                <TabPanel header="Wedding">
                  <div className="wedding-content-box">
                    <div
                      className="border-1 p-3 mb-5 border-round-md"
                      style={{ borderColor: "#ECECEC" }}
                    >
                      <div className="relative lg:w-6">
                        <CustomDropDown
                          filter
                          label="City"
                          col={12}
                          placeholder="City"
                          className="w-full"
                          name="city"
                          value={profileData?.city}
                          onChange={profileHandleChange}
                          options={allCity}
                          optionLabel="label"
                        ></CustomDropDown>
                      </div>
                      <CustomCalenderInput
                        label="Event Date"
                        col={6}
                        placeholder="DD/MM/YYYY"
                        className="w-full"
                        extraClassName="calender-picker"
                        name="weddingDate"
                        value={
                          profileData?.weddingDate
                            ? new Date(profileData?.weddingDate)
                            : null
                        }
                        onChange={profileHandleChange}
                      />
                      <CustomInput
                        label="Number of Guests"
                        col={6}
                        placeholder="450"
                        keyfilter="num"
                        name="weddingGuestNumber"
                        value={profileData?.weddingGuestNumber}
                        onChange={profileHandleChange}
                      ></CustomInput>
                    </div>
                    <ButtonComponent
                      label="Save Changes"
                      onClick={save}
                    ></ButtonComponent>
                  </div>
                </TabPanel>

                <TabPanel header="Reception">
                  <div className="Reception-content-box">
                    <div
                      className="border-1 p-3 mb-5 border-round-md"
                      style={{ borderColor: "#ECECEC" }}
                    >
                      <div className="relative lg:w-6">
                        <CustomDropDown
                          label="City"
                          col={12}
                          placeholder="City"
                          className="w-full"
                          name="city"
                          value={profileData?.city}
                          onChange={profileHandleChange}
                          options={allCity}
                          optionLabel="label"
                        ></CustomDropDown>
                      </div>
                      <CustomCalenderInput
                        label="Event Date"
                        col={6}
                        placeholder="DD/MM/YYYY"
                        className="w-full"
                        extraClassName="calender-picker"
                        name="receptionDate"
                        value={
                          profileData?.receptionDate
                            ? new Date(profileData?.receptionDate)
                            : null
                        }
                        onChange={profileHandleChange}
                      />
                      <CustomInput
                        label="Number of Guests"
                        col={6}
                        placeholder="450"
                        keyfilter="num"
                        name="receptionGuestNumber"
                        value={profileData?.receptionGuestNumber}
                        onChange={profileHandleChange}
                      ></CustomInput>
                    </div>
                    <ButtonComponent
                      label="Save Changes"
                      onClick={save}
                    ></ButtonComponent>
                  </div>
                </TabPanel>
              </TabView>
            </div>
          </div>
        </ContainerBox>
      </div>
    </>
  );
}
