import React, { useState } from "react";
import WhiteAddIcon from "../../assets/icons/white add plus.png";
import WhiteWeddingVenueIcon from "../../assets/icons/white wedding venue.png";
import { Navigate, useNavigate } from "react-router-dom";
import VendorContentData from "./VendorContentData";
import { TabView, TabPanel } from "primereact/tabview";
import { ContainerBox } from "../../shared/ContainerBox";
// import { IndexLayout } from "../../layout";
import { ProfileLayout } from "../../layout/ProfileLayout";
import { ButtonOutlined } from "../../components/buttons/button";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import GraphIcon from "../../assets/icons/graph-monitoring.png";
import { Dialog } from "primereact/dialog";
import GuestsContent from "./GuestsContent";
export default function VendorSelectView() {
  const Navigate = useNavigate();

  const VendorSelectView = () => {
    // 👇️ navigate to Wedding venues
    Navigate("/vendor-select-view");
  };

  const WeddingVenues = () => {
    // 👇️ navigate to Wedding venues
    Navigate("/wedding-venues");
  };

  const [visible, setVisible] = useState(false);

  const { VendorSelect } = VendorContentData();

  return (
    <>
      <div className="wedding-venues-container mt-5 mb-7">
        <ContainerBox>
          <div className="grid p-0 m-0 flex ">
            <div className="col-12 md:col-12 lg:col-12">
              <TabView>
                <TabPanel header="Vendors">
                  <h1 className="merriweather text-2xl text-dark-gray font-bold mb-5">
                    Vendors
                  </h1>
                  <div className="grid ">
                    <div
                      className=" vendor-content-list col-12 md:col-2 cursor-pointer "
                      style={{ width: "19.6667%" }}
                      onClick={VendorSelectView}
                    >
                      <div
                        className="select-vendor p-5 border-round-xl text-center align-items-center justify-content-center px-5 flex "
                        style={{ height: "206px" }}
                      >
                        <div className="select-vendor-content">
                          <img
                            src={WhiteWeddingVenueIcon}
                            alt=""
                            height={39}
                            width={39}
                          />
                          <p className="text-white p-0 m-0 font-semibold text-base">
                            Wedding Venue
                          </p>
                          <div className="flex align-items-center justify-content-center">
                            <img
                              src={WhiteAddIcon}
                              width={20}
                              height={20}
                              alt=""
                              className=""
                            />
                            <span className="text-white ml-2 font-semibold text-base">
                              3
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>

                    {VendorSelect?.map((item, index) => {
                      return (
                        <>
                          <div
                            className=" vendor-content-list col-10 md:col-2 relative"
                            style={{ width: "19.6667%" }}
                          >
                            <div
                              className="border-1  border-round-xl text-center  flex align-items-center flex"
                              style={{
                                borderColor: "#CDD4F0",
                                height: "206px",
                              }}
                            >
                              <div className="align-items-center w-full">
                                <img
                                  src={item.img}
                                  alt=""
                                  width={44}
                                  height={44}
                                ></img>
                                <p className="text-base font-semibold text-light-black p-0 m-0 mb-3">
                                  {item.title}
                                </p>
                                <button
                                  onClick={() => setVisible(true)}
                                  className=" border-1 p-2 px-5  border-round-md bg-transparent cursor-pointer"
                                  style={{ borderColor: "#CDD4F0" }}
                                >
                                  <i
                                    className="pi pi-plus text-light-black"
                                    style={{ fontSize: "1rem" }}
                                  />
                                  <span className="ml-2 text-base font-medium text-light-black ">
                                    Add
                                  </span>
                                </button>
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    })}
                  </div>
                </TabPanel>

                <TabPanel header="Guests">
                  <div className="guests-content">
                    <GuestsContent />
                  </div>
                </TabPanel>

                <TabPanel header="Wedding Website">
                  <div className="wedding-content">
                    {/* <h1>WEDDING</h1> */}
                  </div>
                </TabPanel>

                <TabPanel header="Seating Chart">
                  <div className="seating-chart-content">
                    {/* <h1>SEATING</h1> */}
                  </div>
                </TabPanel>

                <TabPanel header="Budget Planner">
                  <div className="budget-planeer-content">
                    {/* <h1>BUDGET </h1> */}
                  </div>
                </TabPanel>
              </TabView>
            </div>
          </div>
        </ContainerBox>
      </div>

      <Dialog
        header={
          <>
            <h3 className="merriweather text-2xl text-dark-gray font-bold p-0 m-0 mb-3 ml-2 mt-2">
              Add Wedding Venue
            </h3>
          </>
        }
        visible={visible}
        style={{ width: "100vw" }}
        onHide={() => setVisible(false)}
        className="lg:w-4 md:w-8 w-11"
      >
        <p className="m-0">
          <div className="p-inputgroup flex-1 mb-5">
            <InputText placeholder="Write the name of the Vendor" />
            <Button icon="pi pi-search" className="p-button-primary" />
          </div>
          <div className="text-center">
            <img src={GraphIcon} width={68} height={68} alt=""></img>
            <p className="text-xl font-semibold p-0 m-0 text-light-black">
              Still Looking
            </p>
            <p className="font-normal text-sm  text-light-black ">
              Search our Local Venues directory
            </p>
            <ButtonOutlined
              className="font-bold"
              label="Search Wedding Venues"
              onClick={WeddingVenues}
            ></ButtonOutlined>
          </div>
        </p>
      </Dialog>
    </>
  );
}
