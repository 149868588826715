import api from "../../services/api";
import Constants from "../../services/constant";
import formDataApi from "../../services/formDataApi";
import { types } from "../types";
export const uploadFiles = async (files) => {
    const promises = files.map(async (item) => {
        if (typeof item === "string") {
            return item;
        } else {
            const formData = new FormData();
            formData.append("file", item);
            const res = await formDataApi("post", Constants.END_POINT.UPLOAD_FILES);
            if (res.success && res.data) {
                return res.data.path;
            }
        }
    });

    const urls = await Promise.all(promises);
    return urls;
};

export const singleUploadImage = async (image) => {
    if (typeof image === "string") {
        return image;
    } else {
        const formData = new FormData();
        formData.append("file", image);
        const res = await formDataApi("post", Constants.END_POINT.UPLOAD_FILES, formData);
        if (res.success && res.data) {
            return res.data.path;
        }
    }
};

export const uploadImages = async (images) => {
    const promises = images.map(async (item) => {
        if (typeof item === "string") {
            return item;
        } else {
            const formData = new FormData();
            formData.append("file", item);
            const res = await formDataApi("post", Constants.END_POINT.UPLOAD_FILES, formData);
            if (res.success && res.data) {
                return res.data.path;
            }
        }
    });

    const urls = await Promise.all(promises);
    return urls;
};
export const uploadMultipleBusinessImage = (files) => async (dispatch) => {
    // dispatch(showLoaderAction())
    const promises = files?.Photos?.map(async (item) => {
        if (typeof item === "string") {
            return item;
        } else {
            const formData = new FormData();
            formData.append("file", item);
            const res = await api("post", Constants.END_POINT.UPLOAD_MULTIPLE_BUSINESS_IMAGE, formData, {
                "Content-Type": "multipart/form-data",
            });
            if (res.success && res.data) {
                return res.data.path;
            }
        }
    });

    const urls = await Promise.all(promises);
    return urls;
    // const res = await formDataApi(
    //   "post",
    //   Constants.END_POINT.UPLOAD_MULTIPLE_BUSINESS_IMAGE,
    //   data
    // );
    // if (res.success) {
    //   if (res.data) {
    //     dispatch({
    //       type: types.UPLOAD_MULTIPLE_BUSINESS_IMAGE,
    //       payload: res.data,
    //     });
    //   }
    //   // dispatch(hideLoaderAction())
    //   return res;
    // } else {
    //   // dispatch(hideLoaderAction())
    //   dispatch(showToast({ severity: "error", summary: res.message }));
    // }
};
