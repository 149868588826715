import React, { useEffect, useState, useRef } from "react";
import { myProfileDataAction, updateVendorProfile, amenitiesAction } from "../../redux/actions/bussinessInformationAction";
import { vendorCategoryAction } from "../../redux/actions/vendorAction";
import { useDispatch, useSelector } from "react-redux";
import { isWebsiteUrl } from "../../utils/javascript";
import { showToast } from "../../redux/actions/toastAction";
import { useNavigate } from "react-router-dom";
import { types } from "../../redux/types";
export const StoreFrontContentData = () => {
    const dispatch = useDispatch();
    const inputRef = useRef();
    const navigate = useNavigate();
    const [vendorData, setVendorData] = useState("");
    const [amenities, setAmenities] = useState("");
    const [visible, setVisible] = useState(false);
    const [selectedLanguage, setSelectedLanguage] = useState(null);
    const [form, setForm] = useState({
        businessName: "",
        password: "",
        about: "",
        addressLine1: "",
        addressLine2: "",
        city: "",
        state: "",
        postalCode: "",
        capacity: "",
        minBudget: "",
        maxBudget: "",
        website: "",
        spoken: [],
        instagram: "",
        vendorType: "",
        facebook: "",
        google: "",
        twitter: "",
        profileImage: [],
        images: [],
        videos: [],
        preWedding: [],
        amenities: [],
    });

    const guestCapcity = ["50-100", "100-150", "100-200", "200-250", "250-300", "300-350", "350-400", "400-450", "450-500"];
    const budget = ["$50-$100", "$100-150", "$150-$200", "$200-$250", "$250-$300", "$300-$400", "$400-$500"];

    const language = ["Bengali", "Gujarati", "Hindi", "Kannada", "Malayalam", "Marathi", "Nepali", "Punjabi", "Tamil", "Telugu", "Urdu"];
    const socialLogin = ["Facebook", "Instagram"];
    const token = localStorage.getItem("authToken");
    useEffect(() => {
        if (token) {
            dispatch(myProfileDataAction());
            dispatch(vendorCategoryAction());
        }
    }, [token]);
    const myProfileData = useSelector((state) => state?.vendorProfile?.vendorProfile);
    const vendorCategories = useSelector((state) => state?.vendor?.categories);
    useEffect(() => {
        if (myProfileData) {
            setForm({
                ...form,
                businessName: myProfileData?.businessName,
                addressLine1: myProfileData?.addressLine1,
                vendorType: vendorData,
                addressLine2: myProfileData?.addressLine2,
                city: myProfileData?.city,
                state: myProfileData?.state,
                postalCode: myProfileData?.postalCode,
                about: myProfileData?.about,
                capacity: myProfileData?.capacity,
                maxBudget: myProfileData?.maxBudget,
                minBudget: myProfileData?.minBudget,
                website: myProfileData?.website,
                spoken: myProfileData?.spoken,
                facebook: myProfileData?.facebook,
                google: myProfileData?.google,
                instagram: myProfileData?.instagram,
                twitter: myProfileData?.twitter,
                profileImage: myProfileData?.profileImage ? [myProfileData?.profileImage] : [],
                images: myProfileData?.images,
                videos: myProfileData?.videos,
                preWedding: myProfileData?.preWedding,
                amenities: myProfileData?.amenities,
            });
            setSelectedLanguage(myProfileData?.spoken);
        }
    }, [myProfileData, vendorData]);
    const { toastInfo } = useSelector((state) => state?.toast);
    useEffect(() => {
        return () => {
            dispatch({ type: types.SHOW_TOAST, payload: {} });
        };
    }, []);
    useEffect(() => {
        vendorCategories?.length > 0 &&
            vendorCategories?.map((item) => {
                if (item?._id === myProfileData?.vendorType) {
                    setVendorData(item);
                }
            });
    }, [vendorCategories, myProfileData]);
    useEffect(() => {
        if (vendorData) {
            let params = {
                vendorType: vendorData?._id,
            };
            dispatch(amenitiesAction(params));
        }
    }, [vendorData]);
    const handleChange = (e, aboutName) => {
        if (aboutName === "about") {
            setForm((prev) => {
                return {
                    ...prev,
                    about: e.htmlValue,
                };
            });
        } else {
            const { name, value } = e.target;
            if (aboutName === "amenities") {
                let array = [...form?.amenities];
                array.push(amenities);
                setForm({
                    ...form,
                    amenities: array,
                });
                setAmenities("");
            } else {
                setForm({
                    ...form,
                    [name]: value,
                });
            }
        }
    };
    const handleFileChange = ({ name, value }) => {
        setForm((prev) => {
            return {
                ...prev,
                [name]: value,
            };
        });
    };
    const handlePlaceChanged = (place) => {
        if (place) {
            let addressvar = {
                zipcode: "",
                country: "",
                city: "",
                addressLine2: "",
                addressLine1: "",
            };
            place?.address_components?.map((address) => {
                if (address?.types?.includes("postal_code")) {
                    addressvar.zipcode = address?.long_name;
                }
                if (address?.types?.includes("country")) {
                    addressvar.country = address?.long_name;
                }
                if (address?.types?.includes("postal_town")) {
                    addressvar.city = address?.long_name;
                }
                if (address?.types?.includes("sublocality_level_1") || address?.types?.includes("sublocality") || address?.types?.includes("locality")) {
                    addressvar.addressLine2 = address?.long_name;
                }
                if (address?.types?.includes("street_number") || address?.types?.includes("route")) {
                    addressvar.addressLine1 += " " + address?.long_name;
                }
            });

            setForm({
                ...form,
                addressLine1: addressvar?.addressLine1,
                addressLine2: addressvar?.addressLine2,
                city: addressvar?.city,
                state: addressvar?.country,
                postalCode: addressvar?.zipcode,
            });
        }
    };
    const ValidUrl = () => {
        let checkError = true;
        if (!isWebsiteUrl(form?.website) && form?.website?.length > 0) {
            dispatch(showToast({ severity: "error", summary: "Website Url is not valid!" }));
            checkError = false;
        }
        if (!isWebsiteUrl(form?.facebook) && form?.facebook?.length > 0) {
            dispatch(
                showToast({
                    severity: "error",
                    summary: "Facebook Url is not valid!",
                })
            );
            checkError = false;
        }
        if (!isWebsiteUrl(form?.twitter) && form?.twitter?.length > 0) {
            dispatch(
                showToast({
                    severity: "error",
                    summary: "Twitter Url is not valid!",
                })
            );
            checkError = false;
        }
        if (!isWebsiteUrl(form?.instagram) && form?.instagram?.length > 0) {
            dispatch(
                showToast({
                    severity: "error",
                    summary: "Instagram Url is not valid!",
                })
            );
            checkError = false;
        }
        return checkError;
    };
    const handleSubmit = () => {
        if (ValidUrl() === false) {
            return false;
        } else {
            dispatch(updateVendorProfile(form)).then((res) => {
                if (res.success === true) {
                    dispatch(myProfileDataAction());
                }
            });
        }
    };

    // useEffect(() => {
    //   if (form?.addressLine1 === "") {
    //     setForm((prev) => {
    //       return {
    //         ...prev,
    //         addressLine2: "",
    //         state: "",
    //         city: "",
    //         postalCode: "",
    //       };
    //     });
    //   }
    // }, [form?.addressLine1]);
    const handleAmenities = (e) => {
        if (amenities?.length === 0) {
            dispatch(showToast({ severity: "error", summary: "Amenities are required." }));
        } else {
            let array = [...form?.amenities];
            if (!array.filter((item) => item._id === e._id).length) {
                array.push(e);
            }
            setForm({
                ...form,
                amenities: array,
            });
            setAmenities("");
            setVisible(false);
        }
    };

    const removeAmenity = (item) => {
        let array = form.amenities.filter((amenity) => amenity._id !== item._id);
        setForm({ ...form, amenities: array });
    };

    const amenitiesData = useSelector((state) => state?.amenities?.amenitiesProfile);
    useEffect(() => {
        setForm({
            ...form,
            spoken: selectedLanguage,
        });
    }, [selectedLanguage]);

    const clearSearchValue = () => {
        setForm((prev) => ({ ...prev, addressLine1: "" }));
    };

    return {
        form,
        vendorCategories,
        inputRef,
        guestCapcity,
        handleChange,
        setForm,
        handlePlaceChanged,
        budget,
        socialLogin,
        handleSubmit,
        myProfileData,
        handleFileChange,
        setAmenities,
        visible,
        setVisible,
        handleAmenities,
        removeAmenity,
        amenitiesData,
        amenities,
        vendorData,
        language,
        setSelectedLanguage,
        selectedLanguage,
        clearSearchValue,
        toastInfo,
    };
};
