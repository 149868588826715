import React, { useEffect, useRef, useState } from "react";
import { Sidebar } from "primereact/sidebar";
import { Button } from "primereact/button";
import { WeddingTemplateLayout } from "../../../layout/WeddingTemplateLayout";
import { ContainerBox } from "../../../shared/ContainerBox";
import EditIcon from "../../../assets/icons/edit pencil.png";
import CheckInIcon from "../../../assets/icons/CheckInIcon.png";
import PlusIcon from "../../../assets/icons/white add plus.png";
import GirlImg from "../../../assets/images/meet Img1.png";
import GroomImg from "../../../assets/images/groom img.png";
import RingImg from "../../../assets/images/ring img.png";
import StoryImg1 from "../../../assets/images/storyImg1.png";
import StoryImg2 from "../../../assets/images/storyimg2.png";
import StoryImg3 from "../../../assets/images/sroryimg3.png";
import StoryImg4 from "../../../assets/images/storyimg4.png";
import flowersImgLeft from "../../../assets/images/flowersImgLeft.png";
import flowersImgRight from "../../../assets/images/flowersImgRight.png";
import BannerImage from "../../../assets/images/wedding-template-banner.png";
import EndMessageImage from "../../../assets/images/celebration.png";
import { CustomEditor, CustomInput, CustomTextArea, SearchInput } from "../../../components/input/AllInput";
import ButtonComponent from "../../../components/buttons/button";
import WeddingWebsiteContainer from "./WeddingWebsiteContainer";
import moment from "moment";
import { imageprocessingTemplate } from "../../../utils/javascript";
import CustomImageInput from "../../../components/input/CustomImageInput";
import { OverlayPanel } from "primereact/overlaypanel";
import { useLocation, useParams } from "react-router-dom";
import { capitalizeFirstLetter } from "../../../utils/regex";
import { useDispatch, useSelector } from "react-redux";
import { Dialog } from "primereact/dialog";
import { guestForTemplateAction, guestUpdateStatusAction } from "../../../redux/actions/GuestAction";
import { RadioButton } from "primereact/radiobutton";

export default function WeddingTemplate() {
    const { id } = useParams();
    const { invitationId } = useParams();
    let location = useLocation();
    let dispatch = useDispatch();

    const { guestList: guestListFromReducer } = useSelector((state) => state?.guest);

    const { websiteData, websiteDataHandleChange, save, getHostWebsite, setWebsiteData, setWebsiteDataTemp, websiteDataTemp } = WeddingWebsiteContainer();

    useEffect(() => {
        if (Object.values?.(getHostWebsite)?.length > 0) {
            setWebsiteData({
                ...websiteData,
                name: getHostWebsite?.name,
                partnerName: getHostWebsite?.partnerName,
            });
        }
    }, [getHostWebsite]);

    useEffect(() => {
        if (getHostWebsite && Object.values?.(getHostWebsite)?.length > 0) {
            const tempState = {
                ...websiteData,
                welcomeTitle: getHostWebsite?.welcome?.title || "Welcome",
                welcomeDescription:
                    getHostWebsite?.welcome?.description ||
                    "🎉 We're thrilled to invite you to join us as we embark on the beautiful journey of love and companionship. Our wedding day is a celebration of not only our commitment to each other but also the union of two families and the merging of two souls.",
                brideTitle: getHostWebsite?.bride?.title || "👰 Meet the Bride",
                brideDescription:
                    getHostWebsite?.bride?.description ||
                    "Allow us to introduce the radiant bride, [Bride's Name]. With her infectious laughter, kind heart, and a smile that can light up a room, she stole [Groom's Name]'s heart from the very beginning.",
                brideImage: getHostWebsite?.bride?.image ? [getHostWebsite?.bride?.image] : "",
                groomTitle: getHostWebsite?.groom?.title || "👰 Meet the Groom",
                groomDescription:
                    getHostWebsite?.groom?.description ||
                    "Allow us to introduce the radiant bride, [Bride's Name]. With her infectious laughter, kind heart, and a smile that can light up a room, she stole [Groom's Name]'s heart from the very beginning.",
                groomImage: getHostWebsite?.groom?.image ? [getHostWebsite?.groom?.image] : "",
                saveTheDateTitle: getHostWebsite?.saveTheDate?.title || "📅 Save the Date",
                saveTheDateDescription:
                    getHostWebsite?.saveTheDate?.description ||
                    "Mark your calendars for [Date], as we exchange vows and declare our love surrounded by friends, family, and the beauty of [Venue]. We can't wait to share this special moment with you all.",
                saveTheDateImage: getHostWebsite?.saveTheDate?.image ? [getHostWebsite?.saveTheDate?.image] : "",
                ourStoryTitle: getHostWebsite?.ourStory?.title || "📜 Our Story",
                ourStoryDescription:
                    getHostWebsite?.ourStory?.description ||
                    "Our journey began [X years] ago, and it's been a whirlwind of adventures, laughter,and shared dreams. From the moment we met [describe how you met], we knew that our connection was something truly extraordinary.",
                ourStoryImages: getHostWebsite?.ourStory?.images || [],
                rsvpTitle: getHostWebsite?.rsvp?.title || "💌 RSVP",
                rsvpDescription:
                    getHostWebsite?.rsvp?.description ||
                    "Kindly let us know if you can join the celebration by [RSVP Date]. Your response will help us finalize the arrangements and ensure we create an unforgettable experience for everyone.",
                rsvpDate: getHostWebsite?.rsvp?.rsvpDate,
                endMessageTitle: getHostWebsite?.endMessage?.title || "🕊️ Join Us in Celebration",
                endMessageDescription:
                    getHostWebsite?.endMessage?.description ||
                    "We can't wait to share our joy with you and create lasting memories together. Keep an eye on this website for updates and further details. We're counting down the days until we can celebrate, laugh, and dance with you!",
                endMessageImage: getHostWebsite?.endMessage?.image ? [getHostWebsite?.endMessage?.image] : "",
                bannerImage: getHostWebsite?.bannerImage || "",
                contacts: getHostWebsite?.contacts || "[Contacts]",
                receptionLocation: getHostWebsite?.receptionLocation || "[Reception Location]",
                weddingLocation: getHostWebsite?.weddingLocation || "[Wedding Location]",

                weddingDate: getHostWebsite?.weddingDate ? moment(getHostWebsite?.weddingDate).toDate() : "",
                receptionDate: getHostWebsite?.receptionDate ? moment(getHostWebsite?.receptionDate).toDate() : "",
            };
            setWebsiteDataTemp({ ...tempState });
            setWebsiteData({ ...tempState });
        }
    }, [getHostWebsite]);

    const [isGuestView, setIsGuestView] = useState(false);

    const [visibleRight1, setVisibleRight1] = useState(false);
    const [visibleRight2, setVisibleRight2] = useState(false);
    const [visibleRight3, setVisibleRight3] = useState(false);
    const [visibleRightDate, setVisibleRightDate] = useState(false);
    const [visibleRightRSVP, setVisibleRightRSVP] = useState(false);
    const [visibleRightEnd, setVisibleRightEnd] = useState(false);
    const [visibleRightVenue, setVisibleRightVenue] = useState(false);
    const [visibleRightContact, setVisibleRightContact] = useState(false);
    const [visibleGuestConfirm, setVisibleGuestConfirm] = useState(false);

    const [guestInput, setGuestInput] = useState("");
    const [selectedGuest, setSelectedGuest] = useState("");

    const op = useRef(null);

    useEffect(() => {
        if (invitationId) {
            setIsGuestView(true);
            return;
        }
        const searchParams = new URLSearchParams(location.search);
        new URLSearchParams(location.search);
        const viewType = searchParams.get("view");

        if (viewType === "guest") {
            setIsGuestView(true);
        } else {
            setIsGuestView(false);
        }
    }, [location]);

    const resetState = () => {
        setWebsiteData({ ...websiteDataTemp });
    };

    const customHeader = (
        <React.Fragment>
            <h2 style={{ marginBottom: 0 }}>Sidebar</h2>
        </React.Fragment>
    );

    const renderEditorHeader = () => {
        return (
            <span className="ql-formats">
                <button className="ql-bold" aria-label="Bold"></button>
                <button className="ql-italic" aria-label="Italic"></button>
                <button className="ql-underline" aria-label="Underline"></button>
            </span>
        );
    };

    const header = renderEditorHeader();

    const handleGuestSelect = (data) => {
        setSelectedGuest(data);
    };

    const handleConfirmation = (person, confirmStatus, eventType) => {
        const updatedGuest = { ...selectedGuest };

        if (eventType === "Wedding") {
            if (person === selectedGuest) {
                updatedGuest.acceptInviteWedding = confirmStatus;
            } else {
                const updatedFamilyMembers = selectedGuest.familyMemberList.map((member) => {
                    if (member === person) {
                        return { ...member, acceptInviteWedding: confirmStatus };
                    }
                    return member;
                });
                updatedGuest.familyMemberList = updatedFamilyMembers;
            }
        } else if (eventType === "Reception") {
            if (person === selectedGuest) {
                updatedGuest.acceptInviteReception = confirmStatus;
            } else {
                const updatedFamilyMembers = selectedGuest.familyMemberList.map((member) => {
                    if (member === person) {
                        return { ...member, acceptInviteReception: confirmStatus };
                    }
                    return member;
                });
                updatedGuest.familyMemberList = updatedFamilyMembers;
            }
        }

        dispatch(guestUpdateStatusAction(updatedGuest, () => {}));

        setSelectedGuest(updatedGuest);
    };

    const handleCloseGuestConfirmDialog = () => {
        setVisibleGuestConfirm(false);
        setSelectedGuest("");
        setGuestInput("");
    };

    return (
        <>
            <WeddingTemplateLayout>
                <div className="title">
                    <h1 className=" merriweather text-2xl text-center text-dark-gray font-bold">
                        {getHostWebsite && getHostWebsite?.name ? getHostWebsite?.name : getHostWebsite?.name} <cod className="text-main-color">weds</cod>{" "}
                        {getHostWebsite && getHostWebsite?.partnerName ? getHostWebsite?.partnerName : getHostWebsite?.partnerName}
                    </h1>
                </div>
                <div
                    className="wedding-template-container align-items-center mb-4 md:mb-8"
                    style={{
                        backgroundImage: `url(${websiteData && websiteData?.bannerImage ? imageprocessingTemplate(websiteData?.bannerImage) : BannerImage})`,
                    }}
                >
                    <ContainerBox>
                        <div className=" text-right py-2">
                            {!isGuestView ? (
                                <Button className="bg-white border-200" onClick={(e) => op.current.toggle(e)}>
                                    <img src={EditIcon} width={24} height={24} alt="" />
                                    <cod className="ml-2 text-lg text-dark-gray">Edit Photos</cod>
                                </Button>
                            ) : (
                                <div style={{ padding: "26px" }}></div>
                            )}
                        </div>

                        <OverlayPanel ref={op} unstyled={true}>
                            <div className={"text-right"}>
                                <label htmlFor="bannerImageChange" className="bannerImageChangeLabel">
                                    <img src={PlusIcon} width={24} height={24} alt="" />
                                    <cod className="ml-2 text-sm text-white font-normal ">Upload Photo (1440x820)</cod>
                                    <input
                                        onChange={(e) => {
                                            websiteDataHandleChange("bannerImage", e.target.files[0]);
                                        }}
                                        id={"bannerImageChange"}
                                        type="file"
                                        accept="image/*"
                                        hidden
                                    />
                                </label>
                            </div>
                        </OverlayPanel>

                        {/* <div className={visibleRightBanner ? "text-right" : "text-right hidden mb-5"}>
              <Button
                className=""
                style={{ backgroundColor: "#434343", borderColor: "#434343" }}
              >
                <img src={PlusIcon} width={24} height={24} alt="" />
                <cod className="ml-2 text-sm text-white font-normal ">
                  Upload Photo (1440x820)
                </cod>
              </Button>
            </div> */}
                        <div className="h-full py-5 mt-5">
                            <div
                                className="p-2 text-center lg:w-6 align-item-center border-1 border-round-lg"
                                style={{ backgroundColor: "#72DCFF", borderColor: "#E2E2E2" }}
                            >
                                <h1 className="template-banner-heading merriweather text-dark-gray font-bold" style={{ fontSize: "60px" }}>
                                    {" "}
                                    {capitalizeFirstLetter(getHostWebsite && getHostWebsite?.name ? getHostWebsite?.name : getHostWebsite.name)} &{" "}
                                    {capitalizeFirstLetter(
                                        getHostWebsite && getHostWebsite?.partnerName ? getHostWebsite?.partnerName : getHostWebsite.partnerName
                                    )}
                                </h1>
                            </div>
                        </div>
                    </ContainerBox>
                </div>
                <ContainerBox>
                    <div className="welcome-content mb-4 md:mb-8">
                        <div className="text-right">
                            {!isGuestView ? (
                                <Button className="bg-white border-200" onClick={() => setVisibleRight1(true)}>
                                    <img src={EditIcon} width={24} height={24} alt="" />
                                    <cod className="ml-2 text-lg text-dark-gray">Edit</cod>
                                </Button>
                            ) : (
                                <div style={{ padding: "26px" }} />
                            )}
                        </div>
                        <div className="content text-center  merriweather text-2xl  text-dark-gray ">
                            <h2 className="text-xl md:text-2xl ">{websiteData?.welcomeTitle}</h2>
                            {/* <p className=" line-height-4"> */}
                            <p
                                className=" line-height-4 welcomeDescriptionText"
                                dangerouslySetInnerHTML={{
                                    __html:
                                        websiteData?.welcomeDescription ||
                                        "🎉 We're thrilled to invite you to join us as we embark on the beautiful journey of love and companionship. Our wedding day is a celebration of not only our commitment to each other but also the union of two families and the merging of two souls.",
                                }}
                            >
                                {/* {websiteData?.welcomeDescription || "🎉 We're thrilled to invite you to join us as we embark on the beautiful journey of love and companionship. Our wedding day is a celebration of not only our commitment to each other but also the union of two families and the merging of two souls."} */}
                            </p>
                        </div>

                        {invitationId && (
                            <div className="text-center justify-content-center">
                                <Button className="border-200" onClick={() => setVisibleGuestConfirm(true)}>
                                    <img src={CheckInIcon} width={24} height={24} alt="" />

                                    <cod className="ml-2 text-white">Check In</cod>
                                </Button>
                            </div>
                        )}
                    </div>
                </ContainerBox>

                <div className="py-4 md:py-8 webTempBgColor">
                    <ContainerBox>
                        {!isGuestView ? (
                            <div className=" text-right mb-5">
                                <Button className="bg-white border-200" onClick={() => setVisibleRight2(true)}>
                                    <img src={EditIcon} width={24} height={24} alt="" />
                                    <cod className="ml-2 text-lg text-dark-gray">Edit</cod>
                                </Button>
                            </div>
                        ) : null}

                        <div className="grid mb-8">
                            <div className="col-12 md:col-5 align-items-center flex ">
                                <div className="">
                                    <h1 className=" merriweather text-xl md:text-2xl  text-dark-gray font-bold mb-5">
                                        {websiteData?.brideTitle || "👰 Meet the Bride"}
                                    </h1>
                                    <p
                                        className="font-normal text-sm md:text-base line-height-4"
                                        style={{ color: "#626363" }}
                                        dangerouslySetInnerHTML={{
                                            __html:
                                                websiteData?.brideDescription ||
                                                "Allow us to introduce the radiant bride, [Bride's Name]. With her infectious laughter, kind heart, and a smile that can light up a room, she stole [Groom's Name]'s heart from the very beginning.",
                                        }}
                                    >
                                        {/* {websiteData?.brideDescription || "Allow us to introduce the radiant bride, [Bride's Name]. With her infectious laughter, kind heart, and a smile that can light up a room, she stole [Groom's Name]'s heart from the very beginning."} */}
                                    </p>
                                </div>
                            </div>
                            <div className="col-12 md:col-6  ml-auto">
                                <div className="">
                                    <img
                                        src={websiteData?.brideImage?.[0] ? imageprocessingTemplate(websiteData?.brideImage[0]) : GirlImg}
                                        alt=""
                                        className="w-full"
                                    />
                                    {/* <img src={websiteData?.brideImage ? Constants.HOST + websiteData?.brideImage : GirlImg} alt="" className="w-full" /> */}
                                </div>
                            </div>
                        </div>

                        <div className="grid">
                            <div className="col-12 md:col-6  ">
                                <div className="">
                                    <img
                                        src={websiteData?.groomImage?.[0] ? imageprocessingTemplate(websiteData?.groomImage[0]) : GroomImg}
                                        alt=""
                                        className="w-full"
                                    />
                                    {/* <img src={websiteData?.groomImage ? Constants.HOST + websiteData?.groomImage : GroomImg} alt="" className="w-full" /> */}
                                </div>
                            </div>
                            <div className="col-12 md:col-5 ml-auto align-items-center flex">
                                <div className="">
                                    <h1 className=" merriweather text-xl md:text-2xl  text-dark-gray font-bold mb-5">
                                        {websiteData?.groomTitle || "👰 Meet the Groom"}
                                    </h1>
                                    <p
                                        className="font-normal text-sm md:text-base line-height-4"
                                        style={{ color: "#626363" }}
                                        dangerouslySetInnerHTML={{
                                            __html:
                                                websiteData?.groomDescription ||
                                                "Allow us to introduce the radiant bride, [Bride's Name]. With her infectious laughter, kind heart, and a smile that can light up a room, she stole [Groom's Name]'s heart from the very beginning.",
                                        }}
                                    >
                                        {/* {websiteData?.groomDescription || "Allow us to introduce the radiant bride, [Bride's Name]. With her infectious laughter, kind heart, and a smile that can light up a room, she stole [Groom's Name]'s heart from the very beginning."} */}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </ContainerBox>
                </div>

                <div className=" py-4 md:py-8">
                    <ContainerBox>
                        {!isGuestView ? (
                            <div className=" text-right mb-5">
                                <Button className="bg-white border-200" onClick={() => setVisibleRightDate(true)}>
                                    <img src={EditIcon} width={24} height={24} alt="" />
                                    <cod className="ml-2 text-lg text-dark-gray">Edit</cod>
                                </Button>
                            </div>
                        ) : null}
                        <div className="grid mb-5">
                            <div className="col-12 md:col-6  ">
                                <div className="image-container-ring-img">
                                    <img src={RingImg} alt="" />
                                    {websiteData?.saveTheDateImage?.[0] && (
                                        <img src={imageprocessingTemplate(websiteData?.saveTheDateImage[0])} alt="" className="overlay-image-ring-img" />
                                    )}
                                </div>

                                {/* <div className="">
                  <img src={websiteData?.saveTheDateImage?.[0] ? imageprocessingTemplate(websiteData?.saveTheDateImage[0]) : RingImg} alt="" className="w-full" />
                </div> */}
                            </div>
                            <div className="col-12 md:col-5 ml-auto align-items-center flex">
                                <div className="">
                                    <h1 className=" merriweather text-xl md:text-2xl  text-dark-gray font-bold mb-5">
                                        {websiteData?.saveTheDateTitle || "📅 Save the Date"}
                                    </h1>
                                    <h2 className="text-dark-gray font-semibold" style={{ fontSize: "1.5rem" }}>
                                        {" "}
                                        <i>{getHostWebsite?.weddingDate ? moment(getHostWebsite?.weddingDate).format("MMMM Do YYYY") : "Not Added"}</i>{" "}
                                    </h2>
                                    <p
                                        className="font-normal text-sm md:text-base line-height-4"
                                        style={{ color: "#626363" }}
                                        dangerouslySetInnerHTML={{
                                            __html:
                                                websiteData?.saveTheDateDescription ||
                                                "Mark your calendars for [Date], as we exchange vows and declare our love surrounded by friends, family, and the beauty of [Venue]. We can't wait to share this special moment with you all.",
                                        }}
                                    >
                                        {/* {websiteData?.saveTheDateDescription || "Mark your calendars for [Date], as we exchange vows and declare our love surrounded by friends, family, and the beauty of [Venue]. We can't wait to share this special moment with you all."} */}
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="story-content">
                            <div className=" text-right mb-5">
                                {!isGuestView ? (
                                    <Button className="bg-white border-200" onClick={() => setVisibleRight3(true)}>
                                        <img src={EditIcon} width={24} height={24} alt="" />
                                        <cod className="ml-2 text-lg text-dark-gray">Edit</cod>
                                    </Button>
                                ) : (
                                    <div className="mt-5" />
                                )}
                            </div>
                            <div className="text-center mb-8 ">
                                <h2 className="merriweather text-2xl font-bold text-dark-gray">{websiteData?.ourStoryTitle || "📜 Our Story"}</h2>
                                <p
                                    className="merriweather text-sm md:text-2xl font-normal text-dark-gray line-height-4"
                                    dangerouslySetInnerHTML={{
                                        __html:
                                            websiteData?.ourStoryDescription ||
                                            "Our journey began [X years] ago, and it's been a whirlwind of adventures, laughter,and shared dreams. From the moment we met [describe how you met], we knew that our connection was something truly extraordinary.",
                                    }}
                                >
                                    {/* {websiteData?.ourStoryDescription || "Our journey began [X years] ago, and it's been a whirlwind of adventures, laughter,and shared dreams. From the moment we met [describe how you met], we knew that our connection was something truly extraordinary."} */}
                                </p>
                            </div>

                            {websiteData?.ourStoryImages?.length > 0 ? (
                                <div className="grid justify-content-center">
                                    <div
                                        className={`${websiteData?.ourStoryImages?.length > 1 ? "w-full" : ""} flex ${
                                            websiteData?.ourStoryImages?.length === 1 ? "justify-center" : "flex-wrap justify-center"
                                        }`}
                                    >
                                        {websiteData?.ourStoryImages?.map((item, index) => (
                                            <div
                                                key={index}
                                                className={`col-12 md:col-${
                                                    websiteData?.ourStoryImages?.length > 0 ? 12 / websiteData?.ourStoryImages?.length : 3
                                                } p-2 flex justify-center`}
                                            >
                                                <img
                                                    src={imageprocessingTemplate(item)}
                                                    alt="ourStory.images"
                                                    style={{ maxWidth: "100%", height: "auto", maxHeight: "360px" }}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                    {/* <div className="col-12 md:col-3 p-0 m-0">
                  <img src={StoryImg1} alt="" height={360} className="w-full" />
                </div>
                <div className="col-12 md:col-3 p-0 m-0">
                  <img src={StoryImg2} alt="" height={360} className="w-full" />
                </div>
                <div className="col-12 md:col-3 p-0 m-0">
                  <img src={StoryImg3} alt="" height={360} className="w-full" />
                </div>
                <div className="col-12 md:col-3 p-0 m-0">
                  <img src={StoryImg4} alt="" height={360} className="w-full" />
                </div> */}
                                </div>
                            ) : (
                                <div className="grid">
                                    <div className="col-12 md:col-3 p-0 m-0">
                                        <img src={StoryImg1} alt="" height={360} className="w-full" />
                                    </div>
                                    <div className="col-12 md:col-3 p-0 m-0">
                                        <img src={StoryImg2} alt="" height={360} className="w-full" />
                                    </div>
                                    <div className="col-12 md:col-3 p-0 m-0">
                                        <img src={StoryImg3} alt="" height={360} className="w-full" />
                                    </div>
                                    <div className="col-12 md:col-3 p-0 m-0">
                                        <img src={StoryImg4} alt="" height={360} className="w-full" />
                                    </div>
                                </div>
                            )}
                        </div>
                    </ContainerBox>
                </div>

                <div className="py-6 mb-5 webTempBgColor">
                    <ContainerBox>
                        {!isGuestView ? (
                            <div className=" text-right ">
                                <Button className="bg-white border-200" onClick={() => setVisibleRightVenue(true)}>
                                    <img src={EditIcon} width={24} height={24} alt="" />
                                    <cod className="ml-2 text-lg text-dark-gray">Edit</cod>
                                </Button>
                            </div>
                        ) : null}
                        <div className="text-center">
                            <h1 className="merriweather text-xl md:text-2xl font-bold text-dark-gray mb-4">💒 The Venue</h1>

                            <div className="py-3">
                                <h1 className="merriweather text-xl md:text-3xl font-normal text-dark-gray ">
                                    <i> {getHostWebsite?.weddingDate ? moment(getHostWebsite?.weddingDate).format("MMMM Do YYYY") : "Not Added"} </i>{" "}
                                    {/* <i> Saturday, July 14 </i>{" "} */}
                                    <span className=" merriweather text-xl md:text-2xl text-main-color font-bold ml-2">Wedding</span>
                                </h1>
                                <p className="font-semibold text-lg md:text-xl text-dark-gray ">{websiteData?.weddingLocation}</p>
                            </div>

                            <div className="py-3">
                                <h1 className="merriweather text-xl md:text-3xl font-normal text-dark-gray ">
                                    <i> {getHostWebsite?.receptionDate ? moment(getHostWebsite?.receptionDate).format("MMMM Do YYYY") : "Not Added"} </i>{" "}
                                    <span className=" merriweather text-xl md:text-2xl text-main-color font-bold ml-2">Reception</span>
                                </h1>
                                <p className="font-semibold text-lg md:text-xl text-dark-gray ">{websiteData?.receptionLocation}</p>
                            </div>
                        </div>
                    </ContainerBox>
                </div>

                <ContainerBox>
                    {!isGuestView ? (
                        <div className=" text-right">
                            <Button className="bg-white border-200" onClick={() => setVisibleRightRSVP(true)}>
                                <img src={EditIcon} width={24} height={24} alt="" />
                                <cod className="ml-2 text-lg text-dark-gray">Edit</cod>
                            </Button>
                        </div>
                    ) : null}
                    <div className="text-center">
                        <h1 className="merriweather font-bold text-dark-gray text-lg md:text-2xl ">{websiteData?.rsvpTitle || "💌 RSVP"}</h1>
                        <p
                            className="merriweather font-normal text-dark-gray text-sm md:text-2xl line-height-4"
                            dangerouslySetInnerHTML={{
                                __html:
                                    websiteData?.rsvpDescription ||
                                    "Kindly let us know if you can join the celebration by [RSVP Date]. Your response will help us finalize the arrangements and ensure we create an unforgettable experience for everyone.",
                            }}
                        >
                            {/* <i>
                {websiteData?.rsvpDescription || "Kindly let us know if you can join the celebration by [RSVP Date]. Your response will help us finalize the arrangements and ensure we create an unforgettable experience for everyone."}
              </i> */}
                        </p>
                    </div>
                </ContainerBox>

                {/* <div className='celebration-container align-items-center flex'>
                    <ContainerBox>
                        <div className="celebration-content align-items-center p-5 h-full border-round-2xl">
                            <h2 className='merriweather text-2xl font-bold text-white text-center py-5'>🕊️ Join Us in Celebration</h2>
                            <p className='text-center merriweather text-2xl font-normal line-height-4 text-white'>
                                <i>We can't wait to share our joy with you and create lasting memories together. Keep an eye on <br />
                                    this website for updates and further details. We're counting down the days until we can<br />
                                    celebrate, laugh, and dance with you!</i>
                            </p>
                        </div>
                    </ContainerBox>
                </div> */}
                <div
                    className="celebration-container text-white w-full mb-7 "
                    style={{
                        backgroundImage: `url(${
                            websiteData?.endMessageImage?.[0] ? imageprocessingTemplate(websiteData?.endMessageImage[0]) : EndMessageImage
                        })`,
                    }}
                >
                    <div className="align-items-center h-full flex justify-content-center p-2">
                        <div className=" celebration-content text-center px-5 border-round-2xl ">
                            {!isGuestView ? (
                                <div className=" text-right mt-5 mb-5">
                                    <Button className="bg-white border-200" onClick={() => setVisibleRightEnd(true)}>
                                        <img src={EditIcon} width={24} height={24} alt="" />
                                        <cod className="ml-2 text-lg text-dark-gray">Edit</cod>
                                    </Button>
                                </div>
                            ) : null}
                            <h1
                                className="merriweather font-normal text-3xl md:text-5xl  "
                                // style={{ fontSize: "44px" }}
                            >
                                {websiteData?.endMessageTitle || "🕊️ Join Us in Celebration"}
                            </h1>
                            <p
                                className="celebration-heading-text text-base md:text-xl text-center merriweather  font-normal  text-white"
                                style={{ lineHeight: "2" }}
                                dangerouslySetInnerHTML={{
                                    __html:
                                        websiteData?.endMessageDescription ||
                                        "We can't wait to share our joy with you and create lasting memories together. Keep an eye on this website for updates and further details. We're counting down the days until we can celebrate, laugh, and dance with you!",
                                }}
                            >
                                {/* <i>
                  {websiteData?.endMessageDescription || "We can't wait to share our joy with you and create lasting memories together. Keep an eye on this website for updates and further details. We're counting down the days until we can celebrate, laugh, and dance with you!"}
                </i> */}
                            </p>
                        </div>
                    </div>
                </div>

                <ContainerBox>
                    <div className="wedding-template-footer-container py-6">
                        <div className="flex gap-2 align-items-center justify-content-between">
                            <div>
                                <h2 className="merriweather text-xl md:text-2xl text-dark-gray font-bold">
                                    {" "}
                                    {capitalizeFirstLetter(getHostWebsite.name)} & {capitalizeFirstLetter(getHostWebsite.partnerName)}{" "}
                                </h2>
                                <p className="merriweather text-xl md:text-2xl text-dark-gray font-noraml p-0 m-0" style={{ fontFamily: "italic" }}>
                                    {getHostWebsite?.weddingDate ? moment(getHostWebsite?.weddingDate).format("MMMM Do YYYY") : "Not Added"}
                                </p>
                            </div>

                            <div>
                                <h2 className="merriweather text-xl md:text-2xl text-dark-gray font-bold cursor-pointer">
                                    {" "}
                                    Contact{" "}
                                    {!isGuestView ? (
                                        <img onClick={setVisibleRightContact} className="ml-2" src={EditIcon} width={24} height={24} alt="" />
                                    ) : null}
                                </h2>
                                <p className="merriweather text-lg text-dark-gray font-noraml p-0 m-0" style={{ fontFamily: "italic" }}>
                                    {websiteData?.contacts && websiteData?.contacts !== "[Contacts]"
                                        ? websiteData.contacts.split(/\s+/).map((part, index) => {
                                              const phoneNumber = part.replace(/[^0-9]/g, ""); // Remove non-numeric characters
                                              return (
                                                  <span key={index} style={{ display: "block" }}>
                                                      <a href={`tel:${part}`} style={{ textDecoration: "none" }}>
                                                          {part}
                                                      </a>
                                                      {index !== websiteData.contacts.split(/\s+/).length - 1 && " "}
                                                  </span>
                                              );
                                          })
                                        : "[Contacts]"}
                                    {/* {websiteData?.contacts && websiteData?.contacts !== "[Contacts]"
                                        ? websiteData.contacts.split(/\s+/).map((part, index) => (
                                              <span key={index} style={{ display: "block" }}>
                                                  <a href="tel:PHONE_NUM" style={{ textDecoration: "none" }}>
                                                      {part}
                                                  </a>
                                                  {index !== websiteData.contacts.split(/\s+/).length - 1 && " "}
                                              </span>
                                          ))
                                        : "[Contacts]"} */}
                                </p>
                            </div>
                        </div>
                    </div>
                </ContainerBox>

                {/* Welcome Edit-Sidebar */}
                <Sidebar
                    visible={visibleRight1}
                    position="right"
                    showCloseIcon={false}
                    onHide={() => {
                        setVisibleRight1(false);
                        resetState();
                    }}
                    header={false}
                    className="website-template-sidebar"
                >
                    <div className="background-side-popup">
                        <h2 className="merriweather font-bold text-xl text-dark-gray">Edit Welcome Message</h2>
                        <i
                            onClick={() => {
                                setVisibleRight1(false);
                                resetState();
                            }}
                            className="pi pi-times close-sidebar-right-popups"
                        ></i>
                    </div>
                    <div className="input-text">
                        <CustomInput
                            label="Title *"
                            placeholder="👋 Welcome to our wedding!"
                            value={websiteData?.welcomeTitle}
                            onChange={(e) => websiteDataHandleChange("welcomeTitle", e.target.value)}
                            extraClassName="w-full border-0"
                            labeClassName={"font-semibold"}
                        ></CustomInput>
                    </div>
                    <div className="text-area">
                        <CustomEditor
                            className="mb-3"
                            label={"Description *"}
                            value={websiteData?.welcomeDescription}
                            labelClassName={"font-semibold"}
                            placeholder="🎉 We're thrilled to invite you to join us as we embark on the beautiful journey of love and companionship. Our wedding day is a celebration of not only our commitment to each other but also the union of two families and the merging of two souls."
                            onChange={(e) => websiteDataHandleChange("welcomeDescription", e.htmlValue)}
                            headerTemplate={header}
                            style={{ height: "320px" }}
                        />
                        {/* <CustomTextArea
              rows={10}
              cols={30}
              value={websiteData?.welcomeDescription}
              onChange={(e) => websiteDataHandleChange("welcomeDescription", e.target.value)}
              label="Description*"
              placeholder="🎉 We're thrilled to invite you to join us as we embark on the beautiful journey of love and companionship. Our wedding day is a celebration of not only our commitment to each other but also the union of two families and the merging of two souls."
            ></CustomTextArea> */}
                    </div>
                    <ButtonComponent
                        label="Save"
                        onClick={() => {
                            save();
                            setVisibleRight1(false);
                        }}
                    ></ButtonComponent>
                </Sidebar>

                {/* Meet the bride sidebar */}
                <Sidebar
                    visible={visibleRight2}
                    position="right"
                    header={false}
                    className="website-template-sidebar"
                    onHide={() => {
                        setVisibleRight2(false);
                        resetState();
                    }}
                >
                    <div className="background-side-popup">
                        <h2 className="merriweather font-bold text-xl text-dark-gray  p-2 mb-3" style={{ backgroundColor: " #F8F9FF;" }}>
                            👰 Bride
                        </h2>
                        <i
                            onClick={() => {
                                setVisibleRight2(false);
                                resetState();
                            }}
                            className="pi pi-times close-sidebar-right-popups"
                        ></i>
                    </div>
                    <div className="input-text">
                        <CustomInput
                            label="Title *"
                            labeClassName={"font-semibold"}
                            placeholder="👰 Meet the Bride"
                            value={websiteData?.brideTitle}
                            onChange={(e) => websiteDataHandleChange("brideTitle", e.target.value)}
                            extraClassName="w-full border-0"
                        ></CustomInput>
                    </div>
                    <div className="text-area">
                        <CustomEditor
                            className="mb-3"
                            label={"Description *"}
                            value={websiteData?.brideDescription}
                            placeholder="Allow us to introduce the radiant bride, [Bride's Name]. With her infectious laughter, kind heart, and a smile that can light up a room, she stole [Groom's Name]'s heart from the very beginning."
                            labelClassName={"font-semibold"}
                            onChange={(e) => websiteDataHandleChange("brideDescription", e.htmlValue)}
                            headerTemplate={header}
                            style={{ height: "320px" }}
                        />

                        {/* <CustomTextArea
              rows={5}
              cols={10}
              label="Description*"
              value={websiteData?.brideDescription}
              onChange={(e) => websiteDataHandleChange("brideDescription", e.target.value)}
              placeholder="Allow us to introduce the radiant bride, [Bride's Name]. With her infectious laughter, kind heart, and a smile that can light up a room, she stole [Groom's Name]'s heart from the very beginning."
            ></CustomTextArea> */}
                    </div>

                    <CustomImageInput
                        data={websiteData}
                        onFilesChange={(e) => websiteDataHandleChange("brideImage", e.value)}
                        name="brideImage"
                        label="Bride's Image"
                        multiple={false}
                        labelClassName={"font-semibold"}
                        editable
                        removeable
                    />
                    {/* <img src={websiteData?.brideImage ? Constants.HOST + websiteData?.brideImage : GirlImg} alt="" className="w-full" />
          <Button
            label="Add Photo"
            icon=" pi pi-image text-right"
            className="bg-white text-main-color text-base font-medium w-full mb-5"
          ></Button> */}

                    <div className="background-side-popup">
                        <h2 className="merriweather font-bold text-xl text-dark-gray  p-2 mb-3" style={{ backgroundColor: " #F8F9FF;" }}>
                            🤵 Groom
                        </h2>
                    </div>

                    <div className="input-text">
                        <CustomInput
                            label="Title *"
                            placeholder="👰 Meet the Groom"
                            extraClassName="w-full border-0"
                            value={websiteData?.groomTitle}
                            labeClassName={"font-semibold"}
                            onChange={(e) => websiteDataHandleChange("groomTitle", e.target.value)}
                        ></CustomInput>
                    </div>
                    <div className="text-area">
                        <CustomEditor
                            className="mb-3"
                            label={"Description *"}
                            placeholder="Allow us to introduce the radiant bride, [Bride's Name]. With her infectious laughter, kind heart, and a smile that can light up a room, she stole [Groom's Name]'s heart from the very beginning."
                            value={websiteData?.groomDescription}
                            labelClassName={"font-semibold"}
                            onChange={(e) => websiteDataHandleChange("groomDescription", e.htmlValue)}
                            headerTemplate={header}
                            style={{ height: "320px" }}
                        />
                        {/* <CustomTextArea
              rows={5}
              cols={10}
              label="Description*"
              value={websiteData?.groomDescription}
              onChange={(e) => websiteDataHandleChange("groomDescription", e.target.value)}
              placeholder="Allow us to introduce the radiant bride, [Bride's Name]. With her infectious laughter, kind heart, and a smile that can light up a room, she stole [Groom's Name]'s heart from the very beginning."
            ></CustomTextArea> */}
                    </div>

                    <CustomImageInput
                        data={websiteData}
                        onFilesChange={(e) => websiteDataHandleChange("groomImage", e.value)}
                        name="groomImage"
                        label="Groom's Image"
                        labelClassName={"font-semibold"}
                        multiple={false}
                        editable
                        removeable
                    />
                    {/* <img src={websiteData?.groomImage ? Constants.HOST + websiteData?.groomImage : GroomImg} alt="" className="w-full" />
          <Button
            label="Add Photo"
            icon=" pi pi-image text-right"
            className="bg-white text-main-color text-base font-medium w-full mb-8 "
            onCick={(e) => websiteDataHandleChange("groomImage", e.target.value)}
          ></Button> */}

                    <ButtonComponent
                        label="Save"
                        onClick={() => {
                            save();
                            setVisibleRight2(false);
                        }}
                    ></ButtonComponent>
                </Sidebar>

                {/* OUR STORY sidebar */}
                <Sidebar
                    visible={visibleRight3}
                    position="right"
                    header={false}
                    className="website-template-sidebar"
                    onHide={() => {
                        setVisibleRight3(false);
                        resetState();
                    }}
                >
                    <div className="background-side-popup">
                        <h2 className="merriweather font-bold text-xl text-dark-gray  p-2 mb-3" style={{ backgroundColor: " #F8F9FF;" }}>
                            📜 Our Story
                        </h2>
                        <i
                            onClick={() => {
                                setVisibleRight3(false);
                                resetState();
                            }}
                            className="pi pi-times close-sidebar-right-popups"
                        ></i>
                    </div>
                    <div className="input-text">
                        <CustomInput
                            label="Title *"
                            placeholder="📜 Our Story"
                            extraClassName="w-full border-0"
                            value={websiteData?.ourStoryTitle}
                            labeClassName={"font-semibold"}
                            onChange={(e) => websiteDataHandleChange("ourStoryTitle", e.target.value)}
                        ></CustomInput>
                    </div>
                    <div className="text-area">
                        <CustomEditor
                            className="mb-3"
                            label={"Description *"}
                            placeholder="Allow us to introduce the radiant bride, [Bride's Name]. With her infectious laughter, kind heart, and a smile that can light up a room, she stole [Groom's Name]'s heart from the very beginning."
                            labelClassName={"font-semibold"}
                            value={websiteData?.ourStoryDescription}
                            onChange={(e) => websiteDataHandleChange("ourStoryDescription", e.htmlValue)}
                            headerTemplate={header}
                            style={{ height: "320px" }}
                        />

                        {/* <CustomTextArea
              rows={5}
              cols={10}
              label="Description*"
              value={websiteData?.ourStoryDescription}
              onChange={(e) => websiteDataHandleChange("ourStoryDescription", e.target.value)}
              placeholder="Allow us to introduce the radiant bride, [Bride's Name]. With her infectious laughter, kind heart, and a smile that can light up a room, she stole [Groom's Name]'s heart from the very beginning."
            ></CustomTextArea> */}
                    </div>
                    {websiteData?.ourStoryImages?.length <= 4 && (
                        <CustomImageInput
                            data={websiteData}
                            onFilesChange={(e) => websiteDataHandleChange("ourStoryImages", e.value)}
                            name="ourStoryImages"
                            label="Images"
                            multiple={true}
                            labelClassName={"font-semibold"}
                            limit={4}
                            editable
                            removeable
                        />
                    )}

                    {/* <Button
            label="Add Photo"
            icon=" pi pi-image text-right"
            className="bg-white text-main-color text-base font-medium w-full mb-3"
          ></Button>
          <Button
            label="Add Photo"
            icon=" pi pi-image text-right"
            className="bg-white text-main-color text-base font-medium w-full mb-3"
          ></Button>
          <Button
            label="Add Photo"
            icon=" pi pi-image text-right"
            className="bg-white text-main-color text-base font-medium w-full mb-3"
          ></Button>
          <Button
            label="Add Photo"
            icon=" pi pi-image text-right"
            className="bg-white text-main-color text-base font-medium w-full mb-8"
          ></Button> */}

                    <ButtonComponent
                        label="Save"
                        onClick={() => {
                            save();
                            setVisibleRight3(false);
                        }}
                    ></ButtonComponent>
                </Sidebar>

                {/* SAVE THE DATE sidebar */}
                <Sidebar
                    visible={visibleRightDate}
                    position="right"
                    header={false}
                    className="website-template-sidebar"
                    onHide={() => {
                        setVisibleRightDate(false);
                        resetState();
                    }}
                >
                    <div className="background-side-popup">
                        <h2 className="merriweather font-bold text-xl text-dark-gray  p-2 mb-3" style={{ backgroundColor: " #F8F9FF;" }}>
                            📅 Save the Date
                        </h2>
                        <i
                            onClick={() => {
                                setVisibleRightDate(false);
                                resetState();
                            }}
                            className="pi pi-times close-sidebar-right-popups"
                        ></i>
                    </div>
                    <div className="input-text">
                        <CustomInput
                            label="Title *"
                            placeholder="📅 Save the Date"
                            extraClassName="w-full border-0"
                            value={websiteData?.saveTheDateTitle}
                            labeClassName={"font-semibold"}
                            onChange={(e) => websiteDataHandleChange("saveTheDateTitle", e.target.value)}
                        ></CustomInput>
                    </div>
                    <div className="text-area">
                        <CustomEditor
                            className="mb-3"
                            label={"Description *"}
                            placeholder="Allow us to introduce the radiant bride, [Bride's Name]. With her infectious laughter, kind heart, and a smile that can light up a room, she stole [Groom's Name]'s heart from the very beginning."
                            labelClassName={"font-semibold"}
                            value={websiteData?.saveTheDateDescription}
                            onChange={(e) => websiteDataHandleChange("saveTheDateDescription", e.htmlValue)}
                            headerTemplate={header}
                            style={{ height: "320px" }}
                        />

                        {/* <CustomTextArea
              rows={5}
              cols={10}
              label="Description*"
              value={websiteData?.saveTheDateDescription}
              onChange={(e) => websiteDataHandleChange("saveTheDateDescription", e.target.value)}
              placeholder="Allow us to introduce the radiant bride, [Bride's Name]. With her infectious laughter, kind heart, and a smile that can light up a room, she stole [Groom's Name]'s heart from the very beginning."
            ></CustomTextArea> */}
                    </div>
                    <CustomImageInput
                        data={websiteData}
                        onFilesChange={(e) => websiteDataHandleChange("saveTheDateImage", e.value)}
                        name="saveTheDateImage"
                        label="Image"
                        multiple={false}
                        labelClassName={"font-semibold"}
                        editable
                        removeable
                    />
                    <ButtonComponent
                        label="Save"
                        onClick={() => {
                            save();
                            setVisibleRightDate(false);
                        }}
                    ></ButtonComponent>
                </Sidebar>

                {/* RSVP sidebar */}
                <Sidebar
                    visible={visibleRightRSVP}
                    position="right"
                    header={false}
                    className="website-template-sidebar"
                    onHide={() => {
                        setVisibleRightRSVP(false);
                        resetState();
                    }}
                >
                    <div className="background-side-popup">
                        <h2 className="merriweather font-bold text-xl text-dark-gray  p-2 mb-3" style={{ backgroundColor: " #F8F9FF;" }}>
                            💌 RSVP
                        </h2>
                        <i
                            onClick={() => {
                                setVisibleRightRSVP(false);
                                resetState();
                            }}
                            className="pi pi-times close-sidebar-right-popups"
                        ></i>
                    </div>
                    <div className="input-text">
                        <CustomInput
                            label="Title *"
                            labeClassName={"font-semibold"}
                            placeholder="💌 RSVP"
                            extraClassName="w-full border-0"
                            value={websiteData?.rsvpTitle}
                            onChange={(e) => websiteDataHandleChange("rsvpTitle", e.target.value)}
                        ></CustomInput>
                    </div>
                    <div className="text-area">
                        <CustomEditor
                            className="mb-3"
                            label={"Description *"}
                            value={websiteData?.rsvpDescription}
                            placeholder="Allow us to introduce the radiant bride, [Bride's Name]. With her infectious laughter, kind heart, and a smile that can light up a room, she stole [Groom's Name]'s heart from the very beginning."
                            labelClassName={"font-semibold"}
                            onChange={(e) => websiteDataHandleChange("rsvpDescription", e.htmlValue)}
                            headerTemplate={header}
                            style={{ height: "320px" }}
                        />

                        {/* <CustomTextArea
              rows={5}
              cols={10}
              label="Description*"
              value={websiteData?.rsvpDescription}
              onChange={(e) => websiteDataHandleChange("rsvpDescription", e.target.value)}
              placeholder="Allow us to introduce the radiant bride, [Bride's Name]. With her infectious laughter, kind heart, and a smile that can light up a room, she stole [Groom's Name]'s heart from the very beginning."
            ></CustomTextArea> */}
                    </div>
                    <ButtonComponent
                        label="Save"
                        onClick={() => {
                            save();
                            setVisibleRightRSVP(false);
                        }}
                    ></ButtonComponent>
                </Sidebar>

                {/* End Message sidebar */}
                <Sidebar
                    visible={visibleRightEnd}
                    position="right"
                    header={false}
                    className="website-template-sidebar"
                    onHide={() => {
                        setVisibleRightEnd(false);
                        resetState();
                    }}
                >
                    <div className="background-side-popup">
                        <h2 className="merriweather font-bold text-xl text-dark-gray  p-2 mb-3" style={{ backgroundColor: " #F8F9FF;" }}>
                            🕊️ Join Us in Celebration
                        </h2>
                        <i
                            onClick={() => {
                                setVisibleRightEnd(false);
                                resetState();
                            }}
                            className="pi pi-times close-sidebar-right-popups"
                        ></i>
                    </div>
                    <div className="input-text">
                        <CustomInput
                            label="Title *"
                            placeholder="🕊️ Join Us in Celebration"
                            extraClassName="w-full border-0"
                            value={websiteData?.endMessageTitle}
                            labeClassName={"font-semibold"}
                            onChange={(e) => websiteDataHandleChange("endMessageTitle", e.target.value)}
                        ></CustomInput>
                    </div>
                    <div className="text-area">
                        <CustomEditor
                            className="mb-3"
                            label={"Description *"}
                            placeholder="Allow us to introduce the radiant bride, [Bride's Name]. With her infectious laughter, kind heart, and a smile that can light up a room, she stole [Groom's Name]'s heart from the very beginning."
                            labelClassName={"font-semibold"}
                            value={websiteData?.endMessageDescription}
                            onChange={(e) => websiteDataHandleChange("endMessageDescription", e.htmlValue)}
                            headerTemplate={header}
                            style={{ height: "320px" }}
                        />

                        {/* <CustomTextArea
              rows={5}
              cols={10}
              label="Description*"
              value={websiteData?.endMessageDescription}
              onChange={(e) => websiteDataHandleChange("endMessageDescription", e.target.value)}
              placeholder="Allow us to introduce the radiant bride, [Bride's Name]. With her infectious laughter, kind heart, and a smile that can light up a room, she stole [Groom's Name]'s heart from the very beginning."
            ></CustomTextArea> */}
                    </div>
                    <CustomImageInput
                        data={websiteData}
                        onFilesChange={(e) => websiteDataHandleChange("endMessageImage", e.value)}
                        name="endMessageImage"
                        label="Image"
                        labelClassName={"font-semibold"}
                        multiple={false}
                        editable
                        removeable
                    />
                    <ButtonComponent
                        label="Save"
                        onClick={() => {
                            save();
                            setVisibleRightEnd(false);
                        }}
                    ></ButtonComponent>
                </Sidebar>

                {/* VENUE sidebar */}
                <Sidebar
                    visible={visibleRightVenue}
                    position="right"
                    header={false}
                    className="website-template-sidebar"
                    onHide={() => {
                        setVisibleRightVenue(false);
                        resetState();
                    }}
                >
                    <div className="background-side-popup">
                        <h2 className="merriweather font-bold text-xl text-dark-gray  p-2 mb-3" style={{ backgroundColor: " #F8F9FF;" }}>
                            💒 The Venue
                        </h2>
                        <i
                            onClick={() => {
                                setVisibleRightVenue(false);
                                resetState();
                            }}
                            className="pi pi-times close-sidebar-right-popups"
                        ></i>
                    </div>
                    <div className="input-text">
                        <CustomInput
                            label="Wedding Location *"
                            placeholder="ABC MG Road , Hawaii 81063"
                            extraClassName="w-full border-0"
                            value={websiteData?.weddingLocation}
                            labeClassName={"font-semibold"}
                            onChange={(e) => websiteDataHandleChange("weddingLocation", e.target.value)}
                        ></CustomInput>
                    </div>
                    <div className="text-area">
                        <CustomInput
                            label="Reception Location *"
                            placeholder="DEF MG Road , Hawaii 81063"
                            extraClassName="w-full border-0"
                            labeClassName={"font-semibold"}
                            value={websiteData?.receptionLocation}
                            onChange={(e) => websiteDataHandleChange("receptionLocation", e.target.value)}
                        ></CustomInput>
                    </div>
                    <ButtonComponent
                        label="Save"
                        onClick={() => {
                            save();
                            setVisibleRightVenue(false);
                        }}
                    ></ButtonComponent>
                </Sidebar>

                {/* Contact Sidebar */}
                <Sidebar
                    visible={visibleRightContact}
                    position="right"
                    header={false}
                    className="website-template-sidebar"
                    onHide={() => {
                        setVisibleRightContact(false);
                        resetState();
                    }}
                >
                    <div className="background-side-popup">
                        <h2 className="merriweather font-bold text-xl text-dark-gray">Edit Contact</h2>
                        <i
                            onClick={() => {
                                setVisibleRightContact(false);
                                resetState();
                            }}
                            className="pi pi-times close-sidebar-right-popups"
                        ></i>
                    </div>
                    <div className="input-text">
                        <CustomTextArea
                            label="Contact *"
                            placeholder="[Contacts]"
                            value={websiteData?.contacts}
                            labelClassName={"font-semibold"}
                            onChange={(e) => websiteDataHandleChange("contacts", e.target.value)}
                            extraClassName="w-full border-0"
                        ></CustomTextArea>
                    </div>
                    <ButtonComponent
                        label="Save"
                        onClick={() => {
                            save();
                            setVisibleRightContact(false);
                        }}
                    ></ButtonComponent>
                </Sidebar>

                <Dialog
                    dismissableMask={true}
                    className="flower-images-dialog"
                    showHeader={false}
                    draggable={false}
                    visible={visibleGuestConfirm}
                    onHide={() => handleCloseGuestConfirmDialog()}
                >
                    <div className="dialog-images-container">
                        <img src={flowersImgLeft} className="w-5rem md:w-7rem left-flower-image" />
                        <i onClick={handleCloseGuestConfirmDialog} className="pi pi-times close-guest-confirm-dialog" />
                        <img src={flowersImgRight} className="w-5rem md:w-7rem right-flower-image" />
                        <div className="content-between-images">
                            <h2 className="text-base md::text-2xl font-semibold merriweather">You are cordially invited to join us on our special day,</h2>
                            <p className="text-sm md:text-base merriweather guest-popup-para">
                                Kindly confirm your Presence, so we can plan accordingly, For a day filled with love, laughter, and harmony. Your response will
                                help us make our wedding dreams come true, We're looking forward to sharing this moment with you!
                            </p>
                            <p className="font-semibold merriweather ">Please Confirm Below:</p>

                            <div className="checkInConfirmGuest">
                                <div className="checkInConfirmGuestChild">
                                    <h2 className="font-semibold merriweather ml-2">Check In With Your Confirmation</h2>

                                    {!selectedGuest && (
                                        <SearchInput
                                            iconName={"pi pi-search"}
                                            showSearchIcon={true}
                                            placeholder="Enter Your Name"
                                            extraClassName="w-full checkInConfirmGuestInput"
                                            inputClassName={"w-full"}
                                            labeClassName={"font-semibold"}
                                            value={guestInput}
                                            onChange={(e) => {
                                                setGuestInput(e.target.value);
                                                dispatch(guestForTemplateAction({ search: e.target.value }, invitationId));
                                            }}
                                        ></SearchInput>
                                    )}

                                    {guestInput?.length > 0 && !selectedGuest && guestListFromReducer?.length > 0 && (
                                        <div className="checkInConfirmGuestInput guest">
                                            {guestListFromReducer.map((guest) => (
                                                <div key={guest.id}>
                                                    {guest?.name?.toLowerCase()?.includes(guestInput.toLowerCase()) ? (
                                                        <div
                                                            className="merriweather cursor-pointer ml-2 guestNamesTemplate"
                                                            onClick={() => handleGuestSelect(guest)}
                                                        >
                                                            {guest.name}
                                                        </div>
                                                    ) : (
                                                        guest?.familyMemberList?.some((member) =>
                                                            member?.name?.toLowerCase()?.includes(guestInput.toLowerCase())
                                                        ) && (
                                                            <div
                                                                className="merriweather cursor-pointer ml-2 guestNamesTemplate"
                                                                onClick={() => handleGuestSelect(guest)}
                                                            >
                                                                {
                                                                    guest?.familyMemberList.find((member) =>
                                                                        member?.name?.toLowerCase()?.includes(guestInput.toLowerCase())
                                                                    )?.name
                                                                }
                                                            </div>
                                                        )
                                                    )}
                                                </div>
                                            ))}
                                        </div>
                                    )}

                                    {guestInput?.length > 0 && !guestListFromReducer?.length && (
                                        <div className="merriweather ml-3 text-dark-gray text-sm ">No Guests found</div>
                                    )}

                                    {selectedGuest && (
                                        <>
                                            {selectedGuest?.acceptInviteWedding !== "NOTINVITED" && (
                                                <>
                                                    <h3 className="merriweather ml-2 text-main-color">Wedding</h3>
                                                    <div className="confirmation-container">
                                                        <div className="flex flex-row align-items-center">
                                                            <h4 className="merriweather ml-2">{selectedGuest.name}</h4>
                                                            <div className="ml-auto flex items-center">
                                                                <div className="ml-2">
                                                                    <RadioButton
                                                                        checked={selectedGuest.acceptInviteWedding === "CONFIRMED"}
                                                                        onChange={() => handleConfirmation(selectedGuest, "CONFIRMED", "Wedding")}
                                                                        inputId={`confirmationWedding_${selectedGuest._id}`}
                                                                        name={`confirmationWedding_${selectedGuest._id}`}
                                                                        value="CONFIRMED"
                                                                    />
                                                                    <label className="merriweather ml-2" htmlFor={`confirmationWedding_${selectedGuest._id}`}>
                                                                        Confirm
                                                                    </label>
                                                                </div>
                                                                <div className="ml-3">
                                                                    <RadioButton
                                                                        checked={selectedGuest.acceptInviteWedding === "DECLINED"}
                                                                        onChange={() => handleConfirmation(selectedGuest, "DECLINED", "Wedding")}
                                                                        inputId={`confirmationWedding_${selectedGuest._id}`}
                                                                        name={`confirmationWedding_${selectedGuest._id}`}
                                                                        value="DECLINED"
                                                                    />
                                                                    <label className="merriweather ml-2" htmlFor={`confirmationWedding_${selectedGuest._id}`}>
                                                                        Decline
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        {/* Map family members */}
                                                        {selectedGuest.familyMemberList?.map((familyMember) => (
                                                            <>
                                                                {familyMember?.acceptInviteWedding !== "NOTINVITED" && (
                                                                    <div className="flex flex-row align-items-center" key={familyMember._id}>
                                                                        <p className="merriweather ml-2 text-dark-gray">{familyMember.name}</p>
                                                                        <div className="ml-auto flex items-center">
                                                                            <div className="ml-2">
                                                                                <RadioButton
                                                                                    checked={familyMember.acceptInviteWedding === "CONFIRMED"}
                                                                                    onChange={() => handleConfirmation(familyMember, "CONFIRMED", "Wedding")}
                                                                                    inputId={`confirmationWedding_${familyMember._id}`}
                                                                                    name={`confirmationWedding_${familyMember._id}`}
                                                                                    value="CONFIRMED"
                                                                                />
                                                                                <label
                                                                                    className="merriweather ml-2"
                                                                                    htmlFor={`confirmationWedding_${familyMember._id}`}
                                                                                >
                                                                                    Confirm
                                                                                </label>
                                                                            </div>
                                                                            <div className="ml-3">
                                                                                <RadioButton
                                                                                    checked={familyMember.acceptInviteWedding === "DECLINED"}
                                                                                    onChange={() => handleConfirmation(familyMember, "DECLINED", "Wedding")}
                                                                                    inputId={`confirmationWedding_${familyMember._id}`}
                                                                                    name={`confirmationWedding_${familyMember._id}`}
                                                                                    value="DECLINED"
                                                                                />
                                                                                <label
                                                                                    className="merriweather ml-2"
                                                                                    htmlFor={`confirmationWedding_${familyMember._id}`}
                                                                                >
                                                                                    Decline
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </>
                                                        ))}
                                                    </div>
                                                    {selectedGuest?.acceptInviteReception !== "NOTINVITED" && <hr className="hr-divider" />}
                                                </>
                                            )}
                                            {selectedGuest?.acceptInviteReception !== "NOTINVITED" && (
                                                <>
                                                    <h3 className="merriweather ml-2 text-main-color">Reception</h3>
                                                    <div className="confirmation-container">
                                                        <div className="flex flex-row align-items-center">
                                                            <h4 className="merriweather ml-2">{selectedGuest.name}</h4>
                                                            <div className="ml-auto flex items-center">
                                                                <div className="ml-2">
                                                                    <RadioButton
                                                                        checked={selectedGuest.acceptInviteReception === "CONFIRMED"}
                                                                        onChange={() => handleConfirmation(selectedGuest, "CONFIRMED", "Reception")}
                                                                        inputId={`confirmationReception_${selectedGuest._id}`}
                                                                        name={`confirmationReception_${selectedGuest._id}`}
                                                                        value="CONFIRMED"
                                                                    />
                                                                    <label className="merriweather ml-2" htmlFor={`confirmationReception_${selectedGuest._id}`}>
                                                                        Confirm
                                                                    </label>
                                                                </div>
                                                                <div className="ml-3">
                                                                    <RadioButton
                                                                        checked={selectedGuest.acceptInviteReception === "DECLINED"}
                                                                        onChange={() => handleConfirmation(selectedGuest, "DECLINED", "Reception")}
                                                                        inputId={`confirmationReception_${selectedGuest._id}`}
                                                                        name={`confirmationReception_${selectedGuest._id}`}
                                                                        value="DECLINED"
                                                                    />
                                                                    <label className="merriweather ml-2" htmlFor={`confirmationReception_${selectedGuest._id}`}>
                                                                        Decline
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        {/* Map family members */}
                                                        {selectedGuest.familyMemberList?.map((familyMember) => (
                                                            <>
                                                                {familyMember?.acceptInviteReception !== "NOTINVITED" && (
                                                                    <div className="flex flex-row align-items-center" key={familyMember._id}>
                                                                        <p className="merriweather ml-2 text-dark-gray">{familyMember.name}</p>
                                                                        <div className="ml-auto flex items-center">
                                                                            <div className="ml-2">
                                                                                <RadioButton
                                                                                    checked={familyMember.acceptInviteReception === "CONFIRMED"}
                                                                                    onChange={() => handleConfirmation(familyMember, "CONFIRMED", "Reception")}
                                                                                    inputId={`confirmationReception_${familyMember._id}`}
                                                                                    name={`confirmationReception_${familyMember._id}`}
                                                                                    value="CONFIRMED"
                                                                                />
                                                                                <label
                                                                                    className="merriweather ml-2"
                                                                                    htmlFor={`confirmationReception_${familyMember._id}`}
                                                                                >
                                                                                    Confirm
                                                                                </label>
                                                                            </div>
                                                                            <div className="ml-3">
                                                                                <RadioButton
                                                                                    checked={familyMember.acceptInviteReception === "DECLINED"}
                                                                                    onChange={() => handleConfirmation(familyMember, "DECLINED", "Reception")}
                                                                                    inputId={`confirmationReception_${familyMember._id}`}
                                                                                    name={`confirmationReception_${familyMember._id}`}
                                                                                    value="DECLINED"
                                                                                />
                                                                                <label
                                                                                    className="merriweather ml-2"
                                                                                    htmlFor={`confirmationReception_${familyMember._id}`}
                                                                                >
                                                                                    Decline
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </>
                                                        ))}
                                                    </div>
                                                </>
                                            )}
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </Dialog>
            </WeddingTemplateLayout>
        </>
    );
}
