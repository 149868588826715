import React, { useEffect, useState } from "react";
import { ProgressBar } from "primereact/progressbar";
import { Rating } from "primereact/rating";
import LocationIcon from "../../assets/icons/location-blue.png";
import DollarIcon from "../../assets/icons/dollar.png";
import UserIcon from "../../assets/icons/user.png";
import { Divider } from "primereact/divider";
import FlagIcon from "../../assets/icons/flag.png";
import { CardComponent } from "../../components/cards/card";
import ButtonComponent from "../../components/buttons/button";
import { CustomTextArea } from "../../components/input/AllInput";
import { Dialog } from "primereact/dialog";
import CustomeReviewImage from "./CustomeReviewImage";
import Constants from "../../services/constant";
import moment from "moment";
import { Image } from "primereact/image";
import { firstLetterToUppercase } from "../../utils/regex";
import { formatCurrency } from "../../utils/javascript";
import { types } from "../../redux/types";
import { useDispatch, useSelector } from "react-redux";
export default function About({ vendorData, handleChange, reviewData, handleFileChange, handleSubmit }) {
    const [visible, setVisible] = useState(false);
    const dispatch = useDispatch();
    const { toastInfo } = useSelector((state) => state?.toast);
    useEffect(() => {
        return () => {
            dispatch({ type: types.SHOW_TOAST, payload: {} });
        };
    }, []);

    console.log(toastInfo);

    return (
        <>
            <h3 className="merriweather text-2xl text-dark-gray font-bold p-0 m-0 mb-3">{firstLetterToUppercase(vendorData?.businessName)}</h3>

            <div className="grid p-0 m-0">
                {vendorData?.city ? (
                    <div className=" abot-location-content col:md-4 flex align-items-center lg:mb-0 md:mb-0 mb-2 ">
                        <img src={LocationIcon} width={16} height={16} alt="" className="" />
                        <span className="ml-2 font-normal text-sm text-light-black cursor-pointer">{vendorData?.city}</span>
                        <span className="ml-3 text-2xl hidden lg:block md:block" style={{ color: "#D9D9D9" }}>
                            {" "}
                            &#8226;
                        </span>
                    </div>
                ) : (
                    ""
                )}

                {vendorData?.minBudget && vendorData?.maxBudget ? (
                    <div className=" abot-location-content col:md-4 flex align-items-center lg:mb-0 md:mb-0 mb-2">
                        <span className="lg:ml-3 md:ml-3 mt-1">
                            <img src={DollarIcon} width={16} height={16} alt="" className="" />
                        </span>
                        <span className="ml-3 font-normal text-sm text-light-black cursor-pointer ">
                            {formatCurrency(vendorData?.minBudget)}- {formatCurrency(vendorData?.maxBudget)}
                        </span>
                    </div>
                ) : vendorData?.minBudget ? (
                    <div className=" abot-location-content col:md-4 flex align-items-center lg:mb-0 md:mb-0 mb-2">
                        <span className="lg:ml-3 md:ml-3 mt-1">
                            <img src={DollarIcon} width={16} height={16} alt="" className="" />
                        </span>
                        <span className="ml-3 font-normal text-sm text-light-black cursor-pointer ">{formatCurrency(vendorData?.minBudget)}</span>
                    </div>
                ) : vendorData?.maxBudget ? (
                    <div className="col:md-4 flex align-items-center lg:mb-0 md:mb-0 mb-2">
                        <span className="ml-3 mt-1">
                            <img src={DollarIcon} width={16} height={16} alt="" className="" />
                        </span>
                        <span className="ml-3 font-normal text-sm text-light-black cursor-pointer">{formatCurrency(vendorData?.maxBudget)}</span>
                    </div>
                ) : (
                    ""
                )}

                {vendorData?.capacity && vendorData?.vendorType?.name === "Venue" ? (
                    <div className="col:md-4 flex align-items-center">
                        <span className="ml-3 text-2xl hidden lg:block md:block" style={{ color: "#D9D9D9" }}>
                            {" "}
                            &#8226;{" "}
                        </span>
                        <span className="lg:ml-3 md:ml-3 mt-1">
                            <img src={UserIcon} width={16} height={16} alt="" className="" />
                        </span>
                        <span className="ml-3 font-normal text-sm text-light-black cursor-pointer">{vendorData?.capacity} Guest Capacity</span>
                    </div>
                ) : null}
                {vendorData?.spoken && vendorData?.spoken?.length > 0 ? (
                    <div className="col:md-4 flex align-items-center">
                        <span className="ml-3 text-2xl hidden lg:block md:block" style={{ color: "#D9D9D9" }}>
                            {" "}
                            &#8226;{" "}
                        </span>
                        <span className="ml-3">
                            <span className="font-normal text-sm text-light-black cursor-pointer">Speaks -</span>
                        </span>

                        {vendorData["spoken"]?.map((item, index) => {
                            return (
                                <span className="font-normal text-sm text-light-black cursor-pointer">
                                    {index !== 0 ? ", " : ""}
                                    {item}
                                </span>
                            );
                        })}
                    </div>
                ) : null}
            </div>
            <div className="grid p-0 m-0">
                <div className="col-12 md:col-12 flex align-items-center">
                    {vendorData?.facebook?.length > 0 ? (
                        <p
                            className="underline text-light-black text-xs font-normal cursor-pointer"
                            onClick={() => window.open(vendorData?.facebook, "_blank")}
                        >
                            Facebook
                        </p>
                    ) : (
                        ""
                    )}

                    {vendorData?.instagram?.length > 0 ? (
                        <span className="ml-5">
                            <p
                                className="underline text-light-black text-xs font-normal cursor-pointer"
                                onClick={() => window.open(vendorData?.instagram, "_blank")}
                            >
                                Instagram
                            </p>
                        </span>
                    ) : (
                        ""
                    )}

                    {vendorData?.twitter?.length > 0 ? (
                        <span className="ml-5">
                            <p
                                className="underline text-light-black text-xs font-normal cursor-pointer"
                                onClick={() => window.open(vendorData?.twitter, "_blank")}
                            >
                                Twitter
                            </p>
                        </span>
                    ) : (
                        ""
                    )}
                    {vendorData?.website?.length > 0 ? (
                        <span className="ml-5">
                            <p
                                className="underline text-light-black text-xs font-normal cursor-pointer"
                                onClick={() => window.open(vendorData?.website, "_blank")}
                            >
                                Website
                            </p>
                        </span>
                    ) : (
                        ""
                    )}
                </div>
                <di v className="card flex align-items-center mb-3">
                    <Rating value={Number(vendorData?.cumulativeRating)} readOnly cancel={false} />
                    <span className="ml-3 text-lg font-normal text-light-black">({vendorData?.numberOfRatings} Reviews)</span>
                </di>
            </div>
            <Divider className="mb-5" />

            <h3 className="merriweather text-xl text-dark-gray font-bold">About This Vendor</h3>
            <div className="grid flex align-items-center">
                <div className="col-12 md:col-10 ">
                    <div className="flex align-items-center">
                        <img src={FlagIcon} width={24} height={24} alt="" className="" />
                        <span className="text-sm font-semibold text-light-black ml-2">On PlanTheKnot since {moment(vendorData?.time)?.format("YYYY")}</span>

                        <div className=" lg:flex md:flex align-items-center hidden lg:block md:block ">
                            <span className="text-2xl ml-2 hidden lg:block md:block" style={{ color: "#49516F" }}>
                                {" "}
                                &#8226; <img src={FlagIcon} width={24} height={24} alt="" className="lg:hidden md:hidden block" />
                            </span>
                            <span className="text-2xl lg:ml-2 md:ml-2 block lg:hidden md:hidden">
                                <img src={FlagIcon} width={24} height={24} alt="" className="lg:hidden md:hidden block" />
                            </span>

                            <span className="text-sm font-semibold text-light-black ml-2">
                                Last Update: {moment(vendorData?.updatedAt)?.format("MMM YYYY")}
                            </span>
                        </div>
                    </div>
                </div>

                <div className="col-12 md:col-5  block lg:hidden md:hidden">
                    <div className="flex align-items-center">
                        <span className="text-2xl ml-2 hidden lg:block md:block" style={{ color: "#49516F" }}>
                            {" "}
                            &#8226; <img src={FlagIcon} width={24} height={24} alt="" className="lg:hidden md:hidden block" />
                        </span>
                        <span className="text-2xl lg:ml-2 md:ml-2 block lg:hidden md:hidden">
                            <img src={FlagIcon} width={24} height={24} alt="" className="lg:hidden md:hidden block" />
                        </span>

                        <span className="text-sm font-semibold text-light-black ml-2">Last Update: {moment(vendorData?.updatedAt)?.format("MMM YYYY")}</span>
                    </div>
                </div>
            </div>
            <div className="text-light-black text-xs font-normal mb-5" style={{ lineHeight: "30px" }}>
                <p className="mb-5 " dangerouslySetInnerHTML={{ __html: vendorData?.about }}></p>
            </div>

            {vendorData?.amenities?.length > 0 ? (
                <CardComponent className="shadow-1 mb-7">
                    <h3 className="merriweather text-xl font-bold text-dark-gray mb-5 ">Amenities and Details</h3>
                    <div className="grid p-0 m-0">
                        {vendorData?.amenities?.map((item) => {
                            return (
                                <div className="col-12 md:col-6 flex align-items-center mb-5 p-0 m-0">
                                    <img src={Constants.HOST + item?.icon} width={25} height={25} alt="" />
                                    <span className=" amenites-details-text text-light-black  font-normal ml-3 p-0 m-0" style={{ fontSize: "16px" }}>
                                        {item?.name}
                                    </span>
                                </div>
                            );
                        })}
                    </div>
                </CardComponent>
            ) : null}
            <Divider className="mb-6" />

            <h3 className="merriweather text-xl text-dark-gray font-bold mt-5">Reviews and Rating</h3>
            {/* for desktop screen  */}
            <div className="grid p-0 m-0 mb-6 reviews-rating ">
                <div className="col-12 md:col-4 flex p-0 m-0 lg:mb-0 md:mb-0 mb-5">
                    <div
                        className="p-5 review-rating-box border-round-lg justify-content-center align-items-center flex  "
                        style={{ backgroundColor: "#F9FAFB", width: "95%" }}
                    >
                        <div className="align-items-center">
                            <h1 className="font-bold text-dark-gray p-0 m-0 " style={{ fontSize: "60px" }}>
                                {Number(vendorData?.cumulativeRating)}
                            </h1>
                            <Rating value={Number(vendorData?.cumulativeRating)} readOnly cancel={false} />
                            <p className="text-base font-normal text-light-black">({vendorData?.numberOfRatings} Reviews)</p>
                        </div>
                    </div>
                </div>

                <div className="col-12 md:col-8  border-round-lg p-0 m-0 p-3" style={{ backgroundColor: "#F9FAFB" }}>
                    <div className="grid flex align-items-center">
                        {Object.keys(vendorData?.individualRatings || {})?.map((rating) => (
                            <div className="col-12 md:col-12 flex align-items-center">
                                <p>({vendorData?.individualRatings[rating]})</p>
                                {/* <span className="ml-2">
                  <Rating value={1} readOnly stars={1} cancel={false} />
                </span> */}
                                <span className="col-12 md:col-7 rating-review lg:ml-3 md:ml-3">
                                    <ProgressBar
                                        value={
                                            vendorData?.individualRatings[rating]
                                                ? (vendorData?.individualRatings[rating] / vendorData?.numberOfRatings) * 100
                                                : "0"
                                        }
                                    ></ProgressBar>
                                </span>

                                <div className="flex align-items-center ml-2">
                                    <Rating value={rating} readOnly stars={5} cancel={false} />
                                    <span className="ml-3">
                                        {vendorData?.individualRatings[rating]
                                            ? (vendorData?.individualRatings[rating] / vendorData?.numberOfRatings) * 100
                                            : "0"}
                                        %
                                    </span>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            {/* for Mobile screen  */}
            <div className="grid mb-6 lg:hidden md:hidden block ">
                <div className="col-12 md:col-12 flex p-0 m-0 lg:mb-0 md:mb-0 mb-5">
                    <div className=" review-rating-box border-round-lg justify-content-center align-items-center flex  " style={{ backgroundColor: "#F9FAFB" }}>
                        <div className="align-items-center">
                            <h1 className=" reviews-rating-box-title font-bold text-dark-gray p-0 m-0 text-center" style={{ fontSize: "60px" }}>
                                {Number(vendorData?.cumulativeRating)}
                            </h1>
                            <Rating value={Number(vendorData?.cumulativeRating)} readOnly cancel={false} />
                            <p className="text-base font-normal text-light-black">({vendorData?.numberOfRatings} Reviews)</p>
                        </div>
                    </div>
                    <span className="ml-2 w-full">
                        <div className=" border-round-lg p-2" style={{ backgroundColor: "#F9FAFB" }}>
                            <div className="grid flex align-items-center p-0 m-0">
                                <div className="col-12 md:col-12 flex align-items-center p-0 m-0">
                                    <p className="p-0 m-0">5</p>
                                    <span className="ml-2">
                                        <Rating value={1} readOnly stars={1} cancel={false} />
                                    </span>
                                    <span className="w-full ml-2">
                                        <ProgressBar value={vendorData?.individualRatings?.length > 0 ? vendorData?.individualRatings[5] : 0}></ProgressBar>
                                    </span>
                                    <span className="ml-2">{vendorData?.individualRatings?.length > 0 ? vendorData?.individualRatings[5] : 0}</span>
                                </div>

                                <div className="col-12 md:col-12 flex align-items-center p-0 m-0">
                                    <p className="p-0 m-0">4</p>
                                    <span className="ml-2">
                                        <Rating value={1} readOnly stars={1} cancel={false} />
                                    </span>
                                    <span className="w-full ml-2">
                                        <ProgressBar value={vendorData?.individualRatings?.length > 0 ? vendorData?.individualRatings[4] : 0}></ProgressBar>
                                    </span>
                                    <span className="ml-2">
                                        {vendorData?.individualRatings?.length > 0 ? vendorData?.individualRatings[5] : 0}
                                        {/* % */}
                                    </span>
                                </div>

                                <div className="col-12 md:col-12 flex align-items-center p-0 m-0">
                                    <p className="p-0 m-0">3</p>
                                    <span className="ml-2">
                                        <Rating value={1} readOnly stars={1} cancel={false} />
                                    </span>
                                    <span className="w-full ml-2">
                                        <ProgressBar value={vendorData?.individualRatings?.length > 0 ? vendorData?.individualRatings[3] : 0}></ProgressBar>
                                    </span>
                                    <span className="ml-2">{vendorData?.individualRatings?.length > 0 ? vendorData?.individualRatings[5] : 0}</span>
                                </div>

                                <div className="col-12 md:col-12 flex align-items-center p-0 m-0">
                                    <p className="p-0 m-0">2</p>
                                    <span className="ml-2">
                                        <Rating value={1} readOnly stars={1} cancel={false} />
                                    </span>
                                    <span className="w-full ml-2">
                                        <ProgressBar value={vendorData?.individualRatings?.length > 0 ? vendorData?.individualRatings[2] : 0}></ProgressBar>
                                    </span>
                                    <span className="ml-2">{vendorData?.individualRatings?.length > 0 ? vendorData?.individualRatings[5] : 0}</span>
                                </div>

                                <div className="col-12 md:col-12 flex align-items-center p-0 m-0">
                                    <p className="p-0 m-0 "> 1 </p>
                                    <span className="ml-2">
                                        <Rating value={1} readOnly stars={1} cancel={false} />
                                    </span>
                                    &nbsp;
                                    <span className="w-full ml-2">
                                        <ProgressBar value={vendorData?.individualRatings?.length > 0 ? vendorData?.individualRatings[1] : 0}></ProgressBar>
                                    </span>
                                    <span className="ml-2">
                                        {vendorData?.individualRatings?.length > 0 ? vendorData?.individualRatings[5] : 0}
                                        {/* % */}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </span>
                </div>
            </div>

            <h3 className="merriweather text-xl font-bold mt-5" style={{ color: "#1D2939" }}>
                Reviews
            </h3>
            {toastInfo?.summary && <div className="p-3 border border-2 alert-error mb-2 text-center">{toastInfo?.summary}</div>}

            {vendorData?.reviews?.map((item) => {
                return (
                    <div className="grid ">
                        <div className="col-12 md:col-12 flex">
                            <div
                                className="border-1 text-white text-center align-items-center bg-main border-0"
                                style={{
                                    width: "50px",
                                    height: "50px",
                                    borderRadius: "2rem",
                                }}
                            >
                                <p className="p-0 m-0 h-full mt-2 pt-1">{item?.host?.name?.charAt(0).toUpperCase()}</p>
                            </div>
                            <span>
                                <div className="ml-3">
                                    <span className="font-medium text-sm" style={{ color: "#1D2939" }}>
                                        {item?.host?.name.charAt(0).toUpperCase() + item?.host?.name.slice(1)}
                                    </span>
                                    <span className="ml-5 font-normal text-xs " style={{ color: "#4F547B" }}>
                                        {moment.duration(moment(item?.time).diff(moment(new Date())))?.years() === 0
                                            ? moment.duration(moment(item?.time).diff(moment(new Date())))?.months() === 0
                                                ? moment.duration(moment(item?.time).diff(moment(new Date())))?.days() === 0
                                                    ? moment.duration(moment(item.time).diff(moment(new Date())))?.hours() === 0
                                                        ? moment.duration(moment(item.time).diff(moment(new Date())))?.minutes() === 0
                                                            ? `${moment
                                                                  .duration(moment(item.time).diff(moment(new Date())))
                                                                  .seconds()
                                                                  .toString()
                                                                  .substring(1)}sec`
                                                            : `${moment
                                                                  .duration(moment(item.time).diff(moment(new Date())))
                                                                  .minutes()
                                                                  .toString()
                                                                  .substring(1)} min`
                                                        : `${moment
                                                              .duration(moment(item.time).diff(moment(new Date())))
                                                              .hours()
                                                              .toString()
                                                              .substring(1)} hour`
                                                    : `${moment
                                                          .duration(moment(item.time).diff(moment(new Date())))
                                                          .days()
                                                          .toString()
                                                          .substring(1)} day`
                                                : `${moment
                                                      .duration(moment(item.time).diff(moment(new Date())))
                                                      .months()
                                                      .toString()
                                                      .substring(1)} month`
                                            : `${moment
                                                  .duration(moment(item.time).diff(moment(new Date())))
                                                  .years()
                                                  .toString()
                                                  .substring(1)} year`}
                                    </span>
                                    <Rating value={item?.rating} readOnly cancel={false} />
                                    <p className="text-xs font-normal" style={{ color: "#49516F" }}>
                                        {item?.reviewMessage}
                                    </p>
                                    <div className=" about-review flex gap-2">
                                        {item?.images?.length > 0
                                            ? item?.images?.map((item) => {
                                                  return (
                                                      <div className="p-0 m-0 flex justify-content-between overflow-auto">
                                                          <Image src={Constants.HOST + item} width={60} height={60} alt="" className="" preview />
                                                      </div>
                                                  );
                                              })
                                            : null}
                                    </div>
                                </div>
                            </span>
                        </div>

                        <Divider className="py-2"></Divider>
                    </div>
                );
            })}
            {vendorData?.reviews?.length > 3 ? (
                <div className="col-12 lg:col-4 lg:w-4 md:col-6 mb-3">
                    <ButtonComponent label="View All Reviews" className="text-white w-full" />
                </div>
            ) : (
                ""
            )}

            <CardComponent className="shadow-1">
                <h5 className="text-base font-medium text-light-black p-0 m-0 ml-2 mb-4">
                    Review <b>{vendorData?.businessName}</b>
                </h5>
                <p className="flex align-items-center mb-5 ml-2">
                    Rate Vendor*
                    <span className="ml-3">
                        <Rating value={reviewData?.rating} stars={5} cancel={false} onChange={handleChange} name="rating" />
                    </span>
                </p>
                <CustomTextArea
                    className=" w-full"
                    placeholder="Tell Us about your experience*"
                    rows={4}
                    name="reviewMessage"
                    value={reviewData?.reviewMessage}
                    onChange={handleChange}
                />
                <div className=" mt-4 mb-3 md:col-12">
                    <ButtonComponent
                        label="Add Photos"
                        className="text-light bg-transparent border-300 text-sm font-semibold  lg:mb-0 md:mb-0 mb-3 w-full lg:w-3 md:w-5"
                        onClick={() => setVisible(true)}
                    />
                    <span className="lg:ml-3 md:ml-3 w-full">
                        <ButtonComponent label="Submit Review" className="text-white text-sm font-semibold w-full lg:w-3 md:w-5 " onClick={handleSubmit} />
                    </span>
                </div>
            </CardComponent>
            <div className="card flex justify-content-center">
                <Dialog
                    header={
                        <>
                            <h1 className="merriweather font-bold text-2xl text-dark-gray">Add Photos</h1>
                        </>
                    }
                    visible={visible}
                    style={{ width: "100vw" }}
                    onHide={() => setVisible(false)}
                    className="lg:w-4 md:w-10 w-11"
                >
                    <CustomeReviewImage
                        data={reviewData}
                        name="images"
                        editable={true}
                        onFilesChange={handleFileChange}
                        multiple
                        removeable={true}
                        setVisible={setVisible}
                    />
                </Dialog>
            </div>
        </>
    );
}
