import React, { useState } from "react";
import { IndexLayout } from "../../layout";
import { ContainerBox } from "../../shared/ContainerBox";
import { TabView, TabPanel } from "primereact/tabview";
import { WeddingVenuesCard } from "../../components/cards/card";
import VendorContentData from "./VendorContentData";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import FilterIcon from "../../assets/icons/filter.png";
import { Sidebar } from "primereact/sidebar";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Divider } from "primereact/divider";
import { Checkbox } from "primereact/checkbox";
import { CustomCheckbox } from "../../components/input/AllInput";
import { Paginator } from "primereact/paginator";
import { ProfileLayout } from "../../layout/ProfileLayout";
import GuestsContent from "./GuestsContent";

export default function WeddingVenues() {
  const [ingredients, setIngredients] = useState([]);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);

  const onIngredientsChange = (e) => {
    let _ingredients = [...ingredients];

    if (e.checked) _ingredients.push(e.value);
    else _ingredients.splice(_ingredients.indexOf(e.value), 1);

    setIngredients(_ingredients);
  };
  const [checked, setChecked] = useState(false);
  const [visibleRight, setVisibleRight] = useState(false);
  const { WeddingVenuesContent } = VendorContentData();

  return (
    <>
      <ContainerBox>
        <div className="wedding-venues-container mt-5 mb-7">
          <div className="grid p-0 m-0 flex ">
            <div className="col-12 md:col-12 lg:col-12">
              <TabView>
                <TabPanel header="Vendors">
                  <div className="vendor-content">
                    <div className="grid mt-3 mb-3 ">
                      <div className="col-12 md:col-4">
                        <h1 className="merriweather text-2xl text-dark-gray font-bold ">
                          Wedding Venues
                        </h1>
                        <p className="p-0 m-0 text-sm font-normal text-light-black">
                          217 Results{" "}
                        </p>
                      </div>
                      <div className="col-12 md:col-8 flex">
                        <div className="w-full">
                          <div className="p-inputgroup flex-1 mb-6 w-full">
                            <InputText
                              placeholder="Search Vendor"
                              className="bg-blue-50"
                            />
                            <Button
                              icon="pi pi-search"
                              className="p-button-primary"
                            />
                          </div>
                        </div>
                        <span className="ml-5">
                          <button
                            onClick={() => setVisibleRight(true)}
                            className="lg:px-5 flex align-items-center border-1  w-full border-round-lg bg-transparent cursor-pointer"
                            style={{
                              borderColor: "#CDD4F0",
                              padding: "0.8rem",
                            }}
                          >
                            <img
                              src={FilterIcon}
                              width={24}
                              height={24}
                              alt=""
                              className=""
                            />
                            <span className="ml-2 text-base font-medium text-light-black">
                              Filters
                            </span>
                          </button>
                        </span>
                      </div>
                    </div>
                    <div className="flex justify-content-between gap-2 flex-wrap relative">
                      {WeddingVenuesContent.map((item) => {
                        return (
                          <WeddingVenuesCard
                            id={item.id}
                            img={item.img}
                            rating={item.rating}
                            title={item.title}
                            location={item.location}
                            price={item.price}
                            person={item.person}
                          />
                        );
                      })}
                    </div>
                    <Paginator
                      totalRecords={120}
                      first={first}
                      rows={rows}
                      className="justify-content-start flex mt-3"
                    />
                  </div>
                </TabPanel>

                <TabPanel header="Guests">
                  <GuestsContent />
                </TabPanel>

                <TabPanel header="Wedding Website"></TabPanel>

                <TabPanel header="Seating Chart"></TabPanel>

                <TabPanel header="Budget Planner"></TabPanel>
              </TabView>
            </div>
          </div>
        </div>
      </ContainerBox>

      <Sidebar
        visible={visibleRight}
        position="right"
        onHide={() => setVisibleRight(false)}
      >
        <h1 className="merriweather text-2xl text-dark-gray font-bold mb-5">
          All Filters
          <small className="text-sm font-medium text-main-color ml-2">
            Applied (4)
          </small>
        </h1>
        <div className="card">
          <Accordion activeIndex={0}>
            <AccordionTab
              header={
                <>
                  <div className="flex align-items-center">
                    <Checkbox
                      onChange={(e) => setChecked(e.checked)}
                      checked={checked}
                    ></Checkbox>
                    <span className="ml-3">
                      {" "}
                      <h1
                        className="text-base font-medium"
                        style={{ color: "#1C1C1C" }}
                      >
                        Wedding Suppliers
                      </h1>
                    </span>
                  </div>
                </>
              }
            >
              <p className="m-0">
                <div className="search_box mb-3">
                  <span className="p-input-icon-left w-full">
                    <i className="pi pi-search" />
                    <InputText
                      placeholder="Search"
                      className="border-0 bg-blue-50"
                    />
                  </span>
                </div>
                <CustomCheckbox
                  optionLabel="Wedding Venue"
                  labeClassName="text-dark-gray-light"
                  inputId="ingredient1"
                  name="Wedding Venue"
                  value="Wedding Venue"
                  onChange={onIngredientsChange}
                  checked={ingredients.includes("Wedding Venue")}
                />

                <CustomCheckbox
                  optionLabel="Caterers"
                  labeClassName="text-dark-gray-light"
                  inputId="ingredient2"
                  name="Caterers"
                  value="Caterers"
                  onChange={onIngredientsChange}
                  checked={ingredients.includes("Caterers")}
                />

                <CustomCheckbox
                  optionLabel="Photography"
                  labeClassName="text-dark-gray-light"
                  inputId="ingredient3"
                  name="Photography"
                  value="Photography"
                  onChange={onIngredientsChange}
                  checked={ingredients.includes("Photography")}
                />

                <CustomCheckbox
                  optionLabel="Photography"
                  labeClassName="text-dark-gray-light"
                  inputId="ingredient4"
                  name="Photography"
                  value="Photography"
                  onChange={onIngredientsChange}
                  checked={ingredients.includes("Photography")}
                />

                <CustomCheckbox
                  optionLabel="Photography"
                  labeClassName="text-dark-gray-light"
                  inputId="ingredient5"
                  name="Photography"
                  value="Photography"
                  onChange={onIngredientsChange}
                  checked={ingredients.includes("Photography")}
                />
              </p>
            </AccordionTab>
          </Accordion>
        </div>
        <Divider />

        <div className="card">
          <Accordion>
            <AccordionTab
              header={
                <>
                  <div className="flex align-items-center">
                    <Checkbox
                      onChange={(e) => setChecked(e.checked)}
                      checked={checked}
                    ></Checkbox>
                    <span className="ml-3">
                      {" "}
                      <h1
                        className="text-base font-medium"
                        style={{ color: "#1C1C1C" }}
                      >
                        Location
                      </h1>
                    </span>
                  </div>
                </>
              }
            ></AccordionTab>
          </Accordion>
        </div>
        <Divider />

        <div className="card">
          <Accordion>
            <AccordionTab
              header={
                <>
                  <div className="flex align-items-center">
                    <Checkbox
                      onChange={(e) => setChecked(e.checked)}
                      checked={checked}
                    ></Checkbox>
                    <span className="ml-3">
                      {" "}
                      <h1
                        className="text-base font-medium"
                        style={{ color: "#1C1C1C" }}
                      >
                        Price
                      </h1>
                    </span>
                  </div>
                </>
              }
            ></AccordionTab>
          </Accordion>
        </div>
        <Divider />
        <div className="card">
          <Accordion>
            <AccordionTab
              header={
                <>
                  <div className="flex align-items-center">
                    <Checkbox
                      onChange={(e) => setChecked(e.checked)}
                      checked={checked}
                    ></Checkbox>
                    <span className="ml-3">
                      {" "}
                      <h1
                        className="text-base font-medium"
                        style={{ color: "#1C1C1C" }}
                      >
                        No. of Guests
                      </h1>
                    </span>
                  </div>
                </>
              }
            ></AccordionTab>
          </Accordion>
        </div>
        <Divider />
        <div className="card">
          <Accordion>
            <AccordionTab
              header={
                <>
                  <div className="flex align-items-center">
                    <Checkbox
                      onChange={(e) => setChecked(e.checked)}
                      checked={checked}
                    ></Checkbox>
                    <span className="ml-3">
                      {" "}
                      <h1
                        className="text-base font-medium"
                        style={{ color: "#1C1C1C" }}
                      >
                        Venue Type
                      </h1>
                    </span>
                  </div>
                </>
              }
            ></AccordionTab>
          </Accordion>
        </div>
        <Divider />
      </Sidebar>
    </>
  );
}
