import React, { useState, useRef, useEffect } from "react";
import Logo from "../assets/images/logopng.png";
import hamburgerIcon from "../assets/icons/hamburger.png";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { ContainerBox } from "../shared/ContainerBox";
import LayoutData from "./Layout";
import { Divider } from "primereact/divider";
import SettingIcon from "../assets/icons/setting.png";
import LogoutIcon from "../assets/icons/logout.png";
import ProfileIcon from "../assets/icons/profile icon.png";
import { Dialog } from "primereact/dialog";
import FeaturedLogoutIcon from "../assets/icons/Featured icon.png";
import ButtonComponent from "../components/buttons/button";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Checkbox } from "primereact/checkbox";
import { CustomCheckbox } from "../components/input/AllInput";
import { InputText } from "primereact/inputtext";
import { Sidebar } from "primereact/sidebar";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../services/authService";
import { types } from "../redux/types";
import { clearProfile, myProfileAction } from "../redux/actions/profileAction";
import Constants from "../services/constant";
import { imageprocessing } from "../utils/javascript";
import { capitalizeFirstLetter } from "../utils/regex";
import WebsiteModal from "../views/Host/WeddingWebsite/WebsiteModal";

export default function ProfileTopbar() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    let location = useLocation();
    const { invitationId } = useParams();
    const hostProfileData = useSelector((state) => state?.hostProfile?.ProfileData);
    const [visibleRight, setVisibleRight] = useState(false);
    const [visible, setVisible] = useState(false);
    const userData = useSelector((state) => state?.signin?.SigninProfile);

    const activeLink = location?.pathname;
    const { menuItems } = LayoutData();
    const [isNavbarOpen, setIsNavbarOpen] = useState(false);

    const handleCheckboxChange = () => {
        setIsNavbarOpen(!isNavbarOpen);
    };

    const [option, setOption] = useState(false);

    const handleclick = () => {
        setOption(!option);
    };

    const Profile = () => {
        // 👇️ navigate to profile
        navigate("/host/profile");
    };
    const AccountSettings = () => {
        // 👇️ navigate to settings
        navigate("/host/account-settings");
    };

    const handleLogout = () => {
        dispatch(clearProfile());
        setVisible(false);
        logout(() => {
            setTimeout(() => {
                navigate("/");
            }, 400);
        });
    };
    useEffect(() => {
        dispatch(myProfileAction());
    }, []);

    const { getHostWebsite } = useSelector((state) => state?.hostWebsiteData);

    console.log();
    const [showWebsiteDialog, setWebsiteDialog] = useState(false);

    const onWeddingWebsiteClick = () => {
        Object.values?.(getHostWebsite)?.length > 0 ? navigate(`/host/wedding-website-template/${getHostWebsite._id}`) : setWebsiteDialog(true);
    };
    return (
        <>
            <WebsiteModal visible={showWebsiteDialog} setVisible={setWebsiteDialog} />
            <div className={`topbar_container relative z-3`}>
                <ContainerBox>
                    <div className=" pb-3 pt-4 grid  p-0 m-0 align-items-center justify-content-between lg:justify-content-between container-inner">
                        <div className=" md:col-3 p-0 m-0 lg:w-3  ">
                            <Link to="/host/profile" className="no-underline">
                                <img src={Logo} alt="" width={149} height={38}></img>
                            </Link>
                        </div>
                        <input type="checkbox" checked={isNavbarOpen} onChange={handleCheckboxChange} name="menuToggle" id="menuToggle" hidden />
                        <label htmlFor="menuToggle">
                            <img src={hamburgerIcon} height={24} width={24} alt="HamburgerIcon" />
                        </label>
                        <div className={`col-12 md:col-7 menu-container ${location.pathname === "/" ? "home-nav" : "other-nav"} `}>
                            <div className="col-12 md:col-3 p-0 m-0 flex justify-content-end ml-auto text-right align-items-center">
                                <div></div>
                                <label htmlFor="menuToggle" className="close-icon">
                                    X
                                </label>
                            </div>

                            <div className="  grid  align-items-center justify-content-evenly text-gray-light ">
                                {menuItems.map((item, key) => (
                                    <div className="nav cursor-pointer submenu-show " key={key}>
                                        <Link to={item.link} className="capitaltext no-underline text-light-black text-sm" onClick={handleCheckboxChange}>
                                            <span className=" lg:flex md:flex align-items-center vendor-menu">
                                                {item.name}&nbsp;&nbsp;{item.icon}
                                            </span>
                                        </Link>
                                        {item.subMenu ? (
                                            <ul className="grid hidden z-2 menu-show  border-round-lg list-none mt-3" key={key}>
                                                {item.subMenu?.map((subMenu, index) => {
                                                    return (
                                                        <li
                                                            key={index}
                                                            className={`lg:col-4 ${
                                                                subMenu.link === activeLink
                                                                    ? "p-2 side_menuitem text-dark active"
                                                                    : "p-2 side_menuitem text-dark "
                                                            }`}
                                                        >
                                                            <Link
                                                                to={`/explore/${subMenu._id}`}
                                                                className="no-underline side_menuitem text-dark open-sans flex align-items-center pt-2"
                                                                onClick={handleCheckboxChange}
                                                            >
                                                                <img src={Constants.HOST + subMenu.icon} alt="" width={24} height={24} />
                                                                &nbsp;&nbsp;
                                                                {subMenu.name}
                                                            </Link>
                                                        </li>
                                                    );
                                                })}
                                            </ul>
                                        ) : null}
                                    </div>
                                ))}

                                {/* {token === null ? (
                  <>  */}

                                {/* for mobile screen */}
                                <div className="p-3 lg:hidden md:hidden vendor-list">
                                    <Accordion activeIndex={1}>
                                        <AccordionTab
                                            header={
                                                <>
                                                    <p className="text-dark font-medium text-base ">Vendor</p>
                                                </>
                                            }
                                        >
                                            <div className="">
                                                {menuItems.map((item, key) => (
                                                    <div className="nav_link" key={key}>
                                                        {item.subMenu ? (
                                                            <ul className="grid bg-transparent menu-show  border-round-lg list-none p-0 m-0" key={key}>
                                                                {item.subMenu?.map((subMenu, index) => {
                                                                    return (
                                                                        <li
                                                                            key={index}
                                                                            className={`lg:col-4 ${
                                                                                subMenu.link === activeLink
                                                                                    ? "p-2 side_menuitem text-dark active"
                                                                                    : "p-2 side_menuitem text-dark "
                                                                            }`}
                                                                        >
                                                                            <div className="">
                                                                                <Link
                                                                                    to={`/explore/${subMenu._id}`}
                                                                                    className="no-underline side_menuitem text-dark open-sans flex align-items-center "
                                                                                    onClick={handleCheckboxChange}
                                                                                >
                                                                                    <img
                                                                                        src={Constants.HOST + subMenu.icon}
                                                                                        alt=""
                                                                                        width={24}
                                                                                        height={24}
                                                                                        className=""
                                                                                    />
                                                                                    &nbsp;&nbsp;
                                                                                    <p className="ml-2 p-0 m-0 md:mb-2 mt-2  ">{subMenu.name}</p>
                                                                                </Link>
                                                                            </div>
                                                                        </li>
                                                                    );
                                                                })}
                                                            </ul>
                                                        ) : null}
                                                    </div>
                                                ))}
                                            </div>
                                        </AccordionTab>
                                    </Accordion>
                                </div>

                                {/* for mobile screen */}
                                <div className="p-2 lg:hidden md:hidden">
                                    <Accordion activeIndex={1}>
                                        <AccordionTab
                                            header={
                                                <>
                                                    <div className="">
                                                        <p className="flex align-items-center">
                                                            <img
                                                                src={imageprocessing(hostProfileData?.profileImage)}
                                                                width={52}
                                                                height={52}
                                                                alt=""
                                                                className="border-circle"
                                                            />
                                                            <span className="text-lg font-bold ml-3 text-dark-gray w-full">
                                                                {capitalizeFirstLetter(hostProfileData?.name)}
                                                                <p className="p-0 m-0 mt-2">
                                                                    <small
                                                                        className="font-normal p-0 m-0 "
                                                                        style={{
                                                                            fontSize: "13px",
                                                                            color: "#A4A4A4 ",
                                                                        }}
                                                                    >
                                                                        Host
                                                                    </small>{" "}
                                                                </p>
                                                            </span>
                                                        </p>
                                                    </div>
                                                </>
                                            }
                                        >
                                            <div className="  cursor-pointer" onClick={Profile}>
                                                <p className="flex align-items-center p-0 m-0" onClick={handleCheckboxChange}>
                                                    <img src={ProfileIcon} width={24} height={24} alt="" className="" />
                                                    <span className="ml-3 text-sm font-normal" style={{ color: "#212348" }}>
                                                        My Profile
                                                    </span>
                                                </p>
                                            </div>
                                            <Divider />
                                            <div className="flex align-items-center  cursor-pointer pt-2" onClick={AccountSettings}>
                                                <p className="flex align-items-center  " onClick={handleCheckboxChange}>
                                                    <img src={SettingIcon} width={24} height={24} alt="" className="" />
                                                    <span className="ml-3 text-sm font-normal" style={{ color: "#212348" }}>
                                                        Account Settings
                                                    </span>
                                                </p>
                                            </div>
                                            <Divider />
                                            <div className="cursor-pointer pt-2" onClick={() => setVisible(true)}>
                                                <p className="flex align-items-center p-0 m-0 mt-3" onClick={handleCheckboxChange}>
                                                    <img src={LogoutIcon} width={24} height={24} alt="" className="" />
                                                    <span className="ml-3 text-sm font-normal" style={{ color: "#212348" }}>
                                                        Logout
                                                    </span>
                                                </p>
                                            </div>
                                        </AccordionTab>
                                    </Accordion>
                                </div>

                                <div className="vendor-profile ">
                                    <div className=" relative ">
                                        <div className="flex align-items-center ">
                                            <span>
                                                <img
                                                    src={imageprocessing(hostProfileData?.profileImage)}
                                                    width={52}
                                                    height={52}
                                                    alt=""
                                                    className="border-circle"
                                                />
                                            </span>
                                            <span className="text-lg font-bold ml-3 text-dark-gray">
                                                {capitalizeFirstLetter(hostProfileData?.name)}
                                                <i
                                                    className="pi pi-angle-down ml-3 cursor-pointer"
                                                    style={{ fontSize: "1.3rem" }}
                                                    onClick={handleclick}
                                                ></i>{" "}
                                                <br></br>
                                                <small className="font-normal p-0 m-0 ml-1" style={{ fontSize: "13px", color: "#A4A4A4 " }}>
                                                    Host
                                                </small>{" "}
                                            </span>
                                        </div>
                                        <div
                                            className=" p-2 profile-modal bg-white  absolute  left-0 right-0 shadow-2"
                                            style={{
                                                display: option ? "block" : "none",
                                                top: "85px",
                                                width: "200px",
                                            }}
                                        >
                                            <div className="">
                                                <div className=" p-0 m-0 flex justify-content-center">
                                                    <div className=" ">
                                                        <div
                                                            className="flex align-items-center cursor-pointer ml-2 mt-2"
                                                            onClick={() => {
                                                                Profile();
                                                                setOption(false);
                                                            }}
                                                        >
                                                            <img src={ProfileIcon} width={24} height={24} alt="" className="" />

                                                            <span className="ml-3 text-sm font-normal" style={{ color: "#212348" }}>
                                                                My Profile
                                                            </span>
                                                        </div>
                                                        <Divider />
                                                        <div
                                                            className="flex align-items-center  cursor-pointer  ml-2"
                                                            onClick={() => {
                                                                AccountSettings();
                                                                setOption(false);
                                                            }}
                                                        >
                                                            <img src={SettingIcon} width={24} height={24} alt="" className="" />
                                                            <span className="ml-3 text-sm font-normal" style={{ color: "#212348" }}>
                                                                Account Settings
                                                            </span>
                                                        </div>
                                                        <Divider />
                                                        <div
                                                            className="flex align-items-center   cursor-pointer ml-2 mb-2"
                                                            onClick={() => {
                                                                setVisible(true);
                                                                setOption(false);
                                                            }}
                                                        >
                                                            <img src={LogoutIcon} width={24} height={24} alt="" className="" />
                                                            <span className="ml-3 text-sm font-normal" style={{ color: "#212348" }}>
                                                                Logout
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Dialog closable={false} visible={visible} style={{ width: "100vw" }} onHide={() => setVisible(false)} className="lg:w-4 md:w-10 w-11">
                        <p className="m-0">
                            <img src={FeaturedLogoutIcon} width={48} height={48} alt="" className="" />
                            <p className="text-lg font-semibold" style={{ color: "#101828" }}>
                                Are you sure you want to Logout?
                            </p>
                            <p className="text-sm font-normal mb-5" style={{ color: "#475467" }}>
                                If you're ready to logout, click "Logout" below. If you'd like to continue using the platform, click "Cancel."
                            </p>
                            <div className="grid">
                                <div className="col-12 md:col-6 flex align-items-center" onClick={() => setVisible(false)}>
                                    <ButtonComponent label="Cancel" className="w-full  bg-white text-dark border-300 text-lg font-semibold"></ButtonComponent>
                                </div>
                                <div className="col-12 md:col-6">
                                    <ButtonComponent
                                        label="Logout"
                                        className="w-full bg-red-500 border-0 text-lg font-semibold px-4"
                                        onClick={handleLogout}
                                    ></ButtonComponent>
                                </div>
                            </div>
                        </p>
                    </Dialog>
                    <Sidebar visible={visibleRight} position="right" onHide={() => setVisibleRight(false)}>
                        <h1 className="merriweather text-2xl text-dark-gray font-bold mb-5">
                            All Filters
                            <small className="text-sm font-medium text-main-color ml-2">Applied (4)</small>
                        </h1>
                        <div className="card">
                            <div className="search_box mb-3">
                                <span className="p-input-icon-left w-full">
                                    <i className="pi pi-search" />
                                    <InputText placeholder="Search" className="border-0 bg-blue-50" />
                                </span>
                            </div>
                            <Accordion activeIndex={0}>
                                <AccordionTab
                                    header={
                                        <>
                                            <div className="flex align-items-center ml-2">
                                                <h1 className="text-lg font-medium" style={{ color: "#1C1C1C" }}>
                                                    Wedding Venues
                                                </h1>
                                            </div>
                                        </>
                                    }
                                >
                                    <p className="m-0">
                                        <CustomCheckbox
                                            optionLabel="Barn Weddings"
                                            labeClassName="text-dark-gray-light"
                                            inputId="ingredient1"
                                            name="Barn Weddings"
                                            value="Barn Weddings"
                                        />

                                        <CustomCheckbox
                                            optionLabel="Country Weddings"
                                            labeClassName="text-dark-gray-light"
                                            inputId="ingredient2"
                                            name="Country Weddings"
                                            value="Country Weddings"
                                        />

                                        <CustomCheckbox
                                            optionLabel="Bar, Pub and Restaurant"
                                            labeClassName="text-dark-gray-light"
                                            inputId="ingredient3"
                                            name="Bar, Pub and Restaurant"
                                            value="Bar, Pub and Restaurant"
                                        />

                                        <CustomCheckbox
                                            optionLabel="Unique and Unusual"
                                            labeClassName="text-dark-gray-light"
                                            inputId="ingredient4"
                                            name="Unique and Unusual"
                                            value="Unique and Unusual"
                                        />

                                        <CustomCheckbox
                                            optionLabel="Castle Weddings"
                                            labeClassName="text-dark-gray-light"
                                            inputId="ingredient5"
                                            name="Castle Weddings"
                                            value="Castle Weddings"
                                        />

                                        <CustomCheckbox
                                            optionLabel="Historic Weddings"
                                            labeClassName="text-dark-gray-light"
                                            inputId="ingredient6"
                                            name="Historic Weddings"
                                            value="Historic Weddings"
                                        />

                                        <CustomCheckbox
                                            optionLabel="Sporting"
                                            labeClassName="text-dark-gray-light"
                                            inputId="ingredient7"
                                            name="Sporting"
                                            value="Sporting"
                                        />

                                        <CustomCheckbox
                                            optionLabel="Coastal & Beach Weddings"
                                            labeClassName="text-dark-gray-light"
                                            inputId="ingredient8"
                                            name="Coastal & Beach Weddings"
                                            value="Coastal & Beach Weddings"
                                        />
                                    </p>
                                </AccordionTab>
                            </Accordion>
                        </div>
                        <Divider className="" />

                        <div className="card">
                            <Accordion>
                                <AccordionTab
                                    header={
                                        <>
                                            <div className="flex align-items-center ml-2">
                                                <h1 className="text-lg font-medium" style={{ color: "#1C1C1C" }}>
                                                    Price
                                                </h1>
                                            </div>
                                        </>
                                    }
                                >
                                    <div className="m-0">
                                        <CustomCheckbox
                                            optionLabel="Under £40"
                                            labeClassName="text-dark-gray-light"
                                            inputId="ingredient1"
                                            name="Under £40"
                                            value="Under £40"
                                        />

                                        <CustomCheckbox
                                            optionLabel="£40-£70"
                                            labeClassName="text-dark-gray-light"
                                            inputId="ingredient2"
                                            name="£40-£70"
                                            value="£40-£70"
                                        />

                                        <CustomCheckbox
                                            optionLabel="£70-£100"
                                            labeClassName="text-dark-gray-light"
                                            inputId="ingredient3"
                                            name="£70-£100"
                                            value="£70-£100"
                                        />

                                        <CustomCheckbox
                                            optionLabel="£100+"
                                            labeClassName="text-dark-gray-light"
                                            inputId="ingredient4"
                                            name="£100+"
                                            value="£100+"
                                        />
                                    </div>
                                </AccordionTab>
                            </Accordion>
                        </div>
                        <Divider />

                        <div className="card">
                            <Accordion>
                                <AccordionTab
                                    header={
                                        <>
                                            <div className="flex align-items-center ml-2">
                                                <h1 className="text-lg font-medium" style={{ color: "#1C1C1C" }}>
                                                    No. of Guests
                                                </h1>
                                            </div>
                                        </>
                                    }
                                >
                                    <div className="m-0">
                                        <CustomCheckbox
                                            optionLabel="0-99"
                                            labeClassName="text-dark-gray-light"
                                            inputId="ingredient1"
                                            name="0-99"
                                            value="0-99"
                                        />

                                        <CustomCheckbox
                                            optionLabel="100-199"
                                            labeClassName="text-dark-gray-light"
                                            inputId="ingredient2"
                                            name="100-199"
                                            value="100-199"
                                        />

                                        <CustomCheckbox
                                            optionLabel="200-299"
                                            labeClassName="text-dark-gray-light"
                                            inputId="ingredient3"
                                            name="200-299"
                                            value="200-299"
                                        />

                                        <CustomCheckbox
                                            optionLabel="300+"
                                            labeClassName="text-dark-gray-light"
                                            inputId="ingredient4"
                                            name="300+"
                                            value="300+"
                                        />
                                    </div>
                                </AccordionTab>
                            </Accordion>
                        </div>
                        <Divider />

                        <div className="flex align-items-center justify-content-between">
                            <p className="text-lg font-normal underline" style={{ color: "#1C1C1C" }}>
                                Clear Filters
                            </p>
                            <span>
                                <ButtonComponent label="View Result(3)"></ButtonComponent>
                            </span>
                        </div>
                    </Sidebar>
                </ContainerBox>
            </div>
            {!invitationId && (
                <ContainerBox>
                    <div className=" flex gap-4 md:gap-8 p-2 w-full overflow-x-auto  p-0 m-0">
                        <div className="md:ml-4 min-w-max	 " onClick={() => navigate("/host/tentative-vendor")}>
                            <h3
                                className={
                                    location?.pathname === "/host/tentative-vendor"
                                        ? "text-sm font-bold text-main-color cursor-pointer"
                                        : "text-sm font-normal text-dark-gray cursor-pointer"
                                }
                            >
                                Vendors
                            </h3>
                        </div>
                        <div className="md:ml-4 min-w-max	" onClick={() => navigate("/host/guest")}>
                            <h3
                                className={
                                    location?.pathname === "/host/guest"
                                        ? "text-sm font-bold text-main-color cursor-pointer"
                                        : "text-sm font-normal text-dark-gray cursor-pointer"
                                }
                            >
                                Guests
                            </h3>
                        </div>
                        <div
                            className="md:ml-4 min-w-max	"
                            //  onClick={() => navigate("/host/wedding-website")}
                            onClick={onWeddingWebsiteClick}
                        >
                            <h3
                                className={
                                    location?.pathname.includes("/host/wedding-website-template")
                                        ? "text-sm font-bold text-main-color cursor-pointer"
                                        : "text-sm font-normal text-dark-gray cursor-pointer"
                                }
                            >
                                Wedding Website
                            </h3>
                        </div>
                        <div className="md:ml-4 min-w-max	" onClick={() => navigate("/host/seating-planner")}>
                            <h3
                                className={
                                    location?.pathname === "/host/seating-planner"
                                        ? "text-sm font-bold text-main-color cursor-pointer"
                                        : "text-sm font-normal text-dark-gray cursor-pointer"
                                }
                            >
                                Seating Chart
                            </h3>
                        </div>
                        <div className="md:ml-4 min-w-max	" onClick={() => navigate("/host/budget")}>
                            <h3
                                className={
                                    location?.pathname === "/host/budget"
                                        ? "text-sm font-bold text-main-color cursor-pointer"
                                        : "text-sm font-normal text-dark-gray cursor-pointer"
                                }
                            >
                                Budget Planner
                            </h3>
                        </div>
                    </div>
                </ContainerBox>
            )}
            {option === true ? (
                <div
                    className="overlay-box fixed w-full h-full z-2
      top-0
      "
                    style={{ background: "#ff000000" }}
                    onClick={() => setOption(false)}
                ></div>
            ) : null}
        </>
    );
}
