import React from "react";
import { Divider } from "primereact/divider";

export default function MarriageContent({
  BudgetVendorData,
  setSelectedVendorType,
  selectedVendorType,
  setBudgetData,
  budgetData,
}) {
  return (
    <>
      <div className="">
        {BudgetVendorData?.budgetList?.length > 0 &&
          BudgetVendorData?.budgetList?.map((item) => {
            return (
              <>
                <div
                  className={`flex align-items-center justify-content-between font-semibold p-2 cursor-pointer ${
                    selectedVendorType._id === item._id ? "bg-gray-100" : ""
                  }`}
                  onClick={() => {
                    setSelectedVendorType(item);
                    setBudgetData([]);
                  }}
                >
                  <p className="text-sm font-medium font-semibold text-light-black ml-3">
                    {" "}
                    {item.name}
                  </p>
                  <span className="text-sm font-semibold text-dark-gray flex align-items-center mr-3">
                    {item?.addedBudget}
                    <i
                      className="pi pi-angle-right ml-2"
                      style={{ fontSize: "1rem" }}
                    />
                  </span>
                </div>
                <Divider className="p-0 m-0" />
              </>
            );
          })}
      </div>
    </>
  );
}
