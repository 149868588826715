import React, { useEffect, useState } from "react";
import { Signup } from "../signup";
import SignupImg from "../../assets/images/Group 1000008906.png";
import { TabPanel, TabView } from "primereact/tabview";
import { Host } from "../signup/Host";

import Logo from "../../assets/images/logo.png";
import { ContainerBox } from "../../shared/ContainerBox";
import { useLocation, useNavigate } from "react-router-dom";
import { Bussiness } from "../signup/Bussiness";

const ProfileSetup = ({ isLoaded }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const { state, search } = useLocation();
  const navigate = useNavigate();

  //   useEffect(() => {
  //     if (state?.data === true || search === "?user=vendor") {
  //       setActiveIndex(1);
  //     }
  //   }, [state]);

  //   useEffect(() => {
  //     if (activeIndex === 0) navigate("/profile-setup?user=host");
  //     else navigate("/profile-setup?user=vendor");
  //   }, [activeIndex]);
  return (
    <ContainerBox containerType="">
      <div className="grid p-0 m-0">
        <div className="lg:col-6 lg:p-5 md:p-5  text-center signup-left">
          <img src={SignupImg} alt="" className="w-full" />
        </div>

        <div className="lg:col-6 mt-4">
          <div className="bg-white lg:p-4 md:p-4 py-5">
            <h1 className="font-32 merriweather font-normal mb-2 flex align-items-center  text-dark-gray">
              Grow your Business with{" "}
              <img src={Logo} width={149} height={38} alt="" className="ml-3" />
            </h1>
            <div className="text-gray font-medium text-sm mb-4">
              <p>Sign Up to access your Dashboard</p>
            </div>
            <TabView
              className="register"
              activeIndex={activeIndex}
              onTabChange={(e) => setActiveIndex(e.index)}
            >
              <TabPanel header="Host Sign Up">
                <Host />
              </TabPanel>
              <TabPanel header="Business Sign Up">
                hello baby
                <Bussiness />
              </TabPanel>
            </TabView>
          </div>
        </div>
      </div>
    </ContainerBox>
  );
};

export default ProfileSetup;
