import React from "react";
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
} from "react-google-places-autocomplete";
import Constants from "../../services/constant";
import InputLayout from "./InputLayout";

export default function SearchBox({
  searchValue,
  setSearchValue,
  placeholder,
  setLocation,
  label,
  labelClassName,
  col,
  name,
  required,
  extraClassName,
  data,
  errorMessage,
  cityOnly = false,
  clearSearchValue,
}) {
  
  const SearchInput = () => {
    return (
      <div className="relative w-full">
        <GooglePlacesAutocomplete
          selectProps={{
            onChange: (value) => handleSelect(value),
            placeholder: searchValue ? searchValue : placeholder,
            className: searchValue ? "search-value" : "",
            onClick: () => setLocation(""),
          }}
          apiKey={Constants.GOOGLE_API}
          autocompletionRequest={{
            // types: ["(cities)"],
            types: cityOnly ? ["(cities)"] : ["address"],
            componentRestrictions: { country: ["uk"] },
          }}
          debounce={1000}
          minLengthAutocomplete={2}
        />
        {searchValue && !cityOnly ? (
          <span
            className=" absolute top-0 right-0 w-2rem h-full flex justify-content-center align-items-center bg-white text-red-500 cursor-pointer"
            onClick={clearSearchValue}
          >
            <i className="pi pi-times" />
          </span>
        ) : null}
      </div>
    );
  };
  const handleSelect = async (value) => {
    console.log("value", value);
    try {
      const results = await geocodeByPlaceId(value?.value?.place_id);
      //   const addressComponents = results[0].address_components;
      //   const formattedAddress = results[0].formatted_address;

      //   // Extract specific details like postal code
      //   let postalCode;
      //   for (let component of addressComponents) {
      //     if (component.types.includes("postal_code")) {
      //       postalCode = component.long_name;
      //       break;
      //     }
      //   }

      //   console.log("Formatted Address:", formattedAddress);
      //   console.log("Postal Code:", postalCode);
      setSearchValue(results[0]);

      // Handle selected place here
    } catch (error) {
      console.error("Error fetching place details:", error);
    }
  };
  return (
    <div className={`search-autocomplete ${extraClassName}`}>
      {label ? (
        <InputLayout
          labelClassName={labelClassName}
          col={col || 6}
          label={label}
          name={name}
          required={required}
          data={data}
          errorMessage={errorMessage}
        >
          <SearchInput />
        </InputLayout>
      ) : (
        <SearchInput />
      )}
    </div>
  );
}
