import React, { useEffect, useRef, useState } from "react";
import { ContainerBox } from "../../shared/ContainerBox";
import { SupplierCard } from "../../components/cards/card";
import { Paginator } from "primereact/paginator";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import WeedingSupplierImg from "../../assets/images/user.png";
import Constants from "../../services/constant";
import VendorNotFoundImg from "../../assets/images/vendorNotFound.png";
import ButtonComponent from "../../components/buttons/button";
import { useNavigate } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import ExploreData from "./ExploreContent";
import FilterIcon from "../../assets/icons/filter.png";
import { Sidebar } from "primereact/sidebar";
import { Accordion, AccordionTab } from "primereact/accordion";
import { CustomCheckbox } from "../../components/input/AllInput";
import { Divider } from "primereact/divider";
import { Rating } from "primereact/rating";
import { Slider } from "primereact/slider";
import NoDataFound from "../../assets/images/NoDataFound.png";
import { filterVendors } from "../../redux/actions/BusinessProfile";
import { formatCurrency } from "../../utils/javascript";
import SearchBox from "../../components/input/SearchBox";
export const ExploreList = ({ isLoaded }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  const vendors = useSelector((state) => state?.vendor?.allVendors);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const [page, setPage] = useState(0);
  const [totalRecords, setTotalRecords] = useState(1);

  useEffect(() => {
    if (vendors?.vendors?.length) {
      setTotalRecords(Math.ceil(vendors?.vendors?.length / rows));
    }
  }, [vendors?.vendors]);
  const onPageChange = (event) => {
    setFirst(event.first);
    setRows(event.rows);
    setPage(event.page);
  };
  const gotoBtn = () => {
    window.scrollTo({ top: 0, left: 0 });
  };
  const {
    handleSearchChange,
    serachVendorData,
    searchVendor,
    visibleRight,
    setVisibleRight,
    amenitiesData,
    guestCapcity,
    language,
    amenities,
    handleCapcity,
    handleChangeAmenities,
    capacity,
    rating,
    setRating,
    languages,
    handleLanguage,
    setPostalCode,
    postalCode,
    value,
    setValue,
    handleClear,
    filterCount,
    handleView,
    state,
  } = ExploreData();
  const handleSliderChange = (e) => {
    const [newValueStart, newValueEnd] = e.value;
    if (newValueStart <= newValueEnd) {
      setValue([newValueStart, newValueEnd]);
    }
  };
  useEffect(() => {
    if (id && state === null) {
      let params = {
        vendorType: id,
        page: page + 1,
      };
      dispatch(filterVendors(params));
    }
  }, [page, id, state]);
  const inputRef = useRef();
  const handlePlaceChanged = (place) => {
    if (place) {
      let addressVar = {
        city: "",
      };
      place?.address_components?.map((address) => {
        if (
          address?.types?.includes("postal_town") ||
          address?.types?.includes("locality")
        ) {
          addressVar.city = address?.long_name;
        }
        setPostalCode(addressVar?.city);
      }, []);
    }
  };

  const clearSearchValue = () => {
    setPostalCode("");
  };

  return (
    <ContainerBox
      containerType="container py-4"
      titleClass="mt-4"
      descriptionclass="text-light-black"
    >
      <div className="grid justify-content-end mt-3 mb-3 flex  p-0 ">
        {vendors?.vendorTypeName ? (
          <div className="col-12 md:col-6">
            <h1 className="merriweather text-2xl text-dark-gray font-bold mb-5">
              {vendors?.vendorTypeName
                ? vendors?.vendorTypeName
                : serachVendorData?.vendorTypeName}
            </h1>
          </div>
        ) : null}
        <div className="col-12 md:col-5 flex">
          <div className="w-full">
            <div className="p-inputgroup flex-1 mb-6 w-full">
              <InputText
                placeholder="Search Vendor"
                className="bg-blue-50 w-full"
                onChange={handleSearchChange}
              />
              <Button icon="pi pi-search" className="p-button-primary" />
            </div>
          </div>
          <span className="ml-5">
            <button
              onClick={() => setVisibleRight(true)}
              className="lg:px-5 flex align-items-center border-1  w-full border-round-lg bg-transparent cursor-pointer"
              style={{ borderColor: "#CDD4F0", padding: "0.8rem" }}
            >
              <img
                src={FilterIcon}
                width={24}
                height={24}
                alt=""
                className=""
              />
              <span className="ml-2 text-base font-medium text-light-black">
                Filters
              </span>
            </button>
          </span>
        </div>
        {/* <div className="col-12 md:col-2">
          <ButtonComponent label="Add Vendor" className=""></ButtonComponent>
        </div> */}
      </div>
      <div className="flex justify-content-start gap-5 flex-wrap">
        {(serachVendorData?.vendors?.length === 0 &&
          vendors?.vendors?.length > 0) ||
        (serachVendorData?.vendors?.length === 0 && vendors?.length === 0) ? (
          <div className="grid justify-content-center flex w-full">
            <div className="col-12 md:col-12 text-center">
              <img
                src={NoDataFound}
                width={327}
                height={241}
                alt=""
                className=""
              />
              <p className="text-lg font-bold text-dark-gray mb-5">
                No Data Found
              </p>
            </div>
          </div>
        ) : serachVendorData?.vendors?.length > 0 ? (
          serachVendorData?.vendors?.map((item) => {
            return (
              <SupplierCard
                img={
                  item.profileImage
                    ? Constants.HOST + item?.profileImage
                    : WeedingSupplierImg
                }
                rating={item.avgRating}
                title={item.businessName}
                location={item.city}
                item={item}
                person={item.person}
                id={item._id}
                onClick={gotoBtn}
                SubscriptionType={item?.subscriptionType}
              />
            );
          })
        ) : (vendors?.vendors?.length > 0 && searchVendor === undefined) ||
          (vendors?.vendors?.length > 0 && searchVendor === "") ? (
          vendors?.vendors?.map((item) => {
            return (
              <SupplierCard
                img={
                  item.profileImage
                    ? Constants.HOST + item?.profileImage
                    : WeedingSupplierImg
                }
                rating={item.avgRating}
                title={item.businessName}
                location={item.city}
                item={item}
                person={item.person}
                id={item._id}
                onClick={gotoBtn}
              />
            );
          })
        ) : (
          <>
            <div className="grid justify-content-center flex w-full">
              <div className="col-12 md:col-12 text-center">
                <img
                  src={VendorNotFoundImg}
                  width={327}
                  height={241}
                  alt=""
                  className=""
                />
                <p className="text-sm font-medium text-dark-gray mb-5">
                  No vendors available for this category. Are you a business?
                </p>
                <ButtonComponent
                  label="Register Now To Be Listed!"
                  onClick={() => navigate("/signup")}
                ></ButtonComponent>
              </div>
            </div>
          </>
        )}
      </div>
      {vendors?.vendors?.length > 0 ? (
        <Paginator
          first={first}
          rows={rows}
          totalRecords={totalRecords}
          onPageChange={onPageChange}
        />
      ) : null}
      <Sidebar
        visible={visibleRight}
        position="right"
        onHide={() => setVisibleRight(false)}
      >
        <h1 className="merriweather text-2xl text-dark-gray font-bold mb-5">
          All Filters
          <small className="text-sm font-medium text-main-color ml-2">
            Applied ({filterCount})
          </small>
        </h1>
        <div className="card">
          <Accordion activeIndex={null}>
            <AccordionTab
              header={
                <>
                  <div className="flex align-items-center">
                    <span className="ml-3">
                      {" "}
                      <h1
                        className="text-base font-semibold"
                        style={{ color: "#1C1C1C" }}
                      >
                        Amenities
                      </h1>
                    </span>
                  </div>
                </>
              }
            >
              <p className="m-0">
                {amenitiesData?.map((item) => {
                  return (
                    <CustomCheckbox
                      optionLabel={item?.name}
                      labeClassName="text-dark-gray-light"
                      inputId="ingredient1"
                      name="Wedding Venue"
                      value={item}
                      onChange={handleChangeAmenities}
                      checked={
                        amenities?.some((type) => type._id === item._id)
                          ? true
                          : false
                      }
                    />
                  );
                })}
              </p>
            </AccordionTab>
          </Accordion>
        </div>
        <Divider />
        <div className="card">
          <Accordion activeIndex={null}>
            <AccordionTab
              header={
                <>
                  <div className="flex align-items-center">
                    <span className="ml-3">
                      {" "}
                      <h1
                        className="text-base font-semibold"
                        style={{ color: "#1C1C1C" }}
                      >
                        Budget
                      </h1>
                    </span>
                  </div>
                </>
              }
            >
              <p className="m-0">
                <p className="text-center">
                  {formatCurrency(value[0])}-{formatCurrency(value[1])}
                </p>
                <Slider
                  value={value}
                  onChange={handleSliderChange}
                  className="w-full"
                  range
                  min={250}
                  max={25000}
                  step={250}
                />
              </p>
            </AccordionTab>
          </Accordion>
        </div>

        {id === "655da6895d66b696c7adf812" ? (
          <>
            <Divider />
            <div className="card">
              <Accordion activeIndex={null}>
                <AccordionTab
                  header={
                    <>
                      <div className="flex align-items-center">
                        <span className="ml-3">
                          {" "}
                          <h1
                            className="text-base font-semibold"
                            style={{ color: "#1C1C1C" }}
                          >
                            Guests Capacity
                          </h1>
                        </span>
                      </div>
                    </>
                  }
                >
                  <p className="m-0">
                    {guestCapcity?.map((item) => {
                      return (
                        <CustomCheckbox
                          optionLabel={item}
                          labeClassName="text-dark-gray-light"
                          inputId="ingredient1"
                          name="Wedding Venue"
                          value={item}
                          onChange={(e) => handleCapcity(e)}
                          checked={capacity === item}
                        />
                      );
                    })}
                  </p>
                </AccordionTab>
              </Accordion>
            </div>
          </>
        ) : (
          ""
        )}
        <Divider />
        <div className="card">
          <Accordion activeIndex={null}>
            <AccordionTab
              header={
                <>
                  <div className="flex align-items-center">
                    <span className="ml-3">
                      {" "}
                      <h1
                        className="text-base font-semibold"
                        style={{ color: "#1C1C1C" }}
                      >
                        Ratings
                      </h1>
                    </span>
                  </div>
                </>
              }
            >
              <p className="m-0">
                <div className="grid">
                  <div className="col-12 md:col-2 ">
                    <div className="mt-1">
                      <CustomCheckbox
                        labeClassName="text-dark-gray-light"
                        inputId="ingredient1"
                        name="Wedding Venue"
                        value={5}
                        onChange={(e) =>
                          setRating((prev) => (prev !== 5 ? 5 : ""))
                        }
                        checked={rating === 5}
                      />
                    </div>
                  </div>
                  <div className="col-12 md:col-10">
                    <div className="rating mt-2">
                      <Rating value={5} readOnly cancel={false} />
                    </div>
                  </div>
                </div>
                <div className="grid">
                  <div className="col-12 md:col-2 ">
                    <CustomCheckbox
                      labeClassName="text-dark-gray-light"
                      inputId="ingredient1"
                      name="Wedding Venue"
                      value={4}
                      onChange={(e) =>
                        setRating((prev) => (prev !== 4 ? 4 : ""))
                      }
                      checked={rating === 4}
                    />
                  </div>
                  <div className="col-12 md:col-10">
                    <div className="rating mt-1">
                      <Rating value={4} readOnly cancel={false} />
                    </div>
                  </div>
                </div>
                <div className="grid">
                  <div className="col-12 md:col-2 ">
                    <CustomCheckbox
                      labeClassName="text-dark-gray-light"
                      inputId="ingredient1"
                      name="Wedding Venue"
                      value={3}
                      onChange={(e) =>
                        setRating((prev) => (prev !== 3 ? 3 : ""))
                      }
                      checked={rating === 3}
                    />
                  </div>
                  <div className="col-12 md:col-10">
                    <div className="rating mt-1">
                      <Rating value={3} readOnly cancel={false} />
                    </div>
                  </div>
                </div>
                <div className="grid ">
                  <div className="col-12 md:col-2 ">
                    <CustomCheckbox
                      labeClassName="text-dark-gray-light"
                      inputId="ingredient1"
                      name="Wedding Venue"
                      value={2}
                      onChange={(e) =>
                        setRating((prev) => (prev !== 2 ? 2 : ""))
                      }
                      checked={rating === 2}
                    />
                  </div>
                  <div className="col-12 md:col-10">
                    <div className="rating mt-1">
                      <Rating value={2} readOnly cancel={false} />
                    </div>
                  </div>
                </div>
                <div className="grid ">
                  <div className="col-12 md:col-2 ">
                    <CustomCheckbox
                      labeClassName="text-dark-gray-light"
                      inputId="ingredient1"
                      name="Wedding Venue"
                      value={1}
                      onChange={(e) =>
                        setRating((prev) => (prev !== 1 ? 1 : ""))
                      }
                      checked={rating === 1}
                    />
                  </div>
                  <div className="col-12 md:col-10">
                    <div className="rating mt-1">
                      <Rating value={1} readOnly cancel={false} />
                    </div>
                  </div>
                </div>
              </p>
            </AccordionTab>
          </Accordion>
        </div>
        <Divider />
        <div className="card">
          <Accordion activeIndex={null}>
            <AccordionTab
              header={
                <>
                  <div className="flex align-items-center">
                    <span className="ml-3">
                      {" "}
                      <h1
                        className="text-base font-semibold"
                        style={{ color: "#1C1C1C" }}
                      >
                        Language
                      </h1>
                    </span>
                  </div>
                </>
              }
            >
              <p className="m-0">
                {language?.map((item) => {
                  return (
                    <CustomCheckbox
                      optionLabel={item}
                      labeClassName="text-dark-gray-light"
                      inputId="ingredient1"
                      name="Wedding Venue"
                      value={item}
                      onChange={(e) => handleLanguage(e)}
                      checked={languages.includes(item)}
                    />
                  );
                })}
              </p>
            </AccordionTab>
          </Accordion>
        </div>
        <Divider />
        <div className="card">
          <Accordion activeIndex={null}>
            <AccordionTab
              header={
                <>
                  <div className="flex align-items-center">
                    <span className="ml-3">
                      {" "}
                      <h1
                        className="text-base font-semibold"
                        style={{ color: "#1C1C1C" }}
                      >
                        Location
                      </h1>
                    </span>
                  </div>
                </>
              }
            >
              <div className="">
                <SearchBox
                  searchValue={postalCode}
                  setSearchValue={handlePlaceChanged}
                  placeholder="Enter Location"
                  extraClassName="border"
                  clearSearchValue={clearSearchValue}
                  cityOnly={true}
                />
              </div>
            </AccordionTab>
          </Accordion>
        </div>

        <Divider />
        <div className="flex align-items-center justify-content-between">
          <p
            className="text-lg font-normal underline cursor-pointer"
            style={{ color: "#1C1C1C" }}
            onClick={handleClear}
          >
            Clear Filters
          </p>
          <span>
            <ButtonComponent
              // label={`View Results ${serachVendorData?.count}`}
              label={`View Results`}
              onClick={handleView}
            ></ButtonComponent>
          </span>
        </div>
      </Sidebar>
    </ContainerBox>
  );
};
