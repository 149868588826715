import React from "react";
import { ContainerBox } from "../../shared/ContainerBox";
import { WorkCard } from "../../components/cards/card";
import WorkContent from "./WorkContent";

export const ForHost = () => {
  const { ForHostContent } = WorkContent();
  return (
    <ContainerBox
      containerType="container pt-3 md:py-6 md:mb-6"
      title="Enjoy planning your wedding"
      descriptionclass="text-base mb-6 lg:w-10"
      description="Start planning your wedding with us, it's free! Find everything you need to plan your South Asian wedding in one place."
    >
      <div className="flex text-center justify-content-between flex-wrap">
        {ForHostContent.map((item) => {
          return <WorkCard src={item.img} title={item.title} />;
        })}
      </div>
    </ContainerBox>
  );
};
