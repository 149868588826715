import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { checkUserRole, isAuthenticated } from "../services/authService";
import {
  HostLayout,
  PublicLayout,
  VendorLayout,
} from "../layout/NewLayout/PublicLayout";

export const HostRoutes = () => {
  return isAuthenticated() && checkUserRole("Host") ? (
    <HostLayout>
      <Outlet />
    </HostLayout>
  ) : (
    <Navigate to="/" />
  );
};

export const VendorRoute = ({ isLoaded }) => {
  return isAuthenticated() && checkUserRole("Vendor") ? (
    <VendorLayout>
      <Outlet isLoaded={isLoaded} />
    </VendorLayout>
  ) : (
    <Navigate to="/login" />
  );
};

export const PublicRoutes = () => {
  return (
    <PublicLayout>
      <Outlet />
    </PublicLayout>
  );
};
