import React from "react";
import { IndexLayout } from "../../layout";
import { ContainerBox } from "../../shared/ContainerBox";
import ForgotImg from "../../assets/images/forgot.png";
import CheckIcon from "../../assets/icons/successgif.png";
import ButtonComponent from "../../components/buttons/button";
import { Link } from "react-router-dom";

export const SignupSuccessful = () => {
  return (
    <>
      <ContainerBox containerType="">
        <div className="grid p-0 m-0">
          <div className="lg:col-6 lg:p-5 md:p-5">
            <img src={ForgotImg} alt="" className="w-full" />
          </div>
          <div className="lg:col-6 flex align-items-center">
            <div className="bg-white lg:p-4 md:p-4 py-5">
              <img
                src={CheckIcon}
                width={105}
                height={95}
                alt=""
                className="mb-3"
              />
              <h4 className="font-32 merriweather font-normal text-dark-gray ml-3 p-0 m-0">
                Congratulations on your successful{" "}
                <span className="font-bold">sign-up!</span>
              </h4>
              <p className="text-dark-gray ml-3 mb-5 font-normal">
                {" "}
                Email is verified. Thankyou for registering with us.
              </p>
              <Link to="/membership-plan" className="no-underline">
                <ButtonComponent label="Proceed" className="ml-3" />
              </Link>
            </div>
          </div>
        </div>
      </ContainerBox>
    </>
  );
};
