export default class Constants {
    // local URL
    // static HOST = "https://plantheknotapi.appdeft.in/";
    // static BASE_URL = "https://plantheknotapi.appdeft.in/api";
    // static FRONTEND_URL = "https://plantheknot.appdeft.biz/#/";

    // static HOST = "http://localhost:1105/";
    // static BASE_URL = "http://localhost:1105/api";
    // static FRONTEND_URL = "http://localhost:3000/#/";

    // Production URL
    static HOST = "https://app.plantheknot.com/";
    static BASE_URL = "https://app.plantheknot.com/api";
    static FRONTEND_URL = "https://plantheknot.com/";

    static GOOGLE_API = "AIzaSyDkSAurwFH1rrpDoTgSbspE2zJS-XNeBMQ";

    static END_POINT = {
        // Vendors
        VENDOR_LOGIN: "/common/login",
        VENDOR_SIGNUP: "/common/signup",
        VENDOR_RESET_PASSWORD: "/common/reset-successful",
        VENDOR_SEND_OTP: "/common/request-otp",
        VENDOR_CONTACT: "/vendor/contact",
        VENDOR_CATEGORIES: "/common/vendor-types",
        VENDOR_LIST: "/common/vendors/",
        VENDOR_PROFILE: "/vendor/profile",
        UPDATE_PROFILE: "/vendor/profile",
        QUOTE_REQUEST: "/vendor/quote",
        DELETE_QUOTE: "/vendor/quote/decline/",
        VIEW_QUOTE_DATA: "/vendor/quote/",
        IMPORTANT_QUOTE: "/vendor/quote/markImp/",
        AMENITIES: "/vendor/amenities",
        DELETE_VENDOR_PROFILE: "/vendor/profile",
        QUOTE_GRAPH: "/vendor/quote/graph",
        ALL_SUBSCRIPTION_TYPE: "/vendor/subscription-type",
        SUBSCRIPTION_PLAN: "/vendor/subscription-type",
        PAYMENT: "/vendor/subscription",
        SHOW_PLAN: "/vendor/subscription",
        MEMBERSHIPPLANACTIVEINACTIVE: "/vendor/subscription-check",
        CANCEL_PLAN: "/vendor/subscription/",
        CHANGE_PLAN: "/vendor/subscription-change",

        // common
        HOST_SIGNUP: "/common/signup",
        HOST_LOGIN: "/common/login",
        VENDOR_VIEW: "/common/vendors",
        UPLOAD_FILES: "/common/file-upload",
        FORGOT_PASSWORD: "/common/forget-password",
        VERIFY_OTP: "/common/verify-otp",
        VERIFY_FORGET_PASSWORD: "/common/forget-password-verify-otp",
        SET_PASSWORD: "/common/change-forget-password",
        FILTER_VENDORS: "/common/vendors",
        FILTER_AMENTIES: "/common/amenities",
        SOCIAL_LOGIN: "/common/social-signin",
        GUEST_TEMPLATE: "/common/guest/",
        //host
        ADD_REVIEW: "/host/review/",
        ADD_QUOTE: "/host/quote/",
        WEBSITE_TEMPLATE: "/host/wedding-website/",
        PUBLIC_WEBSITE_TEMPLATE: "/common/wedding-website/",

        //host profile
        MYPROFILE: "/host/profile/",
        HOST_PROFILE_PASSWORD_CHANGE: "/common/change-password/",
        DELETE_HOST_PROFILE: "/host/profile/",
        HOST_ADD_TENTATIVE: "/host/my-vendors",
        ALL_TENTATIVE_VENDORS: "/host/my-vendors-by-type/",
        WEDDING_RECEPTION_COUNT: "/host/profile-cards",
        BUDGET_LIST: "/host/budget-planner",
        ADD_BUDGET: "/host/budget-planner",
        GUEST: "/host/guest",
        SEATING: "/host/seating-planner",
    };
}
