import { types } from "../types";

const initialState = {
  getHostWebsite: {},
};

const weddingWebsiteReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.WEBSITE_TEMPLATE:
      return { ...state, getHostWebsite: action.payload };
    default:
      return state;
  }
};

export default weddingWebsiteReducer;
