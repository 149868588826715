import React, { useEffect, useState } from "react";
import { IndexLayout } from "../../layout";
import BusinessBannerImg from "../../assets/images/business wedding banner.png";
import { TabView, TabPanel } from "primereact/tabview";
import { ContainerBox } from "../../shared/ContainerBox";
import { CustomCalenderInput, CustomInput, CustomInputNumber, CustomPassword, CustomTextArea } from "../../components/input/AllInput";
import { CardComponent } from "../../components/cards/card";
import ButtonComponent from "../../components/buttons/button";
import About from "./About";
import Location from "./Location";
import Photos from "./Photos";
import { AboutIndex } from "./AboutIndex";
import Constants from "../../services/constant";
import { Link, useLocation, useNavigate } from "react-router-dom";
import TabPanelVendor from "../overview-vendor/TabPanelVendor";
import { quoteDataAction } from "../../redux/actions/bussinessInformationAction";
import { useDispatch, useSelector } from "react-redux";
import { isAuthenticated } from "../../services/authService";
import { Carousel } from "primereact/carousel";
import { Dialog } from "primereact/dialog";
import { SocialLogin } from "../../pages/signup/CommonFeild";
import { googlelogin, signInAction, socialLogin } from "../../redux/actions/signupAction";
import { useGoogleLogin } from "@react-oauth/google";
import { allValidations } from "../../utils/formValidations";
import { showToast } from "../../redux/actions/toastAction";
import { types } from "../../redux/types";
export default function Business({ isLoaded }) {
    const location = useLocation();
    const data = location?.state;
    const {
        vendorData,
        setBusinessFilter,
        businessFilter,
        photosFilter,
        videosFilter,
        preWeddingFilter,
        reviewData,
        setReviewData,
        handleChange,
        handleFileChange,
        handleSubmit,
        quoteData,
        setQuoteData,
        handleChangeQuote,
        removeTentative,
        businessImage,
        handleQuoteSubmit,
        handleTentative,
        id,
        tentativeHeighlight,
        loginPopup,
        setLoginPopup,
        subscriptionType,
    } = AboutIndex();
    const dispatch = useDispatch();
    const [activeIndex, setActiveIndex] = useState(null);
    const [sortValue, setSortValue] = useState();
    useEffect(() => {
        let params;
        if (sortValue === "old") {
            params = {
                sortOrder: "DESC",
            };
        } else {
            params = {
                sortValue: "ASC",
            };
        }
        if (!isAuthenticated) {
            dispatch(quoteDataAction(params));
        }
    }, [sortValue]);
    const sort = ["old", "New"];
    useEffect(() => {
        setActiveIndex(null);
        if (data?.naviagte === true) {
            setActiveIndex(null);
        }
    }, [data]);

    const productTemplate = (businessImage) => {
        return (
            <>
                <div className="grid">
                    <div className="col-12 md:col-6 m-auto">
                        <img src={businessImage} alt="" className="w-full " style={{ objectFit: "contain" }} height={600} />
                    </div>
                </div>
            </>
        );
    };
    const [form, setForm] = useState({
        email: "",
        password: "",
        userType: "",
    });
    const [errors, setErrors] = useState({});
    const navigate = useNavigate();

    const setField = (field, value) => {
        setForm({
            ...form,
            [field]: value,
        });
        if (!!errors[field]) {
            setErrors({
                ...errors,
                [field]: null,
            });
        }
    };
    const updateFormErrors = (errors) => {
        // Extract the error text from the errors object
        const errorText = Object.keys(errors).reduce((acc, key) => {
            acc[key] = errors[key][Object.keys(errors[key])[0]];
            return acc;
        }, {});

        setErrors(errorText);
    };

    const validateSubmit = (e) => {
        e.preventDefault();

        const tempErrors = {
            email: allValidations("email", form.email, form),
        };

        updateFormErrors(tempErrors);

        const hasErrors = Object.values(tempErrors).some((errors) => Object.values(errors).some((error) => error !== ""));

        if (hasErrors) {
            return;
        }
        handleSubmitLogin();
    };
    // const userData = useSelector((state)=>state)
    const handleSubmitLogin = () => {
        if (!form.email || !form.password) {
            return;
        }

        dispatch(signInAction(form, navigate));
    };

    const googleLoginHandler = useGoogleLogin({
        onSuccess: async (respose) => {
            dispatch(googlelogin(respose)).then((res) => {
                if (res.data.sub) {
                    let payload = {
                        email: res.data.email,
                        socialId: res.data.sub,
                        loginType: "Google",
                        name: res.data.given_name,
                    };
                    dispatch(socialLogin(payload)).then((res) => {
                        console.log("res>>", res);

                        if (res.success) {
                            if (res) {
                                navigate("/host/profile");
                            } else {
                                navigate("/login");
                            }
                        } else {
                            dispatch(showToast({ severity: "error", summary: res.message }));
                        }
                    });
                }
            });
        },
    });

    const { toastInfo } = useSelector((state) => state?.toast);
    useEffect(() => {
        return () => {
            dispatch({ type: types.SHOW_TOAST, payload: {} });
        };
    }, []);
    return (
        <>
            {data?.navigate === true ? (
                <TabPanelVendor
                    activeIndex={activeIndex}
                    setActiveIndex={setActiveIndex}
                    quoteData={quoteData}
                    sort={sort}
                    sortValue={sortValue}
                    setSortValue={setSortValue}
                    isLoaded={isLoaded}
                />
            ) : null}
            {activeIndex === null ? (
                <div className="business-container">
                    {vendorData?.images?.length > 0 ? (
                        <>
                            <div className="">
                                <div className="card">
                                    <Carousel value={businessImage} numVisible={1} numScroll={1} itemTemplate={productTemplate} className="custom-carousel" />
                                </div>
                            </div>
                        </>
                    ) : (
                        ""
                    )}
                    <ContainerBox containerType="">
                        <div className="grid p-0 m-0 flex justify-content-between">
                            <div className="col-12 md:col-12 lg:col-8 p-0 m-0">
                                <TabView>
                                    <TabPanel header="About">
                                        <About
                                            vendorData={vendorData}
                                            reviewData={reviewData}
                                            setReviewData={setReviewData}
                                            handleChange={handleChange}
                                            handleFileChange={handleFileChange}
                                            handleSubmit={handleSubmit}
                                            quoteData={quoteData}
                                            setQuoteData={setQuoteData}
                                            loginPopup={loginPopup}
                                            setLoginPopup={setLoginPopup}
                                        />
                                    </TabPanel>

                                    <TabPanel header="Photos">
                                        <Photos
                                            vendorData={vendorData}
                                            setBusinessFilter={setBusinessFilter}
                                            businessFilter={businessFilter}
                                            photosFilter={photosFilter}
                                            videosFilter={videosFilter}
                                            preWeddingFilter={preWeddingFilter}
                                        />
                                    </TabPanel>
                                    {vendorData?.subscriptionType !== "Standard" ? (
                                        <TabPanel header="Location">
                                            <Location vendorData={vendorData} />
                                        </TabPanel>
                                    ) : (
                                        ""
                                    )}
                                </TabView>
                            </div>
                            <div className="col-12 md:col-12 lg:col-4 py-8">
                                <CardComponent className="shadow-1">
                                    <h1 className="merriweather text-2xl text-dark-gray font-bold mb-5">Get a Quote</h1>
                                    <CustomInput
                                        className="border-noround mb-3"
                                        placeholder="Name "
                                        col={12}
                                        name="name"
                                        value={quoteData?.name}
                                        onChange={handleChangeQuote}
                                    />
                                    <CustomInput
                                        className="border-noround mb-3"
                                        placeholder="Email "
                                        col={12}
                                        name="email"
                                        value={quoteData?.email}
                                        onChange={handleChangeQuote}
                                    />
                                    <CustomInputNumber
                                        inputClassName="border-noround w-full mb-3"
                                        className="border-noround w-full mb-3"
                                        placeholder="Phone number"
                                        col={12}
                                        name="phoneNumber"
                                        value={quoteData?.phoneNumber}
                                        onChange={handleChangeQuote}
                                    />
                                    <CustomCalenderInput
                                        inputClassName="border-noround w-full mb-3"
                                        placeholder="Event date"
                                        name="eventDate"
                                        col={12}
                                        value={quoteData?.eventDate}
                                        onChange={handleChangeQuote}
                                    ></CustomCalenderInput>
                                    <CustomInputNumber
                                        inputClassName="border-noround w-full mb-3"
                                        className="border-noround w-full mb-3"
                                        placeholder="Number of guests"
                                        col={12}
                                        name="numberOfGuests"
                                        value={quoteData?.numberOfGuests}
                                        onChange={handleChangeQuote}
                                    />
                                    <CustomTextArea
                                        className="border-noround w-full"
                                        placeholder="Summarise your dream wedding and how this vendor should help you"
                                        rows={4}
                                        col={12}
                                        name="eventSummarise"
                                        value={quoteData?.eventSummarise}
                                        onChange={handleChangeQuote}
                                    />
                                    <ButtonComponent
                                        label="Get a Quote"
                                        className="text-white font-semibold  w-full text-sm"
                                        onClick={handleQuoteSubmit}
                                    ></ButtonComponent>
                                    <div className="col-12">
                                        <p className="sub-heading social-divider relative text-center font-normal" style={{ color: "#9A9A9A" }}>
                                            or
                                        </p>
                                    </div>
                                    <ButtonComponent
                                        label={tentativeHeighlight === true ? "Remove from Tentative" : "Add As Tentative"}
                                        className={
                                            tentativeHeighlight === true
                                                ? "text-light w-full bg-transparent border-pink-color text-sm font-semibold"
                                                : "text-light w-full bg-transparent border-300 text-sm font-semibold "
                                        }
                                        onClick={(e) => (tentativeHeighlight ? removeTentative(id) : handleTentative(id))}
                                    ></ButtonComponent>
                                </CardComponent>
                            </div>
                        </div>
                    </ContainerBox>
                </div>
            ) : null}
            <div className="card flex justify-content-center">
                <Dialog
                    header={
                        <>
                            <h1 className="merriweather font-bold text-2xl text-dark-gray text-center">Please Login.</h1>
                        </>
                    }
                    visible={loginPopup}
                    style={{ width: "100vw" }}
                    onHide={() => setLoginPopup(false)}
                    className="lg:w-4 md:w-10 w-11"
                >
                    <div className="grid mt-3 p-0 m-0">
                        <form className="col-12" onSubmit={validateSubmit}>
                            <CustomInput
                                col={12}
                                placeholder="Email"
                                value={form?.email}
                                id="email"
                                type="email"
                                onChange={(e) => {
                                    setField("email", e.target.value);
                                }}
                                errorMessage={errors?.email}
                            />
                            <CustomPassword
                                col={12}
                                placeholder="Password"
                                value={form?.password}
                                onChange={(e) => {
                                    setField("password", e.target.value);
                                }}
                                toggleMask
                                feedback={false}
                            />

                            <div className="col-12 text-right">
                                <Link to="/forgot-password" className="text-14 font-semibold text-light-gray no-underline">
                                    Forgot Password?
                                </Link>
                            </div>
                            <ButtonComponent label="Sign In" className="w-full lg:w-3 lg:ml-2 md:ml-2" />
                        </form>

                        <SocialLogin googleLoginHandler={googleLoginHandler} />
                        <p className="col-12 text-center lg:text-left">
                            Don’ t have Account?{" "}
                            <Link to="/signup" className="text-main-color no-underline">
                                Register here
                            </Link>
                        </p>
                    </div>
                </Dialog>
            </div>
        </>
    );
}
