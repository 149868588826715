import { showLoaderAction, hideLoaderAction } from "./loaderAction";
import api from "../../services/api";
import Constants from "../../services/constant";
import { showToast } from "./toastAction";
import { types } from "../types";

export const guestAction =
    ({ search }) =>
    async (dispatch) => {
        dispatch(showLoaderAction());
        const res = search ? await api("get", Constants.END_POINT.GUEST + "?search=" + search, {}) : await api("get", Constants.END_POINT.GUEST, {});
        if (res.success) {
            if (res.data) {
                dispatch({
                    type: types.GUEST_LIST,
                    payload: res.data,
                });
            }
            dispatch(hideLoaderAction());
        } else {
            dispatch(hideLoaderAction());
            dispatch(showToast({ severity: "error", summary: res.message }));
        }
    };

export const addGuestAction = (data, next) => async (dispatch) => {
    dispatch(showLoaderAction());

    const { id } = data;

    const res = id ? await api("put", Constants.END_POINT.GUEST + "/" + id, data) : await api("post", Constants.END_POINT.GUEST, data);
    if (res.success) {
        if (res.data) {
            next();
        }
        dispatch(hideLoaderAction());
    } else {
        dispatch(hideLoaderAction());
        dispatch(showToast({ severity: "error", summary: res.message }));
    }
};
export const addGuestAction1 = (data, next) => async (dispatch) => {
    dispatch(showLoaderAction());
    let value = data?.wedding && data?.reception ? "BOTH" : data?.wedding ? "WEDDING" : data?.reception ? "RECEPTION" : "";
    const payload = {
        relativeFrom: data.relativeFrom,
        name: data.name,
        invitedTo: value,
        acceptInviteReception: data?.reception ? "PENDING" : "NOTINVITED",
        acceptInviteWedding: data?.wedding ? "PENDING" : "NOTINVITED",
        familyMemberList: data?.familyMemberList?.map((member) => ({
            ...member,
            acceptInviteReception: data?.reception ? "PENDING" : "NOTINVITED",
            acceptInviteWedding: data?.wedding ? "PENDING" : "NOTINVITED",
        })),
    };
    const res = await api("post", Constants.END_POINT.GUEST, payload);
    if (res.success) {
        if (res.data) {
            next();
        }
    } else {
        dispatch(showToast({ severity: "error", summary: res.message }));
    }
    dispatch(hideLoaderAction());
};

export const updateGuestAction = (data, next) => async (dispatch) => {
    dispatch(showLoaderAction());
    const res = await api("put", `${Constants.END_POINT.GUEST}/${data.id}`, data);
    if (res.success) {
        if (res.data) {
            next();
        }
        dispatch(hideLoaderAction());
    } else {
        dispatch(hideLoaderAction());
        next();
        dispatch(showToast({ severity: "error", summary: res.message }));
    }
};

export const deleteGuestAction = (id, next) => async (dispatch) => {
    dispatch(showLoaderAction());
    const res = await api("delete", `${Constants.END_POINT.GUEST}/${id}`, {});
    if (res.success) {
        if (res.data) {
            next();
        }
        dispatch(hideLoaderAction());
    } else {
        next();
        dispatch(hideLoaderAction());
        dispatch(showToast({ severity: "error", summary: res.message }));
    }
};

export const guestForTemplateAction =
    ({ search }, id) =>
    async (dispatch) => {
        dispatch(showLoaderAction());
        const res = search ? await api("get", Constants.END_POINT.GUEST_TEMPLATE + id + "?search=" + search, {}) : false;
        if (res.success) {
            if (res.data) {
                dispatch({
                    type: types.GUEST_LIST,
                    payload: res.data,
                });
            }
            dispatch(hideLoaderAction());
        } else {
            dispatch(hideLoaderAction());
            dispatch(showToast({ severity: "error", summary: res.message }));
        }
    };

export const guestUpdateStatusAction = (data, next) => async (dispatch) => {
    dispatch(showLoaderAction());

    const res = await api("put", `${Constants.END_POINT.GUEST_TEMPLATE}${data._id}`, data);
    if (res.success) {
        if (res.data) {
            next();
        }
        dispatch(hideLoaderAction());
    } else {
        dispatch(hideLoaderAction());
        next();
        dispatch(showToast({ severity: "error", summary: res.message }));
    }
};
