import React, { useState, useEffect } from "react";
import { IndexLayout } from "../../layout";
import { ContainerBox } from "../../shared/ContainerBox";
import ForgotImg from "../../assets/images/forgot.png";
import ButtonComponent from "../../components/buttons/button";
import OtpInput from "react-otp-input";
import ClockIcon from "../../assets/icons/clock.png";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { showToast } from "../../redux/actions/toastAction";
import { verifyOtpAction } from "../../redux/actions/signupAction";
import { sendOtpAction } from "../../redux/actions/otpActions";
import { types } from "../../redux/types";
export const VerificationOtp = () => {
    const [otp, setOtp] = useState("");
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [codeTimeout, setCodeTimeout] = useState(30);
    const getEmail = localStorage.getItem("email");
    const { toastInfo } = useSelector((state) => state?.toast);
    useEffect(() => {
        return () => {
            dispatch({ type: types.SHOW_TOAST, payload: {} });
        };
    }, []);

    const handleSubmit = () => {
        if (otp === "") {
            dispatch(showToast({ severity: "error", summary: "Please Enter Otp" }));
        } else {
            let payload = {
                otp: otp,
                email: getEmail,
            };
            dispatch(verifyOtpAction(payload)).then((res) => {
                console.log(res, "res");
                if (res.success) {
                    navigate("/new-password", { state: otp });
                } else {
                    dispatch(showToast({ severity: "error", summary: res.message }));
                }
            });
        }
    };
    useEffect(() => {
        const otpInterval = setInterval(() => {
            if (codeTimeout > 0 && codeTimeout <= 30) {
                setCodeTimeout((prev) => prev - 1);
            } else {
                clearInterval(otpInterval); // Stop the interval when codeTimeout is 0
            }
        }, 1000);

        return () => {
            clearInterval(otpInterval);
        };
    }, [codeTimeout]);
    const handleResendCode = () => {
        if (getEmail) {
            dispatch(sendOtpAction({ email: getEmail }, setCodeTimeout(30)));
        }
    };
    useEffect(() => {
        if (codeTimeout > 0) {
            setTimeout(() => setCodeTimeout(codeTimeout - 1), 1000);
        }
    }, [codeTimeout]);
    return (
        <>
            <ContainerBox containerType="">
                <div className="grid p-0 m-0">
                    <div className="lg:col-6 lg:p-5 md:p-5">
                        <img src={ForgotImg} alt="" className="w-full" />
                    </div>
                    <div className="lg:col-6 ">
                        <div className="bg-white lg:p-4 md:p-4 py-5 mt-5">
                            {/* <img src={CheckIcon} alt="" /> */}
                            <h4 className="font-32 mb-2 merriweather font-normal text-dark-gray">We Send a code to your email</h4>
                            {toastInfo?.summary && <div className="p-3 border border-2 alert-error mb-2 text-center">{toastInfo?.summary}</div>}
                            <p className="text-dark-gray text-sm mb-5 font-medium">Enter the 6-digit verification code sent to {getEmail}</p>
                            <OtpInput
                                value={otp}
                                onChange={setOtp}
                                numInputs={6}
                                inputStyle={{
                                    width: "48px",
                                    height: "48px",
                                    border: "1px solid #ECECEC",
                                    borderRadius: "5px",
                                }}
                                renderSeparator={<span>&nbsp;&nbsp;&nbsp;</span>}
                                renderInput={(props) => <input {...props} />}
                            />

                            <div className="flex align-items-end mb-5 mt-3">
                                <ButtonComponent label="Submit" className="mt-3" onClick={handleSubmit} disabled={otp.length < 6} />
                                <span className="ml-3 flex align-items-center text-sm  font-normal" style={{ color: "#425466" }}>
                                    <img src={ClockIcon} height={20} width={20} alt="" />
                                    &nbsp; {codeTimeout} Sec
                                </span>
                            </div>
                            <p className="col-12 font-normal text-gray-light text-sm no-underline p-0 m-0 mb-4">
                                Didn’t receive the code!
                                {codeTimeout === 0 ? (
                                    <Link to="" onClick={handleResendCode} className="text-main-color no-underline font-semibold">
                                        Resend
                                    </Link>
                                ) : (
                                    <Link to="" className="text-main-color no-underline font-semibold">
                                        Resend
                                    </Link>
                                )}
                            </p>
                            <p className="font-normal  text-sm" style={{ color: "#425466" }}>
                                If you don’t see the email in your inbox, check your spam folder.
                            </p>
                        </div>
                    </div>
                </div>
            </ContainerBox>
        </>
    );
};
