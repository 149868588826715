import { types } from "../types";
import { showToast } from "./toastAction";
import { showLoaderAction, hideLoaderAction } from "./loaderAction";
import Constants from "../../services/constant";
import api from "../../services/api";
import { uploadFiles, uploadImages } from "./uploadFiles";
import { emailValidation } from "../../utils/regex";

export const publicVendorView = (id) => async (dispatch) => {
    dispatch(showLoaderAction());
    const res = await api("get", `${Constants.END_POINT.VENDOR_VIEW}/${id}`);
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.PUBLIC_VENDOR_VIEW,
                payload: res.data,
            });
        }
        dispatch(hideLoaderAction());
    } else {
        dispatch(hideLoaderAction());
        dispatch(showToast({ severity: "error", summary: res.data }));
    }
};

export const addReviewAction = (id, reviewData) => async (dispatch) => {
    reviewData.images = await uploadImages(reviewData.images);

    let payload = {
        ...reviewData,
    };
    const res = await api("post", `${Constants.END_POINT.ADD_REVIEW}${id}`, payload);
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.ADD_REVIEW,
                payload: res.data,
            });
        }
        return res;
    } else {
        dispatch(showToast({ severity: "error", summary: res.message }));
        return res;
    }
};

export const addQuoteACtion = (data) => async (dispatch) => {
    if (data.email !== "" && !emailValidation(data.email)) {
        dispatch(showToast({ severity: "error", summary: "Please Enter Valid Email" }));
    } else {
        let res = await api("post", Constants.END_POINT.ADD_QUOTE, data);
        if (Object.keys(res.data).length > 0) {
            dispatch({
                type: types.ADD_QUOTE,
                payload: res.data,
            });

            return res;
        } else {
            dispatch(showToast({ severity: "error", summary: res.message }));
        }
    }
};

export const addTentativeAction = (data) => async (dispatch) => {
    dispatch(showLoaderAction());
    let res = await api("put", Constants.END_POINT.HOST_ADD_TENTATIVE, data);
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.HOST_ADD_TENTATIVE,
                payload: res.data,
            });
        }
        dispatch(hideLoaderAction());
    } else {
        dispatch(hideLoaderAction());
        dispatch(showToast({ severity: "error", summary: res.message }));
    }
};

export const showAllTentativeAction = (id, next) => async (dispatch) => {
    dispatch(showLoaderAction());
    if (id) {
        let res = await api("get", `${Constants.END_POINT.ALL_TENTATIVE_VENDORS}${id}`);
        if (res.success) {
            if (res.data) {
                dispatch({
                    type: types.ALL_TENTATIVE_VENDORS,
                    payload: res.data,
                });
            }
            next();
            dispatch(hideLoaderAction());
        } else {
            dispatch(hideLoaderAction());
            dispatch(showToast({ severity: "error", summary: res.message }));
        }
    } else {
        let res = await api("get", Constants.END_POINT.ALL_TENTATIVE_VENDORS);
        if (res.success) {
            if (res.data) {
                dispatch({
                    type: types.ALL_TENTATIVE_VENDORS,
                    payload: res.data,
                });
            }
            dispatch(hideLoaderAction());
        } else {
            dispatch(hideLoaderAction());
            dispatch(showToast({ severity: "error", summary: res.message }));
        }
    }
};

export const filterVendors = (params, empty) => async (dispatch) => {
    dispatch(showLoaderAction());
    if (empty) {
        let data = [];
        dispatch({
            type: types.FILTER_VENDORS,
            payload: data,
        });
        dispatch(hideLoaderAction());
    } else {
        let res = await api("get", Constants.END_POINT.FILTER_VENDORS, {}, params);
        if (res.success) {
            if (res.data) {
                dispatch({
                    type: types.FILTER_VENDORS,
                    payload: res.data,
                });
            }
            dispatch(hideLoaderAction());
        } else {
            dispatch(hideLoaderAction());
            dispatch(showToast({ severity: "error", summary: res.message }));
        }
    }
};
