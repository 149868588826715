import React, { useState, useEffect } from "react";
import HouseIcon from "../../assets/icons/House.png";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Paginator } from "primereact/paginator";
import VendorData from "./VendorData";
import { useDispatch, useSelector } from "react-redux";
import { quoteDataAction } from "../../redux/actions/bussinessInformationAction";
export default function InquiriesContent({ sort, sortValue, setSortValue }) {
  const dispatch = useDispatch();
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const [page, setPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [searchData, setSearchData] = useState({
    quotes: "",
  });
  const [totalRecords, setTotalRecords] = useState(1);
  useEffect(() => {
    let params;
    if (sortValue === "old") {
      params = {
        sortOrder: "ASC",
        page: page + 1,
      };
    } else {
      params = {
        sortValue: "DESC",
        page: page + 1,
      };
    }
    dispatch(quoteDataAction(params));
  }, [page]);
  const quoteData = useSelector((state) => state?.quoteData?.quoteDataProfile);
  useEffect(() => {
    if (quoteData?.quotes?.length) {
      setTotalRecords(Math.ceil(quoteData?.quotes?.length / rows));
    }
  }, [quoteData?.quotes]);

  const onPageChange = (event) => {
    setFirst(event.first);
    setRows(event.rows);
    setPage(event.page);
  };
  const handleSearch = (event) => {
    const term = event.target.value;
    setSearchTerm(term);

    const results = quoteData?.quotes?.filter((item) =>
      item.email.toLowerCase().includes(term.toLowerCase())
    );

    setSearchResults(results);
  };
  useEffect(() => {
    setSearchData({
      ...searchData,
      quotes: searchResults,
    });
  }, [searchResults]);
  return (
    <>
      {/* <h3
        className="merriweather text-dark-gray font-bold"
        style={{ fontSize: "22px" }}
      >
        Inquiries
      </h3>
      <div className="grid p-0 m-0 mb-5 ">
        <div className="col-12 md:col-8 bg-white-gray border-round-lg">
          <div className="flex align-items-center bg-white-gray ">
            <img src={HouseIcon} width={61} height={61} alt="" className="" />
            <span className="text-base font-medium text-dark-gray ml-5">
              Your Store front isn’t active yet
              <span className="ml-5">
                <p className="p-0 m-0 text-xs font-normal text-black-gray mt-2">
                  Literally it does not mean anything. It is a sequence of words
                  without a sense of Latin derivation
                </p>
              </span>
            </span>
          </div>
        </div>
      </div> */}
      <div className="grid shadow-1 p-2 border-round-lg mb-8">
        <div className="col-12 md:.col-6">
          <h3
            className="merriweather text-dark-gray font-bold p-0 m-0"
            style={{ fontSize: "22px" }}
          >
            Profile Analytics
          </h3>
        </div>

        <div className="col-12 md:col-3">
          <div
            className="border-round-md p-5 text-center  "
            style={{ backgroundColor: "#F8F9FE" }}
          >
            <h2
              className=" text-dark-gray marmerriweatherr p-0 m-0 "
              style={{ fontSize: "30px" }}
            >
              {quoteData?.newQuotesCount}
            </h2>
            <p className=" text-sm text-light-black m-0 p-0">New</p>
          </div>
        </div>
        <div className="col-12 md:col-3">
          <div
            className="border-round-md p-5 text-center  "
            style={{ backgroundColor: "#F8F9FE" }}
          >
            <h2
              className=" text-dark-gray marmerriweatherr p-0 m-0 "
              style={{ fontSize: "30px" }}
            >
              {quoteData?.importantQuotesCount}
            </h2>
            <p className=" text-sm text-light-black m-0 p-0">Important</p>
          </div>
        </div>
        <div className="col-12 md:col-3">
          <div
            className="border-round-md p-5 text-center  "
            style={{ backgroundColor: "#F8F9FE" }}
          >
            <h2
              className=" text-dark-gray marmerriweatherr p-0 m-0 "
              style={{ fontSize: "30px" }}
            >
              {quoteData?.declinedQuotesCount}
            </h2>
            <p className=" text-sm text-light-black m-0 p-0">Declined</p>
          </div>
        </div>
      </div>

      <div className="grid mt-5 flex justify-content-between  ">
        <div className="col-12 md:col-5 flex p-0 m-0">
          <div className="w-full">
            <div className="p-inputgroup flex-1 lg:mb-6 md:mb-6 mb-5 w-full">
              <InputText
                placeholder="Search Quote"
                className="bg-blue-50 w-full"
                value={searchTerm}
                onChange={handleSearch}
              />
              <Button icon="pi pi-search" className="p-button-primary" />
            </div>
          </div>
        </div>
        <div className="lg:ml-5 md:ml-5 ml-1 lg:mb-0 md:mb-0 mb-5">
          <span className="merriweather text-sm font-semibold text-dark-gray ">
            Sort by:
          </span>
          <span className=" ml-3 p-2 border-round-md text-sm font-normal text-light-black">
            <Dropdown
              placeholder="Newest"
              options={sort}
              onChange={(e) => setSortValue(e.value)}
              value={!sortValue ? "New" : sortValue}
            />
          </span>
        </div>
      </div>
      <VendorData quoteData={quoteData} searchData={searchData} />
      <div className="grid mt-3">
        <Paginator
          first={first}
          rows={rows}
          totalRecords={totalRecords}
          onPageChange={onPageChange}
        />
      </div>
    </>
  );
}
