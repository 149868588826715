import React from "react";
import { ContainerBox } from "../../shared/ContainerBox";
import { Carousel } from "primereact/carousel";
import { Vendorcard } from "../../components/cards/card";
import { useSelector } from "react-redux";
import VenueImg from "../../assets/images/Rectangle 127.png";
import Constants from "../../services/constant";

export const FindVendor = () => {
  const vendorCategories = useSelector((state) => state?.vendor?.categories);
  const responsiveOptions = [
    {
      breakpoint: "1199px",
      numVisible: 1,
      numScroll: 1,
    },
    {
      breakpoint: "991px",
      numVisible: 2,
      numScroll: 1,
    },
    {
      breakpoint: "767px",
      numVisible: 1,
      numScroll: 1,
    },
  ];
  const productTemplate = (category) => {
    return (
      <>
        <Vendorcard
          src={Constants.HOST + category.image}
          servicename={category.name}
          id={category?._id}
        />
      </>
    );
  };
  return (
    <div className="vendor pt-8">
      <ContainerBox
        containerType="container pb-7"
        title="Find vendors for every vibe"
        description="Get inspired with the latest trends and advice from our wedding experts Congratulations on your upcoming wedding! Your special day deserves meticulous planning, attention to detail, and a touch of magic. "
      >
        <Carousel
          value={vendorCategories}
          numVisible={5}
          showIndicators={false}
          prevIcon="pi pi-arrow-left"
          nextIcon="pi pi-arrow-right"
          className="vendor-slider"
          numScroll={1}
          responsiveOptions={responsiveOptions}
          itemTemplate={productTemplate}
        />
      </ContainerBox>
    </div>
  );
};
