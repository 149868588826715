import React from "react";
import { ProfileLayout } from "../../../layout/ProfileLayout";
import { TabView, TabPanel } from "primereact/tabview";
import { ContainerBox } from "../../../shared/ContainerBox";
import ButtonComponent from "../../../components/buttons/button";
import { CustomPassword } from "../../../components/input/AllInput";
import AccountSettingContainer from "./AccountSettingContainer";
import { confirmDialogue } from "../../../utils/javascript";

export default function AccountSettings() {
  const { profileChangePassword, handleChange, save, deleteAcoount } =
    AccountSettingContainer();
  return (
    <>
      <div className="profile-container mt-5 mb-7">
        <ContainerBox containerType="">
          <h1 className="merriweather text-2xl text-dark-gray font-bold mb-5 p-0 m-0">
            Account Setting
          </h1>

          <p className="text-base font-semibold  text-dark-gray mb-5">
            Change Password
          </p>
          <div
            className="grid  p-0 m-0 border-1 p-3 mb-5 border-round-lg"
            style={{ borderColor: "#ECECEC" }}
          >
            <div className="col-12 md:col-12">
              <CustomPassword
                placeholder="Enter your old password"
                label="Old Password"
                name="password"
                value={profileChangePassword?.password}
                onChange={handleChange}
              ></CustomPassword>
              <CustomPassword
                placeholder="Enter your new password"
                label="New Password"
                name="newPassword"
                value={profileChangePassword?.newPassword}
                onChange={handleChange}
              ></CustomPassword>
              <CustomPassword
                placeholder="confirm new password"
                label="Confirm password"
                name="confirmPassword"
                value={profileChangePassword?.confirmPassword}
                onChange={handleChange}
              ></CustomPassword>
              <ButtonComponent
                onClick={save}
                label="Change Password"
                className="text-sm bg-white text-main-color font-semibold ml-1 mt-2"
              ></ButtonComponent>
            </div>
          </div>

          <p className="text-base font-semibold  text-dark-gray mb-4">
            Delete Account
          </p>
          <div
            className="grid p-0 m-0  border-1 p-3 border-round-lg "
            style={{ borderColor: "#ECECEC" }}
          >
            <div className="col-12 md:col-12">
              <p className="p-0 m-0 mb-5 text-sm font-medium  text-light-black ">
                Your account and all the personal information and content saved
                in that account will be removed. Any forum posts made through
                your account will remain active. Are you sure you want to delete
                your account?
              </p>
              <ButtonComponent
                onClick={() => confirmDialogue(deleteAcoount)}
                label="Delete Account"
                className="text-sm bg-white text-main-color font-semibold"
              ></ButtonComponent>
            </div>
          </div>
        </ContainerBox>
      </div>
    </>
  );
}
