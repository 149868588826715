import React, { useEffect, useState } from "react";
import {
  CustomCalenderInput,
  CustomInput,
} from "../../../components/input/AllInput";
import ButtonComponent from "../../../components/buttons/button";
import { Dialog } from "primereact/dialog";
import WeddingWebsiteIcon from "../../../assets/images/wedding website.png";
import { ContainerBox } from "../../../shared/ContainerBox";
import WeddingWebsiteContainer from "./WeddingWebsiteContainer";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom/dist";
import moment from "moment";
import { addWebsiteAction } from "../../../redux/actions/weddingWebsiteAction";

export default function WeddingWebsite() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    websiteData,
    websiteDataHandleChange,
    save,
    getHostWebsite,
    setWebsiteData,
  } = WeddingWebsiteContainer();

  const { getHostWebsite: getUserWebsite } = useSelector(
    (state) => state?.hostWebsiteData
  );

  const hostProfileData = useSelector(
    (state) => state?.hostProfile?.ProfileData
  );

  useEffect(() => {
    if (Object.values?.(hostProfileData)?.length > 0) {
      setWebsiteData({
        ...websiteData,
        name: hostProfileData?.name,
        partnerName: hostProfileData?.partnerName,
        weddingDate: moment(hostProfileData?.weddingDate).toDate(),
        receptionDate: moment(hostProfileData?.receptionDate).toDate(),
      });
    }
  }, [hostProfileData]);

  const [visible, setVisible] = useState(false);

  const handleCreateWebTemp = () => {
    const formData = { ...websiteData };

    formData.welcome = {
      title: formData.welcomeTitle,
      description: formData.welcomeDescription,
    };
    formData.bride = {
      title: formData.brideTitle,
      description: formData.brideDescription,
      image: formData.brideImage,
    };
    formData.groom = {
      title: formData.groomTitle,
      description: formData.groomDescription,
      image: formData.groomImage,
    };
    formData.saveTheDate = {
      title: formData.saveTheDateTitle,
      description: formData.saveTheDateDescription,
      image: formData.saveTheDateImage,
    };
    formData.ourStory = {
      title: formData.ourStoryTitle,
      description: formData.ourStoryDescription,
      images: formData.ourStoryImages,
    };
    formData.rsvp = {
      title: formData.rsvpTitle,
      description: formData.rsvpDescription,
      rsvpDate: formData.rsvpDate,
    };
    formData.endMessage = {
      title: formData.endMessageTitle,
      description: formData.endMessageDescription,
      image: formData.endMessageImage,
    };
    dispatch(addWebsiteAction(formData, navigate));
  };

  return (
    <>
      {/* <div className="guests-container text-white w-full mb-7 ">
        <div className="align-items-center h-full flex justify-content-center">
          <div className="text-center ">
            <h1
              className=" font-bold p-0 m-0 mb-3"
              style={{ fontSize: "90px", fontFamily: "Dancing Script" }}
            >
              Coming Soon
            </h1>
            <p className="text-xl font-normal p-0 m-0 ">
              This is a Premium feature and it will be available soon.
            </p>
          </div>
        </div>
      </div> */}
      <div className="profile-container mt-5 mb-7">
        <ContainerBox>
          <div className="wedding-content">
            <h1 className="merriweather text-2xl text-dark-gray font-bold p-0 m-0 mb-2">
              Choose your wedding website Template for{" "}
              <b className="text-main-color">Free</b>
            </h1>
            <p className="text-xs font-normal text-light-gray p-0 m-0">
              Start with a template from among over 100 designs
            </p>
            <div className="w-6 md:w-2">
              <img
                src={WeddingWebsiteIcon}
                // width={264}
                // height={245}
                alt=""
                className="p-0 w-full m-0 cursor-pointer"
                onClick={() =>
                  Object.values?.(getUserWebsite)?.length > 0
                    ? navigate(
                        `/host/wedding-website-template/${getUserWebsite._id}`
                      )
                    : setVisible(true)
                }
              />
              <p className="p-0 m-0 ml-5 font-semibold text-black text-base">
                Tangerine Dream
              </p>
            </div>
          </div>
          <div className="card flex justify-content-center">
            <Dialog
              header={
                <>
                  <h1 className="merriweather text-2xl text-dark-gray font-bold ">
                    Great Choice , {getHostWebsite?.name}! Who you are marrying
                  </h1>
                </>
              }
              visible={visible}
              style={{ width: "100vw" }}
              onHide={() => setVisible(false)}
              className="lg:w-5 md:w-8 w-11"
            >
              <div className="grid m-0 flex align-items-center">
                <div className="col-12 md:col-6">
                  <CustomInput
                    extraClassName="w-full"
                    label="Name"
                    value={websiteData.name}
                    disabled
                    required
                  ></CustomInput>
                </div>
                <div className="col-12 md:col-6">
                  <CustomInput
                    extraClassName="w-full"
                    label="Your Partner’s Name"
                    value={websiteData.partnerName}
                    disabled
                    required
                  ></CustomInput>
                </div>
                <div className="col-12 md:col-6">
                  <CustomCalenderInput
                    extraClassName="w-full"
                    label="Wedding Date"
                    value={websiteData.weddingDate}
                    onChange={(e) =>
                      websiteDataHandleChange("weddingDate", e.target.value)
                    }
                  ></CustomCalenderInput>
                </div>
                <div className="col-12 md:col-6">
                  <CustomCalenderInput
                    extraClassName="w-full"
                    label="Reception Date"
                    value={websiteData.receptionDate}
                    onChange={(e) =>
                      websiteDataHandleChange("receptionDate", e.target.value)
                    }
                  ></CustomCalenderInput>
                </div>
              </div>
              <div className="col-12 md:col-12">
                <ButtonComponent
                  label="Create My Website"
                  className="w-full p-3"
                  onClick={handleCreateWebTemp}
                ></ButtonComponent>
              </div>
            </Dialog>
          </div>
        </ContainerBox>
      </div>
    </>
  );
}
