import React, { useState } from "react";
import { ButtonOutlined } from "../../../components/buttons/button";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import GraphIcon from "../../../assets/icons/graph-monitoring.png";
import { Dialog } from "primereact/dialog";
import { useNavigate } from "react-router-dom";
import { formatCurrency } from "../../../utils/javascript";

export default function WeddingContent({ weddingReceptionCount }) {
    console.log("weddingReceptionCount==>", weddingReceptionCount);
    const [visible, setVisible] = useState(false);
    const Navigate = useNavigate();
    const WeddingVenues = () => {
        // 👇️ navigate to Wedding venues
        Navigate("/wedding-venues");
    };
    return (
        <>
            <div className="wedding-content ">
                <div className=" flex align-items-center mt-2 mb-3 md:mb-7">
                    <div className="grid w-full text-white text-center">
                        <div className="col-6 md:col-4">
                            <div className="bg-main h-full px-2 md:px-5 " style={{ padding: "1px" }}>
                                <p className="text-base font-semibold">Vendor Added</p>
                                <h3 className="text-2xl font-semibold">{weddingReceptionCount?.weddingCard?.vendorAdded}</h3>
                            </div>
                        </div>
                        <div className="col-6 md:col-4">
                            <div className="bg-main h-full px-2 md:px-5 " style={{ padding: "1px" }}>
                                <p className="text-base font-semibold">Guests Confirmed</p>
                                <h3 className="text-2xl font-semibold">{weddingReceptionCount?.weddingCard?.guestsConfirmed}</h3>
                            </div>
                        </div>
                        {/* <div className="col-6 md:col-3">
                            <div className="bg-main h-full px-2 md:px-5 " style={{ padding: "1px" }}>
                                <p className="text-base font-semibold">Guests Seats</p>
                                <h3 className="text-2xl font-semibold">{weddingReceptionCount?.weddingCard?.guestsSeats}</h3>
                            </div>
                        </div> */}
                        <div className="col-6 md:col-4">
                            <div className="bg-main h-full px-2 md:px-5 " style={{ padding: "1px" }}>
                                <p className="text-base font-semibold">Estimated Cost</p>
                                <h3 className="text-2xl font-semibold">{formatCurrency(weddingReceptionCount?.weddingCard?.estimatedCost)}</h3>
                            </div>
                        </div>
                    </div>
                </div>
                <h1 className="merriweather text-2xl text-dark-gray font-bold mb-3">Recommended Vendors</h1>
            </div>

            <Dialog
                header={
                    <>
                        <h3 className="merriweather text-2xl text-dark-gray font-bold p-0 m-0 mb-3 ml-2 mt-2">Add Wedding Venue</h3>
                    </>
                }
                visible={visible}
                style={{ width: "100vw" }}
                onHide={() => setVisible(false)}
                className="lg:w-4 md:w-8 w-11"
            >
                <p className="m-0">
                    <div className="p-inputgroup flex-1 mb-5">
                        <InputText placeholder="Write the name of the Vendor" />
                        <Button icon="pi pi-search" className="p-button-primary" />
                    </div>
                    <div className="text-center">
                        <img src={GraphIcon} width={68} height={68} alt=""></img>
                        <p className="text-xl font-semibold p-0 m-0 text-light-black">Still Looking</p>
                        <p className="font-normal text-sm  text-light-black ">Search our Local Venues directory</p>
                        <ButtonOutlined className="font-bold" label="Search Wedding Venues" onClick={WeddingVenues}></ButtonOutlined>
                    </div>
                </p>
            </Dialog>
        </>
    );
}
