import { combineReducers } from "redux";
import {
  checkEmailReducer,
  forgetPasswordReducer,
  resetPasswordReducer,
  setPasswordReducer,
  signinReducer,
  signupReducer,
  verifyOtpReducer,
} from "./signupReducer";
import loaderReducer from "./loaderReducer";
import toastReducer from "./toastReducer";
import { socailLoginReducer } from "./socialReducer";
import vendorReducer from "./vednorReducer";
import {
  amenitiesReducer,
  deleteQuoteReducer,
  filterAmenitiesReducer,
  graphReducer,
  importantQuoteReducer,
  quoteDataReducer,
  updateVendorProfileReducer,
  uploadBussinessImageReducer,
  vendorProfileReducer,
  viewQuoteReducer,
  weddingReceptionReducer,
} from "./bussinessInformationReducer";
import {
  VendorProfileViewReducer,
  addQuoteProfileReducer,
  addReviewReducer,
  addTentativeReducer,
  deleteVendorReducer,
  showAllTentativeReducer,
  vendorFilterReducer,
} from "./BusinessReducer";
import profileReducer from "./profileReducer";
import {
  cancelPlanReducer,
  changePlanReducer,
  getAllMembershipReducer,
  membershipPlanActiveInactiveReducer,
  membershipReducer,
  paymentReducer,
  showPlanReducer,
} from "./membershipsReducer";
import { addBudgetReducer, budgetListReducer } from "./BudgetReducer";
import { guestListReducer } from "./GuestReducer";
import weddingWebsiteReducer from "./weddingWebsiteReducer";

export default combineReducers({
  signup: signupReducer,
  loader: loaderReducer,
  toast: toastReducer,
  signin: signinReducer,
  forgetPassword: forgetPasswordReducer,
  resetPassword: resetPasswordReducer,
  socialLogin: socailLoginReducer,
  vendor: vendorReducer,
  emailCheck: checkEmailReducer,
  uploadBusImg: uploadBussinessImageReducer,
  vendorProfile: vendorProfileReducer,
  updateVendorProfile: updateVendorProfileReducer,
  quoteData: quoteDataReducer,
  publicVendorView: VendorProfileViewReducer,
  addReview: addReviewReducer,
  addQuote: addQuoteProfileReducer,
  hostProfile: profileReducer,
  deleteQuote: deleteQuoteReducer,
  verifyOtp: verifyOtpReducer,
  setPassword: setPasswordReducer,
  viewQuote: viewQuoteReducer,
  importantQuote: importantQuoteReducer,
  amenities: amenitiesReducer,
  addTentative: addTentativeReducer,
  showAllTentative: showAllTentativeReducer,
  vendorFilter: vendorFilterReducer,
  deleteVendor: deleteVendorReducer,
  vendorGraph: graphReducer,
  filterAmenities: filterAmenitiesReducer,
  getAllMembership: getAllMembershipReducer,
  membership: membershipReducer,
  payment: paymentReducer,
  WeddingReception: weddingReceptionReducer,
  showPlan: showPlanReducer,
  membershipPlanActiveInActive: membershipPlanActiveInactiveReducer,
  cancelPlan: cancelPlanReducer,
  chnagePlan: changePlanReducer,
  budgetList: budgetListReducer,
  addBudget: addBudgetReducer,
  guest: guestListReducer,
  hostWebsiteData: weddingWebsiteReducer,
});
