import React from "react";
import { ContainerBox } from "../../shared/ContainerBox";
import { WorkCard } from "../../components/cards/card";
import WorkContent from "./WorkContent";

export const ForVendor = () => {
  const { ForVendorContent } = WorkContent();
  return (
    <ContainerBox
      containerType="container pt-5 md:py-7 md:mb-6"
      title="Are you a Business?"
      descriptionclass="text-base mb-6 lg:w-10"
      description="Grow your business with PlanTheKnot! Promote your services on our industry-leading wedding planning platform, designed to meet the requirements of South Asian weddings. Reach local engaged couples and book more weddings through your personalised business page, with SEO optimization so you are found all major search engines.
      "
    >
    <div className="flex gap-2 text-center justify-content-between flex-wrap ">
        {ForVendorContent.map((item) => {
          return <WorkCard src={item.img} title={item.title} />;
        })}
      </div>
    </ContainerBox>
  );
};
