import React from "react";
import { ContainerBox } from "../../shared/ContainerBox";
import ButtonComponent from "../../components/buttons/button";
import ReviewImg from "../../assets/images/adminReview.png";
import SuccessIcon from "../../assets/icons/successgif.png";
import { useNavigate } from "react-router";
const AdminReview = () => {
    const navigate = useNavigate();
    return (
        <>
            <ContainerBox containerType="">
                <div className="grid p-0 m-0">
                    <div className="lg:col-6 lg:p-5 md:p-5">
                        <img src={ReviewImg} alt="" className="w-full" />
                    </div>
                    <div className="lg:col-6 lg:p-5 md:p-5">
                        <div className="bg-white py-3 mt-5 mt-5">
                            <img src={SuccessIcon} width={105} height={95} alt="" className="mb-3" />
                            <h4 className="font-32 merriweather font-normal text-dark-gray ml-3 p-0 m-0">
                                Congratulations on your
                                <br /> successful <span className="font-bold">sign-up!</span>
                            </h4>
                            <p className="text-dark-gray ml-3 mb-5 font-medium text-sm">
                                Our admin team will review your profile and respond to <br /> your provided email.
                                <span className="font-bold">We're excited to have you on board!</span>
                            </p>
                            <ButtonComponent label="Got It" className="lg:ml-3 w-full lg:w-3" onClick={() => navigate("/login")} />
                        </div>
                    </div>
                </div>
            </ContainerBox>
        </>
    );
};
export default AdminReview;
