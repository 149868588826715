import React, { useState } from "react";
import { SearchInput } from "../../components/input/AllInput";
import { Divider } from "primereact/divider";
import { ContainerBox } from "../../shared/ContainerBox";
import ButtonComponent from "../../components/buttons/button";
import { useDispatch, useSelector } from "react-redux";

export const ExploreBanner = () => {
  const dispatch = useDispatch();

  const { stateStore } = useSelector((state) => state);

  const [postalCode, setPostalCode] = useState("");

  const handleSearch = () => {};
  return (
    <ContainerBox containerType="container-fluid explore-banner">
      {/* <div className="grid m-0 w-full h-full">
        <div className="md:col-7 lg:col-5 flex-order-2 md:flex-order-1 banner-content-left flex flex-column justify-content-center ">
          <div className="content-box p-4 border-round-lg">
            <h2 className="font-24 font-bold mt-0 merriweather font-normal text-dark-gray">
              Explore Vendors
            </h2>
            <p className=" text-dark-gray">
              Find vendors near you enter your post code below
            </p>
            <div className="search-box w-10">
              <div className="block lg:flex justify-content-between bg-white border-round-lg relative p-2 lg:p-0 ">
                <SearchInput
                  iconName="pi pi-search"
                  placeholder="Postal Code"
                  extraClassName="search-margin w-full lg:w-8 relative"
                  value={postalCode}
                  onChange={(e) => setPostalCode(e.target.value)}
                />

                <ButtonComponent
                  label="Search"
                  className="border-round-lg lg:border-noround-left w-full relative search-btn"
                  onClick={handleSearch}
                  // TODO: CSS when disabled
                  disabled={!postalCode}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="md:col-5 lg:col-6 flex-order-1 md:flex-order-2 p-0"></div>
      </div> */}
    </ContainerBox>
  );
};
