import React from "react";
import { IndexLayout } from "../../layout";
import { ContainerBox } from "../../shared/ContainerBox";
import { TabPanel, TabView } from "primereact/tabview";
import { LoginPage } from "./LoginPage";
import LoginImg from "../../assets/images/login.png";

export const Login = () => {
  return (
    <>
      <ContainerBox containerType="">
        <div className="grid p-0 m-0">
          <div className="lg:col-6 lg:p-5 md:p-5">
            <img src={LoginImg} alt="" className="w-full" />
          </div>
          <div className="lg:col-6  ">
            <div className="bg-white lg:p-4 md:p-4 py-5 mt-6">
              <h1 className="font-32 merriweather mb-3 text-dark-gray  ml-3 mt-3">
                {/* <img src={HeyIcon} height={40} width={88}></img> */}
                Hello👋
                <br />
                Start Your Journey <span className="text-pink">With Us</span>
              </h1>
              <LoginPage />
              {/* <TabView
                className="register"
                style={{ marginLeft: "9px", marginRight: "9px" }}
              >
                <TabPanel header="Business Sign in" className="">
                  <LoginPage userType="BUSINESS"/>
                </TabPanel>
                <TabPanel header="Host Sign in">
                  <LoginPage userType="HOST"/>
                </TabPanel>
              </TabView> */}
            </div>
          </div>
        </div>
      </ContainerBox>
    </>
  );
};
