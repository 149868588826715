import React from "react";
import _ from "lodash";
import InputLayout from "./InputLayout";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Password } from "primereact/password";
import { InputNumber } from "primereact/inputnumber";
import { Dropdown } from "primereact/dropdown";
import { Checkbox } from "primereact/checkbox";
import { Calendar } from "primereact/calendar";
import PhoneInput from "react-phone-number-input";
import { InputTextarea } from "primereact/inputtextarea";
import { StandaloneSearchBox } from "@react-google-maps/api";
import { imageprocessingTemplate } from "../../utils/javascript";
import ButtonComponent from "../buttons/button";
import { Editor } from "primereact/editor";

export function CustomForm({ children, ...props }) {
    return (
        <div className="grid grid-nogutter" {...props}>
            {children}
        </div>
    );
}
export const CustomInputButton = ({ col, position, ...props }) => {
    return (
        <InputLayout col={col || 6}>
            <Button className="bg-main" {...props} />
        </InputLayout>
    );
};
export const CustomInput = ({
    label,
    name,
    data,
    value,
    onChange,
    errorMessage,
    extraClassName,
    labeClassName,
    required,
    col,
    placeholder,
    inputClass,
    disabled = false,
    keyfilter,
    customIndex,
    fieldName,
    ...props
}) => {
    return (
        <InputLayout
            labelClassName={labeClassName}
            col={col || 6}
            label={label}
            name={name}
            required={required}
            extraClassName={extraClassName}
            data={data}
            errorMessage={errorMessage}
        >
            <InputText
                id={name}
                name={name}
                value={value || data?.[name]}
                keyfilter={keyfilter ? keyfilter : null}
                onChange={(e) => onChange && onChange({ ...e, name: e.target.name, value: e.target.value, customIndex, fieldName })}
                placeholder={placeholder}
                className={`custom-input w-full bg-transparent border-1 mb-2 ${inputClass ? inputClass : ""} ${errorMessage ? "p-invalid" : ""}`}
                disabled={disabled}
                {...props}
            />
        </InputLayout>
    );
};
export const SearchInput = ({
    label,
    name,
    data,
    value,
    onChange,
    errorMessage,
    extraClassName,
    labeClassName,
    required,
    col,
    inputClass,
    placeholder,
    iconName,
    inputClassName,
    disabled = false,
    ...props
}) => {
    return (
        <InputLayout
            labelClassName={labeClassName}
            col={col || 6}
            label={label}
            name={name}
            required={required}
            extraClassName={extraClassName}
            data={data}
            errorMessage={errorMessage}
            inputClassName={inputClassName}
        >
            <span className={`p-input-icon-left h-full ${inputClassName}`}>
                <i className={iconName} />
                <InputText className="border-none h-full inputetext" placeholder={placeholder} onChange={onChange} />
            </span>
        </InputLayout>
    );
};
export const CustomPassword = ({
    label,
    name,
    data,
    value,
    onChange,
    errorMessage,
    extraClassName,
    placeholder,
    required,
    col,
    inputClass,
    disabled = false,
    ...props
}) => {
    return (
        <InputLayout col={col || 6} label={label} name={name} required={required} extraClassName={extraClassName} data={data} errorMessage={errorMessage}>
            <Password
                id={name}
                name={name}
                placeholder={placeholder}
                value={value || data?.[name]}
                onChange={(e) => onChange && onChange({ ...e, name: e.target.name, value: e.target.value })}
                className={`custom-input w-full bg-transparent mb-2  ${inputClass ? inputClass : ""} ${errorMessage ? "p-invalid" : ""}`}
                disabled={disabled}
                {...props}
                toggleMask
            />
        </InputLayout>
    );
};
export const CustomInputNumber = ({
    label,
    name,
    data,
    value,
    onChange,
    errorMessage,
    extraClassName,
    required,
    col,
    min,
    max,
    inputClass,
    placeholder,
    ...props
}) => {
    return (
        <InputLayout col={col || 6} label={label} name={name} required={required} extraClassName={extraClassName} data={data} errorMessage={errorMessage}>
            <InputText
                id={name}
                name={name}
                placeholder={placeholder}
                inputClassName=""
                value={value}
                min={min}
                max={max}
                keyfilter="num"
                onChange={(e) =>
                    onChange &&
                    onChange({
                        ...e,
                        name: e.target.name,
                        value: e.value || e.target.value,
                    })
                }
                className={`w-full  bg-transparent ${inputClass ? inputClass : ""} ${errorMessage ? "p-invalid" : ""}`}
                useGrouping={props.useGrouping || false}
                {...props}
            />
        </InputLayout>
    );
};
export const CustomDropDown = ({
    label,
    name,
    onChange,
    data,
    value,
    labelClassName,
    errorMessage,
    extraClassName,
    required,
    col,
    inputClass,
    disabled = false,
    optionLabel = "name",
    dropdownOptions = [],
    filter,
    ...props
}) => {
    return (
        <InputLayout
            labelClassName={labelClassName}
            col={col || 6}
            label={label}
            name={name}
            required={required}
            extraClassName={extraClassName}
            data={data}
            errorMessage={errorMessage}
        >
            <Dropdown
                id={name}
                name={name}
                filter={filter ? filter : null}
                value={value || data?.[name]}
                onChange={(e) => onChange && onChange({ ...e, name: e.target.name, value: e.value })}
                className={`w-full text-lg text-black font-medium w-full  ${inputClass ? inputClass : ""} ${errorMessage ? "p-invalid" : ""}`}
                optionLabel={optionLabel}
                placeholder={props.placeholder || `Select ${label}`}
                disabled={disabled}
                options={dropdownOptions}
                {...props}
            />
            {/* {errorMessage ? <small className="p-error">{errorMessage}</small> : null} */}
        </InputLayout>
    );
};
export const CustomSearchBox = ({
    label,
    name,
    onChange,
    data,
    value,
    labelClassName,
    errorMessage,
    extraClassName,
    onLoad,
    onPlacesChanged,
    required,
    col,
    inputClass,
    disabled = false,
    optionLabel = "name",
    dropdownOptions = [],
    ...props
}) => {
    return (
        <InputLayout
            labelClassName={labelClassName}
            col={col || 6}
            label={label}
            name={name}
            required={required}
            extraClassName={extraClassName}
            data={data}
            errorMessage={errorMessage}
        >
            <StandaloneSearchBox onLoad={onLoad} onPlacesChanged={onPlacesChanged}>
                <InputText type="text" placeholder="Bekasi, Jawa Barat" value={value} name="addressLine1" onChange={onChange} label="Location" />
            </StandaloneSearchBox>
        </InputLayout>
    );
};

export function CustomCheckbox({
    label,
    name,
    onChange,
    data,
    value,
    errorMessage,
    extraClassName,
    labeClassName,
    required,
    col,
    inputClass,
    checked = "",
    options,
    optionLabel,
    ...props
}) {
    return (
        <InputLayout col={col || 12} label={label} name={name} required={required} extraClassName={extraClassName} data={data} errorMessage={errorMessage}>
            <div className={`flex align-items-center text-lg text-black font-medium`}>
                <Checkbox
                    value={value}
                    className="border-noround"
                    onChange={(e) => onChange && onChange({ name, value: e.target.value, ...e })}
                    checked={checked !== "" ? checked : _.isEqual(value || data?.[name])}
                    id={name}
                    name={name}
                    {...props}
                />
                &nbsp;&nbsp;
                <label className={`text-sm text-gray  ${labeClassName} `} htmlFor={name} labeClassName={labeClassName}>
                    {optionLabel}
                </label>
            </div>
        </InputLayout>
    );
}
export function CustomCheckbox1({
    label,
    name,
    onChange,
    data,
    value,
    errorMessage,
    extraClassName,
    labeClassName,
    required,
    col,
    inputClass,
    checked = "",
    options,
    optionLabel,
    ...props
}) {
    return (
        <InputLayout col={col || 12} label={label} name={name} required={required} extraClassName={extraClassName} data={data} errorMessage={errorMessage}>
            <div className={`flex align-items-center text-lg text-black font-medium`}>
                <Checkbox
                    value={value}
                    className="border-noround"
                    onChange={(e) => onChange && onChange({ ...e, name: e.target.name, value: e.checked })}
                    checked={value || data?.[name]}
                    id={name}
                    name={name}
                    {...props}
                />
                &nbsp;&nbsp;
                <label className={`text-sm text-gray  ${labeClassName} `} htmlFor={name} labeClassName={labeClassName}>
                    {optionLabel}
                </label>
            </div>
        </InputLayout>
    );
}
export const CustomCalenderInput = ({ label, name, data, value, onChange, errorMessage, extraClassName, required, col, inputClass, ...props }) => {
    return (
        <InputLayout col={col || 6} label={label} name={name} required={required} extraClassName={extraClassName} data={data} errorMessage={errorMessage}>
            <Calendar
                id={name}
                name={name}
                value={value}
                onChange={(e) => onChange && onChange({ ...e, name: e.target.name, value: e.target.value })}
                className={`calender-picker w-full text-lg text-black font-medium  ${inputClass ? inputClass : ""} ${errorMessage ? "p-invalid" : ""}`}
                showIcon
                dateFormat="dd/mm/yy"
                {...props}
                // icon="pi pi-clock"
                // timeOnly
            />
        </InputLayout>
    );
};
export const PhoneNumberInput = ({ label, name, data, value, onChange, errorMessage, extraClassName, required, col, inputClass, ...props }) => {
    return (
        <InputLayout col={col || 6} label={label} name={name} required={required} extraClassName={extraClassName} data={data} errorMessage={errorMessage}>
            <PhoneInput
                international
                placeholder="Enter phone number"
                defaultCountry="GB"
                className="border-light p-3 border-round-lg border-400 phone-number"
                value={value}
                onChange={onChange}
            />
        </InputLayout>
    );
};
export const CustomTextArea = ({
    label,
    name,
    onChange,
    data,
    value,
    errorMessage,
    extraClassName,
    required,
    col,
    inputClass,
    labelClassName,
    disabled = false,
    ...props
}) => {
    return (
        <InputLayout
            col={col || 12}
            label={label}
            name={name}
            required={required}
            extraClassName={extraClassName}
            labelClassName={labelClassName}
            data={data}
            errorMessage={errorMessage}
        >
            <InputTextarea
                id={name}
                name={name}
                value={value}
                onChange={(e) => onChange && onChange({ name: e.target.name, value: e.target.value, ...e })}
                className={`w-full ${inputClass ? inputClass : ""} ${errorMessage ? "p-invalid" : ""}`}
                disabled={disabled}
                {...props}
            />
            {/* {errorMessage ? <small className="p-error">{errorMessage}</small> : null} */}
        </InputLayout>
    );
};
export const CustomCheckbox2 = ({ label, name, data, value, onChange, errorMessage, extraClassName, required, col, inputClass, ...props }) => {
    return (
        <InputLayout col={col || 6} name={name} extraClassName={extraClassName} data={data} errorMessage={errorMessage}>
            <Checkbox
                id={name}
                name={name}
                inputId={label}
                checked={value || data?.[name]}
                onChange={(e) => onChange && onChange({ ...e, name: e.target.name, value: e.checked })}
                className={`${inputClass ? inputClass : ""} ${errorMessage ? "p-invalid" : ""}`}
                {...props}
            />
            <label htmlFor={label} className="ml-2 text-sm text-gray ">
                {label}
            </label>
        </InputLayout>
    );
};

export const CustomImage = ({ name, value, onChange, title, buttonLabel, inputClass, inputProps, ...props }) => {
    return (
        <div className="col-12 md:col-12 flex align-items-center">
            <img
                src={imageprocessingTemplate(value)}
                width={150}
                height={150}
                alt=""
                className={`${inputClass ? inputClass : "border-circle"}`}
                {...inputProps}
            />
            {buttonLabel ? (
                <span className="ml-5 text-base font-semibold text-dark-gray">
                    {title} <br />
                    <label htmlFor={name} style={{ display: "block", cursor: "pointer" }}>
                        <ButtonComponent label={buttonLabel} className="bg-white text-main-color border-main mt-3 p-disabled"></ButtonComponent>
                    </label>
                    <input
                        id={name}
                        name={name}
                        type="file"
                        hidden
                        onChange={(e) => onChange && onChange({ name: e.target.name, value: e.target.files[0] })}
                        {...props}
                    />
                </span>
            ) : null}
        </div>
    );
};

export const CustomEditor = ({
    label,
    name,
    onChange,
    data,
    value,
    errorMessage,
    extraClassName,
    required,
    col,
    inputClass,
    disabled = false,
    header,
    labelClassName,
    ...props
}) => {
    return (
        <InputLayout
            col={col || 12}
            label={label}
            name={name}
            labelClassName={labelClassName}
            required={required}
            extraClassName={extraClassName}
            data={data}
            errorMessage={errorMessage}
        >
            <Editor id={name} name={name} value={value} className="mb-3" onTextChange={onChange} {...props} />
        </InputLayout>
    );
};
