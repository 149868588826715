import React, { useEffect } from "react";
import Topbar from "../topbar";
import { Footer } from "../Footer";
import ProfileTopbar from "../ProfileTopbar";
import VendorTopbar from "../VendorTopbar";
import { IndexLayout } from "..";

export const PublicLayout = ({ children }) => {
  return (
    <>
      <div>
        <IndexLayout />
        {children}
        <Footer />
      </div>
    </>
  );
};

export const HostLayout = ({ children }) => {
  return (
    <>
      <div>
        <ProfileTopbar />
        {children}
        <Footer />
      </div>
    </>
  );
};

export const VendorLayout = ({ children }) => {
  return (
    <>
      <div>
        <VendorTopbar />
        <div style={{ minHeight: "60vh" }}>{children}</div>
        <Footer />
      </div>
    </>
  );
};
