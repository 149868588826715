import React, { useState } from "react";
import { IndexLayout } from "../../layout";
import { ContainerBox } from "../../shared/ContainerBox";
import {
  CustomDropDown,
  CustomInput,
  CustomTextArea,
} from "../../components/input/AllInput";
import ButtonComponent from "../../components/buttons/button";
import locationIcon from "../../assets/icons/location.png";
import phoneIcon from "../../assets/icons/phone.png";
import emailIcon from "../../assets/icons/email.png";
import { useDispatch } from "react-redux";
import { EmailFeild } from "../../pages/signup/CommonFeild";
import { allValidations } from "../../utils/formValidations";

export const Contact = () => {
  const dispatch = useDispatch();

  const [form, setForm] = useState({
    name: "",
    email: "",
    contactType: "",
    comment: "",
  });
  const [errors, setErrors] = useState({});

  const setField = (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });
    if (!!errors[field]) {
      setErrors({
        ...errors,
        [field]: null,
      });
    }
  };

  const updateFormErrors = (errors) => {
    // Extract the error text from the errors object
    const errorText = Object.keys(errors).reduce((acc, key) => {
      acc[key] = errors[key][Object.keys(errors[key])[0]];
      return acc;
    }, {});

    setErrors(errorText);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const tempErrors = {
      email: allValidations("email", form.email, form),
      comment: allValidations("comment", form.comment, form),
    };

    updateFormErrors(tempErrors);

    const hasErrors = Object.values(tempErrors).some((errors) =>
      Object.values(errors).some((error) => error !== "")
    );

    if (hasErrors) {
      return;
    }

    // dispatch(contactAction(form));
  };

  const commentTypeOptions = [
    {
      name: "Host",
      value: "Host",
    },
    {
      name: "Vendor",
      value: "Vendor",
    },
    {
      name: "Others",
      value: "Others",
    },
  ];

  return (
    <>
      <ContainerBox
        containerType="container py-3 md:py-7 mb-5"
        title="Get In Touch"
        descriptionclass="text-base mb-5 "
        description={`With this form you can get in touch with PlanTheKnot. 
        You can also get in touch with us about any questions, concerns or suggestions you have about PlanTheKnot through email.
         `}
        // hightlighttext={`More Information"`}
      >
        <div className="grid shadow-2 flex lg:mr-5 p-0 m-0">
          <div className="col-12 md:col-6 lg:px-5 md:px-5 py-6">
            <div className="">
              <h2 className="text-2xl merriweather">Send us a Message</h2>
              <CustomInput
                value={form?.name}
                onChange={(e) => {
                  setField("name", e.target.value);
                }}
                placeholder="Name"
                col={12}
              />
              <EmailFeild
                value={form?.email}
                onChange={(e) => {
                  setField("email", e.target.value);
                }}
                placeholder="Email"
                col={12}
                errorMessage={errors?.email}
              />
              <CustomDropDown
                value={form?.contactType}
                onChange={(e) => {
                  setField("contactType", e.target.value);
                }}
                dropdownOptions={commentTypeOptions}
                placeholder="Select Type"
                col={12}
              />
              <CustomTextArea
                value={form?.comment}
                onChange={(e) => {
                  setField("comment", e.target.value);
                }}
                placeholder="Comment/ Message"
                rows={4}
                col={12}
                errorMessage={errors?.comment}
              />
              <ButtonComponent onClick={handleSubmit} label="Submit" />
            </div>
          </div>
          <div className="col-12 md:col-6 p-4 py-6 bg-main flex flex-column justify-content-between  ">
            <div>
              <h4 className="text-2xl my-2 text-white merriweather">
                Contact Information
              </h4>
              <p className="text-xs text-white mb-5">
                Customer service from <b>9am to 5pm</b> (weekdays, excluding
                Bank Holidays)
              </p>
              {/* <div className="flex align-items-center mb-5">
                <img src={locationIcon} alt="" width={20} />
                <p className="text-sm text-white ml-3">
                  604 Hillgrove Ave Western Springs Indiana 604673558
                </p>
              </div> */}
              <div className="flex align-items-center mb-5">
                <img src={phoneIcon} alt="" width={20} />
                <p className="text-sm text-white ml-3">020 3538 9282</p>
              </div>{" "}
              <div className="flex align-items-center mb-5">
                <img src={emailIcon} alt="" width={20} />
                <p className="text-sm text-white ml-3">
                  support@plantheknot.co.uk
                </p>
              </div>
            </div>
            <div className="flex gap-3">
              {/* <div className="social-icon">
                <i className="pi pi-twitter text-white text-md"></i>
              </div> */}
              <div className="facebook">
                <a
                  href="https://www.facebook.com/profile.php?id=61551853231332"
                  target="_blank"
                  className=" social-icon no-underline"
                >
                  <i className="pi pi-facebook text-white text-md"></i>
                </a>
              </div>
              <div className="facebook">
                <a
                  href="https://www.instagram.com/plantheknot/"
                  target="_blank"
                  className=" social-icon no-underline"
                >
                  <i className="pi pi-instagram text-white text-md"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </ContainerBox>
    </>
  );
};
