import React from "react";
import ProfileTopbar from "./ProfileTopbar";
import { Footer } from "./Footer";

export const ProfileLayout = ({ children }) => {
  return (
    <div>
      <ProfileTopbar />
      {children}
      <Footer />
    </div>
  );
};
