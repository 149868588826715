import React, { useEffect, useMemo, useState } from "react";
import { CustomCalenderInput, CustomCheckbox, CustomCheckbox2, CustomDropDown, CustomInput, CustomPassword } from "../../components/input/AllInput";
import { EmailFeild, SocialLogin } from "./CommonFeild";
import ButtonComponent from "../../components/buttons/button";
import { Link, useNavigate } from "react-router-dom";
import { allValidations, matchPasswordsValidation } from "../../utils/formValidations";
import { useDispatch, useSelector } from "react-redux";
import { googlelogin, signupAction, socialLogin } from "../../redux/actions/signupAction";
// import { signupAction } from "../../redux/actions/authAction";
import { Country, State, City } from "country-state-city";
import { useGoogleLogin } from "@react-oauth/google";
import { sendOtpAction } from "../../redux/actions/otpActions";
import { showToast } from "../../redux/actions/toastAction";
import { types } from "../../redux/types";

export const Host = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { toastInfo } = useSelector((state) => state?.toast);
    console.log(toastInfo);

    const allCity = useMemo(
        () =>
            City.getCitiesOfCountry("GB")?.map((item) => {
                return { label: item.name, value: item.name };
            }),
        []
    );
    useEffect(() => {
        return () => {
            dispatch({ type: types.SHOW_TOAST, payload: {} });
        };
    }, []);

    // const allCity = []

    const [form, setForm] = useState({
        name: "",
        email: "",
        eventCeremony: "",
        partnerName: "",
        weddingDate: "",
        receptionDate: "",
        city: "",
        password: "",
        confirmPassword: "",
        userType: "HOST",
        role: "Host",
    });
    const [checked, setChecked] = useState(true);
    const [errors, setErrors] = useState({});

    const setField = (field, value) => {
        setForm({
            ...form,
            [field]: value,
        });
        if (!!errors[field]) {
            setErrors({
                ...errors,
                [field]: null,
            });
        }
    };

    const updateFormErrors = (errors) => {
        // Extract the error text from the errors object
        const errorText = Object.keys(errors).reduce((acc, key) => {
            acc[key] = errors[key][Object.keys(errors[key])[0]];
            return acc;
        }, {});

        setErrors(errorText);
    };

    const validateSubmit = (e) => {
        e.preventDefault();

        const tempErrors = {
            name: allValidations("name", form.name, form),
            email: allValidations("email", form.email, form),
            eventCeremony: allValidations("event/Ceremony", form.eventCeremony, form),
            confirmPassword: matchPasswordsValidation("confirmPassword", form.password, form.confirmPassword),
        };
        updateFormErrors(tempErrors);

        const hasErrors = Object.values(tempErrors).some((errors) => Object.values(errors).some((error) => error !== ""));

        if (hasErrors) {
            return;
        }
        handleSubmit();
    };

    const handleSubmit = () => {
        dispatch(
            signupAction(form, () => {
                dispatch(sendOtpAction({ email: form.email }, () => {}));
                navigate("/otp");
            })
        );
    };

    const eventCeremonyOptions = [
        { name: "Wedding", value: "wedding" },
        { name: "Reception", value: "Reception" },
        { name: "Both Wedding and Reception", value: "Both" },
    ];
    const googleLoginHandler = useGoogleLogin({
        onSuccess: async (respose) => {
            dispatch(googlelogin(respose)).then((res) => {
                if (res.data.sub) {
                    let payload = {
                        email: res.data.email,
                        socialId: res.data.sub,
                        loginType: "Google",
                        name: res.data.given_name,
                        role: "Host",
                    };
                    dispatch(socialLogin(payload)).then((res) => {
                        console.log("res>>", res);
                        if (res === "error") {
                            dispatch(showToast({ severity: "error", summary: "User with this email already exists. Please try again!" }));
                        } else if (res) {
                            navigate("/host/profile");
                        } else {
                            navigate("/login");
                        }
                    });
                }
            });
        },
    });

    return (
        <div className="grid ">
            <form onSubmit={validateSubmit} className="col-12">
                {toastInfo?.summary && <div className="p-3 border border-2 alert-error mb-2 text-center">{toastInfo?.summary}</div>}

                <CustomInput
                    value={form.name}
                    onChange={(e) => setField("name", e.target.value)}
                    col={12}
                    errorMessage={errors?.name}
                    placeholder="Full Name"
                />
                <EmailFeild value={form.email} col={12} onChange={(e) => setField("email", e.target.value)} errorMessage={errors?.email} />
                <CustomDropDown
                    col={12}
                    value={form.eventCeremony}
                    onChange={(e) => setField("eventCeremony", e.target.value)}
                    placeholder="Event/Ceremony"
                    optionLabel={"name"}
                    dropdownOptions={eventCeremonyOptions}
                    errorMessage={errors.eventCeremony}
                />
                <CustomInput col={12} value={form.partnerName} onChange={(e) => setField("partnerName", e.target.value)} placeholder="Partner’s Name" />
                {form?.eventCeremony === "wedding" ? (
                    <CustomCalenderInput col={12} value={form.eventDate} onChange={(e) => setField("weddingDate", e.target.value)} placeholder="Wedding Date" />
                ) : form?.eventCeremony === "Reception" ? (
                    <CustomCalenderInput
                        col={12}
                        value={form.eventDate}
                        onChange={(e) => setField("receptionDate", e.target.value)}
                        placeholder="Reception Date"
                    />
                ) : form?.eventCeremony === "Both" ? (
                    <>
                        <CustomCalenderInput
                            col={12}
                            value={form.eventDate}
                            onChange={(e) => setField("weddingDate", e.target.value)}
                            placeholder="Wedding Date"
                        />
                        <CustomCalenderInput
                            col={12}
                            value={form.eventDate}
                            onChange={(e) => setField("receptionDate ", e.target.value)}
                            placeholder="Reception Date"
                        />
                    </>
                ) : null}

                <CustomDropDown
                    filter
                    options={allCity}
                    optionLabel="label"
                    col={12}
                    value={form.city}
                    onChange={(e) => setField("city", e.target.value)}
                    placeholder="Location"
                />
                {/* <PasswordFeild /> */}
                <CustomPassword col={12} value={form.password} onChange={(e) => setField("password", e.target.value)} placeholder="Password" />
                <CustomPassword
                    col={12}
                    value={form.confirmPassword}
                    onChange={(e) => setField("confirmPassword", e.target.value)}
                    placeholder="Confirm Password"
                    errorMessage={errors?.confirmPassword}
                />

                <CustomCheckbox2
                    onChange={({ value }) => setChecked(value)}
                    col="12"
                    checked={checked}
                    label={
                        <>
                            I accept PlanTheKnot's{" "}
                            <a className="text-blue-400" href="http://plantheknot.com/#/privacy-policy" target="_blank" rel="noopener noreferrer">
                                Privacy Policy
                            </a>{" "}
                            and{" "}
                            <a className="text-blue-400" href="http://plantheknot.com/#/terms-and-condition" target="_blank" rel="noopener noreferrer">
                                Terms and Conditions
                            </a>
                        </>
                    }
                />
                <ButtonComponent label="Sign Up" onClick={validateSubmit} disabled={!checked} />
            </form>
            <SocialLogin googleLoginHandler={googleLoginHandler} />
            <p className="col-12">
                Already have Account{" "}
                <Link to="/login" className="text-main-color no-underline">
                    Login here
                </Link>
            </p>
        </div>
    );
};
