import { types } from "../types";
import Constants from "../../services/constant";
import formDataApi from "../../services/formDataApi";
import { showLoaderAction, hideLoaderAction } from "./loaderAction";
import { showToast } from "./toastAction";
import api from "../../services/api";
import { uploadFiles, uploadImages } from "./uploadFiles";

export const myProfileDataAction = () => async (dispatch) => {
    dispatch(showLoaderAction());
    const res = await api("get", Constants.END_POINT.VENDOR_PROFILE);
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.VENDOR_PROFILE,
                payload: res.data,
            });
            if (res.data?.subscription == null) {
            }
        }
        dispatch(hideLoaderAction());
    } else {
        dispatch(hideLoaderAction());
        dispatch(showToast({ severity: "error", summary: res.message }));
    }
};

export const updateVendorProfile = (data) => async (dispatch) => {
    dispatch(showLoaderAction());
    let array = [];
    data.profileImage = await uploadImages(data?.profileImage);

    data.images = await uploadImages(data.images);
    data.videos = await uploadImages(data.videos);
    data.preWedding = await uploadImages(data.preWedding);
    data?.amenities?.length > 0 &&
        data?.amenities?.map((item) => {
            array.push(item?._id);
        });
    data.amenities = array;
    let payload = {
        ...data,
        vendorType: data?.vendorType?._id,
        profileImage: data?.profileImage[0],
    };
    const res = await api("put", Constants.END_POINT.UPDATE_PROFILE, payload);
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.UPDATE_VENDER_PROFILE,
                payload: res.data,
            });
        }
        dispatch(hideLoaderAction());
        return res;
    } else {
        dispatch(hideLoaderAction());
        dispatch(showToast({ severity: "error", summary: res.message }));
    }
};

export const quoteDataAction = (params) => async (dispatch) => {
    dispatch(showLoaderAction());
    const res = await api("get", Constants.END_POINT.QUOTE_REQUEST, {}, params);
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.QUOTE_REQUEST,
                payload: res.data,
            });
        }
        dispatch(hideLoaderAction());
    } else {
        dispatch(hideLoaderAction());
        dispatch(showToast({ severity: "error", summary: res.message }));
    }
};

export const deleteQuoteAction = (id, data) => async (dispatch) => {
    dispatch(showLoaderAction());
    const res = await api("delete", `${Constants.END_POINT.DELETE_QUOTE}${id}`, data);
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.DELETE_QUOTE,
                payload: res.data,
            });
        }

        dispatch(hideLoaderAction());
        return res;
    } else {
        dispatch(hideLoaderAction());
        dispatch(showToast({ severity: "error", summary: res.message }));
    }
};

export const viewQuoteAction = (id) => async (dispatch) => {
    // dispatch(showLoaderAction());
    const res = await api("get", `${Constants.END_POINT.VIEW_QUOTE_DATA}${id}`);
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.VIEW_QUOTE_DATA,
                payload: res.data,
            });
        }
        // dispatch(hideLoaderAction());
    } else {
        // dispatch(hideLoaderAction());
        dispatch(showToast({ severity: "error", summary: res.message }));
    }
};

export const importantQuoteAction = (id) => async (dispatch) => {
    dispatch(showLoaderAction());
    const res = await api("put", `${Constants.END_POINT.IMPORTANT_QUOTE}${id}`, {});
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.IMPORTANT_QUOTE,
                payload: res.data,
            });
        }
        dispatch(hideLoaderAction());
        return res;
    } else {
        dispatch(hideLoaderAction());
        dispatch(showToast({ severity: "error", summary: res.message }));
    }
};

export const amenitiesAction = (params) => async (dispatch) => {
    dispatch(showLoaderAction());
    const res = await api("get", Constants.END_POINT.AMENITIES, {}, params);
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.AMENITIES_DATA,
                payload: res.data,
            });
        }
        dispatch(hideLoaderAction());
    } else {
        dispatch(hideLoaderAction());
        dispatch(showToast({ severity: "error", summary: res.message }));
    }
};

export const quoteGraph = (params) => async (dispatch) => {
    dispatch(showLoaderAction());
    const res = await api("get", Constants.END_POINT.QUOTE_GRAPH, {}, params);
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.QUOTE_GRAPH,
                payload: res.data,
            });
        }
        dispatch(hideLoaderAction());
    } else {
        dispatch(hideLoaderAction());
        dispatch(showToast({ severity: "error", summary: res.message }));
    }
};

export const filterAmenitiesAction = (params) => async (dispatch) => {
    const res = await api("get", Constants.END_POINT.FILTER_AMENTIES, {}, params);
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.FILTER_AMENTIES,
                payload: res.data,
            });
        }
    } else {
        dispatch(showToast({ severity: "error", summary: res.message }));
    }
};

export const weddingReceptionAction = () => async (dispatch) => {
    const res = await api("get", Constants.END_POINT.WEDDING_RECEPTION_COUNT);
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.WEDDING_RECEPTION_COUNT,
                payload: res.data,
            });
        }
    } else {
        dispatch(showToast({ severity: "error", summary: res.message }));
    }
};
