import React, { useState } from "react";
import { Rating } from "primereact/rating";
import { Dialog } from "primereact/dialog";
import { CardComponent } from "../../components/cards/card";
import Chartcomponent from "../../components/chart/Chart";
import VendorData from "./VendorData";
import { Dropdown } from "primereact/dropdown";
export default function OverviewContent({
  setActiveIndex,
  quoteData,
  sort,
  setSortValue,
  sortValue,
  getChatData,
  selectedYear,
  setSelectedYear,
}) {
  return (
    <>
      <div className="grid mb-5">
        <div className="col-12 md:col-6">
          <Chartcomponent
            selectedYear={selectedYear}
            setSelectedYear={setSelectedYear}
          />
        </div>

        <div className="col-12 md:col-6">
          <CardComponent className="shadow-1 lg:p-5 md:p-5">
            <div className="grid p-0 m-0">
              <div className="col-12 md:col-6 text-center ">
                <div
                  className="shadow-1 border-round-lg justify-content-center p-3 "
                  style={{
                    backgroundColor: quoteData?.newRequestsThisMonth
                      ? "#FFCEE8"
                      : "#F8F9FE",
                  }}
                >
                  <h1
                    className="p-0 m-0 merriweather  font-bold "
                    style={{
                      fontSize: "30px",
                      color: quoteData?.newRequestsThisMonth
                        ? "#FF0289"
                        : "#333333",
                    }}
                  >
                    {quoteData?.newRequestsThisMonth}
                  </h1>
                  <p className="font-semibold text-sm text-light-black">
                    New Requests
                  </p>
                  <div
                    className="p-2 border-round-lg"
                    style={{ backgroundColor: "#EEF1FF" }}
                  >
                    <p className="p-0 m-0 font-10 text-green-800 ">
                      {quoteData?.percentIncreaseRequests}%
                    </p>
                    <p className="p-0 m-0 text-xs font-semibold">
                      {" "}
                      In the Past 1 month
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-12 md:col-6 text-center  ">
                <div
                  className="shadow-1 border-round-lg justify-content-center p-3 "
                  style={{
                    backgroundColor: "#F8F9FE",
                  }}
                >
                  <h1
                    className="p-0 m-0 merriweather  font-bold text-dark-gray"
                    style={{ fontSize: "30px" }}
                  >
                    {quoteData?.profileViewThisMonth}
                  </h1>
                  <p className="font-semibold text-sm text-light-black">
                    Profile View
                  </p>
                  <div
                    className="p-2 border-round-lg"
                    style={{ backgroundColor: "#EEF1FF" }}
                  >
                    <p className="p-0 m-0 font-10 text-green-800 ">
                      {quoteData?.percentIncreaseProfileView === null
                        ? 0
                        : quoteData?.percentIncreaseProfileView}
                      %
                    </p>
                    <p className="p-0 m-0 text-xs font-semibold">
                      {" "}
                      In the Past 1 month
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </CardComponent>
        </div>
      </div>
      <div className="grid mb-3">
        <div className="col-12 md:col-8 flex align-items-center justify-content-between ">
          <h2 className="merriweather text-lg font-bold text-dark-gray">
            Quote Requests
          </h2>
        </div>
        <div className="col-12 md:col-4 lg:text-right md:text-right lg:mb-0 md:mb-0 mb-3">
          <div className="">
            <span className="merriweather text-sm font-semibold text-dark-gray">
              Sort by
            </span>
            <span className="ml-3 p-2 border-round-md text-sm font-normal text-light-black">
              <Dropdown
                placeholder="Newest"
                options={sort}
                onChange={(e) => setSortValue(e.value)}
                value={!sortValue ? "New" : sortValue}
              />
            </span>
          </div>
        </div>

        <div className="w-full lg:mt-3">
          <VendorData quoteData={quoteData} getChatData={getChatData} />
          <p
            className="text-main-color font-normal text-sm underline cursor-pointer"
            onClick={() => setActiveIndex(2)}
          >
            View All Inquires
          </p>
        </div>
      </div>
    </>
  );
}
