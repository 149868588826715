import { types } from "../types";
import Constants from "../../services/constant";
import { showLoaderAction, hideLoaderAction } from "./loaderAction";
import { showToast } from "./toastAction";
import api from "../../services/api";

export const allMemberShipAction = () => async (dispatch) => {
  dispatch(showLoaderAction());
  const res = await api("get", Constants.END_POINT.ALL_SUBSCRIPTION_TYPE);
  if (res.success) {
    if (res.data) {
      dispatch({
        type: types.ALL_SUBSCRIPTION_TYPE,
        payload: res.data,
      });
    }
    dispatch(hideLoaderAction());
  } else {
    dispatch(hideLoaderAction());
    dispatch(showToast({ severity: "error", summary: res.message }));
  }
};

export const memberShipAction = (id, params) => async (disptach) => {
  disptach(showLoaderAction());
  const res = await api(
    "get",
    `${Constants.END_POINT.SUBSCRIPTION_PLAN}/${id}`,
    {},
    params
  );
  if (res.success) {
    if (res.data) {
      disptach({
        type: types.SUBSCRIPTION_PLAN,
        payload: res.data,
      });
    }
    disptach(hideLoaderAction());
    return res;
  } else {
    disptach(hideLoaderAction());
    disptach(showToast({ severity: "error", summary: res.message }));
  }
};

export const paymentAction = (data) => async (dispatch) => {
  dispatch(showLoaderAction());
  const res = await api("post", Constants.END_POINT.PAYMENT, data);
  if (res.success) {
    if (res.data) {
      dispatch({
        type: types.PAYMENT_METHOD,
        payload: res.data,
      });
    }
    dispatch(hideLoaderAction());
    return res;
  } else {
    dispatch(hideLoaderAction());
    dispatch(showToast({ severity: "error", summary: res.message }));
  }
};

export const showPlan = () => async (dispatch) => {
  dispatch(showLoaderAction());
  const res = await api("get", Constants.END_POINT.SHOW_PLAN);
  if (res.success) {
    if (res.data) {
      dispatch({
        type: types.SHOW_PLAN,
        payload: res.data,
      });
    }
    dispatch(hideLoaderAction());
    return res;
  } else {
    dispatch(hideLoaderAction());
    dispatch(showToast({ severity: "error", summary: res.message }));
  }
};

export const membershipPlanActiveInaActiveAction = () => async (dispatch) => {
  dispatch(showLoaderAction());
  const res = await api(
    "get",
    Constants.END_POINT.MEMBERSHIPPLANACTIVEINACTIVE
  );
  if (res.success) {
    if (res.data) {
      dispatch({
        type: types.MEMBERSHIPPLAN_ACTIVE_INACTIVE,
        payload: res.data,
      });
    }
    dispatch(hideLoaderAction());
    return res;
  } else {
    dispatch(hideLoaderAction());
    dispatch(showToast({ severity: "error", summary: res.message }));
  }
};

export const cancelPlanAction = (id) => async (dispatch) => {
  dispatch(showLoaderAction());
  const res = await api(
    "delete",
    `${Constants.END_POINT.CANCEL_PLAN}${id}`,
    {}
  );
  if (res.success) {
    if (res.data) {
      dispatch({
        type: types.CANCEL_PLAN,
        payload: res.data,
      });
    }
    dispatch(hideLoaderAction());
  } else {
    dispatch(hideLoaderAction());
    dispatch(showToast({ severity: "error", summary: res.message }));
  }
};

// export const changePlanAction = (data) => async (dispatch) => {
//   dispatch(showLoaderAction());
//   const res = await api("post", Constants.END_POINT.CHANGE_PLAN, data);
//   if (res.success) {
//     if (res.data) {
//       dispatch({
//         type: types.CHANGE_PLAN,
//         payload: res.data,
//       });
//     }
//     dispatch(hideLoaderAction());
//   } else {
//     dispatch(hideLoaderAction());
//     dispatch(showToast({ severity: "error", summary: res.message }));
//   }
// };
export const changePlanAction = (data) => async (dispatch) => {
  dispatch(showLoaderAction());
  const res = await api("post", Constants.END_POINT.CHANGE_PLAN, data);
  if (res.success) {
    if (res.data) {
      dispatch({
        type: types.CHANGE_PLAN,
        payload: res.data,
      });
    }
    dispatch(hideLoaderAction());
    return res;
  } else {
    dispatch(hideLoaderAction());
    dispatch(showToast({ severity: "error", summary: res.message }));
  }
};
