import { Dialog } from "primereact/dialog";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CustomCalenderInput, CustomInput } from "../../../components/input/AllInput";
import ButtonComponent from "../../../components/buttons/button";
import WeddingWebsiteContainer from "./WeddingWebsiteContainer";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import { addWebsiteAction } from "../../../redux/actions/weddingWebsiteAction";

export default function WebsiteModal({ visible, setVisible }) {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { websiteData, websiteDataHandleChange, save, getHostWebsite, setWebsiteData } = WeddingWebsiteContainer();

    const hostProfileData = useSelector((state) => state?.hostProfile?.ProfileData);

    useEffect(() => {
        if (Object.values?.(hostProfileData)?.length > 0) {
            setWebsiteData({
                ...websiteData,
                name: hostProfileData?.name,
                partnerName: hostProfileData?.partnerName,
                weddingDate: moment(hostProfileData?.weddingDate).toDate(),
                receptionDate: moment(hostProfileData?.receptionDate).toDate(),
            });
        }
    }, [hostProfileData]);

    const handleCreateWebTemp = () => {
        const formData = { ...websiteData };

        formData.welcome = {
            title: formData.welcomeTitle,
            description: formData.welcomeDescription,
        };
        formData.bride = {
            title: formData.brideTitle,
            description: formData.brideDescription,
            image: formData.brideImage,
        };
        formData.groom = {
            title: formData.groomTitle,
            description: formData.groomDescription,
            image: formData.groomImage,
        };
        formData.saveTheDate = {
            title: formData.saveTheDateTitle,
            description: formData.saveTheDateDescription,
            image: formData.saveTheDateImage,
        };
        formData.ourStory = {
            title: formData.ourStoryTitle,
            description: formData.ourStoryDescription,
            images: formData.ourStoryImages,
        };
        formData.rsvp = {
            title: formData.rsvpTitle,
            description: formData.rsvpDescription,
            rsvpDate: formData.rsvpDate,
        };
        formData.endMessage = {
            title: formData.endMessageTitle,
            description: formData.endMessageDescription,
            image: formData.endMessageImage,
        };

        if (!formData?.partnerName?.trim()) {
            setErr("Partner’s name is required!");
            return;
        }
        dispatch(addWebsiteAction(formData, navigate, () => setVisible(false)));
    };

    const [err, setErr] = useState("");

    return (
        <Dialog
            header={<h1 className="merriweather text-2xl text-dark-gray font-bold ">Great Choice, {hostProfileData?.name}! Who you are marrying</h1>}
            visible={visible}
            style={{ width: "100vw" }}
            onHide={() => setVisible(false)}
            className="lg:w-5 md:w-8 w-11"
            draggable={false}
            resizable={false}
        >
            <div className="grid m-0 flex align-items-center">
                <div className="col-12 md:col-6">
                    <CustomInput extraClassName="w-full" label="Name" value={websiteData.name} disabled required></CustomInput>
                </div>
                <div className="col-12 md:col-6">
                    <CustomInput
                        extraClassName="w-full"
                        label="Your Partner’s Name"
                        value={websiteData.partnerName}
                        onChange={(e) => {
                            setErr("");
                            websiteDataHandleChange("partnerName", e.target.value);
                        }}
                        errorMessage={err}
                        required
                    ></CustomInput>
                </div>
                <div className="col-12 md:col-6">
                    <CustomCalenderInput
                        extraClassName="w-full"
                        label="Wedding Date"
                        value={websiteData.weddingDate}
                        onChange={(e) => websiteDataHandleChange("weddingDate", e.target.value)}
                    ></CustomCalenderInput>
                </div>
                <div className="col-12 md:col-6">
                    <CustomCalenderInput
                        extraClassName="w-full"
                        label="Reception Date"
                        value={websiteData.receptionDate}
                        onChange={(e) => websiteDataHandleChange("receptionDate", e.target.value)}
                    ></CustomCalenderInput>
                </div>
            </div>
            <div className="col-12">
                To update the bride's or groom's name, please go to the{" "}
                <Link to="/host/edit-profile" onClick={() => setVisible(false)}>
                    profile settings
                </Link>{" "}
                .
            </div>
            <div className="col-12 md:col-12">
                <ButtonComponent label="Create My Website" className="w-full p-3" onClick={handleCreateWebTemp}></ButtonComponent>
            </div>
        </Dialog>
    );
}
