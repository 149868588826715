import React from "react";
import { ContainerBox } from "../../shared/ContainerBox";

export const Review = () => {
  return (
    <>
      <div className="review-couples-container mt-5 lg:mb-8">
        <ContainerBox>
          <div className="testimonial relative ">
            <div className="couple-text text-center justify-content-center flex">
              <h2 className=" merriweather lg:w-full md:w-full w-8 text-2xl p-0 m-0 pt-7 lg:mb-5 md:mb-5 text-center text-dark-gray relative w-full ">
                Couples love us (and we love them!)
              </h2>
            </div>
          </div>

          <div className=" relative text-center">
            <iframe
              src="https://player.vimeo.com/video/892178888?h=05ef41d5f9"
              className="review-video p-3"
              width="700px"
              height="350px"
              frameBorder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </ContainerBox>
      </div>
    </>
  );
};
