import React, { useState } from "react";
import { IndexLayout } from "../../layout";
import { ContainerBox } from "../../shared/ContainerBox";
import ForgotImg from "../../assets/images/forgot.png";
import ButtonComponent from "../../components/buttons/button";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { CustomPassword } from "../../components/input/AllInput";
import { matchPasswordsValidation } from "../../utils/formValidations";
import {
  resetPasswordAction,
  setPasswordAction,
} from "../../redux/actions/signupAction";

export const NewPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const state = useLocation();
  const [form, setForm] = useState({
    password: "",
    confirmPassword: "",
    otpcode: state?.state,
    email: "",
  });
  const [errors, setErrors] = useState({});
  const getEmail = localStorage.getItem("email");
  const setField = (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });
    if (!!errors[field]) {
      setErrors({
        ...errors,
        [field]: null,
      });
    }
  };

  const updateFormErrors = (errors) => {
    // Extract the error text from the errors object
    const errorText = Object.keys(errors).reduce((acc, key) => {
      acc[key] = errors[key][Object.keys(errors[key])[0]];
      return acc;
    }, {});

    setErrors(errorText);
  };

  const validateSubmit = (e) => {
    e.preventDefault();

    const tempErrors = {
      confirmPassword: matchPasswordsValidation(
        "confirmPassword",
        form.password,
        form.confirmPassword
      ),
    };

    updateFormErrors(tempErrors);

    const hasErrors = Object.values(tempErrors).some((errors) =>
      Object.values(errors).some((error) => error !== "")
    );

    if (hasErrors) {
      return;
    }
    handleSubmit();
  };

  const handleSubmit = () => {
    let payload = {
      password: form?.password,
      otpcode: parseInt(state?.state),
      email: getEmail,
    };
    dispatch(setPasswordAction(payload)).then((res) => {
      if (res.success === true) {
        navigate("/reset-successful");
      }
    });
  };

  return (
    <>
      <ContainerBox containerType="">
        <div className="grid p-0 m-0">
          <div className="lg:col-6 lg:p-5 md:p-5">
            <img src={ForgotImg} alt="" className="w-full" />
          </div>
          <div className="lg:col-6">
            <div className="bg-white lg:p-4 md:p-4 py-5 mt-5">
              {/* <img src={CheckIcon} alt="" /> */}
              <h4 className="font-32 mb-2 merriweather font-normal text-dark-gray ml-1">
                Create New Password
              </h4>
              <p className="text-dark-gray text-sm mb-5 ml-1 font-medium">
                Create a New Password
              </p>

              {/* <PasswordField  /> */}
              <CustomPassword
                col={12}
                value={form.password}
                onChange={(e) => setField("password", e.target.value)}
                placeholder="Password"
              />
              <CustomPassword
                col={12}
                value={form.confirmPassword}
                onChange={(e) => setField("confirmPassword", e.target.value)}
                placeholder="Confirm Password"
                errorMessage={errors?.confirmPassword}
              />
              <ButtonComponent
                label="Continue"
                className="mt-3 lg:ml-1 w-full lg:w-3"
                onClick={validateSubmit}
              />
            </div>
          </div>
        </div>
      </ContainerBox>
    </>
  );
};
