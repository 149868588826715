import { types } from "../types";

const initialState = {
  vendorViewData: [],
  addReviewProfile: [],
  addQuoteProfile: [],
  addTentativeProfile: [],
  showAllTentativeProfile: [],
  vendorFilterProfile: [],
  deleteVendorProfile: [],
};
export const VendorProfileViewReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.PUBLIC_VENDOR_VIEW:
      return {
        ...state,
        vendorViewData: action.payload,
      };
    default: {
      return state;
    }
  }
};

export const addReviewReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.ADD_REVIEW:
      return {
        ...state,
        addReviewProfile: action.payload,
      };
    default: {
      return state;
    }
  }
};
export const addQuoteProfileReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.ADD_QUOTE:
      return {
        ...state,
        addQuoteProfile: action.payload,
      };
    default: {
      return state;
    }
  }
};

export const addTentativeReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.HOST_ADD_TENTATIVE:
      return {
        ...state,
        addTentativeProfile: action.payload,
      };
    default: {
      return state;
    }
  }
};

export const showAllTentativeReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.ALL_TENTATIVE_VENDORS:
      return {
        ...state,
        showAllTentativeProfile: action.payload,
      };
    default: {
      return state;
    }
  }
};

export const vendorFilterReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FILTER_VENDORS:
      return {
        ...state,
        vendorFilterProfile: action.payload,
      };
    default: {
      return state;
    }
  }
};

export const deleteVendorReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.DELETE_PROFILE:
      return {
        ...state,
        deleteVendorProfile: action.payload,
      };
    default: {
      return state;
    }
  }
};
