import React, { useState, useEffect } from "react";
import { Image } from "primereact/image";
import { getDefaultImage, getImageURL } from "../../utils/imageUrl";
import Gallery from "../../assets/images/image-plus.png";
import ButtonComponent from "../../components/buttons/button";
export default function CustomeReviewImage({
  label,
  name,
  onFilesChange,
  errorMessage,
  extraClassName,
  value,
  data,
  limit,
  multiple,
  boxContainer,
  col,
  required,
  removeable,
  editable,
  disabled = false,
  setVisible,
  ...props
}) {
  const [files, setFiles] = useState(value || data?.[name] || []);

  useEffect(() => {
    if (value || data?.[name]) {
      if (JSON.stringify(value || data?.[name]) !== JSON.stringify(files)) {
        setFiles(value || data?.[name]);
      }
    }
  }, [value || data?.[name]]);

  useEffect(() => {
    if (onFilesChange) {
      onFilesChange({ name, value: files });
    }
  }, [files]);

  const onDelete = (id) => {
    if (editable) {
      const s = files.filter((item, index) => index !== id);
      setFiles([...s]);
    }
  };
  const onFileChange = (e) => {
    if (editable) {
      const chossenFile = e.target?.files;
      handlefileChange(Object.values(chossenFile));
    }
  };
  const handlefileChange = (uploadedfile) => {
    let uploadedFiles = [];
    uploadedfile.forEach((file) => {
      if (files.findIndex((f) => f?.name === file?.name) === -1) {
        uploadedFiles.push(file);
      }
    });
    if (multiple) {
      if (limit) {
        let newarr = [...files, ...uploadedFiles];
        if (newarr.length > limit) {
          newarr = newarr.slice(0, limit);
        }
        setFiles(newarr);
      } else {
        setFiles([...files, ...uploadedFiles]);
      }
    } else {
      setFiles([...uploadedFiles]);
    }
  };

  return (
    <>
      <p className="m-0">
        <div className="grid">
          <div className="col-12 md:col-3">
            <label
              htmlFor={name}
              className=""
              // style={{ backgroundColor: "#F2F4F7" }}
            >
              <Image src={Gallery} alt="" className="" />

              <input
                name={name}
                onChange={onFileChange}
                id={name}
                type="file"
                accept="image/*"
                hidden
                {...props}
                multiple={multiple}
                disabled={disabled}
                className=""
              />
            </label>
          </div>
        </div>

        <div className="flex overflow-auto flex relative ">
          {files.length
            ? Object.values(files).map((image, i) => (
                <div key={i} className="w-full p-1 relative">
                  <Image
                    className="cover-img "
                    src={
                      image
                        ? typeof image === "string"
                          ? getImageURL(image)
                          : URL.createObjectURL(image)
                        : Gallery
                    }
                    onError={(e) => (e.target.src = getDefaultImage())}
                    alt="Image"
                    preview
                    icon="pi pi-eye"
                    width="154px"
                    height="140px"
                  />{" "}
                  {removeable && (
                    <i
                      onClick={() => onDelete(i)}
                      className="pi pi-times cursor-pointer  "
                      style={{ position: "absolute", right: "10px" }}
                    ></i>
                  )}
                </div>
              ))
            : null}
        </div>
        <div className="border-1 border-400 border-round-lg p-3 mb-5 mt-5">
          <p className="p-0 m-0 font-normal text-sm text-gray-white">
            <b className="text-main-color"> Click to upload</b>
            &nbsp;&nbsp; or drag and drop<br></br>
            PNG, JPG or GIF (max. 20Mb)
          </p>
        </div>
        <ButtonComponent
          label="Upload"
          className="w-full"
          onClick={() => setVisible(false)}
        />
      </p>
    </>
  );
}
