import React, { useState, useEffect } from "react";
import { CardComponent } from "../cards/card";
import ReactApexChart from "react-apexcharts";
import { useSelector } from "react-redux";
export default function Chartcomponent({ selectedYear, setSelectedYear }) {
  const [chartData, setChartData] = useState([]);
  const currentYear = new Date().getFullYear();
  const startYear = 2023;
  const years = Array.from(
    { length: currentYear - startYear + 1 },
    (_, index) => startYear + index
  );
  useEffect(() => {
    setSelectedYear(currentYear);
  }, [currentYear]);
  const [impChartData, setImpChartData] = useState([]);
  const [newMonthlyQueries, setNewMonthQueries] = useState([]);
  const [viewedMonthlyQueries, setViewedMonthQueries] = useState([]);
  const handleYearChange = (event) => {
    setSelectedYear(parseInt(event.target.value, 10));
  };

  const graphData = useSelector((state) => state.vendorGraph.graphProfile);
  useEffect(() => {
    let array = [];
    let impMonthlyQueries = [];
    let newMonthly = [];
    let viwedQueries = [];

    if (graphData?.decMonthlyQueries?.length > 0) {
      graphData?.decMonthlyQueries?.map((item) => {
        array.push(item?.count);
      });
      setChartData(array);
    }
    if (graphData?.impMonthlyQueries?.length > 0) {
      graphData?.impMonthlyQueries?.map((item) => {
        impMonthlyQueries?.push(item?.count);
      });
      setImpChartData(impMonthlyQueries);
    }
    if (graphData?.newMonthlyQueries?.length > 0) {
      graphData?.newMonthlyQueries?.map((item) => {
        newMonthly?.push(item?.count);
      });
      setNewMonthQueries(newMonthly);
    }
    if (graphData?.viewedMonthlyQueries?.length > 0) {
      graphData?.viewedMonthlyQueries?.map((item) => {
        viwedQueries?.push(item?.count);
      });
      setViewedMonthQueries(viwedQueries);
    }
  }, [graphData]);

  const options = {
    chart: {
      type: "bar",
      stacked: true, // Set this to true for stacked columns
    },
    plotOptions: {
      bar: {
        horizontal: false,
        dataLabels: {
          total: {
            enabled: true,
            style: {
              fontSize: "13px",
              fontWeight: 900,
            },
            formatter: (val) => (val > 0 ? val : ""),
          },
        },
      },
    },
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
    },
    yaxis: {
      title: {
        text: "Values",
      },
    },
    legend: {
      position: "top",
    },
    colors: ["#5298EA", "#FAD3E7", "#48964D", "#FA564B"],
  };

  const series = [
    {
      name: "New",
      data: newMonthlyQueries,
    },
    {
      name: "Viewed",
      data: viewedMonthlyQueries,
    },
    {
      name: "Important",
      data: impChartData,
    },
    {
      name: "Declined",
      data: chartData,
    },
  ];
  return (
    <>
      <CardComponent className="card shadow-1">
        <div className="grid">
          <div className="col-12 md:col-6">
            <h2 className="merriweather text-lg font-bold text-dark-gray">
              New Requests Overview
            </h2>
          </div>
          <div className="col-12 md:col-6 lg:text-right md:text-right">
            <div className="">
              <span className="merriweather text-sm font-semibold text-dark-gray mx-2 ">
                Sort by
              </span>
              <select
                id="year"
                value={selectedYear}
                onChange={handleYearChange}
                className="p-3 border-round-lg"
                style={{ width: "50%", borderColor: "lightgray" }}
              >
                {years.map((year) => (
                  <option key={year} value={year} placeholder="current Year">
                    {year}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>

        <ReactApexChart
          options={options}
          series={series}
          type="bar"
          height={350}
        />
      </CardComponent>
    </>
  );
}
