import { showLoaderAction, hideLoaderAction } from "./loaderAction";
import api from "../../services/api";
import Constants from "../../services/constant";
import { showToast } from "./toastAction";
import { types } from "../types";

export const seatingChartAction = (next) => async (dispatch) => {
    dispatch(showLoaderAction());
    const res = await api("get", Constants.END_POINT.SEATING, {});
    if (res.success) {
        if (res.data._id) {
            next(res.data);
        }
        dispatch(hideLoaderAction());
    } else {
        dispatch(hideLoaderAction());
        dispatch(showToast({ severity: "error", summary: res.message }));
    }
};

export const addSeatingChartAction = (data, next) => async (dispatch) => {
    dispatch(showLoaderAction());
    const res = await api("post", Constants.END_POINT.SEATING, data);
    if (res.success) {
        if (res.data) {
            if (next) {
                next(res.data);
            }
        }
        dispatch(hideLoaderAction());
    } else {
        dispatch(hideLoaderAction());
        dispatch(showToast({ severity: "error", summary: res.message }));
    }
};

export const updateSeatingChartAction = (id, data, next) => async (dispatch) => {
    const res = await api("put", `${Constants.END_POINT.SEATING}/${id}`, data);
    if (res.success) {
        if (res.data) {
            if (next) {
                next(res.data);
            }
        }
    } else {
        dispatch(showToast({ severity: "error", summary: res.message }));
    }
};

export const deleteSeatingChartAction = (id, next) => async (dispatch) => {
    dispatch(showLoaderAction());
    const res = await api("delete", Constants.END_POINT.SEATING, {});
    if (res.success) {
        if (res.data) {
            next();
        }
        dispatch(hideLoaderAction());
    } else {
        next();
        dispatch(hideLoaderAction());
        dispatch(showToast({ severity: "error", summary: res.message }));
    }
};
