export const types = {
  SING_UP: "SING_UP",
  SHOW_LOADER: "SHOW_LOADER",
  HIDE_LOADER: "HIDE_LOADER",
  SHOW_TOAST: "SHOW_TOAST",
  SIGN_IN: "SIGN_IN",
  FORGET_PASSWORD: "FORGET_PASSWORD",
  RESET_PASSWORD: "RESET_PASSWORD",
  SOCIAL_LOGIN: "SOCIAL_LOGIN",
  REQUEST_OTP: "REQUEST_OTP",
  VERIFY_OTP: "SEND_OTP",
  CLEAR_PROFILE: "CLEAR_PROFILE",
  PUBLIC_VENDOR_VIEW: "PUBLIC_VENDOR_VIEW",
  SET_PASSWORD: "SET_PASSWORD",
  REQUEST_FORGET_OTP: "REQUEST_FORGET_OTP",
  FILTER_VENDORS: "FILTER_VENDORS",
  FILTER_AMENTIES: "FILTER_AMENTIES",
  SOCIAL_SIGNUP: "SOCIAL_LOGIN",

  // Vendors
  VENDOR_CATEGORIES: "VENDOR_CATEGORIES",
  ALL_VENDORS: "ALL_VENDORS",
  VENDORS_BY_CATEGORY: "VENDORS_BY_CATEGORY",
  UPLOAD_BUSSINESS_IMAGE: "UPLOAD_BUSSINESS_IMAGE",
  VENDOR_PROFILE: "VENDOR_PROFILE",
  UPDATE_VENDER_PROFILE: "UPDATE_VENDER_PROFILE",
  UPLOAD_MULTIPLE_BUSINESS_IMAGE: "UPLOAD_MULTIPLE_BUSINESS_IMAGE",
  UPLOAD_MULTIPLE_BUSINESS_VIDEOS: "UPLOAD_MULTIPLE_BUSINESS_VIDEOS",
  UPLOAD_PRE_WEDDING_VIDEOS: "UPLOAD_PRE_WEDDING_VIDEOS",
  QUOTE_REQUEST: "QUOTE_REQUEST",
  ADD_REVIEW: "ADD_REVIEW",
  ADD_QUOTE: "ADD_QUOTE",
  QUOTE_GRAPH: "QUOTE_GRAPH",
  ALL_SUBSCRIPTION_TYPE: "ALL_SUBSCRIPTION_TYPE",
  SUBSCRIPTION_PLAN: "SUBSCRIPTION_PLAN",
  PAYMENT_METHOD: "PAYMENT_METHOD",
  SHOW_PLAN: "SHOW_PLAN",
  MEMBERSHIPPLAN_ACTIVE_INACTIVE: "MEMBERSHIPPLAN_ACTIVE_INACTIVE",
  CANCEL_PLAN: " CANCEL_PLAN",
  CHANGE_PLAN: "CHANGE_PLAN",

  //host profile
  MY_PROFILE: "MY_PROFILE",
  UPDATE_PROFILE: "UPDATE_PROFILE",
  DELETE_QUOTE: "DELETE_QUOTE",
  VIEW_QUOTE_DATA: "VIEW_QUOTE_DATA",
  IMPORTANT_QUOTE: "IMPORTANT_QUOTE",
  AMENITIES_DATA: "AMENITIES_DATA",
  CHANGE_PASSWORD: "CHANGE_PASSWORD",
  DELETE_HOST_ACCOUNT: "DELETE_HOST_ACCOUNT",
  HOST_ADD_TENTATIVE: "HOST_ADD_TENTATIVE",
  ALL_TENTATIVE_VENDORS: "ALL_TENTATIVE_VENDORS",
  DELETE_PROFILE: "DELETE_PROFILE",
  WEDDING_RECEPTION_COUNT: " WEDDING_RECEPTION_COUNT",
  BUDGET_LIST: "BUDGET_LIST",
  ADD_BUDGET: "ADD_BUDGET",
  GUEST_LIST: "GUEST_LIST",
  WEBSITE_TEMPLATE: "WEBSITE_TEMPLATE",
  BUDGET_LIST_BY_VENDOR_TYPE: "BUDGET_LIST_BY_VENDOR_TYPE",
};
