import React from "react";
import { ContainerBox } from "../../shared/ContainerBox";
import ButtonComponent from "../../components/buttons/button";
import { useNavigate } from "react-router-dom";
export const GetStarted = () => {
  const navigate = useNavigate();
  return (
    <ContainerBox containerType="container mb-6">
      <div className="bg-main p-4 lg:w-10 m-auto  text-center border-round-lg">
        <h4 className="font-24 text-white merriweather">
          Embark on your dream wedding journey with PlanTheKnot
        </h4>
        <p className="font-12 text-white">
          Dive into a world of wedding wonders, where your dream day unfolds
          effortlessly. Discover the finest vendors, meticulously curated <br />{" "}
          with prices and reviews, empowering you to make informed choices. From
          enchanting venues <br /> to skilled photographers, we've got your
          back. Your journey to the perfect wedding begins here.
          <br /> Unleash the magic, click to plan, and create memories that last
          a lifetime!
          <br /> #PlanTheKnot #WeddingPlanning #DreamDay"
          {/* PlanTheKnot is a Wedding Planning Website and app where you can find
          the best
          <br /> wedding vendors, with prices and reviews at the click of a
          button */}
        </p>
        <ButtonComponent
          label="Get Started Today"
          className="bg-white text-main-color mb-3"
          onClick={() => navigate("/login")}
        />
      </div>
    </ContainerBox>
  );
};
