import React, { useEffect, useState } from "react";
import { ContainerBox } from "../../shared/ContainerBox";
import { IndexLayout } from "../../layout";
import SignupImg from "../../assets/images/Group 1000008906.png";
import Logo from "../../assets/images/logo.png";
import { TabView, TabPanel } from "primereact/tabview";
import { Bussiness } from "./Bussiness";
import { Host } from "./Host";
import { useLocation, useNavigate } from "react-router-dom";

export const Signup = ({ isLoaded }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const { state, search } = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    if (state?.data === true || search === "?user=vendor") {
      setActiveIndex(1);
    }
  }, [state]);

  useEffect(() => {
    if (activeIndex === 0) navigate("/signup?user=host");
    else navigate("/signup?user=vendor");
  }, [activeIndex]);
  return (
    <>
      <ContainerBox containerType="">
        <div className="grid p-0 m-0">
          <div className="lg:col-6 lg:p-5 md:p-5  text-center signup-left">
            <img src={SignupImg} alt="" className="w-full" />
          </div>

          <div className="lg:col-6 mt-4">
            <div className="bg-white lg:p-4 md:p-4 py-5">
              <h1 className="font-32 merriweather font-normal mb-2 flex align-items-center  text-dark-gray">
                Grow your Business with{" "}
                <img
                  src={Logo}
                  width={149}
                  height={38}
                  alt=""
                  className="ml-3"
                />
              </h1>
              <div className="text-gray font-medium text-sm mb-4">
                <p>Sign Up to access your Dashboard</p>
              </div>
              <TabView
                className="register"
                activeIndex={activeIndex}
                onTabChange={(e) => setActiveIndex(e.index)}
              >
                <TabPanel header="Host Sign Up">
                  <Host />
                </TabPanel>
                <TabPanel header="Business Sign Up">
                  <Bussiness isLoaded={isLoaded} />
                </TabPanel>
              </TabView>
            </div>
          </div>
        </div>
      </ContainerBox>
    </>
  );
};
