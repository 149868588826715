import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  myProfileAction,
  updateProfileAction,
} from "../../../redux/actions/profileAction";
import { Country, State, City } from "country-state-city";

const EditProfileContainer = () => {
  const allCity = useMemo(
    () =>
      City.getCitiesOfCountry("GB")?.map((item) => {
        return { label: item.name, value: item.name };
      }),
    []
  );
  const dispatch = useDispatch();
  const hostProfileData = useSelector(
    (state) => state?.hostProfile?.ProfileData
  );
  const [profileData, setProfileData] = useState({
    name: "",
    email: "",
    mobile: null,
    partnerName: "",
    city: "",
    // weddingLocation: "",
    weddingDate: null,
    weddingGuestNumber: null,
    // receptionLocation: null,
    receptionDate: null,
    receptionGuestNumber: null,
    profileImage: null,
  });

  const profileHandleChange = ({ name, value }) => {
    setProfileData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const save = () => {
    dispatch(
      updateProfileAction(profileData, () => {
        dispatch(myProfileAction());
      })
    );
  };

  useEffect(() => {
    if (Object.values(hostProfileData).length > 0) {
      setProfileData({
        ...hostProfileData,
        name: hostProfileData?.name,
        email: hostProfileData?.email,
        mobile: hostProfileData?.mobile,
        partnerName: hostProfileData?.partnerName,
        weddingLocation: hostProfileData?.weddingLocation,
        weddingDate: hostProfileData?.weddingDate,
        weddingGuestNumber: hostProfileData?.weddingGuestNumber,
        receptionLocation: hostProfileData?.receptionLocation,
        receptionDate: hostProfileData?.receptionDate,
        receptionGuestNumber: hostProfileData?.receptionGuestNumber,
        profileImage: hostProfileData?.profileImage,
      });
    }
  }, [hostProfileData]);

  useEffect(() => {
    dispatch(myProfileAction());
  }, []);

  return {
    profileData,
    profileHandleChange,
    save,
    allCity,
  };
};

export default EditProfileContainer;
