import React, { useEffect, useState } from "react";
import { ContainerBox } from "../../shared/ContainerBox";
import ForgotImg from "../../assets/images/forgot.png";
import ButtonComponent from "../../components/buttons/button";
import { Link, useNavigate } from "react-router-dom";
import { EmailFeild } from "../signup/CommonFeild";
import { useDispatch, useSelector } from "react-redux";
import { allValidations } from "../../utils/formValidations";
import { forgetPasswordAction } from "../../redux/actions/signupAction";
import { types } from "../../redux/types";

export const ForgotPassword = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { toastInfo } = useSelector((state) => state?.toast);

    const [form, setForm] = useState({ email: "" });
    const [errors, setErrors] = useState({});
    useEffect(() => {
        return () => {
            dispatch({ type: types.SHOW_TOAST, payload: {} });
        };
    }, []);

    const setField = (field, value) => {
        setForm({
            ...form,
            [field]: value,
        });
        if (!!errors[field]) {
            setErrors({
                ...errors,
                [field]: null,
            });
        }
    };
    const handleSubmit = () => {
        const { email: emailValidate } = allValidations("email", form.email, form);

        if (emailValidate) {
            setErrors({ email: emailValidate });
            return;
        }

        dispatch(forgetPasswordAction(form, () => navigate("/verification-otp")));
    };

    return (
        <>
            <ContainerBox containerType="">
                <div className="grid p-0 m-0">
                    <div className="lg:col-6 lg:p-5 md:p-5">
                        <img src={ForgotImg} alt="" className="w-full" />
                    </div>
                    <div className="lg:col-6">
                        <div className="bg-white lg:p-4 md:p-4 py-5 mt-5">
                            {/* <img src={CheckIcon} alt="" /> */}
                            <h4 className="font-32 mb-2 merriweather font-normal text-dark-gray ml-1">Reset your password</h4>
                            <p className="text-dark-gray text-sm mb-5 ml-1 font-medium">We will send you an email to reset your password.</p>
                            {toastInfo?.summary && <div className="p-3 border border-2 alert-error mb-2 text-center">{toastInfo?.summary}</div>}

                            <EmailFeild value={form.email} errorMessage={errors?.email} onChange={(e) => setField("email", e.target.value)} />
                            <ButtonComponent label="Submit" className="mt-3 w-full lg:w-3" onClick={handleSubmit} />

                            <p className="ml-1">
                                <Link to="/login" className=" font-semibold text-gray-light underline text-sm">
                                    Back to login
                                </Link>
                            </p>
                        </div>
                    </div>
                </div>
            </ContainerBox>
        </>
    );
};
