import { useLocation, useParams } from "react-router-dom";
import { filterVendors } from "../../redux/actions/BusinessProfile";
import _debounce from "lodash/debounce";
import { useEffect, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { filterAmenitiesAction } from "../../redux/actions/bussinessInformationAction";
import { vendorListAction } from "../../redux/actions/vendorAction";
const ExploreData = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { state } = useLocation();
  const [visibleRight, setVisibleRight] = useState(false);
  const vendors = useSelector((state) => state?.vendor?.allVendors);
  const [amenities, setAmenities] = useState([]);
  const [amenitiesFilter, setAmenitiesFilter] = useState([]);
  const [capacity, setCapacity] = useState("");
  const [languages, setLanguages] = useState([]);
  const [rating, setRating] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [value, setValue] = useState([250, 25000]);
  const [filterCount, setFilterCount] = useState(0);
  const handleDebounceFn = (payload) => {
    if (payload == "empty") {
      dispatch(filterVendors({}, true));
    } else {
      dispatch(filterVendors(payload));
    }
  };
  const [searchVendor, setSearchVendor] = useState("");
  const debounceFn = useCallback(_debounce(handleDebounceFn, 1000), []);
  const handleSearchChange = (e) => {
    if (e.target.value?.length <= 0) {
      setSearchVendor(e?.target?.value);
      debounceFn("empty");
    } else {
      setSearchVendor(e?.target?.value);
      debounceFn({
        search: e?.target?.value,
        vendorType: id,
      });
    }
  };

  const serachVendorData = useSelector(
    (state) => state?.vendorFilter?.vendorFilterProfile
  );
  const amenitiesData = useSelector(
    (state) => state?.filterAmenities?.filterAmenitiesProfile
  );
  useEffect(() => {
    if (visibleRight === true) {
      const params = {
        vendorType: id,
      };
      dispatch(filterAmenitiesAction(params));
    }
  }, [visibleRight]);

  const BudgetData = ["Under £30", "£30-£70", "£70-£100", "£100+"];
  const guestCapcity = [
    "50-100",
    "100-150",
    "100-200",
    "200-250",
    "250-300",
    "300-350",
    "350-400",
    "400-450",
    "450-500",
  ];
  const language = [
    "Bengali",
    "Gujarati",
    "Hindi",
    "Kannada",
    "Malayalam",
    "Marathi",
    "Nepali",
    "Punjabi",
    "Tamil",
    "Telugu",
    "Urdu",
  ];
  const handleChangeAmenities = (e) => {
    let amenitiesCheck = [...amenities];
    if (e.checked) amenitiesCheck.push(e.value);
    else amenitiesCheck.splice(amenitiesCheck.indexOf(e.value), 1);
    setAmenities(amenitiesCheck);
  };
  useEffect(() => {
    let array = [];
    if (amenities?.length > 0) {
      amenities?.map((item) => {
        array.push(item?._id);
      });
      setAmenitiesFilter(array);
    } else {
      setAmenitiesFilter([]);
    }
  }, [amenities]);
  const handleView = () => {
     setVisibleRight(!visibleRight);
    let array = [];
    array.push(languages);
    if (amenitiesFilter || capacity || rating || postalCode || array || value) {
      let params = {
        amenities: amenitiesFilter,
        capacity: capacity,
        minRating: rating,
        city: postalCode,
        minBudget: value[0] === 250 ? "" : value[0],
        maxBudget: value[1] === 25000 ? "" : value[1],
        language: array,
        vendorType: id,
      };
      dispatch(filterVendors(params));
      setSearchVendor(id);
    } else {
      setSearchVendor("");
      debounceFn("empty");
    }
  };
  useEffect(() => {
    let amenities = 0;
    let budgetData = 0;
    let capacityData = 0;
    let ratingData = 0;
    let postalCodeData = 0;
    let lanaguageData = 0;
    if (amenitiesFilter?.length > 0) {
      amenities = 1;
    }
    if (value[0] !== 250 || value[1] !== 25000) {
      budgetData = 1;
    }
    if (capacity !== "") {
      capacityData = 1;
    }
    if (rating !== "") {
      ratingData = 1;
    }
    if (postalCode !== "") {
      postalCodeData = 1;
    }
    if (languages?.length > 0) {
      lanaguageData = 1;
    } else {
    }
    setFilterCount(
      amenities +
        budgetData +
        capacityData +
        ratingData +
        postalCodeData +
        lanaguageData
    );
  }, [amenitiesFilter, capacity, rating, postalCode, languages, value]);
  const handleCapcity = (e) => {
    const { value } = e?.target;
    if (e.checked === true) {
      setCapacity(value);
    } else {
      setCapacity("");
    }
  };
  const handleLanguage = (e) => {
    const { value } = e?.target;
    if (e?.checked === true) {
      setLanguages([...languages, value]);
    } else {
      setLanguages(languages.filter((lang) => lang !== value));
    }
  };
  useEffect(() => {
    if (id && state === null) {
      let params = {
        vendorType: id,
      };
      dispatch(vendorListAction(params)).then((res) => {
        dispatch(filterVendors(params));
      });
      setSearchVendor("");
    }
  }, [id, state]);
  const handleClear = () => {
    setAmenities([]);
    setAmenitiesFilter([]);
    setCapacity("");
    setLanguages([]);
    setValue([250, 25000]);
    setRating("");
    setPostalCode("");
    if (state === null) {
      let params = {
        vendorType: id,
      };
      dispatch(vendorListAction(params)).then(() => {
        dispatch(filterVendors(params));
      });
      setSearchVendor("");
    } else {
      let params = {
        vendorType: id,
        city: state?.city,
      };
      dispatch(filterVendors(params));
      setSearchVendor("");
    }
  };
  return {
    handleSearchChange,
    serachVendorData,
    searchVendor,
    visibleRight,
    setVisibleRight,
    amenitiesData,
    BudgetData,
    guestCapcity,
    language,
    setAmenities,
    amenities,
    handleChangeAmenities,
    setCapacity,
    capacity,
    handleCapcity,
    rating,
    setRating,
    setLanguages,
    languages,
    handleLanguage,
    setPostalCode,
    postalCode,
    value,
    setValue,
    handleClear,
    filterCount,
    handleView,
    state,
  };
};
export default ExploreData;
