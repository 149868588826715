import React from "react";
import { IndexLayout } from "../../layout";

export default function TermsandCondition() {
  return (
    <>
      <div className="container mt-1 md:mt-7">
        <h3 className="text-2xl merriweather text-dark-gray">
          Terms and Conditions
        </h3>
        <p className="text-xs line-height-4 text-light-black mb-5">
          These Terms of Service govern your use of the website located at
          <a href="https://plantheknot.co.uk/" className="text-blue-400">
            https://plantheknot.co.uk/
          </a>{" "}
          and any related services provided by PlanTheKnot.
        </p>
        <p className="text-xs line-height-4 text-light-black mb-5">
          By accessing ,{" "}
          <a href="https://plantheknot.co.uk/" className="text-blue-400">
            https://plantheknot.co.uk/
          </a>{" "}
          you agree to abide by these Terms of Service and to comply with all
          applicable laws and regulations. If you do not agree with these Terms
          of Service, you are prohibited from using or accessing this website or
          using any other services provided by PlanTheKnot.
        </p>
        <p className="text-xs line-height-4 text-light-black mb-5">
          We, PlanTheKnot, reserve the right to review and amend any of these
          Terms of Service at our sole discretion. Upon doing so, we will update
          this page. Any changes to these Terms of Service will take effect
          immediately from the date of publication.
        </p>
        <p className="text-xs line-height-4 text-light-black mb-5">
          These Terms of Service were last updated on 2 November 2023.
        </p>
        <h4 className="text-xs font-bold text-light-black">
          Description of Service
        </h4>
        <p className="text-xs line-height-4 text-light-black mb-5">
          Our platform is dedicated to South Asian wedding planning. We connect
          couples with reputable vendors proficient in Indian, Bengali,
          Pakistani, and Sri Lankan wedding traditions. Our services extend from
          vendor listing and engagement to providing tools for budgeting and
          personalised wedding website creation. By registering on PlanTheKnot,
          vendors gain a unique opportunity to showcase their services to a
          targeted audience, while couples benefit from a streamlined process
          leading them from engagement to wedding celebrations. Our platform
          embodies a commitment to ease the intricate process of wedding
          planning, honouring the cultural and emotional significance of South
          Asian weddings.
        </p>
        <h4 className="text-xs font-bold text-light-black">
          Limitations of Use
        </h4>
        <p className="text-xs line-height-4 text-light-black mb-5">
          By using this website, you warrant on behalf of yourself, your users,
          and other parties you represent that you will not:
        </p>
        <ul className="text-xs line-height-4 text-light-black mb-5">
          <li>
            modify, copy, prepare derivative works of, decompile, or reverse
            engineer any materials and software contained on this website;
          </li>
          <li>
            remove any copyright or other proprietary notations from any
            materials and software on this website;
          </li>
          <li>
            transfer the materials to another person or "mirror" the materials
            on any other server;
          </li>
          <li>
            knowingly or negligently use this website or any of its associated
            services in a way that abuses or disrupts our networks or any other
            service PlanTheKnot provides
          </li>
          <li>
            use this website or its associated services to transmit or publish
            any harassing, indecent, obscene, fraudulent, or unlawful material;
          </li>
          <li>
            use this website or its associated services in violation of any
            applicable laws or regulations;
          </li>
          <li>
            use this website in conjunction with sending unauthorised
            advertising or spam;
          </li>
          <li>
            harvest, collect, or gather user data without the user’s consent; or
          </li>
          <li>
            use this website or its associated services in such a way that may
            infringe the privacy, intellectual property rights, or other rights
            of third parties
          </li>
        </ul>
        <h4 className="text-xs font-bold text-light-black">
          Intellectual Property
        </h4>
        <p className="text-xs line-height-4 text-light-black mb-5">
          The intellectual property in the materials contained in this website
          are owned by or licensed to PlanTheKnot and are protected by
          applicable copyright and trademark law. We grant our users permission
          to download one copy of the materials for personal, non-commercial
          transitory use.
        </p>
        <p className="text-xs line-height-4 text-light-black mb-5">
          This constitutes the grant of a licence, not a transfer of title. This
          licence shall automatically terminate if you violate any of these
          restrictions or the Terms of Service, and may be terminated by
          PlanTheKnot at any time.
        </p>
        <h4 className="text-xs font-bold text-light-black">
          User-Generated Content
        </h4>
        <p className="text-xs line-height-4 text-light-black mb-5">
          You retain your intellectual property ownership rights over the
          content you submit to us for publication on our website. We will never
          claim ownership of your content, but we do require a licence from you
          in order to use it.
        </p>
        <p className="text-xs line-height-4 text-light-black mb-5">
          When you use our website or its associated services to post, upload,
          share, or otherwise transmit content covered by intellectual property
          rights, you grant to us a non-exclusive, royalty-free, transferable,
          sub-licensable, worldwide licence to use, distribute, modify, run,
          copy, publicly display, translate, or otherwise create derivative
          works of your content in a manner that is consistent with your privacy
          preferences and our Privacy Policy.
        </p>
        <p className="text-xs line-height-4 text-light-black mb-5">
          The licence you grant us can be terminated at any time by deleting
          your content or account. However, to the extent that we (or our
          partners) have used your content in connection with commercial or
          sponsored content, the licence will continue until the relevant
          commercial or post has been discontinued by us.
        </p>
        <p className="text-xs line-height-4 text-light-black mb-5">
          You give us permission to use your username and other identifying
          information associated with your account in a manner that is
          consistent with your privacy preferences and our Privacy Policy.
        </p>
        <h4 className="text-xs font-bold text-light-black">
          Account Management
        </h4>
        <p className="text-xs line-height-4 text-light-black mb-5">
          In order to use our services you need to create an account. Any
          information provided needs to be accurate and updated if necessary.
          Customers are liable for maintaining their accounts and are fully
          responsible for all activities occurring under their accounts. Should
          anybody gain unauthorised access to your account, you need to notify
          us immediately. PlanTheKnot is not liable for any loss or damage
          arising from failure to maintain the confidentiality of your account.
          When creating your account you are required to provide your financial
          information relevant to the chosen payment method, in some cases, you
          may be requested to provide your credit card details.
        </p>
        <h4 className="text-xs font-bold text-light-black">
          Vendor Engagement
        </h4>
        <p className="text-xs line-height-4 text-light-black mb-5">
          Upon registration as a vendor with PlanTheKnot, acknowledgement of our
          platform’s specialisation in facilitating South Asian weddings is
          required. Vendors are expected to adhere to the cultural norms and
          expectations of Indian, Bengali, Pakistani, and Sri Lankan
          communities. Providing accurate, current, and complete information
          during the registration process is required, and vendors are obliged
          to update this information to keep it accurate, current, and complete.
        </p>
        <p className="text-xs line-height-4 text-light-black mb-5">
          In managing your profile, a truthful representation of your business’s
          expertise and service offerings is essential. Your profile should
          reflect adherence to the cultural nuances and traditions integral to
          the weddings serviced.
        </p>
        <p className="text-xs line-height-4 text-light-black mb-5">
          Listing your services comprehensively is required. This includes clear
          information on pricing, service duration, and any additional costs
          that may be incurred. Misrepresentation or failure to disclose
          pertinent information may lead to disqualification from the platform.
        </p>
        <p className="text-xs line-height-4 text-light-black mb-5">
          Communication with users of PlanTheKnot should always be prompt and
          professional. Any reported instances of disrespectful or
          unprofessional conduct may result in temporary or permanent suspension
          from the platform. Clear and concise responses to inquiries are
          encouraged.
        </p>
        <h4 className="text-xs font-bold text-light-black">User Agreement</h4>
        <p className="text-xs line-height-4 text-light-black mb-5">
          Users of PlanTheKnot agree to engage respectfully with vendors,
          appreciating the cultural significance and unique services offered.
          Dissemination of false information, harassment, or any form of
          misconduct on the platform is strictly prohibited and may lead to
          account suspension or termination.
        </p>
        <p className="text-xs line-height-4 text-light-black mb-5">
          Regarding bookings and payments, users are encouraged to thoroughly
          review vendor offerings, ask necessary questions, and ensure the
          services meet their expectations before proceeding to booking. All
          payment transactions should be conducted through PlanTheKnot’s
          designated payment channels to ensure a secure and reliable process.
          Any attempts to circumvent this system may result in account
          suspension
        </p>
        <p className="text-xs line-height-4 text-light-black mb-5">
          In the event of a dispute arising between users and vendors,
          PlanTheKnot will endeavour to provide a neutral mediation platform.
          Both parties agree to cooperate fully with PlanTheKnot’s dispute
          resolution processes. Failure to comply with the resolutions
          determined by PlanTheKnot’s mediation process may result in account
          suspension or termination.
        </p>
        <p className="text-xs line-height-4 text-light-black mb-5">
          Honest feedback and reviews are encouraged to maintain a transparent
          and helpful community. Users agree to provide constructive feedback
          that reflects their experience accurately.
        </p>
        <h4 className="text-xs font-bold text-light-black">Liability</h4>
        <p className="text-xs line-height-4 text-light-black mb-5">
          Our website and the materials on our website are provided on an 'as
          is' basis. To the extent permitted by law, PlanTheKnot makes no
          warranties, expressed or implied, and hereby disclaims and negates all
          other warranties including, without limitation, implied warranties or
          conditions of merchantability, fitness for a particular purpose, or
          non-infringement of intellectual property, or other violation of
          rights.
        </p>
        <p className="text-xs line-height-4 text-light-black mb-5">
          In no event shall PlanTheKnot or its suppliers be liable for any
          consequential loss suffered or incurred by you or any third party
          arising from the use or inability to use this website or the materials
          on this website, even if PlanTheKnot or an authorised representative
          has been notified, orally or in writing, of the possibility of such
          damage.
        </p>
        <p className="text-xs line-height-4 text-light-black mb-5">
          In the context of this agreement, "consequential loss" includes any
          consequential loss, indirect loss, real or anticipated loss of profit,
          loss of benefit, loss of revenue, loss of business, loss of goodwill,
          loss of opportunity, loss of savings, loss of reputation, loss of use
          and/or loss or corruption of data, whether under statute, contract,
          equity, tort (including negligence), indemnity or otherwise.
        </p>
        <p className="text-xs line-height-4 text-light-black mb-5">
          Because some jurisdictions do not allow limitations on implied
          warranties, or limitations of liability for consequential or
          incidental damages, these limitations may not apply to you.
        </p>
        <h4 className="text-xs font-bold text-light-black">
          Accuracy of Materials
        </h4>
        <p className="text-xs line-height-4 text-light-black mb-5">
          The materials appearing on our website are not comprehensive and are
          for general information purposes only. PlanTheKnot does not warrant or
          make any representations concerning the accuracy, likely results, or
          reliability of the use of the materials on this website, or otherwise
          relating to such materials or on any resources linked to this website.
          You should not rely solely on the said materials to make decisions, we
          advise you to consult other, more complete sources of information. You
          are solely responsible for the strict reliance on the materials posted
          on our website. We have the right to change the information on our
          website without prior notice and users are responsible to monitor said
          changes. Any historical information present on our website is provided
          for reference only..
        </p>
        <h4 className="text-xs font-bold text-light-black">Links</h4>
        <p className="text-xs line-height-4 text-light-black mb-5">
          PlanTheKnot has not reviewed all of the sites linked to its website
          and is not responsible for the contents of any such linked site. The
          inclusion of any link does not imply endorsement, approval or control
          by PlanTheKnot of the site. Use of any such linked site is at your own
          risk and we strongly advise you make your own investigations with
          respect to the suitability of those sites.
        </p>
        <h4 className="text-xs font-bold text-light-black">
          Inaccuracies and Mistakes
        </h4>
        <p className="text-xs line-height-4 text-light-black mb-5">
          Please note that any content available on our website may contain
          typographical errors, inaccuracies, or mistakes related to the
          service’s description, pricing, promotion, and availability. We have
          the right to cancel or update any existing services should any
          information provided by us be proven inaccurate. Said information can
          be amended at any time without prior notice. Please note that we are
          not obligated to clarify any of the information related to our website
          and service, including pricing information, except as required by law.
        </p>
        <h4 className="text-xs font-bold text-light-black">Indemnification</h4>
        <p className="text-xs line-height-4 text-light-black mb-5">
          You agree to defend, indemnify, and hold us harmless, including our
          subsidiaries, affiliates, and all of our respective officers, agents,
          partners, and employees, from and against any loss, damage, liability,
          claim, or demand, including reasonable attorneys’ fees and expenses,
          made by any third party due to or arising out of: (1) your
          Contributions; (2) use of the website; (3) breach of these Terms of
          Service; (4) any breach of your representations and warranties set
          forth in these Terms of Service; (5) your violation of the rights of a
          third party, including but not limited to intellectual property
          rights; or (6) any overt harmful act toward any other user of the
          website with whom you connected via the website.
        </p>
        <p className="text-xs line-height-4 text-light-black mb-5">
          Notwithstanding the foregoing, we reserve the right, at your expense,
          to assume the exclusive defence and control of any matter for which
          you are required to indemnify us, and you agree to cooperate, at your
          expense, with our defence of such claims. We will use reasonable
          efforts to notify you of any such claim, action, or proceeding which
          is subject to this indemnification upon becoming aware of it.
        </p>
        <h4 className="text-xs font-bold text-light-black">
          Right to Terminate
        </h4>
        <p className="text-xs line-height-4 text-light-black mb-5">
          We may suspend or terminate your right to use our website and
          terminate these Terms of Service immediately upon written notice to
          you for any breach of these Terms of Service.
        </p>
        <h4 className="text-xs font-bold text-light-black">Severance</h4>
        <p className="text-xs line-height-4 text-light-black mb-5">
          Any term of these Terms of Service which is wholly or partially void
          or unenforceable is severed to the extent that it is void or
          unenforceable. The validity of the remainder of these Terms of Service
          is not affected.
        </p>
        <h4 className="text-xs font-bold text-light-black">Entire Agreement</h4>
        <p className="text-xs line-height-4 text-light-black mb-5">
          These Terms of Service and any policies or operating rules posted by
          us on the website or with respect to the website constitute the entire
          agreement and understanding between you and us. Our failure to
          exercise or enforce any right or provision of these Terms of Service
          shall not operate as a waiver of such right or provision. These Terms
          of Service operate to the fullest extent permissible by law. We may
          assign any or all of our rights and obligations to others at any time.
          We shall not be responsible or liable for any loss, damage, delay, or
          failure to act caused by any cause beyond our reasonable control.
        </p>
        <p className="text-xs line-height-4 text-light-black mb-5">
          If any provision or part of a provision of these Terms of Service is
          determined to be unlawful, void, or unenforceable, that provision or
          part of the provision is deemed severable from these Terms of Service
          and does not affect the validity and enforceability of any remaining
          provisions. There is no joint venture, partnership, employment, or
          agency relationship created between you and us as a result of these
          Terms of Service or use of the website. You agree that these Terms of
          Service will not be construed against us by virtue of having drafted
          them. You hereby waive any and all defences you may have based on the
          electronic form of these Terms of Service and the lack of signing by
          the parties hereto to execute these Terms of Service.
        </p>
        <h4 className="text-xs font-bold text-light-black">Governing Law</h4>
        <p className="text-xs line-height-4 text-light-black mb-5">
          These Terms of Service are governed by and construed in accordance
          with the laws of the United Kingdom. You irrevocably submit to the
          exclusive jurisdiction of the courts in that location.
        </p>
      </div>
    </>
  );
}
