import React, { useEffect, useState } from "react";
import { TabView, TabPanel } from "primereact/tabview";
import { ContainerBox } from "../../shared/ContainerBox";
import { CustomCalenderInput, CustomInput, CustomInputNumber, CustomTextArea } from "../../components/input/AllInput";
import { CardComponent } from "../../components/cards/card";
import ButtonComponent from "../../components/buttons/button";
import About from "./About";
import Location from "./Location";
import Photos from "./Photos";
import { AboutIndex } from "./AboutIndex";
import { useLocation, useParams } from "react-router-dom";
import TabPanelVendor from "../overview-vendor/TabPanelVendor";
import { quoteDataAction } from "../../redux/actions/bussinessInformationAction";
import { useDispatch, useSelector } from "react-redux";
import { isAuthenticated } from "../../services/authService";
import { Carousel } from "primereact/carousel";
import { types } from "../../redux/types";
export default function VendorProfile({ isLoaded }) {
    const location = useLocation();

    const data = location?.state;
    const {
        vendorData,
        setBusinessFilter,
        businessFilter,
        photosFilter,
        videosFilter,
        preWeddingFilter,
        reviewData,
        setReviewData,
        handleChange,
        handleFileChange,
        handleSubmit,
        quoteData,
        setQuoteData,
        handleChangeQuote,
        businessImage,
        handleQuoteSubmit,
        handleTentative,
        id,
        tentativeHeighlight,
        loginPopup,
        setLoginPopup,
    } = AboutIndex();
    const dispatch = useDispatch();
    const [activeIndex, setActiveIndex] = useState(null);
    const [sortValue, setSortValue] = useState();
    const { toastInfo } = useSelector((state) => state?.toast);

    console.log(toastInfo, "toast");
    useEffect(() => {
        return () => {
            dispatch({ type: types.SHOW_TOAST, payload: {} });
        };
    }, []);
    useEffect(() => {
        let params;
        if (sortValue === "old") {
            params = {
                sortOrder: "DESC",
            };
        } else {
            params = {
                sortValue: "ASC",
            };
        }
        if (!isAuthenticated) {
            dispatch(quoteDataAction(params));
        }
    }, [sortValue]);
    const sort = ["old", "New"];
    useEffect(() => {
        setActiveIndex(null);
        if (data?.naviagte === true) {
            setActiveIndex(null);
        }
    }, [data]);
    const productTemplate = (businessImage) => {
        return (
            <>
                <div className="grid">
                    <div className="col-12 md:col-6 m-auto">
                        <img src={businessImage} alt="" className="w-full " style={{ objectFit: "cover" }} height={600} />
                    </div>
                </div>
            </>
        );
    };
    return (
        <>
            {data?.navigate === true ? (
                <TabPanelVendor
                    activeIndex={activeIndex}
                    setActiveIndex={setActiveIndex}
                    quoteData={quoteData}
                    sort={sort}
                    sortValue={sortValue}
                    setSortValue={setSortValue}
                    isLoaded={isLoaded}
                />
            ) : null}
            {activeIndex === null ? (
                <div className="business-container">
                    {vendorData?.images?.length > 0 ? (
                        <>
                            <div className="">
                                <div className="card">
                                    <Carousel value={businessImage} numVisible={1} numScroll={1} itemTemplate={productTemplate} className="custom-carousel" />
                                </div>
                            </div>
                        </>
                    ) : (
                        ""
                    )}

                    <ContainerBox containerType="">
                        <div className="grid p-0 m-0 flex justify-content-between">
                            <div className="col-12 md:col-12 lg:col-12 p-0 m-0">
                                <TabView>
                                    <TabPanel header="About">
                                        <About
                                            vendorData={vendorData}
                                            reviewData={reviewData}
                                            setReviewData={setReviewData}
                                            handleChange={handleChange}
                                            handleFileChange={handleFileChange}
                                            handleSubmit={handleSubmit}
                                            quoteData={quoteData}
                                            setQuoteData={setQuoteData}
                                            loginPopup={loginPopup}
                                            setLoginPopup={setLoginPopup}
                                        />
                                    </TabPanel>

                                    <TabPanel header="Photos">
                                        <Photos
                                            vendorData={vendorData}
                                            setBusinessFilter={setBusinessFilter}
                                            businessFilter={businessFilter}
                                            photosFilter={photosFilter}
                                            videosFilter={videosFilter}
                                            preWeddingFilter={preWeddingFilter}
                                        />
                                    </TabPanel>

                                    <TabPanel header="Location">
                                        <Location vendorData={vendorData} />
                                    </TabPanel>
                                </TabView>
                            </div>{" "}
                        </div>
                        {/* <div className="col-12 md:col-12 lg:col-4 py-8">
                <CardComponent className="shadow-1">
                  <h1 className="merriweather text-2xl text-dark-gray font-bold mb-5">
                    Get a Quote
                  </h1>
                  <CustomInput
                    className="border-noround mb-3"
                    placeholder="Name "
                    col={12}
                    name="name"
                    value={quoteData?.name}
                    onChange={handleChangeQuote}
                  />
                  <CustomInput
                    className="border-noround mb-3"
                    placeholder="Email "
                    col={12}
                    name="email"
                    value={quoteData?.email}
                    onChange={handleChangeQuote}
                  />
                  <CustomInputNumber
                    inputClassName="border-noround w-full mb-3"
                    className="border-noround w-full "
                    placeholder="Phone number"
                    col={12}
                    name="phoneNumber"
                    value={quoteData?.phoneNumber}
                    onChange={handleChangeQuote}
                  />
                  <CustomCalenderInput
                    inputClassName="border-noround w-full mb-3"
                    placeholder="Event date"
                    name="eventDate"
                    col={12}
                    value={quoteData?.eventDate}
                    onChange={handleChangeQuote}
                  ></CustomCalenderInput>
                  <CustomInputNumber
                    inputClassName="border-noround w-full mb-3"
                    className="border-noround w-full "
                    placeholder="Number of guests"
                    col={12}
                    name="numberOfGuests"
                    value={quoteData?.numberOfGuests}
                    onChange={handleChangeQuote}
                  />
                  <CustomTextArea
                    className="border-noround w-full"
                    placeholder="Summarise your dream wedding and how this vendor should help you"
                    rows={4}
                    col={12}
                    name="eventSummarise"
                    value={quoteData?.eventSummarise}
                    onChange={handleChangeQuote}
                  />
                  <ButtonComponent
                    label="Get a Quote"
                    className="text-white font-semibold  w-full text-sm"
                    onClick={handleQuoteSubmit}
                  ></ButtonComponent>
                  <div className="col-12">
                    <p
                      className="sub-heading social-divider relative text-center font-normal"
                      style={{ color: "#9A9A9A" }}
                    >
                      or
                    </p>
                  </div>
                  <ButtonComponent
                    label={
                      tentativeHeighlight === true
                        ? "Remove From Tentative"
                        : "Add As Tentative"
                    }
                    className={
                      tentativeHeighlight === true
                        ? "text-light w-full bg-transparent border-pink-color text-sm font-semibold"
                        : "text-light w-full bg-transparent border-300 text-sm font-semibold "
                    }
                    onClick={(e) => handleTentative(id)}
                  ></ButtonComponent>
                </CardComponent>
              </div> */}
                    </ContainerBox>
                </div>
            ) : null}
        </>
    );
}
