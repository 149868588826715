import React, { useState, useEffect } from "react";
import { TabView, TabPanel } from "primereact/tabview";
import { ContainerBox } from "../../shared/ContainerBox";
import OverviewContent from "./OverviewContent";
import StoreFrontContent from "./StoreFrontContent";
import InquiriesContent from "./InquiriesContent";

const TabPanelVendor = ({
  activeIndex,
  setActiveIndex,
  quoteData,
  sort,
  sortValue,
  setSortValue,
  isLoaded,
  getChatData,
  selectedYear,
  setSelectedYear,
}) => {
  return (
    <div className="overview-vendor-container mt-5">
      <ContainerBox containerType="">
        <div className="grid  flex ">
          <div className="col-12 md:col-12 lg:col-12">
            <TabView
              activeIndex={activeIndex}
              onTabChange={(e) => setActiveIndex(e.index)}
            >
              <TabPanel header="Overview">
                <div className="Overview-content">
                  <OverviewContent
                    setActiveIndex={setActiveIndex}
                    quoteData={quoteData}
                    sort={sort}
                    sortValue={sortValue}
                    setSortValue={setSortValue}
                    getChatData={getChatData}
                    selectedYear={selectedYear}
                    setSelectedYear={setSelectedYear}
                  />
                </div>
              </TabPanel>

              <TabPanel header="Storefront">
                <div className="Storefront-content">
                  <StoreFrontContent isLoaded={isLoaded} />
                </div>
              </TabPanel>

              <TabPanel header="Inquiries">
                <div className="Inquiries-content">
                  <InquiriesContent
                    sort={sort}
                    sortValue={sortValue}
                    setSortValue={setSortValue}
                  />
                </div>
              </TabPanel>
            </TabView>
          </div>
        </div>
      </ContainerBox>
    </div>
  );
};

export default TabPanelVendor;
