import React from "react";

import { Button } from "primereact/button";

export default function ButtonComponent({
    onClick,
    label,
    loading,
    onChange,
    style,
    icon,
    iconPos,
    className,
    labelclassName,

    ...props
}) {
    return (
        <Button
            label={label}
            style={style}
            className={`main-button text-sm font-medium ${className}`}
            onClick={onClick}
            icon={icon}
            loading={loading}
            onChange={onChange}
            {...props}
        />
    );
}
export function ButtonOutlined({ onClick, label, Link, className, labelclassName, loading, ...props }) {
    return (
        <Button
            label={label}
            labelclassName={labelclassName}
            className={`main-button text-main-color bg-transparent border-outlined-button font-medium text-sm ${className}`}
            onClick={onClick}
            loading={loading}
            Link={Link}
            {...props}
        />
    );
}
