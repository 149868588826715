import React from "react";
import VendorTopbar from "./VendorTopbar";
import { Footer } from "./Footer";

export const VendorLayout = ({ children }) => {
  return (
    <div>
      <VendorTopbar />
      <div className="min-h-screen">{children}</div>
      <Footer />
    </div>
  );
};
