import { types } from "../types";

const initialState = {
  isLoading: false,
};

const loaderReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SHOW_LOADER:
      return { isLoading: true };
    case types.HIDE_LOADER:
      return setTimeout(
        () => ({
          isLoading: false,
        }),
        2000
      );

    default:
      return state;
  }
};

export default loaderReducer;
