import { types } from "../types";
const initialState = {
    socialReducer: {}
}

export const socailLoginReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.SOCIAL_LOGIN:
            return {
                ...state,
                socialReducer: action.payload
            }
        default: {
            return state;
        }
    }
}