import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./assets/styles/theme/theme.css";
import { Provider } from "react-redux";
import store, { persistor } from "./redux/index";
import { PersistGate } from "redux-persist/integration/react";
import { GoogleOAuthProvider } from "@react-oauth/google";
//core
import "primereact/resources/primereact.min.css";

import "primeflex/primeflex.css";
import { BrowserRouter } from "react-router-dom";

// import 'primeicons/primeicons.css';

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <GoogleOAuthProvider clientId="229703785357-gfv3430sija9bd94lff535j69bg22qkr.apps.googleusercontent.com">
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <BrowserRouter>
                    <App />
                </BrowserRouter>
            </PersistGate>
        </Provider>
    </GoogleOAuthProvider>
);
