import React from "react";
import { TabView, TabPanel } from "primereact/tabview";
import { ContainerBox } from "../../shared/ContainerBox";
import VendorContent from "./VendorContent";
import { ProfileLayout } from "../../layout/ProfileLayout";
import GuestsContent from "./GuestsContent";
import BudgetContent from "./BudgetContent";
import WeddingWebsite from "../Host/WeddingWebsite/WeddingWebsite";
import SeatingPlanner from "./SeatingPlanner";

export default function Vendor() {
  return (
    <>
      <ProfileLayout>
        <div className="wedding-venues-container mt-5 mb-7">
          <ContainerBox containerType="">
            <div className="grid p-0 m-0 flex ">
              <div className="col-12 md:col-12 lg:col-12">
                <TabView>
                  <TabPanel header="Vendors">
                    <VendorContent />
                  </TabPanel>

                  <TabPanel header="Guests">
                    <GuestsContent />
                  </TabPanel>

                  <TabPanel header="Wedding Website">
                    <WeddingWebsite />
                  </TabPanel>

                  <TabPanel header="Seating Chart">
                    <SeatingPlanner />
                  </TabPanel>

                  <TabPanel header="Budget Planner">
                    <BudgetContent />
                  </TabPanel>
                </TabView>
              </div>
            </div>
          </ContainerBox>
        </div>
      </ProfileLayout>
    </>
  );
}
