import React from "react";
import ReactApexChart from "react-apexcharts";

export default function ChartData({ budgetVendorData }) {
  let data = budgetVendorData?.budgetList?.filter(
    (item) => item.finalTotal !== 0
  );
  const series = data?.map((vendor) => vendor.finalTotal) || [];
  const options = {
    chart: {
      width: "100%",
      type: "pie",
    },
    labels: data?.map((vendor) => vendor.name),
    legend: {
      show: true,
      fontSize: 14,
      markers: {
        width: 14,
        height: 14,
        radius: 0,
      },
      itemMargin: {
        horizontal: 10,
        vertical: 5,
      },
    },
    tooltip: {
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        return (
          '<div class="p-2">' +
          w.globals.labels[seriesIndex] +
          "- " +
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "GBP",
          }).format(series[seriesIndex]) +
          "</div>"
        );
      },
    },
    responsive: [
      {
        breakpoint: 576,
        options: {
          chart: {
            width: 400,
            height: 800,
          },
        },
      },
    ],
  };

  return (
    <div
      className="card flex justify-content-center"
      style={{ minHeight: "250px" }}
    >
      <ReactApexChart
        options={options}
        series={series}
        type="pie"
        width={500}
        height={350}
      />
    </div>
  );
}
// graph
