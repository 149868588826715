import ProfileImg from "../../assets/icons/profile.png";
import ServiceImg from "../../assets/icons/serviceimg.png";
import CoupleImg from "../../assets/icons/couple.png";
import GrowImg from "../../assets/icons/grow.png";
import ShowcaseImg from "../../assets/icons/showcase.png";
import weedingImg from "../../assets/icons/weeding.png";
import ChecklistImg from "../../assets/icons/checklist.png";
import CollabrateImg from "../../assets/icons/collabrate.png";
import budgetImg from "../../assets/icons/budget.png";
import CustomizeImg from "../../assets/icons/customize.png";
import ManageSupplier from "../../assets/icons/Manage Supplier.png";
import guestList from "../../assets/icons/Guest list.png";
import seatingChart from "../../assets/icons/seating chart.png";
import WeddingInvitation from "../../assets/icons/wedding inviation.png";
import BudgetPlanner from "../../assets/icons/budget planner.png";
import WeddingWebsite from "../../assets/icons/Cutom wedding website.png";
const WorkContent = () => {
  const ForVendorContent = [
    {
      img: ProfileImg,
      title: "Create Your Vendor Profile",
    },
    {
      img: ServiceImg,
      title: "Receive enquiries",
    },
    {
      img: CoupleImg,
      title: "Connect with Couples",
    },
    {
      img: GrowImg,
      title: " Grow Your Business",
    },
    {
      img: ShowcaseImg,
      title: "Showcase Your Work",
    },
  ];
  const ForHostContent = [
    {
      img: weedingImg,
      title: "Explore Venues and Suppliers",
    },
    {
      img: ChecklistImg,
      title: "Plan your budget",
    },
    {
      img: CollabrateImg,
      title: "Wedding Website with QR Code",
    },
    {
      img: budgetImg,
      title: "Manage your guest list",
    },
    {
      img: CustomizeImg,
      title: "Plan your guest’s seats",
    },
  ];
  const AboutContent = [
    {
      img: ManageSupplier,
      title: "Your checklist to stay on top of all your wedding tasks",
    },
    {
      img: guestList,
      title: "Keep track of your RSVPs with our guest list tool",
    },
    {
      img: seatingChart,
      title: "Use our seating chart to organise all of your guests",
    },
    {
      img: WeddingInvitation,
      title: "Manage all your favourite supplier in one place",
    },
    {
      img: BudgetPlanner,
      title: "Manage your wedding expenses with the budget planner",
    },
    { img: WeddingWebsite, title: "Create your own custom wedding website" },
  ];
  return { ForVendorContent, ForHostContent, AboutContent };
};
export default WorkContent;
