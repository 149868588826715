import React, { useRef, useState } from "react";
import { TabView, TabPanel } from "primereact/tabview";
import { InputText } from "primereact/inputtext";
import EditPencilIcon from "../../assets/icons/edit pencil.png";
import BookIcon from "../../assets/icons/book pen.png";
import { Dialog } from "primereact/dialog";
import { Password } from "primereact/password";
import ButtonComponent from "../../components/buttons/button";
import { Editor } from "primereact/editor";
import { Tag } from "primereact/tag";
import { RadioButton } from "primereact/radiobutton";
import { CustomDropDown, CustomInput, CustomInputNumber, CustomSearchBox } from "../../components/input/AllInput";
import GroupAlbumIcon from "../../assets/icons/group album.png";
import { CardComponent } from "../../components/cards/card";
import RefreshIcon from "../../assets/icons/refresh.png";
import { InputNumber } from "primereact/inputnumber";
import { StoreFrontContentData } from "./StoreFrontContentDataIndex";
import { StandaloneSearchBox } from "@react-google-maps/api";
import CustomImageInput from "./CustomeImageInput";
import CustomeBusinessPhoto from "./CustomeBusinessPhoto";
import CustomeBusinessVideo from "./CustomeBusinessVideo";
import CustomePreWedding from "./CustomePreWedding";
import { checkUserLoginType } from "../../services/authService";
import { MultiSelect } from "primereact/multiselect";
import SearchBox from "../../components/input/SearchBox";
import { types } from "../../redux/types";
import { useDispatch } from "react-redux";
export default function StoreFrontContent({ isLoaded }) {
    const [socailVisible, setSocialVisible] = useState(false);
    const [socialName, setSocialName] = useState("");
    const [tabview, setTabView] = useState("Photos");
    const dispatch = useDispatch();
    const {
        myProfileData,
        setAmenities,
        form,
        vendorCategories,
        handleFileChange,
        handleSubmit,
        inputRef,
        socialLogin,
        guestCapcity,
        handleChange,
        handlePlaceChanged,
        visible,
        setVisible,
        handleAmenities,
        removeAmenity,
        amenities,
        amenitiesData,
        vendorData,
        language,
        selectedLanguage,
        clearSearchValue,
        setSelectedLanguage,
        toastInfo,
    } = StoreFrontContentData();

    return isLoaded ? (
        <>
            <h3 className="merriweather text-dark-gray font-bold" style={{ fontSize: "22px" }}>
                Edit Your Business Information
            </h3>
            <div className="grid  mb-5 ">
                <div className="col-12 md:col-8 bg-white-gray border-round-lg p-3">
                    <div className="flex align-items-center bg-white-gray">
                        <img src={BookIcon} width={63} height={63} alt="" className="" />
                        <span className="text-base font-medium text-dark-gray ml-5">
                            Your storefront features information about your wedding services to attract and convert your audience of engaged couples
                            <span className="ml-5">
                                <p className="p-0 m-0 text-xs font-normal text-black-gray mt-2">
                                    Its important that all the information on your storefront is up to date and accurate
                                </p>
                            </span>
                        </span>
                    </div>
                </div>
            </div>

            <div className="storefront-content grid  flex ">
                <div className="col-12 md:col-12 lg:col-12">
                    <TabView>
                        <TabPanel header="About">
                            <div className="about-content lg:p-3 md:p-3 p-1 border-round-lg" style={{ background: "#EFF4FF" }}>
                                <h3 className="merriweather  text-dark-gray font-bold ml-2" style={{ fontSize: "22px" }}>
                                    Your login details
                                </h3>
                                <div className="grid mb-3 p-0 m-0">
                                    <CustomImageInput
                                        boxContainer="bussiness-image-container px-2"
                                        data={form}
                                        name="profileImage"
                                        editable={true}
                                        onFilesChange={handleFileChange}
                                    />
                                </div>

                                <div className="col-12 md:col-12  mb-2">
                                    <span className="p-input-icon-right lg:w-6 w-full">
                                        <CustomInput
                                            type="text"
                                            placeholder="Business Name"
                                            extraClassName="w-full"
                                            className="w-full"
                                            value={form?.businessName}
                                            onChange={handleChange}
                                            name="businessName"
                                            label="Business Name"
                                        />
                                    </span>
                                </div>
                            </div>
                            <h3 className="merriweather  text-dark-gray font-bold" style={{ fontSize: "22px" }}>
                                Describe your business and services
                            </h3>

                            <div className="lg:p-4 md:p-4 p-3 border-round-lg" style={{ background: "#EFF4FF" }}>
                                <p className="text-sm text-dark-gray font-medium">
                                    Share unique, descriptive information about your business in order to attract more couples and boost your visibility across
                                    top search engines. Please do not include any contact information in this section.
                                </p>
                                <p className="text-sm font-normal text-light-black">View an example of a storefront</p>
                                <div className="card">
                                    <Editor
                                        name="about"
                                        value={form?.about ? form?.about : myProfileData?.about}
                                        onTextChange={(e) => handleChange(e, "about")}
                                        style={{ height: "320px" }}
                                    />
                                </div>

                                <h3 className="merriweather text-base text-dark-gray font-bold">Amenities and Details</h3>

                                <div className="card flex flex-wrap  gap-2">
                                    {form?.amenities?.length > 0 &&
                                        form?.amenities?.map((item) => {
                                            return (
                                                <Tag className="text-xs px-1 pl-2 font-normal border-round-sm bg-blue-100 text-main-color border-1 border-main cursor-pointer">
                                                    <div className="flex align-items-center gap-2">
                                                        <span>{item?.name}</span>
                                                        <i className="pi pi-times-circle text-red-500" onClick={() => removeAmenity(item)} />
                                                    </div>
                                                </Tag>
                                            );
                                        })}

                                    <Tag
                                        onClick={() => setVisible(true)}
                                        value="+"
                                        className="text-xs px-3 font-normal cursor-pointer border-round-sm bg-white text-dark"
                                    ></Tag>
                                </div>
                            </div>

                            <h3 className="merriweather text-xl text-dark-gray font-bold">Contact and Business details</h3>

                            <div className="lg:p-3 md:p-3 p-2 border-round-lg " style={{ background: "#EFF4FF" }}>
                                <div className="grid p-0 m-0">
                                    <div className="col-12 md:col-12">
                                        <CustomDropDown
                                            label="Vendor type"
                                            placeholder="Photographer"
                                            dropdownOptions={vendorCategories}
                                            optionLabel="name"
                                            value={form?.vendorType}
                                            onChange={handleChange}
                                            name="vendorType"
                                            disabled={checkUserLoginType("google") ? false : true}
                                        />
                                    </div>
                                    {/* <div className="col-12 md:col-12">
                    <SearchBox
                      setSearchValue={handlePlaceChanged}
                      // searchValue={form?.addressLine1?.replace(/[,\s]+/g, " ")}
                      searchValue={form?.addressLine1}
                      label="Location"
                    />
                  </div> */}

                                    <div className="col-12 md:col-12">
                                        {vendorData?.name === "Venue" ? (
                                            <CustomDropDown
                                                label="Guest Capacity"
                                                placeholder="50-150"
                                                dropdownOptions={guestCapcity}
                                                optionLabel=""
                                                name="capacity"
                                                onChange={handleChange}
                                                value={form?.capacity}
                                            />
                                        ) : null}{" "}
                                        <div className="grid ">
                                            <div className="col-12 md:col-3">
                                                <CustomInputNumber
                                                    label="Min Budget"
                                                    placeholder="£1200- £20000"
                                                    optionLabel=""
                                                    className="w-full"
                                                    extraClassName="w-full"
                                                    name="minBudget"
                                                    onChange={handleChange}
                                                    value={form?.minBudget}
                                                />
                                            </div>
                                            <div className="col-12 md:col-3">
                                                <CustomInputNumber
                                                    label="Max Budget"
                                                    placeholder="£1200- £20000"
                                                    optionLabel=""
                                                    className="w-full"
                                                    extraClassName="w-full lg:ml-2"
                                                    name="maxBudget"
                                                    onChange={handleChange}
                                                    value={form?.maxBudget}
                                                />
                                            </div>
                                        </div>
                                        <CustomInput
                                            label="Website url"
                                            placeholder="http://www.labdrill.com"
                                            name="website"
                                            className="w-full"
                                            onChange={handleChange}
                                            value={form?.website}
                                        />
                                        <div className="col-12 md:col-6  ">
                                            <label className={`block mb-2 font-medium text-lg text-dark`}>Languages Spoken</label>
                                            <MultiSelect
                                                label="Languages Spoken"
                                                options={language}
                                                placeholder="English"
                                                className="w-full "
                                                value={selectedLanguage}
                                                onChange={(e) => setSelectedLanguage(e.value)}
                                                name="spoken"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 md:col-12">
                                        <h3 className="p-0 m-0 ml-1 merriweather text-base text-dark-gray font-bold">Social Accounts</h3>
                                    </div>
                                    <div className="card flex flex-wrap  gap-2 col-12 md:col-12 ml-1">
                                        {socialLogin?.length > 0 &&
                                            socialLogin?.map((item) => {
                                                return (
                                                    <Tag
                                                        value={item}
                                                        onClick={() => {
                                                            setSocialVisible(true);
                                                            setSocialName(item);
                                                        }}
                                                        className={
                                                            (item === "Facebook" && form?.facebook?.length > 0) ||
                                                            (item === "Instagram" && form?.instagram?.length > 0) ||
                                                            (item === "Twitter" && form?.twitter?.length > 0) ||
                                                            form?.twitter !== null
                                                                ? "text-xs px-3 font-normal border-round-sm bg-blue-100 text-main-color border-1 border-main cursor-pointer"
                                                                : "text-xs px-3 font-normal border-round-sm bg-white text-dark cursor-pointer"
                                                        }
                                                    ></Tag>
                                                );
                                            })}
                                    </div>
                                </div>
                            </div>

                            <ButtonComponent label="Save" className="mt-5" onClick={handleSubmit}></ButtonComponent>
                        </TabPanel>

                        <TabPanel header="Photos">
                            <div className="Photos-content">
                                <h3 className="merriweather text-dark-gray font-bold" style={{ fontSize: "22px" }}>
                                    Add 8 high quality photos
                                </h3>
                                <div className="grid  mb-5 ">
                                    <div className="col-12 md:col-8 bg-white-gray border-round-lg p-3">
                                        <div className="flex align-items-center bg-white-gray">
                                            <img src={GroupAlbumIcon} width={63} height={63} alt="" className="" />
                                            <span className="text-base font-medium text-dark-gray ml-5">
                                                The best way to showcase your products or services to couples is to include at least 8 photos to summarise your
                                                best work.Storefronts with more photos typically receive more enquiries.
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <p className="text-base font-bold text-black-gray"> Add Photos</p>
                                <div className="grid mb-5">
                                    <div className="col-12 md:col-2">
                                        <div className="flex align-items-center">
                                            <RadioButton
                                                inputId="ingredient1"
                                                name="Photos"
                                                value="Photos"
                                                onChange={(e) => setTabView(e.value)}
                                                checked={tabview === "Photos"}
                                            />
                                            <label htmlFor="ingredient1" className="ml-2">
                                                Photos
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-12 md:col-2">
                                        <div className="flex align-items-center">
                                            <RadioButton
                                                inputId="ingredient2"
                                                name="Videos"
                                                value="Videos"
                                                onChange={(e) => setTabView(e.value)}
                                                checked={tabview === "Videos"}
                                            />
                                            <label htmlFor="ingredient1" className="ml-2">
                                                Videos
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-12 md:col-8">
                                        <div className="flex align-items-center">
                                            <RadioButton
                                                inputId="ingredient3"
                                                name="Wedding"
                                                value="Wedding"
                                                onChange={(e) => setTabView(e.value)}
                                                checked={tabview === "Wedding"}
                                            />
                                            <label htmlFor="ingredient1" className="ml-2">
                                                Pre-Wedding
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className=" grid mb-5">
                                    <div className="col-12 md:col-12">
                                        <CardComponent className="shadow-1">
                                            {tabview === "Photos" ? (
                                                <CustomeBusinessPhoto
                                                    data={form}
                                                    name="images"
                                                    editable={true}
                                                    onFilesChange={handleFileChange}
                                                    multiple
                                                    removeable={true}
                                                />
                                            ) : tabview === "Videos" ? (
                                                <CustomeBusinessVideo
                                                    data={form}
                                                    name="videos"
                                                    editable={true}
                                                    onFilesChange={handleFileChange}
                                                    multiple
                                                    removeable={true}
                                                />
                                            ) : tabview === "Wedding" ? (
                                                <CustomePreWedding
                                                    data={form}
                                                    name="preWedding"
                                                    editable={true}
                                                    onFilesChange={handleFileChange}
                                                    multiple
                                                    removeable={true}
                                                />
                                            ) : (
                                                ""
                                            )}
                                        </CardComponent>
                                    </div>
                                </div>
                                <ButtonComponent label="Save" onClick={handleSubmit}></ButtonComponent>
                            </div>
                        </TabPanel>

                        <TabPanel header="Location">
                            <div className="Inquiries-content">
                                <h3 className="merriweather text-dark-gray font-bold" style={{ fontSize: "22px" }}>
                                    Add your address
                                </h3>
                                <div className="grid mb-5 ">
                                    <div className="col-12 md:col-8 bg-white-gray border-round-lg">
                                        <div className="flex align-items-center bg-white-gray">
                                            <img src={RefreshIcon} width={63} height={63} alt="" className="" />
                                            <span className="text-base font-medium text-dark-gray ml-5 p-0 m-0">
                                                Update your business location by adding your address.
                                                <span>
                                                    <p className="p-0 m-0 text-sm font-normal text-black-gray">
                                                        Address must contain the street name and any additional information (building number, floor, suite,
                                                        etc.). Example: Baker Street, 221B
                                                    </p>
                                                </span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <h3 className="merriweather text-base  text-black-gray font-bold">Location details</h3>
                                <div className="mb-5 p-2 border-round-lg " style={{ background: "#EFF4FF" }}>
                                    <div className="col-12 md:col-6  mb-2  ">
                                        <span className="p-input-icon-right w-full ">
                                            <label htmlFor="partner name" className="text-gray-light text-sm font-normal">
                                                Address Line 1
                                            </label>
                                            <SearchBox
                                                searchValue={form?.addressLine1}
                                                setSearchValue={handlePlaceChanged}
                                                clearSearchValue={clearSearchValue}
                                            />
                                        </span>
                                    </div>
                                    <div className="col-12 md:col-6 ">
                                        <span className="p-input-icon-right w-full mb-3 ">
                                            <label htmlFor="partner name" className="text-gray-light text-sm font-normal">
                                                Address Line 2
                                            </label>
                                            <InputText
                                                type="text"
                                                placeholder="Address Line 2"
                                                className="w-full"
                                                value={form?.addressLine2?.replace(/[,\s]+/g, " ")}
                                                name="addressLine2"
                                                onChange={handleChange}
                                                // disabled
                                            />
                                        </span>
                                    </div>
                                    <div className="col-12 md:col-6  mb-2 ">
                                        <span className="p-input-icon-right w-full ">
                                            <label htmlFor="partner name" className="text-gray-light text-sm font-normal">
                                                Post Code
                                            </label>
                                            <InputText
                                                type="text"
                                                placeholder="Postal Code"
                                                className="w-full"
                                                value={form?.postalCode?.replace(/[,\s]+/g, " ")}
                                                // disabled
                                                onChange={handleChange}
                                                name="postalCode"
                                                useGrouping={false}
                                            />
                                        </span>
                                    </div>
                                    <div className="col-12 md:col-6  mb-2 ">
                                        <span className="p-input-icon-right w-full ">
                                            <label htmlFor="partner name" className="text-gray-light text-sm font-normal">
                                                Country
                                            </label>
                                            <InputText
                                                type="text"
                                                placeholder="Toledo"
                                                className="w-full"
                                                value={form?.state}
                                                onChange={handleChange}
                                                name="state"
                                            />
                                        </span>
                                    </div>
                                    <div className="col-12 md:col-6  mb-2 ">
                                        <span className="p-input-icon-right w-full ">
                                            <label htmlFor="partner name" className="text-gray-light text-sm font-normal">
                                                City
                                            </label>
                                            <InputText
                                                type="text"
                                                placeholder="Pembroke Pines"
                                                className="w-full"
                                                value={form?.city}
                                                onChange={handleChange}
                                                name="city"
                                                // disabled
                                            />
                                        </span>
                                    </div>
                                </div>
                                <ButtonComponent label="Save" onClick={handleSubmit}></ButtonComponent>
                            </div>
                        </TabPanel>
                    </TabView>
                </div>
            </div>

            <Dialog
                className="lg:w-5 md:w-8 w-11"
                visible={socailVisible}
                style={{ width: "100vw" }}
                onHide={() => setSocialVisible(false)}
                draggable={false}
                resizable={false}
            >
                <h2 className="merriweather">Add Social Account</h2>
                <p className="m-0">
                    <CustomDropDown placeholder="Facebook" col={12} dropdownOptions={socialLogin} optionLabel="" value={socialName} disabled />
                    <div className="col-12 mb-2 ">
                        <label className="text-sm">Share link</label>
                    </div>
                    <CustomInput
                        col={12}
                        placeholder="www.untitledui.com/blog"
                        name={
                            socialName === "Facebook"
                                ? "facebook"
                                : socialName === "Google"
                                ? "google"
                                : socialName === "Instagram"
                                ? "instagram"
                                : socialName === "Twitter"
                                ? "twitter"
                                : ""
                        }
                        onChange={handleChange}
                        value={
                            socialName === "Facebook"
                                ? form?.facebook
                                : socialName === "Google"
                                ? form?.google
                                : socialName === "Instagram"
                                ? form?.instagram
                                : socialName === "Twitter"
                                ? form?.twitter
                                : ""
                        }
                    />
                </p>
                <ButtonComponent className="w-full" label="Add" onClick={() => setSocialVisible(false)} />
            </Dialog>

            <Dialog
                header={
                    <>
                        <h1 className="text-2xl font-bold  merriweather text-dark-gray ">Add Amenities</h1>
                    </>
                }
                visible={visible}
                style={{ width: "100vw" }}
                onHide={() => {
                    setVisible(false);
                    dispatch({ type: types.SHOW_TOAST, payload: {} });
                }}
                className="lg:w-4 md:w-8 w-11"
                draggable={false}
                resizable={false}
                blockScroll={true}
            >
                <p className="m-0">
                    {toastInfo?.summary && <div className="p-3 border border-2 alert-error mb-2 text-center">{toastInfo?.summary}</div>}
                    <CustomDropDown
                        col={12}
                        name="amenities"
                        placeholder="Choose Amenities"
                        dropdownOptions={amenitiesData.map((amenity) =>
                            form.amenities.filter((item) => item._id === amenity._id).length ? { ...amenity, disabled: true } : amenity
                        )}
                        optionLabel="name"
                        value={amenities}
                        onChange={(e) => setAmenities(e?.target?.value)}
                    />
                    <ButtonComponent
                        label="Save"
                        className="w-full"
                        onClick={(e) => {
                            handleAmenities(amenities);
                        }}
                    ></ButtonComponent>
                </p>
            </Dialog>
        </>
    ) : (
        ""
    );
}
