import React, { useState } from "react";
import Logo from "../assets/images/logopng.png";
import ButtonComponent, { ButtonOutlined } from "../components/buttons/button";
import hamburgerIcon from "../assets/icons/hamburger.png";
import { Link, useLocation } from "react-router-dom";
import { ContainerBox } from "../shared/ContainerBox";
import LayoutData from "./Layout";
import { useNavigate } from "react-router-dom";
import Constants from "../services/constant";
import { Sidebar } from "primereact/sidebar";
import { Accordion, AccordionTab } from "primereact/accordion";

export default function Topbar() {
  let location = useLocation();
  const activeLink = location?.pathname;
  const { menuItems } = LayoutData();
  const [isNavbarOpen, setIsNavbarOpen] = useState(false);

  const handleCheckboxChange = () => {
    setIsNavbarOpen(!isNavbarOpen);
  };

  return (
    <>
      <div className={`topbar_container relative z-3`}>
        <ContainerBox>
          <div className=" pb-3 pt-4 grid  p-0 m-0 align-items-center justify-content-between lg:justify-content-between container-inner">
            <div className=" md:col-3 p-0 m-0 lg:w-3  ">
              <Link to="/" className="no-underline">
                <img src={Logo} alt="" width={149} height={38}></img>
              </Link>
            </div>
            <input
              type="checkbox"
              checked={isNavbarOpen}
              onChange={handleCheckboxChange}
              name="menuToggle"
              id="menuToggle"
              hidden
            />
            <label htmlFor="menuToggle">
              <img
                src={hamburgerIcon}
                height={24}
                width={24}
                alt="HamburgerIcon"
              />
            </label>
            <div
              className={`col-12 md:col-7 menu-container ${
                location.pathname === "/" ? "home-nav" : "other-nav"
              } `}
            >
              <div className="col-12 md:col-3 p-0 m-0 flex justify-content-end ml-auto text-right align-items-center">
                <div></div>
                <label htmlFor="menuToggle" className="close-icon">
                  X
                </label>
              </div>
              <div className="grid  align-items-center justify-content-evenly text-gray-light ">
                {menuItems.map((item, key) => (
                  <div className="nav cursor-pointer submenu-show " key={key}>
                    <Link
                      to={item.link}
                      className="capitaltext no-underline text-light-black text-sm"
                      onClick={handleCheckboxChange}
                    >
                      <span className=" lg:flex md:flex align-items-center vendor-menu">
                        {item.name}&nbsp;&nbsp;{item.icon}
                      </span>
                    </Link>
                    {item.subMenu ? (
                      <ul
                        className="grid hidden z-2 menu-show  border-round-lg list-none mt-3"
                        key={key}
                      >
                        {item.subMenu?.map((subMenu, index) => {
                          return (
                            <li
                              key={index}
                              className={`lg:col-4 ${
                                subMenu.link === activeLink
                                  ? "p-2 side_menuitem text-dark active"
                                  : "p-2 side_menuitem text-dark "
                              }`}
                            >
                              <Link
                                to={`/explore/${subMenu._id}`}
                                className="no-underline side_menuitem text-dark open-sans flex align-items-center pt-2"
                                onClick={handleCheckboxChange}
                              >
                                <img
                                  src={Constants.HOST + subMenu.icon}
                                  alt=""
                                  width={24}
                                  height={24}
                                />
                                &nbsp;&nbsp;
                                {subMenu.name}
                              </Link>
                            </li>
                          );
                        })}
                      </ul>
                    ) : null}
                  </div>
                ))}

                {/* {token === null ? (
                  <>  */}

                {/* for mobile screen */}
                <div className="p-3 lg:hidden md:hidden vendor-list">
                  <Accordion activeIndex={1}>
                    <AccordionTab
                      header={
                        <>
                          <p className="text-light-black font-medium text-sm ">
                            Vendor
                          </p>
                        </>
                      }
                    >
                      <div className="">
                        {menuItems.map((item, key) => (
                          <div className="nav_link" key={key}>
                            {/* <Link to={item.link} className="capitaltext">
                          <span className=" lg:flex md:flex align-items-center">
                            {item.name}&nbsp;&nbsp;{item.icon}
                          </span>
                        </Link> */}
                            {item.subMenu ? (
                              <ul
                                className="grid bg-transparent menu-show  border-round-lg list-none p-0 m-0"
                                key={key}
                              >
                                {item.subMenu?.map((subMenu, index) => {
                                  return (
                                    <li
                                      key={index}
                                      className={`lg:col-4 ${
                                        subMenu.link === activeLink
                                          ? "p-2 side_menuitem text-dark active"
                                          : "p-2 side_menuitem text-dark "
                                      }`}
                                    >
                                      <div className="">
                                        <Link
                                          to={`/explore/${subMenu._id}`}
                                          className="no-underline side_menuitem text-dark open-sans flex align-items-center "
                                          onClick={handleCheckboxChange}
                                        >
                                          <img
                                            src={Constants.HOST + subMenu.icon}
                                            alt=""
                                            width={24}
                                            height={24}
                                          />
                                          &nbsp;&nbsp;
                                          <p className="ml-2 p-0 m-0 md:mb-2 md:mt-2">
                                            {subMenu.name}
                                          </p>
                                        </Link>
                                      </div>
                                    </li>
                                  );
                                })}
                              </ul>
                            ) : null}
                          </div>
                        ))}
                      </div>
                    </AccordionTab>
                  </Accordion>
                </div>

                <div className=" topbar-button flex justify-content-end">
                  <div className=" lg:mb-0  mb-5 lg:mt-0 md:mt-3  mt-3 ">
                    <Link to="/signup" className="no-underline  ">
                      <ButtonComponent
                        label="Register"
                        className="register-button bg-white text-main-color w-full "
                        onClick={handleCheckboxChange}
                      />
                    </Link>
                  </div>
                  <div className="  font-medium lg:ml-3 ">
                    <Link to="/login" className="no-underline ">
                      <ButtonComponent
                        label="Login"
                        className="  border-1 w-full"
                        onClick={handleCheckboxChange}
                      ></ButtonComponent>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ContainerBox>
      </div>
    </>
  );
}
