import { initializeApp } from "firebase/app";
import { getAuth, FacebookAuthProvider } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyD_FV4zbuttkA-rubPGQJEf0vTYlZQ6W4g",
  authDomain: "plantheknot-cff0d.firebaseapp.com",
  projectId: "plantheknot-cff0d",
  storageBucket: "plantheknot-cff0d.appspot.com",
  messagingSenderId: "194031069620",
  appId: "1:194031069620:web:b514598dce05ed1da92ae1",
  measurementId: "G-JG3N9RJ2TX",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const provider = new FacebookAuthProvider();

export { auth, provider };
