import React, { useEffect, useState } from "react";
import { ProfileLayout } from "../../../layout/ProfileLayout";
import { TabView, TabPanel } from "primereact/tabview";
import { ContainerBox } from "../../../shared/ContainerBox";
import ButtonComponent from "../../../components/buttons/button";
import WatchIcon from "../../../assets/icons/watch.png";
import WeddingContent from "./WeddingContent";
import ReceptionContent from "./ReceptionContent";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { imageprocessing } from "../../../utils/javascript";
import OtherIcon from "../../../assets/icons/other.png";
import { showAllTentativeAction } from "../../../redux/actions/BusinessProfile";
import Constants from "../../../services/constant";
import { myProfileAction } from "../../../redux/actions/profileAction";
import moment from "moment";
import { capitalizeFirstLetter } from "../../../utils/regex";
import { weddingReceptionAction } from "../../../redux/actions/bussinessInformationAction";
// import Vendor from "../vendor";

export default function Profile() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const hostProfileData = useSelector((state) => state?.hostProfile?.ProfileData);
    const showAllTentativeData = useSelector((state) => state?.showAllTentative?.showAllTentativeProfile);
    useEffect(() => {
        dispatch(showAllTentativeAction());
    }, []);
    const handleTentativeData = (id) => {
        dispatch(showAllTentativeAction(id, () => navigate(`/host/tentatives-vendors/${id}`)));
    };
    const calculateTimeRemaining = () => {
        let targetData;
        if (hostProfileData?.weddingDate !== null && hostProfileData?.receptionDate !== null) {
            targetData = hostProfileData?.weddingDate;
        } else if (hostProfileData?.weddingDate !== null && hostProfileData?.receptionDate === null) {
            targetData = hostProfileData?.weddingDate;
        } else {
            targetData = hostProfileData?.receptionDate;
        }
        const now = new Date().getTime();
        const targetTime = new Date(targetData).getTime();
        if (now >= targetTime) {
            const days = 0;
            const hours = 0;
            const minutes = 0;
            const seconds = 0;
            return { days, hours, minutes, seconds };
        } else {
            const timeDifference = targetTime - now;
            const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24)) || 0;
            const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)) || 0;
            const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60)) || 0;
            const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000) || 0;

            return { days, hours, minutes, seconds };
        }
    };

    const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setTimeRemaining(calculateTimeRemaining());
        }, 1000);

        return () => clearInterval(intervalId);
    }, [hostProfileData]);
    useEffect(() => {
        dispatch(myProfileAction());
        dispatch(weddingReceptionAction());
    }, []);
    const weddingReceptionCount = useSelector((state) => state?.WeddingReception?.weddingReceptionProfile);

    return (
        <>
            <div className="profile-container mt-1 md:mt-5 mb-7">
                <ContainerBox containerType="">
                    {/* Profile-section */}
                    <div className="grid p-0 m-0 flex justify-content-between mb-2 md:mb-5 align-items-center">
                        <div className="flex-order-2 md:flex-order-1 col-12 md:col-10 flex align-items-center">
                            <img
                                src={imageprocessing(hostProfileData?.profileImage)}
                                width={153}
                                height={153}
                                alt=""
                                className=" wedding-profile-img border-circle"
                            />
                            <span className="lg:ml-5  ml-3">
                                <div className=" ">
                                    <h1 className="merriweather text-2xl text-dark-gray font-bold ">
                                        {capitalizeFirstLetter(hostProfileData.businessName || hostProfileData.name)}
                                    </h1>
                                    {(hostProfileData?.city || hostProfileData?.weddingDate || hostProfileData?.receptionDate) && (
                                        <p className="text-xs font-normal p-0 m-0" style={{ color: "#49516F" }}>
                                            Getting married
                                            {hostProfileData?.weddingDate ? (
                                                <> on {moment(hostProfileData?.weddingDate).format("DD/MM/YYYY")}</>
                                            ) : hostProfileData?.receptionDate ? (
                                                <>on {moment(hostProfileData?.receptionDate).format("DD/MM/YYYY")}</>
                                            ) : null}
                                            {/* {hostProfileData?.weddingDate !== null && hostProfileData?.receptionDate !== null
                                                ? moment(hostProfileData?.weddingDate).format("DD/MM/YYYY")
                                                : hostProfileData?.weddingDate !== null && hostProfileData?.receptionDate === null
                                                ? moment(hostProfileData?.weddingDate).format("DD/MM/YYYY")
                                                : hostProfileData?.weddingDate === null && hostProfileData?.receptionDate !== null
                                                ? moment(hostProfileData?.receptionDate).format("DD/MM/YYYY")
                                                : null} */}
                                            {hostProfileData?.city ? <> in {hostProfileData?.city}</> : null}
                                        </p>
                                    )}
                                </div>
                            </span>
                        </div>

                        <div className="flex-order-1 md:flex-order-2 col-12 md:col-2 text-right md:text-center">
                            <ButtonComponent
                                label="Edit Profile"
                                className="bg-white border-main-color p-1 text-main-color text-base"
                                onClick={() => navigate("/host/edit-profile")}
                            ></ButtonComponent>
                        </div>
                    </div>

                    {/* profile-banner */}
                    <div className="grid profile-banner p-0 m-0 lg:mr-4 md:mr-4 mb-5 p-0 m-0 " style={{ height: "381px" }}>
                        <div className="col-12 md:col-12 text-center ">
                            <div className="grid flex justify-content-center align-items-center h-full ">
                                <div className="flex align-items-center justify-content-center text-center">
                                    <img src={WatchIcon} width={30} height={30} alt="" className="" />
                                    <span>
                                        <h1 className="merriweather text-xl md:text-2xl text-white font-bold ml-3 ">
                                            {hostProfileData?.weddingDate !== null && hostProfileData?.receptionDate !== null
                                                ? "Wedding"
                                                : hostProfileData?.weddingDate !== null && hostProfileData?.receptionDate === null
                                                ? "Wedding"
                                                : hostProfileData?.weddingDate === null && hostProfileData?.receptionDate !== null
                                                ? "Reception"
                                                : null}{" "}
                                            Countdown
                                        </h1>
                                    </span>
                                </div>

                                <div className="col-12 md:col-12 ">
                                    {/* for mobile-screen */}
                                    <div className="grid p-0 m-0 ml-0 mr-0 lg:justify-content-center lg:hidden ">
                                        <div className="col-12 md:col-3 lg:col-2">
                                            <div
                                                className="bg-white align-items-center p-2 flex justify-content-center"
                                                // style={{ width: "141px", height: "119px" }}
                                            >
                                                <div className="align-items-center">
                                                    <h3 className="p-0 m-0">{timeRemaining?.days}</h3>
                                                    <h3 className="p-0 m-0 text-base lg:text-lg">DAYS</h3>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-4 md:col-3 lg:col-2">
                                            <div className="bg-white p-2 align-items-center flex justify-content-center">
                                                <div className="align-items-center">
                                                    <h3 className="p-0 m-0">{timeRemaining?.hours}</h3>
                                                    <h3 className="p-0 m-0 text-base lg:text-lg">HOURS</h3>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-4 md:col-3 lg:col-2 ">
                                            <div className="bg-white p-2 align-items-center flex justify-content-center">
                                                <div className="align-items-center">
                                                    <h3 className="p-0 m-0">{timeRemaining?.minutes}</h3>
                                                    <h3 className="p-0 m-0 text-base lg:text-lg">MINUTES</h3>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-4 md:col-3 lg:col-2 ">
                                            <div className="bg-white p-2 align-items-center flex justify-content-center">
                                                <div className="align-items-center ">
                                                    <h3 className="p-0 m-0">{timeRemaining?.seconds}</h3>
                                                    <h3 className="p-0 m-0 text-base lg:text-lg">SECONDS</h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* for desktop-screen */}
                                    <div className=" text-2xl font-bold text-dark-gray lg:flex align-items-center justify-content-center hidden lg:block  ">
                                        <div className="flex align-items-center">
                                            <div
                                                className="bg-white align-items-center flex justify-content-center"
                                                style={{ width: "141px", height: "119px" }}
                                            >
                                                <div className="align-items-center">
                                                    <h3 className="text-2xl font-bold p-0 m-0">{timeRemaining?.days}</h3>
                                                    <h3 className=" text-2xl font-bold p-0 m-0">DAYS</h3>
                                                </div>
                                            </div>
                                        </div>

                                        <span className="ml-2">
                                            <div className="text-white px-6">
                                                <h2>:</h2>
                                            </div>
                                        </span>

                                        <span>
                                            <div className="flex align-items-center">
                                                <div
                                                    className="bg-white align-items-center flex justify-content-center"
                                                    style={{ width: "141px", height: "119px" }}
                                                >
                                                    <div className="align-items-center">
                                                        <h3 className="p-0 m-0 text-2xl font-bold">{timeRemaining?.hours}</h3>
                                                        <h3 className="p-0 m-0 text-2xl font-bold">HOURS</h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </span>

                                        <span className="ml-2">
                                            <div className="text-white px-6">
                                                <h2>:</h2>
                                            </div>
                                        </span>

                                        <span className="flex align-items-center">
                                            <div
                                                className="bg-white align-items-center flex justify-content-center"
                                                style={{ width: "141px", height: "119px" }}
                                            >
                                                <div className="align-items-center">
                                                    <h3 className="p-0 m-0 text-2xl font-bold">{timeRemaining?.minutes}</h3>
                                                    <h3 className="p-0 m-0 text-2xl font-bold">MINUTES</h3>
                                                </div>
                                            </div>
                                        </span>

                                        <span className="ml-2">
                                            <div className="text-white px-6">
                                                <h2>:</h2>
                                            </div>
                                        </span>

                                        <span className="flex align-items-center">
                                            <div
                                                className="bg-white align-items-center flex justify-content-center"
                                                style={{ width: "141px", height: "119px" }}
                                            >
                                                <div className="align-items-center ">
                                                    <h3 className="p-0 m-0 text-2xl font-bold">{timeRemaining?.seconds}</h3>
                                                    <h3 className="p-0 m-0 text-2xl font-bold">SECONDS</h3>
                                                </div>
                                            </div>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className=" wedding-details-content  ">
                        <TabView>
                            <TabPanel header="Wedding">
                                <WeddingContent weddingReceptionCount={weddingReceptionCount} />
                            </TabPanel>

                            <TabPanel header="Reception">
                                <ReceptionContent weddingReceptionCount={weddingReceptionCount} />
                            </TabPanel>
                        </TabView>

                        <div className="grid ">
                            {Array.isArray(showAllTentativeData) === true &&
                                showAllTentativeData?.map((item, index) => {
                                    return index <= 3 ? (
                                        <>
                                            {item?.vendors?.length > 0 ? (
                                                <>
                                                    <div
                                                        className=" vendor-content-list col-10 md:col-2 relative cursor-pointer"
                                                        style={{
                                                            width: "19.6667%",
                                                        }}
                                                    >
                                                        <div
                                                            className="border-1  border-round-xl text-center  flex align-items-center flex relative"
                                                            style={{
                                                                borderColor: "#CDD4F0",
                                                                height: "206px",
                                                                backgroundImage: `url(${Constants.HOST + item?.image})`,
                                                                backgroundSize: "cover",
                                                            }}
                                                            onClick={() => handleTentativeData(item?._id)}
                                                        >
                                                            <div className="overlay overlay_2  border-round-xl">
                                                                <div className="align-items-center w-full">
                                                                    <img
                                                                        src={Constants.HOST + item.icon}
                                                                        alt=""
                                                                        width={44}
                                                                        height={44}
                                                                        className="filtered-image"
                                                                    ></img>
                                                                    <p className="text-base font-semibold text-white p-0 m-0 mb-3">{item.name}</p>
                                                                    <span className="ml-4 text-lg font-semibold text-white ">{item?.vendorCount}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div
                                                            onClick={() => {
                                                                navigate(`/explore/${item?._id}`);
                                                                // setVendorId(item?._id);
                                                            }}
                                                            className=" add-vendor-menu cursor-pointer absolute "
                                                            style={{
                                                                borderColor: "#CDD4F0",
                                                                top: "145px",
                                                                left: "108px",
                                                            }}
                                                        >
                                                            <i
                                                                className="pi pi-plus p-1 bg-white text-dark"
                                                                style={{
                                                                    fontSize: "0.875rem",
                                                                    borderRadius: "15px",
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </>
                                            ) : (
                                                <>
                                                    <div className=" vendor-content-list col-10 md:col-2 relative" style={{ width: "19.6667%" }}>
                                                        <div
                                                            className="border-1  border-round-xl text-center  flex align-items-center flex"
                                                            style={{
                                                                borderColor: "#CDD4F0",
                                                                height: "206px",
                                                            }}
                                                        >
                                                            <div className="align-items-center w-full">
                                                                <img src={Constants.HOST + item.icon} alt="" width={44} height={44}></img>
                                                                <p className="text-base font-semibold text-light-black p-0 m-0 mb-3">{item.name}</p>
                                                                <button
                                                                    onClick={() => {
                                                                        navigate(`/explore/${item?._id}`);
                                                                        // setVendorId(item?._id);
                                                                    }}
                                                                    className=" border-1 p-2 px-5  border-round-md bg-transparent cursor-pointer"
                                                                    style={{ borderColor: "#CDD4F0" }}
                                                                >
                                                                    <i className="pi pi-plus text-light-black" style={{ fontSize: "1rem" }} />
                                                                    <span className="ml-2 text-base font-medium text-light-black ">Add</span>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                        </>
                                    ) : null;
                                })}
                            <>
                                <div className=" vendor-content-list col-10 md:col-2 relative" style={{ width: "19.6667%" }}>
                                    <div
                                        className="border-1  border-round-xl text-center  flex align-items-center flex"
                                        style={{
                                            borderColor: "#CDD4F0",
                                            height: "206px",
                                        }}
                                    >
                                        <div className="align-items-center w-full">
                                            <img src={OtherIcon} alt="" width={44} height={44}></img>
                                            <p className="text-base font-semibold text-light-black p-0 m-0 mb-3">Others</p>
                                            <button
                                                onClick={() => {
                                                    navigate("/host/tentative-vendor");
                                                    // setVendorId(item?._id);
                                                }}
                                                className=" border-1 p-2 px-5  border-round-md bg-transparent cursor-pointer"
                                                style={{ borderColor: "#CDD4F0" }}
                                            >
                                                <i className="pi pi-plus text-light-black" style={{ fontSize: "1rem" }} />
                                                <span className="ml-2 text-base font-medium text-light-black ">Add</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </>
                        </div>
                    </div>
                </ContainerBox>
            </div>
        </>
    );
}
