import ButtonComponent from "../../components/buttons/button";
import { CustomInput, CustomPassword } from "../../components/input/AllInput";
import GoogleImg from "../../assets/icons/google.png";
import FacebookImg from "../../assets/icons/facebook.png";
import { signInWithPopup } from "firebase/auth";
import { auth, provider } from "../login/facebooklogin/firebaseConfig";
import { useState } from "react";

export const EmailFeild = ({
  label,
  name,
  data,
  value,
  onChange,
  errorMessage,
  extraClassName,
  labeClassName,
  required,
  col,
  placeholder,
  inputClass,
  disabled = false,
  ...props
}) => {
  return (
    <CustomInput
      label={label}
      name={name}
      errorMessage={errorMessage}
      extraClassName={extraClassName}
      labeClassName={labeClassName}
      required={required}
      col={col || 6}
      inputClass={inputClass}
      value={value}
      onChange={onChange}
      placeholder="Email"
    />
  );
};
export const PasswordFeild = () => {
  return (
    <>
      <CustomPassword col={12} placeholder="Password" />
      <CustomPassword col={12} placeholder="Confirm Password" />
    </>
  );
};
export const SocialLogin = ({ googleLoginHandler }) => {
  const [facebookData, setFacebookData] = useState(false);

  const handleFacebookLogin = () => {
    signInWithPopup(auth, provider)
      .then((result) => {
        console.log("result", result);
        setFacebookData(result.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <>
      <div className="col-12">
        <p className="sub-heading social-divider relative text-center">or</p>
      </div>
      <div className="col-12 block md:flex justify-content-around lg:justify-content-between  ">
        <ButtonComponent
          className="border-light text-light-gray bg-transparent px-4 mb-2 w-full md:w-auto  "
          onClick={googleLoginHandler}
        >
          <img src={GoogleImg} alt="" height={24} width={24} className="" />{" "}
          &nbsp; &nbsp;Sign In with Google
        </ButtonComponent>
        <ButtonComponent
          onClick={() => handleFacebookLogin()}
          className="border-light text-light-gray bg-transparent px-4 mb-2 w-full md:w-auto "
        >
          <img src={FacebookImg} alt="" height={24} width={24} /> &nbsp;
          &nbsp;Sign In with Facebook
        </ButtonComponent>
      </div>
    </>
  );
};
