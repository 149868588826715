import { types } from "../types";
import Constants from "../../services/constant";
import api from "../../services/api";
import { showToast } from "./toastAction";
import { hideLoaderAction, showLoaderAction } from "./loaderAction";

export const vendorCategoryAction = (data, next) => async (dispatch) => {
  const res = await api("GET", Constants.END_POINT.VENDOR_CATEGORIES);
  if (res.success) {
    if (res.data) {
      dispatch({
        type: types.VENDOR_CATEGORIES,
        payload: res.data,
      });
    }
    // next()
    // dispatch(showToast({ severity: "success", summary: res.message }))
  } else {
    dispatch(showToast({ severity: "error", summary: res.message }));
  }
};
export const vendorListAction = (params, empty) => async (dispatch) => {
  dispatch(showLoaderAction());
  if (empty) {
    let data = [];
    dispatch({
      type: types.ALL_VENDORS,
      payload: data,
    });
    dispatch(hideLoaderAction());
  } else {
    const res = await api("GET", Constants.END_POINT.VENDOR_LIST, {}, params);
    if (res.success) {
      dispatch({
        type: types.ALL_VENDORS,
        payload: res.data,
      });
      dispatch(hideLoaderAction());
      return res;
      // next()
      // dispatch(showToast({ severity: "success", summary: res.message }))
    } else {
      dispatch(hideLoaderAction());
      dispatch(showToast({ severity: "error", summary: res.message }));
    }
  }
};
