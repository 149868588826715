import React from "react";
import ButtonComponent from "../components/buttons/button";
import { ContainerBox } from "../shared/ContainerBox";
import Logo from "../assets/images/logo.png";
import { Link } from "react-router-dom";
import { CustomInput } from "../components/input/AllInput";
import TwitterIcon from "../assets/icons/twitter.png";
import FcebookIcon from "../assets/icons/facebook.png";
import InstaIcon from "../assets/icons/instagram.png";
import { useNavigate } from "react-router-dom";
export const Footer = () => {
  const navigate = useNavigate();
  const gotoBtn = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };
  return (
    <div className="footer_container topbar_container bg-lightest-graywhite">
      <ContainerBox>
        <div className="grid justify-content-between py-2 m-0 footer_content mb-5 ">
          <div className="col-12 md:col-4 lg:col-4 ">
            <img
              src={Logo}
              alt=""
              width={118}
              height={29}
              className="md:mt-5 mt-5"
            />
            <p className="font-11 font-normal text-dark-gray">
              © 2023 Plan The Knot Ltd
            </p>
            <div className="footer-social-icon flex align-items-center ">
              {/* <span>
                <img
                  src={TwitterIcon}
                  alt=""
                  height={24}
                  width={24}
                  className=""
                />
              </span> */}
              <a
                href="https://www.facebook.com/profile.php?id=61551853231332"
                target="_blank"
              >
                <img
                  src={FcebookIcon}
                  alt=""
                  height={24}
                  width={24}
                  className="ml-2"
                />
              </a>
              <a href="https://www.instagram.com/plantheknot/" target="_blank">
                <img
                  src={InstaIcon}
                  alt=""
                  height={24}
                  width={24}
                  className="ml-2"
                />
              </a>
            </div>
          </div>
          <div className="footer-link col-12 md:col-2 lg:col-2 lg:mt-5  ">
            <div className="">
              <h1 className="font-semibold text-main-color text-xs ">
                Company
              </h1>
              <div className="text-lg font-medium text-black">
                <p className="nav_link my-2">
                  <Link to="/about" className="no-underline text-xs">
                    About
                  </Link>
                </p>
                <p className="nav_link my-2">
                  <Link to="" className="no-underline text-xs">
                    Vendors
                  </Link>
                </p>
                {/* <p className="nav_link my-2">
                  <Link to="/gallery" className="no-underline text-xs">
                    Gallery
                  </Link>
                </p> */}
              </div>
            </div>
          </div>
          <div className=" footer-link col-12 md:col-2 lg:col-3  lg:mt-5 ">
            <div className="">
              <h1 className="font-semibold text-main-color text-xs">Help</h1>
              <div className="text-lg font-medium text-black">
                <p className="nav_link my-2 text-xs">
                  <Link to="/contact" className="no-underline text-xs">
                    Contact us
                  </Link>
                </p>
                <p className="nav_link my-2">
                  <Link
                    to="/terms-and-condition"
                    className="no-underline text-xs"
                    onClick={() => gotoBtn()}
                  >
                    Terms & Conditions
                  </Link>
                </p>
                <p className="nav_link my-2">
                  <Link
                    to="/privacy-policy"
                    className="no-underline text-xs"
                    onClick={() => gotoBtn()}
                  >
                    Privacy Policy
                  </Link>
                </p>
              </div>
            </div>
          </div>
          <div className="footer-link  col-12 md:col-4 lg:col-3 lg:mt-5  ">
            <h1 className="font-semibold text-main-color text-xs">
              Newsletter
            </h1>
            <div className="grid mt-3 justify-content-center ">
              <CustomInput
                className=""
                placeholder="Enter your email address"
                extraClassName="w-full"
                col={12}
              />
              <div className="btn w-full ml-1 mr-1">
                <ButtonComponent
                  label="Subscribe Now"
                  className="lg:col-12  border-1 border-main w-full text-white font-medium subscribe-button "
                />
              </div>
            </div>
          </div>
        </div>
        <hr className="m-auto" style={{ width: "80%" }} />

        <div className=" footer-arrow-section grid p-0 m-0 align-items-center">
          <div className="col-12 md:col-11 text-center">
            <p className="footer-copy-right text-xs font-normal text-dark-gray ">
              © Copyright 2023, All Rights Reserved by PlanTheKnot
            </p>
          </div>
        </div>
      </ContainerBox>
    </div>
  );
};
