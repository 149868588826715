import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { publicVendorView, addReviewAction, addQuoteACtion, addTentativeAction } from "../../redux/actions/BusinessProfile";
import { Image } from "primereact/image";
import Constants from "../../services/constant";
import { useParams } from "react-router-dom";
import { getImageUrlData } from "../../utils/javascript";
import moment from "moment";
import { isAuthenticated } from "../../services/authService";
import { showToast } from "../../redux/actions/toastAction";
export const AboutIndex = () => {
    const dispatch = useDispatch();
    const { id } = useParams();
    const [subscriptionType, setSubscriptionType] = useState();
    const [businessFilter, setBusinessFilter] = useState("Photos ");
    const [businessImage, setBusinessImage] = useState("");
    const [tentativeHeighlight, setTentativeHeighlight] = useState(false);
    const [reviewData, setReviewData] = useState({
        reviewMessage: "",
        rating: "",
        images: [],
        spentOnVendor: "",
    });
    const [quoteData, setQuoteData] = useState({
        name: "",
        email: "",
        phoneNumber: "",
        eventDate: "",
        numberOfGuests: "",
        eventSummarise: "",
    });
    const [loginPopup, setLoginPopup] = useState(false);
    const [previousTentiveId, setPreviousTentiveId] = useState([]);
    const getTentativeId = useSelector((state) => state?.addTentative?.addTentativeProfile);
    const userData = useSelector((state) => state?.hostProfile?.ProfileData);
    useEffect(() => {
        dispatch(publicVendorView(id));
    }, []);
    useEffect(() => {
        if (getTentativeId.length > 0) {
            let allTentive = getTentativeId?.map((item) => {
                return item._id;
            });
            setPreviousTentiveId(allTentive);
        }
    }, [getTentativeId]);
    const vendorData = useSelector((state) => state?.publicVendorView?.vendorViewData);
    useEffect(() => {
        setSubscriptionType(vendorData?.subscriptionType);
    }, [subscriptionType]);

    const photosFilter = () => {
        return (
            <div className="grid">
                {vendorData?.images?.length > 0 ? (
                    vendorData?.images?.map((item, index) => {
                        return (
                            <>
                                <div className="col-12 md:col-3 business-photo">
                                    <Image src={Constants.HOST + item} alt="" height={246} width={201} preview />
                                </div>
                            </>
                        );
                    })
                ) : (
                    <div>No photos available for this vendor</div>
                )}
            </div>
        );
    };
    const videosFilter = () => {
        return (
            <div className="grid">
                {vendorData?.videos?.length > 0 ? (
                    vendorData?.videos?.map((item, index) => {
                        return (
                            <>
                                <div className="col-12 md:col-3">
                                    <video height={246} width={201} controls className="business-video" src={Constants.HOST + item}>
                                        <source src={Constants.HOST + item} type="video/mp4" />
                                    </video>
                                </div>
                            </>
                        );
                    })
                ) : (
                    <div>Apologies, business videos are currently unavailable.</div>
                )}
            </div>
        );
    };
    const preWeddingFilter = () => {
        return (
            <div className="grid">
                {vendorData?.preWedding?.length > 0 ? (
                    vendorData?.preWedding?.map((item, index) => {
                        return (
                            <>
                                <div className="col-12 md:col-3 ">
                                    {typeof item == "string" ? (
                                        item?.includes(".mp4" || ".webm" || ".ogg" || ".avi" || ".mov" || ".wmv") ? (
                                            <video height={246} width={201} controls className="business-video" src={Constants.HOST + item}>
                                                <source src={Constants.HOST + item} type="video/mp4" />
                                            </video>
                                        ) : (
                                            <Image
                                                className=" cover-img "
                                                style={{
                                                    height: "246px",
                                                    width: "154px",
                                                }}
                                                src={Constants.HOST + item}
                                                alt="Image"
                                                preview
                                                icon="pi pi-eye"
                                                width="100%"
                                                height="140px"
                                            />
                                        )
                                    ) : item?.type?.includes("video") ? (
                                        <video height="190" className="w-full " controls src={Constants.HOST + item}>
                                            <source src={Constants.HOST + item} type="video/mp4" />
                                        </video>
                                    ) : (
                                        <Image
                                            className=" cover-img "
                                            style={{
                                                height: "188px",
                                                width: "154px",
                                            }}
                                            src={Constants.HOST + item}
                                            alt="Image"
                                            preview
                                            icon="pi pi-eye"
                                            width="100%"
                                            height="140px"
                                        />
                                    )}
                                </div>
                            </>
                        );
                    })
                ) : (
                    <div>Sorry, no pre-wedding photos or videos available for businesses at the moment.</div>
                )}
            </div>
        );
    };
    const handleChange = (e) => {
        const { name, value } = e.target;
        setReviewData({
            ...reviewData,
            [name]: value,
        });
    };
    const handleChangeQuote = (e) => {
        const { name, value } = e.target;
        setQuoteData({
            ...quoteData,
            [name]: value,
        });
    };
    const handleFileChange = ({ name, value }) => {
        setReviewData((prev) => {
            return {
                ...prev,
                [name]: value,
            };
        });
    };
    const token = localStorage.getItem("authToken");
    const handleSubmit = () => {
        if (token !== null) {
            dispatch(addReviewAction(id, reviewData)).then((res) => {
                console.log(res);
                if (res.success) {
                    dispatch(publicVendorView(id));
                    setReviewData({
                        reviewMessage: "",
                        rating: "",
                        images: [],
                        spentOnVendor: "",
                    });
                } else {
                    console.log(res);
                    dispatch(showToast({ severity: "error", summary: res.message }));
                }
            });
        } else {
            setLoginPopup(true);
        }
    };
    const handleQuoteSubmit = () => {
        let payload = {
            vendor: id,
            name: quoteData?.name,
            email: quoteData?.email,
            phoneNumber: quoteData?.phoneNumber,
            eventDate: moment(quoteData?.eventDate).toISOString(),
            numberOfGuests: quoteData?.numberOfGuests,
            eventSummarise: quoteData?.eventSummarise,
        };
        if (token !== null) {
            dispatch(addQuoteACtion(payload)).then((res) => {
                if (res?.success === true) {
                    setQuoteData({
                        name: "",
                        email: "",
                        phoneNumber: "",
                        eventDate: "",
                        numberOfGuests: "",
                        eventSummarise: "",
                    });
                }
            });
        } else {
            setLoginPopup(true);
        }
    };
    const handleTentative = (id) => {
        if (!isAuthenticated()) {
            setLoginPopup(true);
        } else {
            let payload = {
                vendors: [...previousTentiveId, id],
            };
            dispatch(addTentativeAction(payload));
            setTentativeHeighlight(true);
        }
    };
    const removeTentative = (id) => {
        if (!isAuthenticated()) {
            setLoginPopup(true);
        } else {
            let payload = {
                vendors: previousTentiveId.filter((vendor) => vendor !== id),
            };
            dispatch(addTentativeAction(payload));
            setTentativeHeighlight(false);
        }
    };

    useEffect(() => {
        if (vendorData?.images?.length > 0) {
            let data = getImageUrlData(vendorData?.images);
            setBusinessImage(data);
        }
    }, [vendorData?.images]);

    useEffect(() => {
        if (userData?.vendors) {
            setTentativeHeighlight(userData?.vendors?.includes(id));
        } else setTentativeHeighlight(false);
    }, [userData]);
    return {
        subscriptionType,
        vendorData,
        setBusinessFilter,
        businessFilter,
        photosFilter,
        videosFilter,
        preWeddingFilter,
        reviewData,
        setReviewData,
        handleChange,
        handleFileChange,
        handleSubmit,
        setQuoteData,
        quoteData,
        handleChangeQuote,
        handleQuoteSubmit,
        businessImage,
        removeTentative,
        handleTentative,
        getTentativeId,
        previousTentiveId,
        id,
        tentativeHeighlight,
        loginPopup,
        setLoginPopup,
    };
};
